import { IconButton, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import upload from '../../../assets/upload.png';
import deletes from '../../../assets/trash.png';
import { putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import { addnewLineItems, submitEditLineItem } from "../../../store/Actions/dashboard.action";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { FiTrash } from "react-icons/fi";
const AddLineItems = (props) => {
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const [lineItem, setLineItem] = useState(props?.value || {
        "name": "",
        "price": 0,
        "description": "",
        "quantity": "1",
        "tax": "0",
        "taxName": "no Tax",
        "optional": false,
        "unitPrice": '',
        
    })
    const [addImage, setImage] = useState(props?.value?.picUrl || '')
    const [addImageData, setImagedata] = useState(props?.value?.picUrl || '')
    const [addEditImageData, setEditImagedata] = useState('')
    const [isNew, setNew] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const handleFileChange = (event) => {

        const selectedFile = event.target.files[0];
        // Handle the selected file, e.g., upload it
        console.log('Selected file:', selectedFile.path);
        const fileURL = URL.createObjectURL(selectedFile)
        console.log('Selected file:', fileURL);
        setImage(fileURL)
        setImagedata(selectedFile)
        setEditImagedata(selectedFile)
    };

    const onSubmit = async () => {
        if (props?.value?.edit) {

            const res = await dispatch(submitEditLineItem(addImageData, { ...lineItem, "itemId": props?.value?._id, picUrl: addEditImageData ? '' : lineItem?.picUrl }))
            if (res.status) {
                props?.onSubmitsw();
                props?.setModalOpenInvoice(false)
            }
            else {
                setError("Image size too large. The image size needs to be under 2 MB.")
            }
        }
        else {
            const res = await dispatch(addnewLineItems(addImageData, lineItem))
            if (res.status) {
                props?.onSubmitsw();
                props?.setModalOpenInvoice(false)
            }
            else {
                setError("Image size too large. The image size needs to be under 2 MB.")
            }
        }


    }
    return (
        <>
            <Flexbox >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'start', }}>{props?.value?.edit ? "Edit product" : "Add product"}</h2>
                    <h3> {`$${putCommaInvalue(totalprice(lineItem?.price, lineItem?.quantity || 1, lineItem?.tax || 0)?.toFixed(2))}`}</h3>
                </div>

                <div style={{ marginBottom: '10px', width: '95%' }} >
                    <Label>Name</Label>
                    <Inputbox
                        type="text"
                        placeholder='item name'
                        style={{ width: '100%' }}
                        value={lineItem?.name}
                        onChange={(e) => setLineItem({ ...lineItem, name: e.target.value })}
                    />
                </div>
                <div style={{ marginBottom: '10px', width: '93.5%' }} >
                    <Label>Description</Label>
                    <InputText
                        placeholder="Description"
                        type="text"
                        value={lineItem?.description}
                        onChange={(e) => setLineItem({ ...lineItem, description: e.target.value })}

                    />
                </div>
                <div style={{ display: 'flex', width: '95%', marginBottom: '10px' }}>

                    <div style={{ width: '31%' }}>
                        <Label>Quantity</Label>
                        <CustomUrl
                            value={lineItem?.quantity}
                            onChange={(e) => setLineItem({ ...lineItem, quantity: e.target.value })}
                            placeholder='Quantity' type='number' />
                    </div>
                    <div style={{ marginLeft: '25px', width: '31%' }}>
                        <Label>Price</Label>
                        <CustomUrl type='number' placeholder='Price'
                            value={lineItem?.price}
                            onChange={(e) => setLineItem({ ...lineItem, price: e.target.value })}
                        />
                    </div>
                    <div style={{ marginLeft: '25px', width: '31%' }}>
                        <Label>Cost</Label>
                        <CustomUrl type='number' placeholder='Cost'
                            value={lineItem?.unitPrice}
                            onChange={(e) => setLineItem({ ...lineItem, unitPrice: e.target.value })}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', width: '98%', marginBottom: '10px' }}>

                    <div style={{ width: '100%' }}>
                        <Label>Tax</Label>
                        <Selectbox style={{ width: '100%', height: '40px', background: 'white', fontSize: 14, borderRadius: 8 }}
                            onChange={(e) => {
                                console.log(e.target)
                                setLineItem({ ...lineItem, tax: e.target?.value?.value, taxName: e.target?.value?.name })
                            }}
                            renderValue={() => lineItem?.tax ? lineItem?.tax : "Select tax"}
                            defaultValue={"0"}

                        >
                            {[{ name: 'No tax', value: '0' }, ...tax]?.map((item, index) =>
                                <StyledMenuItem key={item.value} value={item}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #f3f3f3', width: '100%', marginTop: '-10px', marginBottom: '-10px' }}>


                                        <div>
                                            <p style={{ fontSize: '14px', textAlign: 'start' }}>{item?.name}</p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ fontSize: '14px' }}>{item?.value}%</p>

                                        </div>
                                    </div>
                                </StyledMenuItem>
                            )}
                        </Selectbox>
                    </div>
                    <div style={{ width: '100%', marginLeft: '15px' }}>
                        <Label>Select type</Label>
                        <Selectbox style={{ width: '100%', height: '40px', background: 'white', fontSize: 14, borderRadius: 8 }}
                            onChange={(e) => {
                                console.log(e.target)
                                setLineItem({ ...lineItem, type: e.target?.value })
                            }}
                            renderValue={() => lineItem?.type ? lineItem?.type : "Select type"}
                            defaultValue={"0"}

                        >
                            {[{ name: 'Service', value: 'Service' }, { name: 'Material', value: 'Material' }]?.map((item, index) =>
                                <StyledMenuItem key={item.value} value={item?.name}>
                                    {item.name}
                                </StyledMenuItem>
                            )}
                        </Selectbox>
                    </div>
                </div>
                {/* {isNew ? <div style={{ marginBottom: '10px', width: '48%' }} >
                    <Label>Tax agency</Label>
                    <div style={{display:'flex'}}>
                    <Inputbox
                        type="text"
                        placeholder='Tax agency'
                        style={{ width: '100%' }}
                        value={lineItem?.taxAgencyName}
                        onChange={(e) => setLineItem({ ...lineItem, taxAgencyName: e.target.value })}
                    />
                    <IconButton onClick={()=>{
                        setLineItem({ ...lineItem, taxAgencyName: "" })
                        setNew(false)}} style={{marginLeft:'5px'}}>
                    <FiTrash style={{fontSize:'18px'}}/>
                    </IconButton>
                    </div>
                </div> :
                    <div style={{ width: '47%' }}>
                        <Label>Tax agency</Label>
                        <Selectbox style={{ width: '100%', height: '40px', background: 'white', fontSize: 14, borderRadius: 8 }}
                            onChange={(e) => {
                                console.log(e.target)
                                if(e?.target?.value==="+ New tax agency"){
                                    setLineItem({ ...lineItem, taxAgencyName: "" })
                                    setNew(true)
                                }
                                else{
                                setLineItem({ ...lineItem, taxAgencyName: e.target?.value })
                                }
                            }}
                            renderValue={() => lineItem?.taxAgencyName ? lineItem?.taxAgencyName : "Select agency"}
                            defaultValue={"0"}

                        >
                            {["+ New tax agency",...props?.taxAgency]?.map((item, index) =>
                                <StyledMenuItem key={item} value={item}>
                                    {item}
                                </StyledMenuItem>
                            )}
                        </Selectbox>
                    </div>} */}

                <div style={{ marginBottom: '10px', paddingBottom: '30px', display: 'flex', flexDirection: 'column', width: '95%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Label>Image</Label>
                        {addImage && <Label style={{ cursor: 'pointer', color: 'black', fontWeight: '600' }} onClick={() => setImage('')}><DeleteIcon src={deletes} /> Remove</Label>}
                    </div>
                    <Label style={{ fontSize: '12px', marginTop: 0 }}>Appears at checkout. JPEG or PNG under 2MB.</Label>
                    {addImage ? <ImageUrl>
                        <Image src={addImage} />
                    </ImageUrl> : <><FileInputButton htmlFor="fileInput">
                        <Icon src={upload} />
                        Upload</FileInputButton>
                        <FileInput
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </>
                    }
                    {error && <p style={{ fontSize: '13px', textAlign: 'start', marginTop: '25px', color: 'red' }}>{error}</p>}
                </div>
                <div style={{ alignSelf: 'flex-end', position: 'fixed', bottom: 20, display: 'flex', marginRight: '30px', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#ddd', border: '1px solid #ddd', color: '#000', marginRight: '15px' }} onClick={() => {
                        // setNotes(tempUserDetails?.notes)
                        // setBoolValue({ ...boolValue, description: false })
                        props?.setModalOpenInvoice(false)
                    }}>Cancel</RippleButton>
                    <RippleButton style={{ marginRight: -15 }} onClick={async () => {
                        // addNotes()
                        // setBoolValue({ ...boolValue, description: false })
                        // setValue({ ...value, ...editValue })
                        // setEditDetail(false)
                        await onSubmit()
                    }}>Save</RippleButton>
                </div>
            </Flexbox>
        </>
    )
}

export default AddLineItems

const ImageUrl = styled.div`
width:50px;
height:50px;

`
const Image = styled.img`
width:80px;
height:80px;

`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:150px;
height:40px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const Flexbox = styled.div`
width:32wv;
margin-left:20px;
flex-direction:column;
align-items:start;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const FileInputButton = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-top:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:120px;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const Inputbox = styled.input`
height: 35px;
font-size: 14px;
padding-left:10px;
width:100%;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;

border-radius:8px;
align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-top:15px;
    margin-bottom: 8px;
    margin-left:5px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:8px;
height: 35px;
width:100%;

&:focus {
    outline: none;
    border: 1px solid #000; /* Remove border on focus */
  }
`
const InputText = styled.textarea`
  font-family: Inter;
  height: 40px;
  font-size: 14px;
  padding: 10px;
  word-wrap: break-word;
  width:100%;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical; 
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const Selectbox = styled(Select)`
width:300px;
border-radius:8px;
text-align:start;
display:flex;
border:1px solid #f3f3f3;
height:38px;
font-size:14px;

.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
align-self:start;
@media (min-width: 260px) and (max-width: 700px){
    zoom:0.6;
}
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const DeleteIcon = styled.img`
width: 20px;
height: 20px;
margin-right:10px;
margin-top:-2px;
`
const Icon = styled.img`
width: 15px;
height: 15px;
margin-right:10px;
`