import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { palette } from "../../utils/Palette";
import { Player } from "@lottiefiles/react-lottie-player";
import Header from "../../Components/Header/MainHeader";
import IOSSwitchComponent from "../../ReuseableComponets/IOSSwitch";
import { FiInfo } from "react-icons/fi";
import FieldTableComponent from "./components/FieldTableComponent";
import WorkingHoursSelector from "./components/WorkingHoursSelector";
import FormWizardPreview from "./components/PreviewOnlineForm";
import ResponsiveModal from "./components/ResponsiveModal";
import {
  setLoader,
  setPreview,
  updateValue,
  toggleDay,
  updateTime,
  setHours,
  setMinutes,
  updateSelectedDays,
  createOnlineForm,
  resetJobForm,
  getOnlineBookingForm,
  getonlineFormDataId,
  updateOnlineForm,
} from "../../store/BookingForm/bookingFormReducer";
import doneAnimation from "../../assets/done.json";
import ErrorComponent from "../../Components/ErrorComponent/ErrorCompoennt";
import { useToast } from "../../Components/Toaster/ToasterContext";
import { useNavigate, useParams } from "react-router-dom";

const CreatejobForm = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state?.auth?.userData);
  const navigate = useNavigate();
  const { id } = useParams();
  const { showToast } = useToast();
  const {
    loader,
    formLoader,
    preview,
    value,
    hours,
    minutes,
    selectedDays,
    uniqueId,
    error,
    buttonLoader,
  } = useSelector((state) => state.jobForm);
  const handleDayChange = (day) => {
    dispatch(
      updateSelectedDays({
        ...selectedDays,
        [day?.toLocaleLowerCase()]: { ...selectedDays[day?.toLocaleLowerCase()], enabled: !selectedDays[day?.toLocaleLowerCase()]?.enabled },
      })
    );
  };
  const handleTimeChange = (day, type, value) => {
    dispatch(
      updateSelectedDays({
        ...selectedDays,
        [day?.toLocaleLowerCase()]: { ...selectedDays[day?.toLocaleLowerCase()], [type]: value },
      })
    );
  };
  const handleSubmitForm = async () => {
    if (id !== "new" && id) {
      await dispatch(
        updateOnlineForm({

          ...value,
          settings: {
            workingHours: {
             
              payment: {
                required: value?.payment,
                timing: "before_booking",
              },
              image: {
                url: userdata?.logoImage,
                key: "",
                alt: "",
              },
              ...selectedDays,
              duration: {
                hours: hours,
                minutes: minutes,
              },
             
            },
           
          },
          id:id
        })
      );
    } else {
      await dispatch(
        createOnlineForm({
          ...value,
          settings: {
            workingHours: {
              duration: {
                hours: hours,
                minutes: minutes,
              },
           
              image: {
                url: userdata?.logoImage,
                key: "",
                alt: "",
              },
              ...selectedDays,
            
            },
            payment: {
              required: value?.payment,
              timing: "before_booking",
            },
          },
       
        })
      );
    }
  };

  const init = () => {
    if (id !== "new" && id) {
      dispatch(getonlineFormDataId(id));
    }
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (!buttonLoader && uniqueId) {
      navigate(-1);
      dispatch(resetJobForm());
      dispatch(getOnlineBookingForm());

      showToast("Form created successfully!", doneAnimation);
    }
  }, [uniqueId]);
  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src ="http://localhost:3000/booking-widget.js"
  //   script.dataset.bookingId = '0295cb769acb1a1f';
  //   document.body.appendChild(script);
  // }, []);
  return (
    <>
      <Body>
        <Header nonsearchable />

        {formLoader ? (
          <div
            style={{
              display: "flex",
              height: "calc(100% - 40px)",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <Player
              src={require("../../assets/newloader1.json")}
              className="player"
              style={{ width: "200px" }}
              loop
              autoplay
            />
          </div>
        ) : (
          <Container>
            <EstimateHeader>
              <h1 style={{ display: "flex" }}>
                {id !== "new" && id ? "Edit request" : "Booking Request"}
              </h1>
              <div className="button-group">
                {
                  <button
                    className="button preview"
                    onClick={() => {
                      dispatch(setPreview(true));
                    }}
                  >
                    {"Preview"}
                  </button>
                }

                {
                  <button
                    className="button save"
                    onClick={async (e) => {
                      await handleSubmitForm();
                    }}
                  >
                    {buttonLoader ? <Loaders /> : "Save"}
                  </button>
                }
              </div>
            </EstimateHeader>
            {error && (
              <div style={{ paddingBottom: "20px" }}>
                <ErrorComponent
                  header="There was an issue with your form. Please try again."
                  subheader={error}
                />
              </div>
            )}
            <Section>
              <FlexSection>
                <div style={{ width: "80%" }}>
                  <Label>Internal name</Label>
                  <Inputbox
                    type="text"
                    value={value?.title}
                    placeholder="Internal name"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          ...value,
                          title: e.target.value,
                        })
                      );
                    }}
                  />
                  <Label style={{ marginTop: "10px" }}>Description</Label>
                  <InputText
                    value={value?.description}
                    placeholder="Description"
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          ...value,
                          description: e.target.value,
                        })
                      );
                    }}
                  />
                </div>
                {/* <DragArea htmlFor={value?.pdfFile ? "" : "fileInput"}>
                  {value?.pdfFile ? (
                    <UploadIcon style={{ position: "relative" }}>
                      <Cancel
                        onClick={() => setValue({ ...value, pdfFile: "" })}
                        style={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          color: "black",
                        }}
                      />
                      <FaFilePdf style={{ fontSize: "80px" }} />
                      <ExpendTitle style={{ fontSize: "16px" }}>
                        {value?.fileName}
                      </ExpendTitle>
                    </UploadIcon>
                  ) : (
                    <>
                      <ExpendTitle>Click to upload Pdf file</ExpendTitle>
                      <ExpendTitle>Max 20MB</ExpendTitle>
                      <FileInput
                        id="fileInput"
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileUpload}
                      />
                    </>
                  )}
                </DragArea> */}
                <ProfilePic src={userdata?.logoImage} />
              </FlexSection>
              <div className="switchcontainer">
                <IOSSwitchComponent
                  checked={value?.autoBooking}
                  onChange={(val) =>
                    dispatch(
                      updateValue({
                        ...value,
                        autoBooking: value?.autoBooking ? false : true,
                      })
                    )
                  }
                />
                Auto accept booking requests
              </div>
              <div className="switchcontainer">
                <IOSSwitchComponent
                  checked={value?.payment}
                  onChange={(val) =>
                    dispatch(
                      updateValue({
                        ...value,
                        payment: value?.payment ? false : true,
                      })
                    )
                  }
                />
                Require payment upfront
              </div>
            </Section>
            <Section>
              <BookingContainer className="booking-container">
                <div className="title">Booking</div>

                <div className="info-box">
                  <FiInfo size={18} />
                  <span style={{ marginLeft: "10px" }}>
                    Online booking will use pricing forms so customers can
                    quickly visualize the total price and duration of their
                    booked job.
                  </span>
                </div>

                <div className="duration-container">
                  <div className="label">
                    Duration <FiInfo size={14} />
                  </div>
                  <div className="dropdowns">
                    <select
                      className="hours-dropdown"
                      value={hours}
                      onChange={(e) => dispatch(setHours(e.target.value))}
                    >
                      {[...Array(13).keys()].map((h) => (
                        <option key={h} value={h}>
                          {h} hours
                        </option>
                      ))}
                    </select>

                    <select
                      className="minutes-dropdown"
                      value={minutes}
                      onChange={(e) => dispatch(setMinutes(e.target.value))}
                    >
                      {[0, 15, 30, 45].map((m) => (
                        <option key={m} value={m}>
                          {m} minutes
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </BookingContainer>
            </Section>
            <Section>
              <FieldTableComponent
                fields={value?.fields}
                setownerData={(data) => {
                  console.log(data, { ...value, fields: data }, "cscd");
                  dispatch(updateValue({ ...value, fields: data }));
                  //   dispatch(updateValue({ ...value, fields: data }));
                }}
              />
            </Section>
            <Section>
              <WorkingHoursSelector
                selectedDays={selectedDays}
                onDayChange={handleDayChange}
                onTimeChange={handleTimeChange}
              />
            </Section>
          </Container>
        )}
        {preview && (
          <ResponsiveModal
            isOpen={preview}
            onClose={() => dispatch(setPreview(false))}
          >
            <FormWizardPreview
              data={{
                ...value,
                workingHours: {
                  ...value?.workingHours,
                  ...selectedDays,
                },
              }}
            />
          </ResponsiveModal>
        )}
      </Body>
    </>
  );
};

export default CreatejobForm;

const Container = styled.div`
  max-width: 1030px;
  min-width: 900px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-self: center;

  flex-grow: 1;
  flex-direction: column;
  padding-bottom: 80px;
  height: 100vh;
`;

const EstimateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .button-group {
    display: flex;
    gap: 10px;

    .button {
      padding: 8px 18px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }

    .preview {
      background-color: white;
      border: 1px solid #0f52ba;
      color: #0f52ba;
    }

    .save {
      background-color: #0f52ba;
      color: white;
    }
  }
`;

const Section = styled.div`
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 10px;

  gap: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.07);
  border: 1px solid #f3f3f3;

  .switchcontainer {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0px 0px 20px;
    align-items: center;
    gap: 20px;
    font-size: 14px;
  }
`;
const FlexSection = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 0px 20px;
`;
const Inputbox = styled.input`
  height: 35px;
  font-size: 13px;
  padding-left: 10px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  color: ${palette.charcoleBlack};
  border-radius: 4px;
  align-self: center;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  &[readonly] {
    border: 1px solid grey;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const InputText = styled.textarea`
  font-family: Inter;
  width: 98%;
  border-radius: 5px;
  font-size: 13px;
  height: 45px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 0.5px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;

const Body = styled.div`
  width: calc(100% - 240px);
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 240px;
  overflow-x: scroll;
`;

const BookingContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0px 20px;
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  }

  .info-box {
    display: flex;
    align-items: flex-start;
    background: #eaf3fc;
    padding: 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #0f52ba;
    margin-top: 10px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
  }

  .duration-container {
    margin-top: 15px;
  }

  .label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }

  .dropdowns {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
  }
`;

const BottomMessageText = styled.p`
  font-size: 14px;
  color: ${palette.black};
  line-height: 1.2;
  white-space: pre-line;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: start;
  padding: 0px 15px;
  margin-top: 10px;
`;
const DragArea = styled.label`
  border: 2px dashed #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin: 20px;
  background-color: #fafbff;

  color: #666;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #f1f6ff;
    border-color: #7b7bf5;
  }

  &.drag-over {
    background-color: #e6f0ff;
    border-color: #6b6bf5;
  }
`;

const UploadIcon = styled.div`
  font-size: 60px;
  color: #a9a9f5;
  margin-bottom: 10px;
`;
const PhotosStyle = styled.img`
  display: flex;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
`;
const ProfilePic = styled.img`
  background: #fff;
  color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
  color: #344054;
  line-height: 16px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: Inter;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`;

const Loaders = styled.div`
  border: 3px solid #d3d3d3;
  border-top: 2px solid #4169e1;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 0.6s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
