import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StartOutlined } from '@mui/icons-material';
import { palette } from '../../../utils/Palette';
import { useSelector } from 'react-redux';

const TravelTime = React.memo(({ item, index,onClick }) => {
    const detail = useSelector(state => state?.auth?.userDetails)
    const getName = (channel) => {
        if (!channel) {
          return
        }
        const val = channel?.split(' ')
    
        if (val.length == 2) {
          return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
          return `${val[0]?.charAt(0).toUpperCase()}`
        }
    
      }
    return (
        <Container marginTop={index === 0 ? 10 : -5} onClick={()=>onClick(item?.sessions[0]?.pauseHistory,`${item?.firstName|| detail?.firstName} ${item?.lastName|| detail?.lastName}`)}>
            <Box>
           
                    <StatusContainer>
                        <StatusIndicator 
                            backgroundColor={ palette.newBlue}
                        >
                            
                           {getName(`${item?.firstName|| detail?.firstName} ${item?.lastName|| detail?.lastName}`)}
                        </StatusIndicator>
                        <StatusText>
                            {item?.pausedBy?.length>0?`${item?.firstName|| detail?.firstName} ${item?.lastName|| detail?.lastName} `:`${item?.firstName|| detail?.firstName} ${item?.lastName|| detail?.lastName} `}
                        </StatusText>
                    </StatusContainer>
                   
                    <StatusText style={{ textAlign:'start',width:'33%',}}>
                            {item?.sessions[0]?.status === 'stopped' ? 'Job finished':item?.sessions[0]?.status}
                        </StatusText>
                
                   
               
                <InvoiceDetails>
                    <DateText>{moment(item?.sessions[0]?.pauseHistory[0]?.time).format('DD MMM YYYY hh:mm A')}</DateText>
                    {/* {item?.description && (
                        <Description>
                            <StatusText>{item?.description}</StatusText>
                        </Description>
                    )} */}
                </InvoiceDetails>
            </Box>
         
        </Container>
    );
});

const Container = styled.div`
    padding: 10px 10px 10px 10px;
    margin-top:10px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
    border-radius:5px;
    &:hover {
        background-color: #f0f0f0;
        transform: scale(1.008);
    }

    &:active {
        background-color: #e0e0e0;
    }
`;
const Box = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
`;

const Header = styled.div`
    flex-direction: row;
    padding-vertical: 5px;
    align-items: center;
    justify-content: space-between;
`;

const StatusContainer = styled.div`
    display:flex;
    align-items: center;
`;

const StatusIndicator = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 15px;
    justify-content: center;
    display:flex;
    font-size:13px;
    color:#fff;
    align-items: center;
    margin-right: 10px;
    background-color: ${props => props.backgroundColor};
`;

const StatusText = styled.div`
    font-size: 14px;
    font-weight: bold;
`;

const InvoiceDetails = styled.div`
 
`;

const DateText = styled.div`
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    color: ${palette.newGrey};
    font-weight: 400;
    margin-left: 5px;
`;

const Description = styled.div`
    background-color: ${palette.smokeWhite};
    margin-top: 15px;
    border-radius: 4px;
    padding: 10px;
`;

const ConnectorContainer = styled.div`
    padding-left: 25px;
`;

const ConnectorLine = styled.div`
    border-left-width: 1px;
    height: 30px;
    border-color: ${palette.lightGrey};
`;

export default TravelTime;
