import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getTipReport } from "../../../store/Actions/PaymentAction";

const TipsReportPage = () => {

    const dispatch=useDispatch()
  const handleGetReport = async() => {
   const res= await dispatch(getTipReport())
   if(res?.status===true){
       window.open(res?.downloadUrl)
   }
   console.log(res,"dckdbjn")

  };

  return (
    <Container>
      <Header>Reports</Header>
      <Content>
        <Description>
          Generate detailed reports about tips collected. Click the button below to get your report.
        </Description>
       
        <Button onClick={handleGetReport}>Generate Report</Button>
      </Content>
    </Container>
  );
};

export default TipsReportPage;

const Container = styled.div`
width:100%;
background:#fff;
padding-top:30px;
flex-direction:column;
padding:20px 30px;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`;

const Header = styled.h1`
  margin-bottom: 20px;
  text-align: start;

`;

const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background: #f9f9f9;
border: 1px solid #ddd;
border-radius: 10px;
padding: 20px;
margin-top:20px;
width: 300px;
cusrsor:pointer;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
transition: transform 0.2s, box-shadow 0.2s;

&:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 30px;
`;

const ReportOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: #0f52ba;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #084a9b;
  }

  &:active {
    background-color: #063d82;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #0f52ba;
  }
`
