import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const ModalOverlay = styled(motion.div)`
z-index: 1500;
position: fixed;
justify-content: flex-end;
width: 100%;
height: 100%;
top: 0;
left: 0;
margin: 0;
padding: 0;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
align-content: stretch;
align-items: center;
  
`;

const ModalContent = styled(motion.div)`
background-color: #FAFAFA;
margin-left: auto;
will-change: transform;
box-shadow: 0 12px 48px rgba(32, 40, 77, 0.4), 0 32px 80px rgba(32, 40, 77, 0.1);
opacity: 1.5;
padding-left: 20px;
padding-right: 20px;
border-radius: 8px;
position: relative;
flex-shrink: 0;
overflow: auto;
width: 35%;
height: 100vh;
max-height: 100vh;
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const RightSideModal = ({ isOpen, onClose, children,customStyle }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") onClose();
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => document.removeEventListener("keydown", handleEscape);
  }, [isOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        >
          <ModalContent
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
            style={{...customStyle}}
          >
            
            {children}
           
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default RightSideModal;
