import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { useSelector } from 'react-redux';
import { formatMobileNumber } from '../../../utils/Commonfunction';

const TemplateContainer = styled.div`
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
`;

const Header = styled.div`
  margin-bottom: 15px;
  text-align: left;

  .quoteNo {
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  }

  .date {
    font-size: 14px;
    color: #666;
    text-align: start;
    line-height:1.8;
  }
`;

const Body = styled.div`
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;
  text-align: start;


  .greeting {
    font-weight: bold;
    font-size: 18px;
    text-align: start;
  }

  .body-text {
    margin-top: 10px;
    text-align: start;
  }
`;

const Details = styled.div`
  margin-bottom: 20px;
  text-align: start;
  h3{
    font-size:14px;
    font-weight:500;
}

  ul {
    list-style: none;
    padding: 0;
    font-size: 14px;
    text-align: start;

    li {
      margin-bottom: 5px;
    }
  }

`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: start;
  font-size: 14px;
  color: #666;


  .company-info {
    margin-top: 15px;
    padding: 15px;
    border-top: 1px solid #ddd;
    text-align: left;
    display:flex;
    justify-content:space-around;
    align-items:center;
    background:#eee;
    border-radius:8px;
    font-size:13px;

    .company-name {
      font-weight: bold;
    }
  }
`;
const ProfilePic = styled.img`
color:white;
height:70px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:-20px;
font-weight:bold;
`

const EmailTemplate = ({ quoteNo, date, customerName, body,type, address, total, spMail, spPhone, company, phoneNumber, companyAddress }) => {
    const userData = useSelector(state => state?.auth?.userData)
  return (
    <TemplateContainer>
      <Header>
        <div className="quoteNo">{type==='quote'? 'Follow Up Estimate #{{estimateNo}}':type==='job'?'Reminder': 'Follow Up Invoice #{{invoiceNo}}'}</div>
       {type!=='job'&&  <div className="date">{"Date: {{date}}"}</div>}
      </Header>

      <Body>
        <div className="greeting">{"Hello {{customerName}},"}</div>
        <div className="body-text">{body}</div>
      </Body>

    {type!=='job'&&  <Details>
        <h3>{type==='quote'? 'ESTIMATE':'INVOICE'} DETAILS</h3>
        <ul>
          <li>{"• Customer: {{customerName}}"}</li>
          <li>{"• Address: {{address}}"}</li>
          <li>{type==='quote'?"• Estimate Amount: ${{total}}":"• Invoice Amount: ${{total}}"}</li>
        </ul>
      </Details>}
      {type!=='job'&&  <RippleButton style={{fontWeight:'500',background:'#000',borderColor:'#000',borderRadius:'20px'}}>Approve</RippleButton>}

      <Footer>
        <p>
          {`If you have any questions about this ${type} or need further assistance, please feel free to contact us at ${userData?.email} or ${formatMobileNumber(`+${localStorage.getItem('number')}`)}.`}
        </p>
        <p>Thank you for choosing {userData?.businessName}!</p>

        <div className="company-info">
        <ProfilePic src={userData?.logoImage} />
            <div>
          <div className="company-name">{userData?.businessName}</div>
          <div>Phone: {formatMobileNumber(`+${localStorage.getItem('number')}`)}</div>
          <div>Email: {userData?.email}</div>
          <div>Address: {companyAddress}</div>
          </div>
        </div>
       
      </Footer>
    </TemplateContainer>
  );
};

EmailTemplate.propTypes = {
  quoteNo: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  body: PropTypes.string,
  address: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  spMail: PropTypes.string.isRequired,
  spPhone: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  companyAddress: PropTypes.string.isRequired,
};

export default EmailTemplate;
