import React, { useState } from 'react';
import styled from 'styled-components';
import logoName from '../../assets/LogoName.png'
import { palette } from '../../utils/Palette';
const BookingResponsePage = () => {

    return(<>
    <Container>
        <MainContainer>
            <Form>
                <h3>Thank You for Your Booking!</h3>
                <p>{"Your request have been successfully submitted. Once the booking is confirmed, we will reach out."}</p>

            </Form>
            <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center'}}>
                    Powered by
                    <img style={{ width: '70px', marginLeft: '15px' }} src={logoName} />
                    </div>
        </MainContainer>
    </Container>

    </>)
}

export default BookingResponsePage;

const Container = styled.div`
display: flex;
  justify-content: center;

  background:#FAFAFA;
  width:100%;
`

const MainContainer = styled.div`
height:100vh;

@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100%;
    max-width: 520px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const Form = styled.div`
border-top:5px solid #4169e1;
overflow-y:scroll;
 margin-bottom:40px;
 margin-top:60px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  p{
  color:${palette.grey};
  }

`;