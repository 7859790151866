import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { FaSignature } from "react-icons/fa";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CustomerPdfView = ({ value }) => {
    const [numPages, setNumPages] = useState(null);
    const [fields, setField] = useState(value?.fields || []);

    const customerData = {
        firstName: "John",
        lastName: "Doe",
        email: "john.doe@example.com",
        phone: "+123456789",
        address: "123 Main St, Springfield",
        businessName: "John's Bakery",
        signature: null,
        dropdownMultiple: "true",
        dropdownSingle: "hell ya"
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const renderFieldValue = (field, customerData) => {
        switch (field.type) {
            case "firstname":
                return field?.value || "N/A";
            case "lastname":
                return field?.value || "N/A";
            case "email":
                return field?.value || "N/A";
            case "phone":
                return field?.value || "N/A";
            case "address":
                return field?.value || "N/A";
            case "businessname":
                return field?.value || "N/A";
                case "text":
                    return (
                       field?.isEditable? <div style={{border:'1.5px solid #000',padding:'2px'}}>
                        Enter here
                        </div>:field?.value || "N/A"
                    )
                    
            case "checkbox":
                return <CheckBoxOutlineBlank />;
            case "signHere":
                return (
                    <div style={{border:'1.5px solid #000',padding:'2px'}}>
<FaSignature/> Signature
                    </div>
                    
                );
            default:
                return "N/A";
        }
    };

    return (
        <Container>
            {value?.pdfLink && (
                <div className="pdf-container">
                    <Document
                        file={value?.pdfLink}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="pdf-document"
                    >
                        {Array.from(new Array(numPages), (el, pageIndex) => (
                            <div className="pdf-page" key={`page_${pageIndex + 1}`}>
                                {value?.fields?.filter(field => field.pageNumber === pageIndex+1)
                                    ?.map((field, fieldIndex) => (
                                        <div
                                            key={`${pageIndex+1}-${fieldIndex}`}
                                            className="field-overlay"
                                            style={{
                                                position: "absolute",
                                                top: field.y,
                                                left: field.x,
                                                width: field.width,
                                                height: field.height,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "start",
                                                fontSize: "14px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: 'wrap',
                                                textAlign: "start",
                                                zIndex: 1000,
                                                border: field.type === "signature" ? "1px dashed #000" : "none",
                                            }}
                                        >
                                            {renderFieldValue(field, customerData)}
                                        </div>
                                    ))}
                                <Page
                                    pageNumber={pageIndex + 1}
                                    width={794}
                                    renderTextLayer={false}
                                />
                            </div>
                        ))}
                    </Document>
                </div>
            )}
        </Container>
    );
};

export default CustomerPdfView;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .pdf-container {
        position: relative;
        width: 794px;
        height: auto;
        margin-bottom: 20px;
    
        overflow: auto;
    }

    .pdf-document {
        position: relative;
    }

    .pdf-page {
        width: auto;
        margin: 0 auto;
        border: 1px solid #ddd;
        overflow: hidden;
        margin-bottom: 20px;
        position: relative;
        page-break-after: always;
    }

    .pdf-page:last-child {
        page-break-after: auto;
    }

    .field-overlay {
        position: absolute;
        pointer-events: none;
    }
`;