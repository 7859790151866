import React, { useState } from "react";
import { Button, Card, CardContent, LinearProgress } from "@mui/material";
import styled from "styled-components";
import { putCommaInvalue } from "../../../utils/Commonfunction";
import logoName from "../../../assets/LogoName.png";
import { Check } from "@mui/icons-material";
import CustomerDetails from "./CustomerDetails";
import Calendar from "./CalendarComponent";
const formData = [
  {
    fieldId: "",
    id: 1,
    type: "single_select",
    label: "What do you looking for?",
    required: true,
    options: [
      {
        _id: "66e04ebe23c0f18b41c93afe",
        userEmail: "devs@notifi.ca",
        name: "Test",
        price: "10",
        description: "TEST DEVELOPER",
        quantity: "1",
        tax: "5",
        taxName: "GST",
        optional: false,
        GST: false,
        PST: false,
        picUrl: "",
        type: "Material",
        __v: 0,
        label: "Test",
      },
      {
        _id: "6746ea14b25b3accf4b6394d",
        userEmail: "devs@notifi.ca",
        name: "sampleR",
        price: "190",
        description: "Sample item for testing",
        quantity: "1",
        tax: "5",
        unitPrice: "180",
        taxName: "GST",
        optional: false,
        GST: false,
        PST: false,
        picUrl: "",
        type: "Material",
        __v: 0,
        label: "sampleR",
      },
      {
        _id: "66edc1ea6a4053bf2a306499",
        userEmail: "devs@notifi.ca",
        name: "cdcsd",
        price: "10",
        description: "dccddc",
        quantity: "1",
        optional: false,
        GST: false,
        PST: false,
        picUrl: "",
        __v: 0,
        label: "cdcsd",
      },
    ],
  },
  {
    fieldId: "",
    id: 2,
    type: "quantity_select",
    label: "Qunatiy of line item",
    required: true,
    options: [
      {
        label: "",
        price: 0,
      },
    ],
  },
  {
    fieldId: "",
    id: 3,
    type: "multi_select",
    label: "What do you looking for multiple?",
    required: true,
    options: [
      {
        _id: "66edc1ea6a4053bf2a306499",
        userEmail: "devs@notifi.ca",
        name: "cdcsd",
        price: "10",
        description: "dccddc",
        quantity: "1",
        optional: false,
        GST: false,
        PST: false,
        picUrl: "",
        __v: 0,
        label: "cdcsd",
        tax: "0",
        taxName: "No tax",
      },
      {
        _id: "6746eb15b25b3accf4b67b96",
        userEmail: "devs@notifi.ca",
        name: "regulator",
        price: "200",
        description: "regulator that regulates",
        quantity: "2",
        tax: "5",
        unitPrice: "90",
        taxName: "GST",
        optional: false,
        GST: false,
        PST: false,
        picUrl: "",
        type: "Service",
        __v: 0,
        label: "regulator",
        taxNumber: "1234455",
      },
    ],
  },
];

export default function FormWizardPreview({ data }) {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState(data?.fields || []);
  const [editValue, setEditValue] = useState({
    name: "",
    businessName: "",
    email: "",
  });

  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    address: "",
  });

  const editContact = () => {
    console.log("Saving contact:", editValue, address);
  };

  const totalSteps = formData.length + 2;

  const handleSelect = (option, isMultiple) => {
    if (isMultiple) {
      setAnswers((prev) => {
        const existing = prev[formData[step].id] || [];
        return {
          ...prev,
          [formData[step].id]: existing.includes(option)
            ? existing.filter((item) => item !== option)
            : [...existing, option],
        };
      });
    } else {
      setAnswers({ ...answers, [formData[step].id]: option });
      if (step < totalSteps - 1) {
        setStep(step + 1);
      }
    }
  };

  const handleQuantityChange = (amount) => {
    setAnswers((prev) => ({
      ...prev,
      [formData[step].id]: Math.max((prev[formData[step].id] || 1) + amount, 1),
    }));
  };

  return (
    <Container className="max-w-lg mx-auto p-4">
      <Label style={{ margin: "20px 10px" }}>
        Step {step + 1} of {totalSteps}
      </Label>
      <LinearProgress
        variant="determinate"
        style={{ height: "6px", borderRadius: "10px" }}
        value={((step + 1) / totalSteps) * 100}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {step > 0 && (
          <Button
            style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
            onClick={() => setStep(step - 1)}
          >
            Back
          </Button>
        )}
        <Button
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          onClick={() => {
            if (step < totalSteps - 1) {
              setStep(step + 1);
            }
          }}
        >
          {step === totalSteps - 1 ? "Finish" : "Next"}
        </Button>
      </div>
      <div style={{ maxHeight: "68vh", overflowY: "scroll" }}>
        {step < formData.length ? (
          <>
            <Header>{formData[step]?.label}</Header>

            {formData[step]?.type === "single_select" && (
              <div>
                {formData[step]?.options?.map((option) => (
                  <CardStyle
                    key={option._id}
                    onClick={() => handleSelect(option.label, false)}
                  >
                    <RowContainer>
                      <RowHeader>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <RowName>{option.name}</RowName>
                          <RowDetails>{option.description || "_"}</RowDetails>
                        </div>
                        <p className="price">
                          ${putCommaInvalue(option.price)}
                        </p>
                      </RowHeader>
                    </RowContainer>
                  </CardStyle>
                ))}
              </div>
            )}

            {formData[step]?.type === "multi_select" && (
              <div>
                {formData[step]?.options?.map((option) => (
                  <CardStyle
                    key={option._id}
                    onClick={() => handleSelect(option.label, true)}
                    className={
                      answers[formData[step].id]?.includes(option.label)
                        ? "selected"
                        : ""
                    }
                  >
                    <RowContainer>
                      <RowHeader>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            {answers[formData[step].id]?.includes(
                              option.label
                            ) && (
                              <Check
                                style={{ fontSize: "16px", marginRight: "5px" }}
                              />
                            )}
                            <RowName>{option.name}</RowName>
                          </div>
                          <RowDetails>{option.description || "_"}</RowDetails>
                        </div>
                        <p className="price">
                          ${putCommaInvalue(option.price)}
                        </p>
                      </RowHeader>
                    </RowContainer>
                  </CardStyle>
                ))}
              </div>
            )}

            {formData[step]?.type === "quantity_select" && (
              <InputText
                id="outlined-basic"
                size="small"
                type={"date"}
                placeholder={"Enter here"}
                variant="outlined"
              />
            )}
          </>
        ) : step === formData?.length ? (
          <CustomerDetails
            editValue={editValue}
            setEditValue={setEditValue}
            editContact={editContact}
            address={address}
            setEdit={()=> setStep(step + 1)}
            setAddress={setAddress}
          />
        ) : (
          <Calendar
            editValue={editValue}
            setEditValue={setEditValue}
            editContact={editContact}
            address={address}
            setAddress={setAddress}
            days={data?.workingHours}
          />
        )}
      </div>
      <FooterContainer>
        Powered by
        <Logo style={{ marginLeft: "10px" }} src={logoName} />
      </FooterContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 30px;
  }
`;

const CardStyle = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 20px 10px;
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.04);
  &:hover,
  &.selected {
    background-color: #f0f8ff;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border-top: 1px solid #eee;
  transition: background-color 0.2s ease;
  cursor: pointer;
  position: relative;
`;

const RowHeader = styled.div`
  display: flex;
  align-items: center;
 justify-content:space-between;
 p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-top:0px;
      margin-bottom:5px;
  
    }
`;

const RowName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: start;
`;

const RowDetails = styled.span`
  font-size: 13px;
  color: #757575;
  text-align: start;
  margin-top: 4px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  text-align: start;
`;

const Header = styled.h2`
  font-weight: 600;
  text-align: start;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;

  .button {
    background-color: #e0e0e0;
    border: none;
    border-radius: 0px 20px 20px 0px;
    cursor: pointer;
    padding: 1px;
    width: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  .button:hover {
    background-color: #d6d6d6;
  }

  .quantity {
    background-color: white;
    padding: 8px 15px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    flex: 1;
  }
`;

const QuantityValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  min-width: 40px;

  text-align: center;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px 0;
  font-size: 13px;
`;

const Logo = styled.img`
  width: 60px;
  margin-left: 15px;
`;

const InputText = styled.textarea`
  font-family: Inter;
  width: 90%;
  border-radius: 5px;
  font-size: 14px;
  min-height: 95px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 0.5px solid #d0d5dd;

  padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;
