import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'react-redux';
import Activeplus from '../../assets/Activeplus.png'
import { getAllconversations, getname, getnumber } from '../../store/Actions/Auth.action';
import { getUserDetails } from '../../store/Actions/dashboard.action';
import { DataList, DataListFB } from './DataList';
import { useLocation, useNavigate } from 'react-router-dom';
import { palette } from '../../utils/Palette';
import { FileCopyOutlined, LocalAtmOutlined, LoginOutlined, ManageAccountsOutlined, ReceiptLongOutlined } from '@mui/icons-material'
import { Device } from '@twilio/voice-sdk';
import { logout } from '../../store/Actions/SettingsAction';

// import arrow from '../.././assets/arrow.png'
function SettingList(props) {
    const [value, setValue] = React.useState('OPEN');
    let navigate = useNavigate()
    const [val, setVal] = React.useState("Account details");
    const [userDet, setUserDet] = useState({})
    const detail = useSelector(state => state?.auth?.convo)
    const token = useSelector(state => state?.auth?.token)
    console.log(detail, 'conversations')
    let fromnumber = localStorage.getItem('fromnumber')
    const location = useLocation();
    let dispatch = useDispatch()
    const handleactive = (name, screen) => {
        setVal(name)
        props?.setIndex(screen)

    }


    return (
        <Main>
            <br />
            <br />
            <Convos>
                {(localStorage.getItem('fromnumber') !== '+12363122675' ? DataList : DataListFB)?.map((x, index) => {
                    let locationname = location.pathname.split("/")[3];
                    return (

                        <div>
                            <Name style={{ marginLeft: '15px', fontSize: '14px', fontWeight: '500' }} className={index === val ? 'whitetext' : ''}>{x?.title ? x?.title : x.title}</Name>
                            {x?.data?.map((x, index) => {
                                const OutlinedComponent = x?.outlined
                                return (
                                    (x?.title == 'Service plans' && (localStorage.getItem('number') !== '16043326202' && localStorage.getItem('number') !== '12086688040' && localStorage.getItem('number') !== '12085029077' && localStorage.getItem('number') !== '19372000612')) ? null :
                                        <ConvoHead key={index} onClick={() => {
                                            if (x?.title === 'Terms of service' || x?.title === 'Privacy policy' || x.title === 'Log out') {
                                                if (x?.title === 'Privacy policy') {
                                                    window.open('https://www.getnotifi.com/legal/privacy-policy')
                                                }
                                                else if (x?.title === 'Terms of service') {
                                                    window.open('https://www.getnotifi.com/legal/terms-of-service')
                                                }
                                                else {
                                                    dispatch(logout());
                                                    const tokens = new Device("")
                                                    tokens.updateToken('csjbjbshcbjs_jsbjbsjdbh')
                                                    dispatch({ type: "CALLTOKEN", payload: token })
                                                    tokens.destroy();
                                                    tokens.unregister();
                                                    tokens.disconnectAll();
                                                    tokens.removeAllListeners();
                                                    localStorage.removeItem('token')
                                                    localStorage.removeItem('fbToken')
                                                    localStorage.clear()

                                                    navigate('/')
                                                    window.location.reload();
                                                }
                                            }
                                            else {
                                                handleactive(x?.title, x?.screen)

                                            }
                                        }} style={{ background: locationname === x?.screen ? palette.newBlue : '' }} >
                                            {OutlinedComponent && <OutlinedComponent style={{ fontSize: '16px', color: locationname === x?.screen ? '#fff' : '#353a44', marginLeft: '5px', marginRight: '10px' }} />}
                                            <span style={{ color: locationname === x?.screen ? '#fff' : '#353a44' }}>{x.title}</span>

                                        </ConvoHead>
                                )
                            })}
                        </div>
                    )
                })}

            </Convos>
        </Main>
    )
}
export default SettingList;
const Icon = styled.img`
height:18px;
width:18px;
margin-right:10px;
padding-left:8px;
color:black
`

const Main = styled.div`
width:230px;

border-right: 1px solid #f3f3f3;
flex-shrink: 0;
overflow: auto; 
margin-top:-10px;
padding-top:5px;
padding-bottom:10px;
height: auto;
`
const Heading = styled.p`
font-weight:600;
font-size: 15px;
padding-left:10px;
margin-bottom:-12px;
text-align:start;
`
const SearchContainer = styled.div`
display: flex;
align-items: center;
width: 95%;
border: 1px solid #E2E8F0;
border-radius: 8px;
padding: 3px;
color:#E2E8F0;
`
const Searchinput = styled.input`
flex: 1;
border: none;
padding: 5px;
font-size: 14px;
outline: none;

`
const Searchbutton = styled.button`
background: none;
  border: none;
  padding: 0;
`
const Searchimg = styled.img`
width: 15px;
height: 15px;
margin-left:10px;
margin-top:2px;
margin-right:5px;
`
const Subdiv = styled.div`
display:flex;
margin-bottom:7px;
padding-left:20px;
padding-right:20px;
margin-top:-9px;
`
const AddDiv = styled.div`
height:35px;
width:35px;
background:rgb(70, 110, 255);
border-radius:8px;
margin-left:8px;
`
const AddIcon = styled.img`
height:60%;
width:60%;
margin-top:8px;
`
const CustomTab = styled(Tab)`
width:50%;
`
const OutProfile = styled.div`
background:#e1275f;
color:white;
height:18px;
padding:13px;
padding-left:15px;
padding-right:15px
padding-bottom:0px;
margin-left:10px;
border-radius:10px;

margin-bottom:3px;
`
const Convos = styled.div`
height:auto;
overflow-y: scroll;
&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.blueseg{
background:#B3D6F5;
font-weight:600;
}
.whitetext{
color:#000;

}
.smokytext{
color:#787373;
}
.limit2{
color:white;
background:#1e90ff;
margin-bottom:15px;
}
`
// const Rating=styled.img`
// height:15px;
// width:15px;
// padding:1px;
// `
// const Icons=styled.img`
// height:20px;
// width:18px;
// padding-right:6px;
// margin-bottom:-5px;
// `
const Name = styled.p`
font-size: 14px;

color: rgb(45, 47, 49);
text-align:start;
font-weight: 500;
`
const Day = styled.p`
color:#a1a1a1;
font-size: 12px;
padding-top:7px;
`

const ConvoHead = styled.div`
font-size:13px;
line-spacing:1.5;
cursor:pointer;
display:flex;
justify-content:start;
font-weight:500;
align-items:center;
border-radius:8px;
padding-top:9px;
padding-bottom:9px;
padding-left:5px;
margin-left:10px;
margin-right:10px;
margin-bottom:10px;
&:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); 
  }
  .blueclass{
    background-color:${palette.newBlue};
    font-weight:500;
    
    }

.limit{
background:rgba(231, 62, 81, 0.16);
color: rgb(231, 62, 81);
margin-bottom:15px;
}
.review{
background:rgb(231, 237, 254);
color:rgb(99, 114, 125);
}
.general{
background:#d8e5f2;
color:rgb(99, 114, 125);
}
.general2{
background:#3973ac;
color:#FAFAFA;
}
`
const Namediv = styled.div`
display:flex;
align-items:center;

`
const Flexdiv = styled.div`
display:flex;
margin-top:-5px;
`
const ChatText = styled.div`
font-size:13px;
margin-left:0px;
margin-top:-4px;
color:#a1a1a1;
text-align:start;
`
// const Googleimg=styled.img`
// height:20px;
// width:20px;
// margin-right:5px;
// border-radius:4px;
// margin-top:-6px;
// `
// const Boxes=styled.div`
// border-radius:5px;
// width:60px;
// font-size:10px;
// .boxtext{
// padding-top:3px;
// padding-bottom:3px;
// }
// `

const Sublogo = styled.img`


width: 37px;
margin-left:15px;
margin-top:17px;
height: 37px;


`