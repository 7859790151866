import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RippleButton from '../../ReuseableComponets/RippleButton';

const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: #FAFAFA;
  box-shadow: 0 12px 48px rgba(32,40,77,0.4), 0 32px 80px rgba(32,40,77,0.1);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  height: auto;
  max-height: 70vh;
  width: 40%;
  z-index: 150;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h2`
  margin: 0;
  padding-bottom: 15px;
  font-size: 16px;
  color: #20284D;
  text-align:start;
`;

const Label = styled.p`
  font-size: 12px;
  margin: 10px 0 20px;
  color: #333;
  text-align:start;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 20px;
  border-radius: 8px;
  border: none;
  font-weight:550;
  cursor: pointer;
  font-size: 13px;

  color: white;

  ${({ variant }) =>
    variant === 'cancel'
      ? `
        background-color: #CCCCCC;
        color: #333;
      `
      : `
        background-color: #0F52BA;
      `}
`;

const ToastModal = ({ isVisible, header, label, onCancel, onConfirm }) => {
  if (!isVisible) {
    return null; 
  }

  return (
    <Overlay onClick={onCancel}>
      <Modal>
        <Header>{header}</Header>
        <Label>{label}</Label>
        <ButtonContainer>
          <Button variant="cancel" onClick={onCancel}>Cancel</Button>
          <RippleButton onClick={onConfirm}>Confirm</RippleButton>
        </ButtonContainer>
      </Modal>
    </Overlay>
  );
};

ToastModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ToastModal;
