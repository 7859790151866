import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "../../../ReuseableComponets/CheckBox";

const Container = styled.div`
  font-family: Arial, sans-serif;
  width: 400px;
  h3 {
    margin-top: 0;
    text-align: start;
    padding-left: 20px;
  }
`;

const DayRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-left: 20px;
  width: 100%;
`;

const Label = styled.label`
  flex: 1;
  text-align: start;
  min-width: 120px;
`;

const TimeSelect = styled.select`
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px 25px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display:flex;
  justify-content:space-between;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
  &:disabled {
    color: #999;
    background: #f5f5f5;
    border-color: #ddd;
    cursor: not-allowed;
    background-image: none;
  }
`;

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const times = ["08:00am", "09:00am", "10:00am", "11:00am", "12:00pm", "01:00pm", "02:00pm", "03:00pm", "04:00pm", "05:00pm", "06:00pm", "07:00pm", "08:00pm", "09:00pm", "10:00pm", "11:00pm"];

const WorkingHoursSelector = ({ selectedDays, onDayChange, onTimeChange }) => {
  
  const getValidEndTimes = (startTime) => {
    const startIndex = times.indexOf(startTime);
    return times.slice(startIndex + 1);
  };

  return (
    <Container>
      <h3>Available Booking Hours</h3>
      {daysOfWeek?.map((day) => {
        const startTime = selectedDays[day?.toLocaleLowerCase()]?.start || "8:00am";
        const validEndTimes = getValidEndTimes(startTime);
        const currentEndTime = selectedDays[day?.toLocaleLowerCase()]?.end || "5:00pm";

        // Auto-correct end time if it becomes invalid
        const correctedEndTime = validEndTimes.includes(currentEndTime) ? currentEndTime : validEndTimes[0];

        return (
          <DayRow key={day?.toLocaleLowerCase()}>
            <Checkbox
            style={{height:'20px',marginTop:'-5px'}}
              type="checkbox"
              checked={selectedDays[day?.toLocaleLowerCase()]?.enabled || false}
              onChange={() => onDayChange(day)}
            />
            <Label>{day}</Label>
            <TimeSelect
              value={startTime}
              onChange={(e) => onTimeChange(day, "start", e.target.value)}
              disabled={!selectedDays[day?.toLocaleLowerCase()]?.enabled}
            >
              {times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </TimeSelect>
            <span> to </span>
            <TimeSelect
              value={correctedEndTime}
              onChange={(e) => onTimeChange(day, "end", e.target.value)}
              disabled={!selectedDays[day?.toLocaleLowerCase()]?.enabled}
            >
              {validEndTimes.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </TimeSelect>
          </DayRow>
        );
      })}
    </Container>
  );
};

WorkingHoursSelector.propTypes = {
  selectedDays: PropTypes.object.isRequired,
  onDayChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

export default WorkingHoursSelector;


