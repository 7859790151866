import { KeyboardArrowDown, KeyboardArrowUp, Menu, TipsAndUpdatesOutlined } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { FiEdit, FiEdit2, FiPlus, FiTrash2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Checkbox from "../../../ReuseableComponets/CheckBox";
import InputBox from "../../../ReuseableComponets/InputBox";
import IOSSwitchComponent from "../../../ReuseableComponets/IOSSwitch";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { addServiceFee, deleteTips, getServiceFee, getTips, updateServiceFee, updateTips } from "../../../store/Actions/PaymentAction";
import { deleteCallTree, getCallTree, updateAutomation, updateCallTree } from "../../../store/Actions/SettingsAction";
import { formatMobileNumber } from "../../../utils/Commonfunction";
import { palette } from "../../../utils/Palette";
import RightModal from "../../RightSideModal";
import TopModal from "../../TopModal";
import AddCallTreeDetails from "../component/AddCallTreeDetails";
import Addtip from "../component/AddTip";

const Container = styled.div`
  background-color: #ffffff;
  color: #333333;
  width: 100%;

  border-radius: 8px;
  padding: 20px;
  border: 1px solid #ddd;
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  ${({ isExpanded }) =>
        isExpanded
            ? `
    max-height: auto; 
    padding: 16px;
  `
            : `
    max-height: 70px; 
    padding: 16px;
  `}
  margin: 20px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  text-align: start;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 14px;
`;

const SubText = styled.p`
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  color: grey;
  font-weight: 400;
  text-align: start;

`;

const Details = styled.div`
  margin-top: 16px;
`;

const GreetingMessage = styled.div`
  margin-bottom: 12px;
  display:flex;
  align-items:start;
  justify-content:space-between;
  
`;

const InstructionText = styled.p`
  font-size: 14px;
  margin: 8px 0;
  color: #666;
  text-align:start;
 margin-right:10px;
  white-space: pre-line;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items:start;
  width:100%;
  gap: 12px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Select = styled.select`
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  flex: 1;
`;

const AddOptionButton = styled.button`
  background-color: #0f52ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #084d9e;
  }
`;

const SetGreetingButton = styled.button`
  background-color: #0f52ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #084d9e;
  }
`;

const ContainerMain = styled.div`
  width: 100%;
  background: #fff;
  padding-top:20px;
  display: flex;


  @media (min-width: 260px) and (max-width: 1300px) {
    overflow: auto;
  }
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left:10px;
  padding:3px 7px;
  border-radius:12px;
  margin-top:-2px;
  background: ${(props) => (props.isActive ? "#90EE90" : "#eee")};

`;

const StatusIndicator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "green" : "#ccc")};
  margin-right:5px;
`;

const StatusText = styled.span`
  font-size: 12px;
  margin-top:-2px;
  color: ${(props) => (props.isActive ? "green" : "#777")};
`;

const CustomTable = styled(TableContainer)`
&::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}
border-color: red;

.MuiTableBody-root {
  
  font-family: Inter;
  font-size: 13px;
}

.MuiTableHead-root {
  font-weight: 700;
  font-family: Inter;
  font-size: 14px;
  color:${palette.black};
}

.MuiTableRow-root {
  height: 35px;
  
  font-family: Inter;
  font-size: 13px;
  &:hover {
    background: #f8f8f8;
  }
}

.MuiTableCell-root {
  border-color: #f3f3f3;
  border-right:1px solid #f3f3f3;
  height:10px;
  font-family: Inter;
  white-space: nowrap;
  word-break: break-word;
  border-collapse: separate;
  line-height: 16px;
  color:${palette.black};
  font-size: 13px;
  font-weight:450;
}
.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;

}


.purple {
  background: #974EDE;
}

.green {
  background: #32cd32;
}

.dimgreen {
  background: #008000;
}
`;

const CustomHead = styled(TableHead)`
position: sticky;
top: 0;
background-color: white;
z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
position: sticky;
background:#f9f9f9;
color:${palette.black};
z-index: 1;
min-width:30px;
max-width:30px;

}
`
const InputText = styled.textarea`
font-family: Inter;
height: 60px;
font-size: 14px;
padding: 10px;
word-wrap: break-word;
width:95%;
display: block;
border-radius: 5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
resize: vertical; 
margin-top:10px;
::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}
&:focus {
  outline: none;
  border: 1px solid #000;
}
@media (min-width: 260px) and (max-width: 721px) {
  width: 90%;
}
`;


const Subtitle = styled.p`
  font-size: 14px;
  color: #666666;
  margin-bottom: 20px;
  text-align: center;
  margin:15px 0px;
  text-align:start;
    
`;



const Custompayments = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandedMsg, setIsExpandedMsg] = useState(false);
    const [menuOptions, setMenuOptions] = useState([{ key: "1", destination: "" }]);
    const [treeData, setTreeData] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [autoText, setAutoText] = useState('');
    const [autoPlay, setAutoPlay] = useState('');
    const [boolValue, setBoolValue] = useState({})
    const [AutomationValue, setAutomationValue] = useState(false)
    const [paymentData, setPaymentData] = useState({})
    const [tipsData, setTipsData] = useState({})
    const [isnewCustomTag, setIsNewCustomTag] = useState(false)
    const dispatch = useDispatch()
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const addMenuOption = () => {
        setMenuOptions([...menuOptions, { key: menuOptions.length + 1, destination: "" }]);
    };

    const init = async () => {
        const res = await dispatch(getServiceFee())
        const res2 = await dispatch(getTips())
        setPaymentData(res?.data)
        setAutoPlay(res?.data?.serviceFeePercentage)
        setAutomationValue(res?.data?.serviceFeeEnabled)
        setTipsData(res2?.data)


        console.log(res2, "sdcdkcndk")




    }
    useEffect(() => {
        init()
    }, [])

    const handleDelete = async () => {
        const res = await dispatch(deleteCallTree(treeData?._id))
        setDeleteModalOpen(false)
        init()

    }
    const handleCHangeAutoText = async (text, type) => {
        if (paymentData?.serviceFeeEnabled) {
            const res = await dispatch(updateServiceFee({
                "serviceFeePercentage": autoPlay,
                "serviceFeeEnabled": AutomationValue,
            }))
        }
        else {
            const res = await dispatch(addServiceFee({
                "serviceFeePercentage": autoPlay,
                "serviceFeeEnabled": AutomationValue,
            }))
        }
        init()
    }

    const updateTipValue=async()=>{
        const res = await dispatch(updateTips({
            "tipEnabled":tipsData?.tipEnabled?false:true,
        }))
        init()
    }


    const deleteTipValue=async(val)=>{
        const res = await dispatch(deleteTips(val))
        init()
    }


    return (
        <div style={{ width: '100%', overflowY: 'scroll' }}>
            <h2 style={{ textAlign: 'start', marginLeft: '20px', marginTop: '30px', marginBottom: '10px' }}>Payments</h2>
            <SubText style={{ textAlign: 'start', marginLeft: '20px', fontSize: '14px' }}>Configure your payment flow</SubText>
            <ContainerMain style={{ paddingTop: '0px', }}>
                <Container isExpanded={boolValue?.isGreetingMessage} aria-expanded={boolValue?.isGreetingMessage}>
                    <Header onClick={() => setBoolValue({ ...boolValue, isGreetingMessage: true })}>
                        <TitleContainer>
                            <Title style={{ marginTop: '0px', display: 'flex' }}>Service fee
                                <StatusContainer isActive={paymentData?.serviceFeeEnabled} onClick={(e) => {
                                    e.stopPropagation()
                                    if (boolValue?.isGreetingMessage) {
                                        setBoolValue({ ...boolValue, isGreetingMessage: false })
                                    }
                                    else {
                                        setBoolValue({ ...boolValue, isGreetingMessage: true })
                                    }
                                }}>
                                    <StatusIndicator isActive={paymentData?.serviceFeeEnabled} />
                                    <StatusText isActive={paymentData?.serviceFeeEnabled} >
                                        {paymentData?.serviceFeeEnabled ? "Active" : "Inactive"}
                                    </StatusText>

                                </StatusContainer>
                            </Title>
                            {<SubText style={{ marginBottom: '0px' }}>{"Minimize the impact of processing fees by charging service fees on card payments."}</SubText>}
                        </TitleContainer>
                        <div style={{ display: 'flex', }}>
                            <Tooltip arrow placement="top" title="Add fee">
                                <IconButton onClick={(e) => {
                                    e.stopPropagation()
                                    if (boolValue?.isGreetingMessage) {
                                        setBoolValue({ ...boolValue, isGreetingMessage: false })
                                    }
                                    else {
                                        setBoolValue({ ...boolValue, isGreetingMessage: true })
                                    }
                                }}>
                                    {<FiPlus style={{ fontSize: '18px' }} />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Header>
                    {boolValue?.isGreetingMessage &&
                        <div style={{ marginTop: '15px', }}>

                            <SubText>
                                This fee will be added on top of your clients' totals when they pay with a credit card
                            </SubText>

                            <Label>Add service fee (%)</Label>

                            <InputBox
                                type='number'
                                value={autoPlay}
                                onChange={(e) => setAutoPlay(e.target.value)}
                                style={{ width: '95%' }}
                                placeholder="Service fee" />
                            <Checkbox
                                checked={AutomationValue}
                                onChange={() => {
                                    setAutomationValue((prev) => !prev)
                                }}
                                headerStyle={{ textAlign: 'start', display: 'flex' }}
                                label={`By checking this box, you acknowledge and agree that you are solely
          responsible for ensuring that any payment of Service fee as defined in
          Notifi's Terms`} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px', }}>
                                {/* <RippleButton onClick={async () => {
                                    await handleCHangeAutoText('', "autoplay")
                                    setBoolValue({ ...boolValue, isGreetingMessage: false })
                                }} style={{ background: '#fff', borderColor: '#fff', color: '#fa3e3e', textDecoration: 'underline' }}>Delete</RippleButton> */}
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <RippleButton onClick={() => {
                                        setBoolValue({ ...boolValue, isGreetingMessage: false })
                                        setAutoText(paymentData?.serviceFeePercentage)
                                    }} style={{ background: '#fff', borderColor: '#fff', color: '#000', marginRight: '15px', textDecoration: 'underline' }}>Cancel</RippleButton>
                                    <RippleButton onClick={async () => {
                                        await handleCHangeAutoText(autoPlay, "autoplay")
                                        setBoolValue({ ...boolValue, isGreetingMessage: false })
                                    }}>Save</RippleButton>
                                </div>
                            </div>
                        </div>
                    }
                </Container>
            </ContainerMain>
            <ContainerMain style={{ paddingTop: '0px', }}>
                <Container isExpanded={true} aria-expanded={true}>
                    <Header >
                        <TitleContainer>
                            <Title style={{ marginTop: '0px', display: 'flex' }}>Payment tips

                            <StatusContainer isActive={tipsData?.tipEnabled} >
                                    <StatusIndicator isActive={tipsData?.tipEnabled} />
                                    <StatusText isActive={tipsData?.tipEnabled} >
                                        {tipsData?.tipEnabled ? "Active" : "Inactive"}
                                    </StatusText>

                                </StatusContainer>                            </Title>
                            {<SubText style={{ marginBottom: '0px' }}>{"Encourage tipping to reward excellent service and offset payment processing costs"}</SubText>}
                        </TitleContainer>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Tooltip arrow placement="top" title="Add fee">
                                <IOSSwitchComponent
                                    checked={tipsData?.tipEnabled}
                                    onChange={async (val) => {
                                       await updateTipValue()
                                    }
                                    }
                                />
                            </Tooltip>

                        </div>
                    </Header>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <RippleButton onClick={()=>setIsNewCustomTag(true)} style={{ marginTop: '15px' }}>+ Add tip</RippleButton>
                    </div>
                    <CustomTable style={{ marginTop: '20px' }}>
                        <Table style={{ gridTemplateColumns: 'repeat(1, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                            <CustomHead>
                                <TableRow >
                                    <TableCell className="sticky" size="small" style={{ cursor: 'pointer', fontWeight: '700' }}>
                                        Tips</TableCell>

                                    <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700', width: '70px', }} size='small'>
                                        Action
                                    </TableCell>


                                </TableRow>
                            </CustomHead>
                            {tipsData?.tipValues?.map((option, index) => (
                                <TableBody key={index} style={{ background: '#fff', cursor: 'pointer' }} >
                                    <TableRow >
                                        <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  >{option}%</TableCell>

                                        <TableCell size='small' style={{ maxHeight: '70px',fontSize:'16px' }} onClick={()=>deleteTipValue(option)} >
                                           <FiTrash2/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}

                        </Table>
                        {tipsData?.tipValues?.length > 0 ? null : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                                height="30vh"
                                sx={{ color: 'gray' }}
                            >
                                <TipsAndUpdatesOutlined sx={{ fontSize: '50px', marginBottom: 2 }} />
                                <Typography variant="h5" align="center">
                                    Not enabled the tip
                                </Typography>

                            </Box>
                        )}
                    </CustomTable>
                </Container>
            </ContainerMain>

            <Addtip
                isVisible={isnewCustomTag}
               
                onClose={() => setIsNewCustomTag(false)}
                onSubmit={() => {
                    setIsNewCustomTag(false)
                    init()
                }}
            />

            {modalOpen && (
                <RightModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}>
                    <AddCallTreeDetails
                        data={treeData}
                        onSubmit={() => {
                            setModalOpen(false)
                            init()
                        }}
                        onClose={() => setModalOpen(false)}
                    />
                </RightModal>
            )}
            {deleteModalOpen && (
                <TopModal
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    overlayStyle={{ alignItems: 'center', paddingTop: '0px' }}
                >
                    <Box p={3}>
                        <Title style={{ textAlign: 'start' }}>
                            Are you sure you want to disable the Phone Menu?
                        </Title>
                        <Box display="flex" justifyContent="flex-end" mt={4}>
                            <RippleButton onClick={() => setDeleteModalOpen(false)} style={{ backgroundColor: '#ddd', borderColor: '#ddd', color: '#000', marginRight: '20px' }} onClick={() => setDeleteModalOpen(false)}>
                                Cancel
                            </RippleButton>
                            <RippleButton onClick={() => handleDelete()} style={{ backgroundColor: 'red', borderColor: 'red' }}>
                                Disable
                            </RippleButton>
                        </Box>
                    </Box>
                </TopModal>
            )}
        </div>
    );
};

export default Custompayments;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
  text-align:start;
  display:flex;
  margin-bottom:5px;
  margin-top:10px;
  margin-left:1%;
`;
