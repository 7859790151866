import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Cancel } from "@mui/icons-material";

import Place from "react-google-autocomplete";
import InputBox from "../../../ReuseableComponets/InputBox";
import { palette } from "../../../utils/Palette";
import { formatMobileNumber } from "../../../utils/Commonfunction";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: scroll;
  padding-bottom: 70px;
    z-index: 1500000;
      &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;


`;

const Header = styled.h2`
  font-weight: 600;
  text-align: start;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const SubHeader = styled.div`
  font-weight: 400;
  text-align: start;
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 14px;
  color: grey;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 13px;
  white-space: pre-line;
  color: #344054;
  line-height: 16px;
  display: flex;
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: Inter;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 94%;
  margin-left: 3px;
      &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  justify-content: space-between;
    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 480px) {
      width: 90%;
    }
  .buttoncontainer {
    width: 45%;
    @media (max-width: 768px) {
      width: 43%;
    }

    @media (max-width: 480px) {
      width: 43%;
    }
  }
`;
const Footer = styled.div`
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
`;

const NextButton = styled.button`
  padding: 10px 20px;
  background-color: ${palette.newBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const AutoComplete=styled(Place)`
.pac-container {
    z-index: 99999999999;
  }
`
const CustomerDetails = ({
  editValue,
  setEditValue,
  setEdit,
  editContact,
  address,
  setAddress,
}) => {
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    if (!editValue.firstName) tempErrors.firstName = "First name is required";
    if (!editValue.lastName) tempErrors.lastName = "Last name is required";
    if (!editValue.phone) tempErrors.phone = "Phone number is required";
    if (!editValue.email) tempErrors.email = "Email is required";
    if (!address.address) tempErrors.address = "Address is required";
    if (!address.city) tempErrors.city = "City is required";
    if (!address.postalCode) tempErrors.postalCode = "Zip code is required";
    if (!address.state) tempErrors.state = "State is required";
    if (!address.country) tempErrors.country = "Country is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleNext = () => {
    if (validateFields()) {
      setEdit(true);
    }
  };
  useEffect(() => {
    setErrors({});
  }, [editValue]);
  return (
    <>
      <Header>{"Customer details"}</Header>

      <Container>
        <FlexContainer>
          <div className="buttoncontainer">
            <InputBox
              header="First name"
              placeholder="First name"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={editValue?.firstName}
              onChange={(e) =>
                setEditValue({ ...editValue, firstName: e.target.value })
              }
              error={errors?.firstName}
            />
          </div>
          <div className="buttoncontainer">
            <InputBox
              header="Last name"
              placeholder="Last name"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={editValue?.lastName}
              onChange={(e) =>
                setEditValue({ ...editValue, lastName: e.target.value })
              }
              error={errors?.lastName}
            />
          </div>
        </FlexContainer>
        <FlexContainer>
          <div className="buttoncontainer">
            <InputBox
              header="Phone number"
              placeholder="phone number"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type={"tel"}
              value={formatMobileNumber(editValue?.phone)}
              onChange={(e) =>
                setEditValue({ ...editValue, phone: e.target.value })
              }
              error={errors?.phone}
            />
          </div>
          <div className="buttoncontainer">
            <InputBox
              header="Email"
              placeholder="Email"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={editValue?.email}
              onChange={(e) =>
                setEditValue({ ...editValue, email: e.target.value })
              }
              error={errors?.email}
            />
          </div>
        </FlexContainer>
        <SubHeader>
          Please add the address where you want the service to take place.
        </SubHeader>
        <FlexContainer>
          <div className="buttoncontainer">
            <Label htmlFor="Address">Address</Label>
            <AutoComplete
              apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
              types={["address"]}
              options={{ types: ["address"] }}
              defaultValue={editValue?.street}
              style={{
                width: "100%",
                borderRadius: "5px",
                fontSize: "14px",
                height: "20px",
                fontWeight: "400",
                fontFamily: "Inter, sans-serif",
                color: "#344054",
                background: "#ffffff",
                border:errors?.address?'1.5px solid red': "0.5px solid #d0d5dd",
                padding: "10px 14px",
                zIndex:'2345678765432'
              }}
              
              placeholder="Street 1"
              onPlaceSelected={(details) => {
               
                const street = `${
                  details?.address_components.find((comp) =>
                    comp.types.includes("street_number")
                  )?.short_name || ""
                } ${
                  details?.address_components.find((comp) =>
                    comp.types.includes("route")
                  )?.short_name || ""
                }`;
                const zipCode = details?.address_components.find((comp) =>
                  comp.types.includes("postal_code")
                )?.short_name;
                const region = details?.address_components.find((comp) =>
                  comp.types.includes("administrative_area_level_1")
                )?.long_name;
                const city = details?.address_components.find((comp) =>
                  comp.types.includes("locality")
                )?.long_name;
                const country = details?.address_components.find((comp) =>
                  comp.types.includes("country")
                )?.long_name;
              
                const addressvalue = {
                 
                  postalCode: zipCode || editValue.postalCode,
                  city: city || editValue.city,
                  state: region || editValue.state,
                  street: street || editValue.street,
                  country: country || editValue.country,
                  address: details?.formatted_address || editValue.address,
                 
                };
              
                setAddress({...addressvalue});
                setErrors({});
              }}
              
            />
            {errors?.address&&<ErrorText>{errors?.address}</ErrorText>}
          </div>
          <div className="buttoncontainer">
            <InputBox
              header="Street 2"
              placeholder="Street 2"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={address?.street2}
              onChange={(e) =>
                setAddress({ ...address, street2: e.target.value })
              }
            />
          </div>
        </FlexContainer>
        <FlexContainer>
          <div className="buttoncontainer">
            <InputBox
              header="City"
              placeholder="City"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={address?.city}
              onChange={(e) =>
                setAddress({ ...address, city: e.target.value })
              }
              error={errors?.city}
            />
          </div>
          <div className="buttoncontainer">
            <InputBox
              header="Zip code"
              placeholder="Zip code"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={address?.postalCode}
              onChange={(e) =>
                setAddress({ ...address, postalCode: e.target.value })
              }
              error={errors?.postalCode}
            />
          </div>
        </FlexContainer>
        <FlexContainer>
          <div className="buttoncontainer">
            <InputBox
              header="State"
              placeholder="State"
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              type="text"
              value={address?.state}
              onChange={(e) =>
                setAddress({ ...address, state: e.target.value })
              }
              error={errors?.state}
            />
          </div>
          <div className="buttoncontainer">
            <InputBox
              header="Country"
              placeholder="Country"
              type="text"
              value={address?.country}
              headerStyle={{ marginTop: "10px", fontWeight: "500" }}
              onChange={(e) =>
                setAddress({ ...address, country: e.target.value })
              }
              error={errors?.country}
            />
          </div>
        </FlexContainer>
      </Container>
      <Footer>
        <NextButton onClick={handleNext}>Next</NextButton>
      </Footer>
    </>
  );
};

CustomerDetails.propTypes = {
  editValue: PropTypes.object.isRequired,
  setEditValue: PropTypes.func.isRequired,
  setEdit: PropTypes.func.isRequired,
  editContact: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
};

export default CustomerDetails;

const ErrorText = styled.span`
    color: red;
    font-size: 12px;
    margin-top: 4px;
    display: block;
    text-align:start;
`;

