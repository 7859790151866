import { ArrowDropDown, Cancel, SelectAllOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import RippleButton from "../../../../ReuseableComponets/RippleButton";
import {
  createEnvolpe,
  createSubscription,
  getAllDigitalTemplate,
} from "../../../../store/Actions/SettingsAction";
import { palette } from "../../../../utils/Palette";
import ContractSearch from "../../../CustomerSearch/ContractSearch";
import CustomerPdfView from "../../../../Pages/DigitalSignature/CustomerSide";
import { FaListAlt } from "react-icons/fa";
const AddContactTemplate = (props) => {
  const { contactData } = props;

  const [loader, setLoader] = useState(false);
  const [selecttemplate, setSelectTemplate] = useState(false);
  const [digitalContract, setDigitalContact] = useState([]);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [to, setTo] = useState(contactData?.email ? [contactData?.email] : []);
  const [value, setvaue] = useState({
    to: "",
    bcc: "",
    cc: "",
    subject:`Template for ${contactData?.name} – Please Review`
  });
  const [currentInput, setCurrentInput] = useState("");
  const [focusedField, setFocusedField] = useState("");

  const init = async () => {
    setLoader(true);
    const res = await dispatch(getAllDigitalTemplate());

    if (res.status === true) {
      setLoader(false);
      setDigitalContact(res?.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    init();
  }, []);
  const handleAddEmail = (list, setList, type) => {
    if (currentInput.trim() && validateEmail(currentInput)) {
      setList([...list, currentInput.trim()]);
      setCurrentInput("");
    }
  };

  const handleKeyDown = (e, list, setList, type) => {
    if (e.key === "Enter" || e.key === "Tab" || e.key === ",") {
      e.preventDefault();
      handleAddEmail(list, setList, type);
    }
  };

  const handleRemoveEmail = (index, list, setList) => {
    const updatedList = [...list];
    updatedList.splice(index, 1);
    setList(updatedList);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async () => {
    const data = {
      documentName: value?.templateName,
      pdfUrl: value?.pdfLink,
      recipientEmail: to[0],
      recipientName: contactData?.name,
      emailSubject: value?.subject,
      emailMessage: "Please review and sign the attached contract.",
      contactId: contactData?._id,
      fields: value?.fields,
    };

    const res = await dispatch(createEnvolpe(data));

    if (res.status === true) {
      props?.onSubmit();
    } else {
      setError(res?.error||res?.message);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>
          {"Send Agreement"}
        </h4>
        <Cancel
          onClick={() => {
            props?.onClose();
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <>
          <div style={{ display: "flex",width:'100%',justifyContent:'space-between' }}>
            <div style={{width:'400px'}}>
              <FormGroup style={{ position: "relative" }}>
                <Label htmlFor="servicePlan">Select template</Label>

                <StyledSelect
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    !selecttemplate && setSelectTemplate(true);
                  }}
                >
                  {value?.templateName || "Select template"}
                  <ArrowDropDown />
                </StyledSelect>
                {selecttemplate && (
                  <ContractSearch
                    customers={digitalContract}
                    onclose={() => setSelectTemplate(false)}
                    onSelect={(data) => {
                      const renderFieldValue = (field) => {
                        if (field.isCustom) {
                          // Handle custom fields
                          const matchedCustomField =
                            contactData?.userFields?.find(
                              (custom) => custom.fieldName === field.label
                            );
                          return matchedCustomField?.fieldValue || "";
                        } else {
                          // Handle predefined types
                          switch (field.type) {
                            case "firstname":
                              return contactData?.name || "";
                            case "lastname":
                              return contactData?.name || "";
                            case "email":
                              return contactData?.email || to[0];
                            case "phone":
                              return contactData?.number || "";
                            case "address":
                              return contactData?.address || "";
                            case "businessname":
                              return contactData?.businessName || "";
                            default:
                              return null;
                          }
                        }
                      };
                      const populatedData = data.fields?.map((field) => ({
                        ...field,
                        value: renderFieldValue(field),
                      }));
                      setvaue({
                        ...value,
                        fields: populatedData,
                        templateName: data?.name,
                        pdfLink: data?.pdfLink,
                      });
                      console.log(populatedData, "dksjndjknjkscds");
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>

                <EmailInputWrapper>
                  {to.length === 1 && (
                    <EmailChip>
                      {to[0]}
                      <span onClick={() => handleRemoveEmail(0, to, setTo)}>
                        ×
                      </span>
                    </EmailChip>
                  )}
                  {to.length === 0 && (
                    <EmailInput
                      type="text"
                      placeholder="Add recipient"
                      value={currentInput}
                      onChange={(e) => setCurrentInput(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, to, setTo)}
                      onBlur={() => {
                        handleAddEmail(to, setTo);
                        setFocusedField("");
                      }}
                      onFocus={() => setFocusedField("to")}
                    />
                  )}
                </EmailInputWrapper>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="manualDays">Subject</Label>
                <Input
                  type="text"
                  placeholder="Add subject"
                  value={value?.subject}
                  onChange={(e) =>
                    setvaue({ ...value, subject: e.target.value })
                  }
                />
              </FormGroup>
              {error ? (
            <div>
              <br />
              <p
                style={{
                  fontSize: "14px",
                  color: "#D81159",
                  textAlign: "start",
                  marginTop: "-20px",
                }}
              >
                {error}
              </p>
            </div>
          ) : null}
            </div>
            <div
              style={{
                width: "210mm",
                height: "157mm",
                zoom: 0.82,
                display: "flex",
                justifyContent: "center",
                background: "white",
                boxShadow: "0px 4px 16px rgba(30, 30, 30, 0.05)",
                overflow: "auto",
                boxSizing: "border-box",
              }}
            >
              {value?.pdfLink? <CustomerPdfView
                value={value}
                />:
                <EmptyContainer>
              <FaListAlt style={{ fontSize: '50px', color: '#0F52BA', marginBottom: '10px' }} />
  <h2 >Select the Template</h2>
  <p >
    No templates are available at the moment.
  </p>
                </EmptyContainer>
                }
            </div>
          </div>

        
          <Actions>
            <Link onClick={() => {
            props?.onClose();
          }}>Cancel</Link>
          <RippleButton
            onClick={async () => {
              await handleFormSubmit();
            }}
            style={{marginRight:'30px',marginTop:'20px'}}
            type="Send"
          >
            Send
          </RippleButton>
          </Actions>
        </>
      )}
    </Container>
  );
};

export default AddContactTemplate;



const Container = styled.div`
  width: auto;
  position: relative;
  padding: 20px;
  border: 1px solid #e0e0e0;
  height: 100%;
  display: flex;
  flex-direction: column;
    overflow:hidden;
  background-color: #ffffff;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align: start;
  font-size: 14px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position:fixed;
  bottom:10%;
right:12.5%;
  background-color:#fff;
  width: 75%;
  display: flex;
align-self:center;
  height: auto;
padding-bottom:20px;
align-items: center;
border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
  z-index: 1000; 
 border-top:1px solid #ddd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
`;

const StyledSelect = styled.button`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  appearance: none;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
  align-content: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  text-align: center;
  margin-top: 40px;

  border-radius: 8px;
  padding: 20px;


  p{
    font-size: 14px;
  color: #666;
  margin-top: 10px;
  }
  h2{
   font-size: 18px;
  color: #333;
  margin: 0;
  }

`;
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #0f52ba;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  margin: 20px auto;
`;

const EmailInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  min-height: 35px;
  padding-left: 10px;
  transition: border-color 0.3s;

  &.focus {
    border-color: ${palette.charcoleBlack};
  }
`;

const EmailChip = styled.div`
  display: flex;
  align-items: center;
  background-color: #e3f2fd;
  border-radius: 16px;
  padding: 0px 8px;
  margin: 4px 0px;
  font-size: 13px;
  color: #1565c0;
  font-weight: 400;

  span {
    margin-left: 8px;
    font-weight: 500;
    color: #d32f2f;
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 100px;
  border-radius: 0 4px 4px 0;

  &::placeholder {
    color: #aaa;
  }
`;

const Link = styled.div`
  color: grey;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  padding: 12px 20px;
  margin-right:10px;
  border: none;
  border-radius: 8px;
  display:flex;
  align-items:center;
  text-align:start;
  font-weight:500;

  &:hover {
    text-decoration: underline;
    color: black;
  }
`;
const Input = styled.input`
  height: 35px;
  font-size: 13px;
  padding-left: 10px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  color: ${palette.charcoleBlack};
  border-radius: 4px;
  align-self: center;
  width: 97%;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
