import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  format,
  addDays,
  addMonths,
  isWeekend,
  isBefore,
  startOfDay,
} from "date-fns";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CalendarMonthOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { palette } from "../../../utils/Palette";
import { generateTimeSlots } from "../../../utils/Commonfunction";
import RippleButton from "../../../ReuseableComponets/RippleButton";

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: auto;
  padding-bottom: 70px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Header = styled.h2`
  font-weight: 600;
  text-align: start;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const DateListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const ScrollButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #007bff;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const DateList = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
  width: 80%;
  scroll-behavior: smooth;
`;

const DateButton = styled.button`
  padding: 15px;
  border-radius: 8px;
  background: ${({ selected }) => (selected ? "#E0ECFF" : "#fff")};
  color: ${({ selected }) => (selected ? "#007BFF" : "#333")};
  border: ${({ selected }) =>
    selected ? "2px solid #007BFF" : "1px solid #ddd"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: all 0.3s ease;
  min-width: 80px;

  &:hover {
    background: ${({ disabled }) => (disabled ? "#fff" : "#f0f8ff")};
  }
`;

const TimeBox = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
`;

const NextButton = styled.button`
  padding: 10px 20px;
  background-color: ${palette.newBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const DateButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? "#f5f5f5" : "#fff")};
  color: #000;
  width: 37vw;
  margin: 10px 0px;
  flex: 1;
  position: relative;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DatePicker = styled(ReactDatePicker)`
  width: 100%;
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    z-index: 9999;
  }
`;
const TimeSlotsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 20px;
  gap: 10px;
`;

const TimeSlotButton = styled.button`
  padding: 10px 15px;
  border-radius: 8px;
  background: ${({ selected }) => (selected ? "#E0ECFF" : "#fff")};
  color: ${({ selected }) => (selected ? "#007BFF" : "#333")};
  border: ${({ selected }) =>
    selected ? "2px solid #007BFF" : "1px solid #ddd"};
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: #f0f8ff;
  }
`;

const ArrivalWindowPicker = ({
  days,
  selectedDatevalue,
  selectetedTime,
  onClick,
}) => {
  console.log(days, "scd");
  const [selectedDate, setSelectedDate] = useState(null);
  const [dates, setDates] = useState([]);
  const dateListRef = useRef(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    const today = startOfDay(new Date());
    const threeMonthsLater = addMonths(today, 3);
    const futureDates = [];
    let currentDate = today;
    while (isBefore(currentDate, threeMonthsLater)) {
      futureDates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }

    setDates(futureDates);
  }, []);

  const scroll = (direction) => {
    if (dateListRef.current) {
      const scrollAmount = 150;
      dateListRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const CustomInput = ({ value, onClick, disabled }) => (
    <DateButtons disabled={disabled} onClick={onClick}>
      {value || "Date"}
      <CalendarMonthOutlined style={{ fontSize: "20px" }} />
    </DateButtons>
  );

  return (
    <>
      <Header>Pick an arrival window</Header>
      <CalendarContainer>
        <DatePickerWrapper>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              if (dateListRef.current) {
                const index = dates.findIndex(
                  (dates) =>
                    format(dates, "yyyy-MM-dd") === format(date, "yyyy-MM-dd")
                );
                if (index !== -1) {
                  const scrollAmount = index * 90;
                  dateListRef.current.scrollTo({
                    left: scrollAmount,
                    behavior: "smooth",
                  });
                }
              }
              const { start, end } =
                days[`${format(date, "EEEE")?.toLocaleLowerCase()}`];
              setTimeSlots(generateTimeSlots(start, end));
              setSelectedDate(date);
              selectedDatevalue(date);
            }}
            dateFormat="dd MMMM yyyy"
            minDate={new Date()}
            maxDate={addMonths(new Date(), 3)}
            filterDate={(date) =>
              days[format(date, "EEEE")?.toLocaleLowerCase()]?.enabled
            }
            customInput={<CustomInput />}
          />
        </DatePickerWrapper>
        <DateListWrapper>
          <ScrollButton onClick={() => scroll("left")}>
            <ArrowBackIosOutlined />{" "}
          </ScrollButton>
          <DateList ref={dateListRef}>
            {dates.map((date) => (
              <DateButton
                key={date}
                onClick={() => {
                  if (
                    days[format(date, "EEEE")?.toLocaleLowerCase()]?.enabled
                  ) {
                    setSelectedDate(date);
                    selectedDatevalue(date);
                    const { start, end } =
                      days[format(date, "EEEE")?.toLocaleLowerCase()];
                    setTimeSlots(generateTimeSlots(start, end));
                  }
                }}
                selected={
                  selectedDate &&
                  format(selectedDate, "yyyy-MM-dd") ===
                    format(date, "yyyy-MM-dd")
                }
                disabled={
                  !days[format(date, "EEEE")?.toLocaleLowerCase()]?.enabled
                }
              >
                {!days[format(date, "EEEE")?.toLocaleLowerCase()]?.enabled ? (
                  <div style={{ fontSize: "10px" }}>Unavailable</div>
                ) : (
                  format(date, "EEE")
                )}{" "}
                <br />
                <div style={{ fontSize: "12px" }}>{format(date, "MMM d")}</div>
              </DateButton>
            ))}
          </DateList>
          <ScrollButton onClick={() => scroll("right")}>
            <ArrowForwardIosOutlined />{" "}
          </ScrollButton>
        </DateListWrapper>
        <label style={{ display: "flex" }}>We’ll arrive</label>
        <TimeSlotsContainer>
          {timeSlots.map((slot) => (
            <TimeSlotButton
              key={slot}
              onClick={() => {
                setSelectedTime(slot);
                selectetedTime(slot);
              }}
              selected={selectedTime === slot}
            >
              {slot}
            </TimeSlotButton>
          ))}
        </TimeSlotsContainer>
      </CalendarContainer>
      <Footer>
        <RippleButton
          disabled={!selectedDate || !selectedTime}
          style={{
            background:
              !selectedDate || !selectedTime ? "#eee" : palette.newBlue,
            color: !selectedDate || !selectedTime ? "#000" : palette.white,
            border:'1px solid #fff',
            width:'100%'
          }}
          onClick={async() => await onClick()}
        >
          Confirm Booking Request
        </RippleButton>
      </Footer>
    </>
  );
};

export default ArrivalWindowPicker;
