import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Circle, DrawingManager, GoogleMap, InfoWindow, MarkerF, Polygon } from "@react-google-maps/api";
import mapStyles from '../../Components/Tracking/mapStyles';
import { useState, useRef, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import RippleButton from '../../ReuseableComponets/RippleButton';
import { ArrowBackIos, Person2 } from '@mui/icons-material';
import { palette } from '../../utils/Palette';
import FullScreenModal from '../../Components/FullScreenModal';
import AddContact from '../../Components/Settings/AddContact';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import { FiMapPin } from 'react-icons/fi';
import { FaDrawPolygon } from 'react-icons/fa';
import Header from '../../Components/Header/MainHeader';
import { useDispatch } from 'react-redux';
import { createTerritory, deleteTerritory, getContactMapData, getTerritory, updateTerritory } from '../../store/Contacts/Actions/ActionContacts';
import RightModal from '../../Components/RightSideModal';
import AssignTerritory from './Components/AssignTerritory';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { calendarFormat } from 'moment';
import { calendarTeamColor, getName } from '../../utils/Commonfunction';
import { Typography } from '@mui/material';
import AllCrew from './Components/Allcrew';

function CrewLocation() {

    const googleMarkerColors = [
        "red-dot.png",
        "blue-dot.png",
        "green-dot.png",
        "yellow-dot.png",
        "purple-dot.png",
        "orange-dot.png",
        "pink-dot.png",
    ];

    // Generate random markers
    const generateMarkers = () => {
        const randomIcon =
            `https://maps.google.com/mapfiles/ms/icons/` +
            googleMarkerColors[Math.floor(Math.random() * googleMarkerColors.length)];

        return randomIcon;

    };


    const [selectedProgress, setSelectedProgress] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const [opendAddContact, setOpenAddContact] = useState(false);
    const userDetails = useSelector(state => state?.auth?.userData)
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedMarker, setSelectedMarker] = useState({});
    const workerLocation = useSelector(state => state?.auth?.userData?.workerLocation)
    const [loader, setLoader] = useState(false);
    const [starDrawing, setDrawing] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const [selectedTerritory, setSelectedTeritory] = useState([]);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [boolValue, setBoolValue] = useState({})
    const dispatch = useDispatch()
    const [territory, setTerritory] = useState([
        { lat: 37.772, lng: -122.414 },
        { lat: 37.774, lng: -122.412 },
        { lat: 37.776, lng: -122.416 },
        { lat: 37.772, lng: -122.414 },
    ]);
    const [polygons, setPolygons] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(5);

    const mapContainerStyle = {
        width: "100%",
        height: "500px",
    };

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ["polygon"],
        },
        polygonOptions: {
            fillColor: "#0F52BA",
            fillOpacity: 0.3,
            strokeWeight: 2,
            strokeColor: "#0F52BA",
            clickable: true,
            editable: true, // Allow editing
            draggable: true, // Allow dragging
        },
    };

    const handlePolygonComplete = (polygon) => {
        const path = polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
        }));

        setPolygons((prevPolygons) => [...prevPolygons, {
            area: path
        }]);
        polygon.setMap(null);
        setDrawing(false)
    };

    const init = async () => {
        const data = [];
    
        // Use Promise.all to handle asynchronous operations
        const promises = workerLocation?.map(async (item) => {
            const geocoder = new window.google.maps.Geocoder();
            const location = { lat: parseFloat(item?.location?.latitude), lng: parseFloat(item?.location?.longitude) };
    
            return new Promise((resolve, reject) => {
                geocoder.geocode({ location }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        const components = results[0].address_components;
                        const addressDetails = {
                            street: getFullStreetAddress(components),
                            city: getAddressComponent(components, 'locality') || '',
                            state: getAddressComponent(components, 'administrative_area_level_1') || '',
                            postalCode: getAddressComponent(components, 'postal_code') || '',
                            country: getAddressComponent(components, 'country') || '',
                            address: results[0].formatted_address,
                        };
    
                        resolve({ ...item, ...addressDetails });
                    } else {
                        reject(`Geocoding failed: ${status}`);
                    }
                });
            });
        });
    
        try {
            const results = await Promise.all(promises);
            setMarkers(results); // Update the markers with the completed data
            console.log(results, "Updated markers data");
        } catch (error) {
            console.error("Error during geocoding:", error);
        }
    };
    
    useEffect(() => {
        setLoader(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },

                (error) => {
                    setCurrentLocation({
                        lat: 38.7946,
                        lng: -106.5348,
                    });
                }
            );
        }
        else {

            setCurrentLocation({
                lat: 38.7946,
                lng: -106.5348,
            });
        }
        init()
        setLoader(false)
    }, []);

    const polygonOptions = {
        fillColor: "#0F52BA",
        fillOpacity: 0.2,
        strokeColor: "#0F52BA",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: true,
        editable: true, // Allow territory editing
        draggable: true, // Enable dragging the shape
    };

    const handlePolygonEdit = React.useCallback((newPath) => {
        const updatedTerritory = newPath.map(({ lat, lng }) => ({ lat, lng }));
        setTerritory(updatedTerritory);
    }, []);

    const handleOnLoad = (map) => {
        mapRef.current = map;
        fitBoundsToMarkers(map);
        setIsMapLoaded(true)

        map.addListener("zoom_changed", () => handleZoomChanged(map));
    };



    const fitBoundsToMarkers = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng));
        map.fitBounds(bounds);
        new window.google.maps.event.addListenerOnce(map, 'idle', function () {
            if (map.getZoom() > 19) map.setZoom(19);
        });
    };

    const reverseGeocode = async (lat, lng, markerId) => {
        const geocoder = new window.google.maps.Geocoder();
        const location = { lat, lng };
        geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[0]) {
                console.log(results[0], "cdcdsddc")

                const components = results[0].address_components;
                const addressDetails = {
                    street: getFullStreetAddress(components),
                    city: getAddressComponent(components, 'locality') || '',
                    state: getAddressComponent(components, 'administrative_area_level_1') || '',
                    postalCode: getAddressComponent(components, 'postal_code') || '',
                    country: getAddressComponent(components, 'country') || '',
                    address: results[0].formatted_address
                };

                updateMarkerAddress(markerId, addressDetails);
            }
        });
    };
    const getAddressComponent = (components, type) => {
        const component = components.find(comp => comp.types.includes(type));
        return component ? component.long_name : null;
    };

    const getFullStreetAddress = (components) => {
        const premise = getAddressComponent(components, 'premise');
        const sublocalityLevel3 = getAddressComponent(components, 'sublocality_level_3');
        const sublocalityLevel2 = getAddressComponent(components, 'sublocality_level_2');
        const route = getAddressComponent(components, 'route');
        let fullStreet = '';

        if (premise) {
            fullStreet += premise;
        }

        if (sublocalityLevel3) {
            fullStreet += fullStreet ? `, ${sublocalityLevel3}` : sublocalityLevel3;
        }

        if (sublocalityLevel2) {
            fullStreet += fullStreet ? `, ${sublocalityLevel2}` : sublocalityLevel2;
        }

        if (route) {
            fullStreet += fullStreet ? `, ${route}` : route;
        }

        return fullStreet;
    };

    const updateMarkerAddress = (markerId, address) => {
        setMarkers((prevMarkers) => prevMarkers.map(marker =>
            marker.name === markerId ? { ...marker, ...address } : marker
        ));
        console.log(markers, "dcjknkndc")
    };

    const handleMapClick = async (e) => {
        const clickedLocation = e.latLng;
        let matchedPolygon = null;

        polygons.forEach((polygonPath, index) => {
            const polygon = new window.google.maps.Polygon({ paths: polygonPath.area });
            if (window.google.maps.geometry.poly.containsLocation(clickedLocation, polygon)) {
                matchedPolygon = { ...polygonPath, index };
            }
        });
        if (selectedProgress) {
            const newMarker = {
                id: markers.length + 1,
                position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                icon: selectedProgress.icon,
                name: selectedProgress.value,
                color: selectedProgress.color,
                address: '',
                assignedUsers: matchedPolygon?.assignedUsers || []
            };
            setMarkers([...markers, newMarker]);
            console.log([...markers, newMarker], 'dsjdsjknjkc')
            await reverseGeocode(newMarker.position.lat, newMarker.position.lng, newMarker.id);
            setSelectedProgress(null);
            setAnchorEl(null);
        }
    };

    const handleIconSelect = (item) => {
        setSelectedProgress(item);
        setAnchorEl(null);
    };

    const handleMarkerClick = (markerId) => {
        if (selectedMarkerId === markerId) {
            setSelectedMarkerId(null);
        } else {
            setSelectedMarkerId(markerId);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const removeMarker = (markerId) => {
        console.log(markerId, markers)
        setIsMapLoaded(true)
        setMarkers((prevMarkers) => prevMarkers.filter(marker => marker.id !== markerId));
        setIsMapLoaded(false)
    };

    const submitAssignMember = async (value, id) => {
        const selectedData = []

        value?.map((item) => {
            selectedData.push(item?.email)
        })
        if (id) {
            const res = await dispatch(updateTerritory({
                "assignedUsers": selectedData,
                "area": selectedTerritory?.area,
                "color": value[0]?.color || selectedTerritory?.color,
                "background": value[0]?.background,
                territoryId: id
            }))

        }
        else {
            const res = await dispatch(createTerritory({
                "assignedUsers": [
                    value[0]?.email

                ],
                "area": selectedTerritory?.area,
                "color": value[0]?.color,
                "background": value[0]?.background,
            }))
        }
        init()
        setIsAssigned(false)
    }
    const handleZoomChanged = (map) => {
        setZoomLevel(map.getZoom());
    };
    return (
        <>
            <Body>
                {loader ? <StyledPlayer> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </StyledPlayer> : <Container>
                    <Header nonsearchable={true} />
                    <ProgressContainer>

                        <div style={{ display: 'flex', gap: 'px', alignItems: 'center' }}>

                            <StyledButton
                                variant="contained"
                                onClick={() => {
                                    setBoolValue({ ...boolValue, allLead: true })
                                }}
                                style={{ display: 'flex', alignItems: 'center', background: starDrawing && '#ddd' }}
                            >
                                <Person2 style={{ marginRight: '10px' }} />
                                <Name style={{ textTransform: 'capitalize' }}>{"All crew"}</Name>
                            </StyledButton>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}

                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: 'auto',
                                    maxWidth: '450px'
                                },
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                {userDetails?.statusOptions?.map((item) => (
                                    <Grid item xs={4} key={item.value}>

                                        <div onClick={() => handleIconSelect(item)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer', alignSelf: 'center', alignItems: 'center' }}>
                                            <Avatar src={item.icon} sx={{ marginRight: 1, marginBottom: '5px' }} />
                                            <Name style={{ fontWeight: '500' }}>{item.value}</Name>
                                        </div>

                                    </Grid>
                                ))}
                            </Grid>
                        </Menu>
                    </ProgressContainer>

                    {currentLocation?.lat && <MapContainer>

                        <GoogleMap
                            onLoad={handleOnLoad}
                            onClick={handleMapClick}
                            clickableIcons
                            mapContainerStyle={{ width: "100%", height: "100%" }}
                            options={{ styles: mapStyles }}
                            zoom={zoomLevel}
                            center={currentLocation}

                        >
                            {currentLocation && (
                                <Circle
                                    center={currentLocation}
                                    radius={100}

                                    options={{
                                        strokeColor: "#0F52BA",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 1,
                                        fillColor: "#0F52BA",
                                        fillOpacity: 0.15,
                                        clickable: false
                                    }}
                                />
                            )}


                            <MarkerF
                                position={currentLocation}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 8,
                                    fillColor: palette.blue,
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: "#ffffff",
                                }}
                            />

                            {markers?.map((marker, index) => (
                                <MarkerF
                                    key={marker.id}
                                    position={{ lat: parseFloat(marker?.location?.latitude), lng: parseFloat(marker?.location?.longitude) }}
                                    icon={{
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        fillColor: calendarTeamColor[index]?.original,
                                        fillOpacity: 1,
                                        scale: 10,
                                        strokeColor: "white",
                                        strokeWeight: 2,
                                    }}

                                    onClick={() => handleMarkerClick(marker.name)}
                                >
                                    {selectedMarkerId === marker.name && (
                                        <InfoWindow position={marker.position} onCloseClick={() => setSelectedMarkerId(null)}>
                                            <div style={{ padding: '15px 5px', paddingRight: '10px', minWidth: '300px' }}>

                                                <Content>
                                                  
                                                    <div style={{ display: 'flex', alignItems: 'center',color:palette?.newBlue, fontWeight: '500' }}>

                                                        {marker.name}

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', fontWeight: '400' }}>

                                                        {marker.address}

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px',marginBottom:'10px', fontWeight: '400' }}>

                                                    {marker.email} 

                                                    </div>
                                                </Content>

                                            </div>
                                        </InfoWindow>
                                    )}
                                </MarkerF>
                            ))}
                        </GoogleMap>

                    </MapContainer>}
                </Container>}

            </Body>

            {boolValue?.allLead &&
                <RightModal
                    isOpen={boolValue?.allLead}
                    onClose={() => {
                        setBoolValue({ ...boolValue, allLead: false })
                    }}
                >
                    <AllCrew
                        value={selectedTerritory}
                        data={markers}
                        onClick={(channel) => {
                            if (channel?.contactId) {
                                navigate(`/dashboard/contacts/${channel?.contactId}`)
                                setBoolValue({ ...boolValue, allLead: false })
                            }
                            else {
                                setSelectedMarker(channel)
                                setBoolValue({ ...boolValue, allLead: false })
                                setOpenAddContact(true)

                            }

                        }}
                        onDelete={async (id) => {
                            await dispatch(deleteTerritory(id))
                            init()
                            setBoolValue({ ...boolValue, allLead: false })
                        }}
                        onClose={() => {
                            setBoolValue({ ...boolValue, allLead: false })
                        }}
                    />
                </RightModal>
            }
        </>
    );
}

export default CrewLocation;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ProgressContainer = styled.div`
    display: flex;
width:100%;
    gap: 20px;
    padding: 10px;
  justify-content:flex-end;
  

    overflow-y: auto;
`;

const MapContainer = styled.div`
    flex-grow: 1; /* Allow the map to grow and fill the remaining space */
`;

const Name = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin: 0;
`;
const HeaderMain = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #F44336;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
`;
const Content = styled.div`
  
    font-size: 14px;
    color: #333;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px 18px; 
border-radius: 4px;
margin-right:12px;
cursor: pointer;
transition: background-color 0.3s ease, box-shadow 0.3s ease;
font-size: 13px;
color: rgba(32, 32, 32, 0.85);
font-weight:500;
background-color: transparent; 
margin-right:30px;
svg {
  font-size: 16px; 
  color: rgba(32, 32, 32, 0.85);
}
&:hover {
  background-color: #eeeeee;
  border-radius: 4px;
  color:#000;
  svg {
      color:#000;
  }
}



  
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const StyledPlayer = styled.div`
display:flex;
height:80vh; 
align-items:center; 
justify-content:center; 
`
const Label = styled.label`

    font-weight: 600;
    font-size: 13px;
    margin-left:4px;
    color: ${palette.charcoleBlack};
    display: flex;
    margin-bottom: 8px;
    margin-top:20px;
    align-self: flex-start;
    font-family:Inter;

  
`
