import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Divider, IconButton, Snackbar } from "@mui/material";
import { palette } from "../../../utils/Palette";
import {
  AddCircleOutlined,
  AddOutlined,
  ArrowDropDown,
  Cancel,
} from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FiDelete, FiInfo, FiTrash, FiXCircle } from "react-icons/fi";
import LineItemSearch from "../../../Components/CustomerSearch/LineItemSearch";
import CustomFormDropdown from "./DropDownComponent";
import Taxsearch from "../../../Components/CustomerSearch/TaxSearch";

const FieldTableComponent = (props) => {
  const { type,fields } = props;
  const mainRef = useRef(null);
  const addLineFieldRef = useRef(null);
  const lineItem = useSelector((state) => state?.auth?.userData?.lineItem);
  const tax = useSelector((state) => state?.auth?.userData?.tax);
  const [isFocused, setIsFocused] = useState(false);
  const [boolValue, setBoolValue] = useState({
    newItem: false,
  });
  const [editInvoiceData, setEditInvoiceData] = useState(props?.value?.edit);
  const [open1, setopen1] = useState(false);
  const [addOwnerData, setAddOwnerData] = useState(props?.fields || []);
  useEffect(() => {
    if (isFocused && addLineFieldRef.current && mainRef.current) {
      addLineFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      if (mainRef.current) {
        mainRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [isFocused]);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const draggedItem = addOwnerData[result.source.index];
    const remainingItems = [...addOwnerData];
    remainingItems.splice(result.source.index, 1);
    remainingItems.splice(result.destination.index, 0, draggedItem);

    setAddOwnerData(remainingItems);

    if (editInvoiceData) {
      setEditInvoiceData(false);
    }
  };
  const handleDragStart = () => {
    const focusedElement = document.activeElement;
    if (focusedElement && focusedElement.tagName === "INPUT") {
      focusedElement.blur();
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setopen1(false)}
      >
        <Cancel fontSize="small" />
      </IconButton>
    </React.Fragment>
  );



  const handleInputChange = (index, field, value) => {
    const updatedLineItems = [...addOwnerData]; 
    const updatedLineItem = { ...updatedLineItems[index] };
  
    updatedLineItem[field] = value; 

    if (type !== "quantity_select"&&field==='type') {
        updatedLineItem["options"] = [
            {
              label: "",
              price: 0,
            },
          ];
    }
    updatedLineItems[index] = updatedLineItem;
    setAddOwnerData(updatedLineItems);
    props?.setownerData(updatedLineItems);
  };

  
  const handleRemoveItem = (index) => {
    const updatedLineItems = addOwnerData.filter((_, idx) => idx !== index);
    setAddOwnerData(updatedLineItems);
    props?.setownerData(updatedLineItems);
  };


  const handleRemoveOption = (itemIndex, index) => {
    const updatedLineItems = [...addOwnerData];
    const updatedOptions = [...updatedLineItems[itemIndex]["options"]].filter((_, idx) => idx !== index);
    updatedLineItems[itemIndex] = {
      ...updatedLineItems[itemIndex],
      options: updatedOptions, 
    };

    setAddOwnerData(updatedLineItems);
    props?.setownerData(updatedLineItems);
  };
  


  return (
    <>
      <BookingContainer>
        <div className="title">Fields</div>
        <p className="subtext">
          Customize your pricing form by adding fields that capture the detail
          needed to calculate the price of the service
        </p>
        <div className="info-box">
          <FiInfo size={18} />
          <span style={{ marginLeft: "10px" }}>
            Some examples are pricing based on the number of items or the type
            of service required.
          </span>
        </div>
      </BookingContainer>
      <div>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <Droppable droppableId="selectedItems">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {addOwnerData?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          top: "auto",
                          left: "auto",
                          userSelect: "none",

                          background: snapshot.isDragging ? "#f3f3f3" : "white",

                          padding: "25px 20px",
                          borderBottom: "1px solid #ddd",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomFormDropdown
                            select={item?.type}
                            onChange={(val) =>
                              handleInputChange(index, "type", val)
                            }
                          />
                          <IconButton onClick={() => handleRemoveItem(index)}>
                            <FiTrash
                              style={{ fontSize: "20px", color: "#C70000" }}
                            />
                          </IconButton>
                        </div>
                        <div>
                          <Label>Title</Label>
                          <Inputbox
                            value={item?.label}
                            placeholder={"Title"}
                            style={{
                              width: "45%",
                              paddingLeft: "10px",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "label",
                                e?.target?.value
                              )
                            }
                          />
                        </div>
                        {item?.type !== "quantity_select" && (
                          <>
                            <Label>Options</Label>
                            {item?.options?.map((itm, idx) => (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  marginTop: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <StyledSelect
                                  onClick={() =>
                                    !boolValue?.lineIndex &&
                                    setBoolValue({
                                      ...boolValue,
                                      lineIndex: true,
                                      index: index,
                                      optionIndex: idx,
                                    })
                                  }
                                >
                                  {(itm?.name||itm?.label) || "Select item"}
                                  <ArrowDropDown />
                                  {boolValue?.lineIndex &&
                                    boolValue?.index === index &&
                                    boolValue?.optionIndex === idx && (
                                      <LineItemSearch
                                        nonNewCreate={true}
                                        customers={lineItem}
                                        onSelect={(val) => {
                                            const updatedLineItems = [...addOwnerData];
                                            const optionItem = [...(updatedLineItems[index]?.options || [])];
                                          
                                            optionItem[idx] = {
                                              ...val,
                                              label: val?.name,
                                              lineItemId:[val?._id]
                                            };
                                          
                                            updatedLineItems[index] = {
                                              ...updatedLineItems[index],
                                              options: optionItem,
                                            };
                                          
                                            setAddOwnerData(updatedLineItems);
                                            props?.setownerData(updatedLineItems);
                                          }}
                                        onclose={() =>
                                          setBoolValue({
                                            ...boolValue,
                                            lineIndex: false,
                                          })
                                        }
                                      />
                                    )}
                                </StyledSelect>
                                <div
                                  style={{ position: "relative", width: "30%" }}
                                >
                                  <PercentageSymbol>$</PercentageSymbol>
                                  <Inputbox
                                    placeholder={"Price"}
                                    value={itm?.price}
                                    type="number"
                                    style={{
                                      width: "88%",
                                      paddingLeft: "30px",
                                    }}
                                    onChange={(e) => {
                                      const updatedLineItems = [...addOwnerData];
                                  
                                      const optionItem = [...updatedLineItems[index]["options"]];
                                      optionItem[idx] = {
                                        ...optionItem[idx],
                                        price: e.target.value,
                                      };
                                  
                                      updatedLineItems[index] = {
                                        ...updatedLineItems[index],
                                        options: optionItem,
                                      };
                                  
                                      setAddOwnerData(updatedLineItems);
                                      props?.setownerData(updatedLineItems);
                                    }}
                                  />
                                </div>
                                <StyledSelect 
                                style={{width:'20%'}}
                                onClick={() =>  
                                !boolValue?.istax &&
                                    setBoolValue({
                                      ...boolValue,
                                      istax: true,
                                      index: index,
                                      optionIndex: idx,
                                    })}>
                                  {itm?.tax ? `${itm?.tax} %` : "Tax"}
                                  <ArrowDropDown />
                                  {boolValue?.istax &&
                                    boolValue?.index === index &&
                                    boolValue?.optionIndex === idx && (
                                      <Taxsearch
                                        Taxs={[
                                          { name: "No tax", value: "0" },
                                          ...tax,
                                        ]}
                                        onSelect={(val) => {
                                            const updatedLineItems = [...addOwnerData];
                                          
                                            const optionItem = [...updatedLineItems[index]["options"]]; 
                                            optionItem[idx] = {
                                              ...optionItem[idx],
                                              tax: val?.value,
                                              taxName: val?.name,
                                              taxNumber: val?.description,
                                            };
                                          
                                            updatedLineItems[index] = {
                                              ...updatedLineItems[index], 
                                              options: optionItem,
                                            };
                                          
                                            setAddOwnerData(updatedLineItems);
                                            props?.setownerData(updatedLineItems);
                                          }}
                                          
                                        onclose={() =>
                                          setBoolValue({
                                            ...boolValue,
                                            istax: false,
                                          })
                                        }
                                      />
                                    )}
                                </StyledSelect>

                                <FiXCircle
                                  onClick={() => {
                                    handleRemoveOption(index, idx);
                                  }}
                                  style={{
                                    marginRight: "30px",
                                    fontSize: "22px",
                                  }}
                                />
                              </div>
                            ))}
                            <StyledTextBox
                             onClick={() => {
                                const updatedLineItems = [...addOwnerData]; 
                                updatedLineItems[index] = { 
                                  ...updatedLineItems[index],
                                  options: [
                                    ...updatedLineItems[index].options, 
                                    {
                                      label: "",
                                      price: 0,
                                    },
                                  ],
                                };
                                
                                setAddOwnerData(updatedLineItems);
                              }}
                            >
                              <AddOutlined
                                style={{
                                  paddingBottom: "0px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span>Option</span>
                            </StyledTextBox>
                          </>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                <Divider />
                <ButtonContainer>
                  <StyledButton
                    onClick={() => {
                      setAddOwnerData([
                        ...addOwnerData,
                        {
                          fieldId:addOwnerData?.length + 1,
                          id: addOwnerData?.length + 1,
                          type: "quantity_select",
                          label: "",
                          required: true,
                        },
                      ]);
                    }}
                  >
                    <AddCircleOutlined style={{ paddingBottom: "0px" }} />{" "}
                    <span>Add field</span>
                  </StyledButton>
                </ButtonContainer>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Snackbar
        open={open1}
        onClose={() => setopen1(false)}
        autoHideDuration={3000}
        message="Connect your bank account"
        style={{ zIndex: 100000000000 }}
        action={action}
        key={"top" + "right"}
      />
    </>
  );
};

const BookingContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0px 20px;

  .subtext {
    border-radius: 6px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    color:${palette.grey};
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  }

  .info-box {
    display: flex;
    align-items: flex-start;
    background: #eaf3fc;
    padding: 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #0f52ba;
    margin-top: 10px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
  }

  .duration-container {
    margin-top: 15px;
  }

  .label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }

  .dropdowns {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
  }
`;

const Inputbox = styled.input`
  height: 35px;
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  color: ${palette.charcoleBlack};
  border-radius: 4px;
  align-self: start;
  display: flex;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const StyledSelect = styled.button`
  width: 50%;
  height: 40px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? "#f5f5f5" : "#fff")};
  color: ${(props) => (props.disabled ? "#000" : "#000")};
  appearance: none;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 12px 0;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 20px;
  justify-content: flex-start;

  position: relative;
`;

const StyledButton = styled.div`
  color: ${palette.newBlue};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  cursor: pointer;

  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const StyledTextBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #0f52ba;
  background-color: transparent;
  margin-top: 20px;

  &:hover {
    background-color: #dce8f5;
  }

  svg {
    font-size: 16px;
    color: #0f52ba;
    transition: color 0.3s;

    &:hover {
      color: #083f7c;
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  align-text: start;
  display: flex;
  margin-top: 15px;
  margin-left: 3px;
  align-self: flex-start;
  font-family: Inter;
  text-align: flex-start;
`;

const PercentageSymbol = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 13px;
  color: grey;
  pointer-events: none;
`;

export default FieldTableComponent;
