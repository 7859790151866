import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formatMobileNumber, palettes, putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import { changePaymentMethod, deleteContact, fetchAllContacts, getCallLogs, submitchangeStatus } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Dialog, DialogActions, DialogContent, Tooltip, DialogContentText, DialogTitle, IconButton, InputAdornment, MenuItem, Select, TextField, Avatar } from "@mui/material";
import FullScreenModal from "../../FullScreenModal";
import { Call, MessageOutlined, PersonAddAlt1, PersonAddAlt1Outlined, Phone } from "@mui/icons-material";
import RightModal from "../../RightSideModal";
import RightSideConversations from "../RightSideConversation";
import ContactDetails from "./ContactDetails";
import searchs from '../../../assets/searchs.png'
import AddContact from "../../Settings/AddContact";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { palette } from "../../../utils/Palette";
import { FiPhoneCall } from "react-icons/fi";
import Header from "../../Header/MainHeader";

function CallLogs(props) {
  const location = useLocation()
  const popupRef = useRef(null);
  const navigate = useNavigate()
  const detail = useSelector(state => state?.auth?.allContacts)
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = React.useState('');
  const ProgressDataColor = [
    { textColor: '#007BFF', backgroundColor: '#E6F2FF' },
    { textColor: '#FFC107', backgroundColor: '#FFF3E0' },
    { textColor: '#B02A37', backgroundColor: '#F5D7D9' },
    { textColor: '#DC3545', backgroundColor: '#F7D7DA' },
    { textColor: '#28A745', backgroundColor: '#E6F4EA' },
    { textColor: '#FF851B', backgroundColor: '#FFF0E4' }
  ];
  const ProgressData = [
    'Potential', 'In-Progress', 'Qualified', 'Bad fit', 'Customer', 'Not Interested'
  ]
  const [loader, setLoader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [contactDetail, setContactDetail] = useState({})
  const [customerValue, setCustomerValue] = useState({})
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [allContact, setAllContact] = useState([])
  const [searchContact, setSearchContact] = useState([])
  const [statusModalOpen, setStatusModalOpen] = useState(false)
  const [opendAddContact, setOpenAddContact] = useState(false)
  const [invoice, setInvoice] = useState({})
  const containerRef = useRef(null);
  const token = useSelector(state => state?.auth?.token)
  const [isDelete, setIsDelete] = useState(false)


  const dispatch = useDispatch()
  const init = async () => {
    const searchParams = new URLSearchParams(location.search);
    const invoiceParam = searchParams.get('contact');
    if (invoiceParam === 'true') {
      setOpenAddContact(true)
    }
    setLoader(true)
    let res = await dispatch(getCallLogs())
    const Dates = res?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
    if (res.status === true) {
      setLoader(false)
      setAllContact(Dates)
      setSearchContact(Dates)

    }
  }

  useEffect(() => {
    init()
  }, [])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setStatusModalOpen(false);


      }

    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setStatusModalOpen]);


  const OnsubmitStauts = async (e, status) => {
    console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
    e.stopPropagation()

    const res = await dispatch(submitchangeStatus({
      "numberId": invoice?._id,
      "status": status
    }))
    init()
    setStatusModalOpen(false)
  }

  const searchFilterFunction = (text) => {
    console.log(text, "data");
    if (text) {
      const newData = allContact?.filter((item) => {
        const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const numberData = item.number ? item.to.toUpperCase() : ''.toUpperCase();
        const searchText = text.toUpperCase();
        return nameData.includes(searchText) || numberData.includes(searchText);
      });
      setSearchContact(newData);
      setSearch(text);
    } else {
      setSearchContact(allContact);
      setSearch(text);
    }
  };
  const getName = (channel) => {
    if (!channel) {
      return;
    }
    const trimmedChannel = channel.trim();
    const val = trimmedChannel.split(' ');

    if (val.length === 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
    } else {
      return `${val[0]?.charAt(0).toUpperCase()}`;
    }
  };

  return (
    <>
      <Body>
        <Flexbox>
          <Header
            searchComponent={<input type="text" placeholder="Search..." />}
            handleShowJobs={() => console.log('Show jobs')}
            value={search}
            onChangeSearch={(text) => searchFilterFunction(text)}
          />
          <StyledContactFlex>
            <div>
              <h2>Call logs </h2>
              <span><Phone style={{ marginRight: '5px', fontSize: '20px' }} /> {allContact?.length || 0}</span>
            </div>

          </StyledContactFlex>
          

          <CustomTable>


            {loader && !detail?.invoice ? <StyledPlayer> <Player
              src={require('../../../assets/loaders.json')}
              className="player"
              style={{ width: '200px', height: '200px' }}
              loop
              autoplay
            />
            </StyledPlayer>

              :
              <div>

                <div ref={containerRef} style={{ overflowY: 'auto', scrollbarWidth: 'none', height: '99vh', paddingBottom: '120px' }}>
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                    <CustomHead>
                      <TableRow >

                        <TableCell size='small'>
                          Customer name

                        </TableCell>
                        <TableCell style={{ width: '15px' }} size="small">

                        </TableCell>
                        <TableCell style={{ width: '15px' }} size="small">

                        </TableCell>

                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Status</TableCell>
                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Date & Time</TableCell>

                        <TableCell size="small">

                        </TableCell>

                      </TableRow>
                    </CustomHead>


                    {searchContact?.map((item, index) => <>

                      <TableBody key={index} onClick={(event) => {
                        //     var cleaned = ("" + item?.to).replace(/\D/g, "");
                        // setContactDetail({ ...item,number:`+${cleaned}` })
                        // setIsDetailOpen(true)


                      }} style={{ background: '#fff', cursor: 'pointer' }} >
                        <TableRow style={{}}>
                          <TableCell size='small' >
                            <div style={{ display: 'flex', alignItems: 'center', color: palette.black,fontWeight:'500' }}>
                            <Tooltip arrow title={`${formatMobileNumber(item?.to || item?.number)}`} placement='top'>
                              <StyledAvatar
                                sx={{
                                  background: 'grey',
                                  
                                }} key={item?.name}>{getName(item?.name)}</StyledAvatar>
                                </Tooltip>
                              {item?.name || formatMobileNumber(item?.direction === 'outgoing'? item?.to:item?.from)}
                            </div>
                          </TableCell>
                          {/* <TableCell

                          onClick={(event) => {
                            event.stopPropagation()
                            // setStatusIndex(index)
                            setStatusModalOpen(true)
                            setInvoice({ ...item })
                            const rect = event.target.getBoundingClientRect();
                            const containerRect = containerRef.current.getBoundingClientRect();
                            const scrollTop = containerRef.current.scrollTop;
                            const scrollLeft = containerRef.current.scrollLeft;

                            // Calculate the correct position of the popup
                            const posX = rect.left - containerRect.left + scrollLeft;
                            const posY = rect.bottom - containerRect.top + scrollTop;

                            setPopupPosition({ x: posX, y: posY });


                          }}
                          size='small'>
                          <div style={{
                            padding: 5, width: '90px', fontWeight: '400', borderRadius: 5, display: 'flex', textAlign: 'center', justifyContent: 'center',
                            color: item?.status === 'Potential' ? ProgressDataColor[0]?.textColor : item?.status === 'In-Progress' ? ProgressDataColor[1]?.textColor : item?.status === 'Qualified' ? ProgressDataColor[2]?.textColor : item?.status === 'Bad fit' ? ProgressDataColor[3]?.textColor : item?.status === 'Customer' ? ProgressDataColor[4]?.textColor : item?.status === 'Not Interested' ? ProgressDataColor[5]?.textColor : '#000'
                            , background: item?.status === 'Potential' ? ProgressDataColor[0]?.backgroundColor : item?.status === 'In-Progress' ? ProgressDataColor[1]?.backgroundColor : item?.status === 'Qualified' ? ProgressDataColor[2]?.backgroundColor : item?.status === 'Bad fit' ? ProgressDataColor[3]?.backgroundColor : item?.status === 'Customer' ? ProgressDataColor[4]?.backgroundColor : item?.status === 'Not Interested' ? ProgressDataColor[5]?.backgroundColor : '#f3f3f3'
                          }}>
                            {item?.status ? item?.status : 'Status'}
                          </div>

                        </TableCell> */}

                          <Tooltip arrow title={`Call ${formatMobileNumber(item?.direction === 'outgoing'? item?.to:item?.from)}`} placement='top'>
                            <TableCell
                              style={{ width: '15px', }}
                              size='small'
                              onClick={(e) => {
                                e.stopPropagation()
                                const call = token.connect({
                                  params: {
                                    to: item?.direction === 'outgoing'? item?.to:item?.from,
                                    from: `+${localStorage.getItem('number')}`
                                  }
                                })

                                dispatch({ type: "CONNECTION", payload: call })
                                dispatch({
                                  type: "CALLDETAIL", payload: {
                                    name: item?.name,
                                    to: item?.direction === 'outgoing'? item?.to:item?.from
                                  }
                                })
                                dispatch({ type: "CALLSHOW", payload: true })
                              }}
                            >
                              <IconBox>
                                <Call style={{ fontSize: '14px', color: 'rgba(32, 32, 32, 0.85)' }} />
                              </IconBox>
                            </TableCell>
                          </Tooltip>
                          <Tooltip arrow title={`Message  ${item?.name}`} placement='top'>
                            <TableCell
                              onClick={(e) => {
                                e.stopPropagation()
                                setCustomerValue({ name: item?.name, number:item?.direction === 'outgoing'? item?.to:item?.from})
                                setModalOpen(true)
                              }}
                              style={{ width: '15px' }}
                              size='small'>
                              <IconBox >
                                <MessageOutlined style={{ fontSize: '14px', color: 'rgba(32, 32, 32, 0.85)' }} />
                              </IconBox>
                            </TableCell>
                          </Tooltip>
                          {/* <FlexDiv>
                              <IconBox onClick={(e) => {
                                e.stopPropagation()
                                setCustomerValue({ name: item?.name, number: item?.to })
                                setModalOpen(true)
                              }}>
                                <Icon1 src={convo} alt={convo} />
                              </IconBox>
                              <IconBox
                                onClick={(e) => {
                                  e.stopPropagation()
                                  const call = token.connect({
                                    params: {
                                      to: item?.to || item?.number,
                                      from: `+${localStorage.getItem('number')}`
                                    }
                                  })

                                  dispatch({ type: "CONNECTION", payload: call })
                                  dispatch({
                                    type: "CALLDETAIL", payload: {
                                      name: item?.name,
                                      to: item?.to || item?.to
                                    }
                                  })
                                  dispatch({ type: "CALLSHOW", payload: true })
                                }}
                              >
                                <Icon1 src={calltel} alt={calltel} />
                              </IconBox>
                            </FlexDiv> */}


                          <TableCell style={{ color: item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? 'red' : palette.charcoleBlack : palette.charcoleBlack }} size='small'> {item?.direction === 'outgoing' ? "● Outgoing" :item?.isMissedCall?<span style={{ color: 'red' }}>● Missed</span>: <span style={{ color: 'green' }}>● Incoming</span>}</TableCell>
                          <TableCell size='small'>
                            {moment(item?.createdAt).format('DD MMM YYYY, hh:mm A')}

                          </TableCell>
                          <TableCell style={{ width: '15px' }} onClick={(e) => {
                            e.stopPropagation()
                            setOpenAddContact(true)
                            setContactDetail({...item,number:item?.direction === 'outgoing'? item?.to:item?.from})
                          }} size="small">
                            {!item?.name && <IconBox>
                              <PersonAddAlt1 style={{ fontSize: '20px', color: 'black' }} />
                            </IconBox>}

                          </TableCell>



                        </TableRow>

                      </TableBody>
                      <Dialog
                        aria-labelledby="responsive-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                          elevation: 10,
                          sx: {
                            border: "solid 1px #ddd",
                            boxShadow: 'none'
                          }
                        }}
                        BackdropProps={{
                          style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',  // Fully transparent
                            boxShadow: 'none',

                          },
                        }}
                        onClose={() => setIsDelete(false)} open={isDelete}>
                        <DialogTitle id="alert-dialog-title">
                          {"Delete Contact"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {` Do you want to delete ${contactDetail?.name} contact?`}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button style={{ textTransform: 'none' }} onClick={() => setIsDelete(false)} >Cancel</Button>
                          <Button style={{ background: 'red', color: 'white', textTransform: 'none' }} autoFocus onClick={async () => {

                            await dispatch(deleteContact(contactDetail?._id));
                            setIsDelete(false)
                            init()

                          }}>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>

                        <StyledPopupContainer ref={popupRef} style={{
                          top: popupPosition.y - 120,
                          left: popupPosition.x,

                        }}>
                          <StyledPopupContent >
                            <StyledPopupItems>

                              {
                                ProgressData?.map((items, index) =>
                                  <PopupValues style={{ width: '100%' }} onClick={e => {
                                    OnsubmitStauts(e, items)
                                    // setDatadet(item)
                                    // OnsubmitStauts(e, items)
                                  }}>
                                    <a style={{ fontSize: '16px', color: ProgressDataColor[index]?.textColor, marginRight: '8px' }}>{`●  `}</a>
                                    {items}</PopupValues>
                                )
                              }
                            </StyledPopupItems>

                          </StyledPopupContent>
                        </StyledPopupContainer>


                      </div>}
                    </>

                    )}

                  </Table>

                </div>
              </div>
            }

          </CustomTable>

        </Flexbox>
        {isDetailOpen && <FullScreenModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <div style={{ display: 'flex' }}>
            <ContactDetails
              setModal={() => setIsDetailOpen(false)}
              value={{ ...contactDetail, to: `+${("" + contactDetail?.direction === 'outgoing'? contactDetail?.to:contactDetail?.from).replace(/\D/g, "")}` }}
              setConnection={(call) => {
                props?.setConnection(call)
              }}
            />
          </div>
        </FullScreenModal>}

        {modalOpen && <RightModal
          customStyle={{ background: '#fff' }}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <div style={{ height: '100%', display: 'flex' }}>
            <RightSideConversations setConnection={(call) => {
              // setConnection(call)
            }} value={customerValue} />
          </div>
        </RightModal>}
        {opendAddContact && <FullScreenModal
          isOpen={opendAddContact}
          onClose={() => {
            setOpenAddContact(false)

            searchParams.delete('contact');
            setSearchParams(searchParams)
          }}
        >


          <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
            <AddContact
              value={{ ...contactDetail, to: `+${("" + contactDetail?.to).replace(/\D/g, "")}` }}
              onCancel={(num) => {
                init()
                setOpenAddContact(false)

                searchParams.delete('contact');
                setSearchParams(searchParams)


              }}
              onSubmit={(num) => {
                init()
                setOpenAddContact(false)
                searchParams.delete('contact');
                setSearchParams(searchParams)
                navigate(`/dashboard/contacts/${num?._id}`)
                setContactDetail({ ...num })
                setIsDetailOpen(true)

              }}
            />
          </div>
        </FullScreenModal>}
      </Body>
    </>
  )


}


export default CallLogs;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const StyledContactFlex = styled.div`
 display:flex; 
 justify-content:space-between; 
 align-items:center; 
 margin-left:20px; 
 margin-right:0px;
 margin-top:10px;
 margin-bottom:10px; 

 h2{
  text-align:center; 
  margin-top:0px; 
  margin-bottom:3px; 
  line-spacing:0.5px;
 }
 span{
   color:grey;
   font-size:15px;
   text-align:start;
   display:flex;
   align-items:flex-end;
   margin-bottom:5px; 
   font-weight:400;
   
 }

`

const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 250px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
width:88%;
padding:8px;
cursor:pointer;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const StyledPlayer = styled.div`
display:flex;
height:70vh; 
align-items:center; 
justify-content:center; 
`
const DotContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
align-items:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px; 
padding:5px;
background:#fff;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none;
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:400;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
overflow-y:hidden;
display:flex;
flex-direction:column;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const SubTable = styled.div`

display:flex;
width:100%; 
justify-content:flex-end; 
align-self:flex-end;
padding-top:25px; 

.key{
    width:30%;
     text-align:start; 
     font-weight:bold;
}
`

const Icon1 = styled.img`
height:15px;
width:15px;


`
const FlexDiv = styled.div`
display:flex;
align-items:center;
`
const IconBox = styled.div`
display:flex;
justify-content:center;
align-items:center;
padding-top:4px;
padding-bottom:4px;
border-radius:5px;
color:white;
`
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
font-size:14px;

background:#0F52BA;
color:#fff;
display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`

const StyledAvatar = styled(Avatar)`
  && {
   
    font-size: 12px;
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;