import { ContactEmergencyOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { palette } from "../../utils/Palette";
import { TableContainer, TableHead, TableCell, Table, TableRow, TableBody, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Modal from "../../Components/Modal";
import CustomTabs from "../../ReuseableComponets/CustomTab";
import { deleteDigitalTemplate, getAllCustomField, getAllDigitalTemplate } from "../../store/Actions/SettingsAction";
import PdfTemplateEditor from "./PdfTemplateEditor";
import { Player } from "@lottiefiles/react-lottie-player";
import { FaSignature } from "react-icons/fa";
const DigitalContract = () => {
    const dispatch = useDispatch()
    const [fields, setFields] = useState([])
    const [modal, setModal] = useState(false)
    const [digitalData, setDigitalData] = useState([])
    const [loader, setLoader] = useState(false)


    const mapFieldsToButtons = (fields) => {


        return fields.map((field) => ({
            label: field.fieldName,
            type: field.fieldType,
            assigned: field.type,
            isCustom: true,

        }));
    };

    const init = async () => {
        setLoader(true)
        const res1 = await dispatch(getAllDigitalTemplate())
        if(res1?.status == true){
            setDigitalData(res1?.data)
        }
        const res = await dispatch(getAllCustomField())
        if (res?.status == true) {
            const fields = [...res?.data?.contactFields, ...res?.data?.jobFields];
            const buttons = mapFieldsToButtons(fields);

            setFields(buttons)
            console.log([...res?.data?.contactFields, ...res?.data?.jobFields])
        }
        setLoader(false)

    }
    useEffect(() => {
        init()
    }, [])


    const deleteContract=async(id)=>{
        const res=await dispatch(deleteDigitalTemplate(id))
        init()
    }

    const renderItem = (fields, type) => {

        return (
            <>

                {loader ? <StyledPlayer> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </StyledPlayer>
                    : <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                        <CustomHead>
                            <TableRow >
                                <TableCell className="sticky" size="small" style={{ cursor: 'pointer', width: '100px', }}>
                                    #</TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                    Name
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                    Description
                                </TableCell>

                                <TableCell style={{ width: '100px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                    Actions
                                </TableCell>

                            </TableRow>
                        </CustomHead>

                        {fields?.map((field, index) => (
                            <TableBody key={index} style={{ background: '#fff', cursor: 'pointer' }} >
                                <TableRow >
                                    <TableCell style={{ fontWeight: '500' }} className="sticky" size='small'>{index + 1}</TableCell>

                                    <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  >{field?.name}</TableCell>

                                    <TableCell size='small' style={{ fontWeight: '500',maxWidth:'200px' }}  >{field?.description}</TableCell>
                                    <TableCell size='small'  >
                                        <ActionIcons>

                                            <FiTrash2 onClick={async () => {
                                                deleteContract(field?._id)
                                            }} />

                                        </ActionIcons >
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        ))}


                    </Table>}
                {fields?.length > 0 ? null : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="60vh"
                        sx={{ color: 'gray' }}
                    >
                        <FaSignature sx={{ fontSize: '50px', marginBottom: 2 }} />
                        <Typography variant="h5" align="center">
                            No template available
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            You can add new contract to manage your data more effectively.
                        </Typography>
                    </Box>
                )}

            </>
        )
    }

    return (
        <Container>
            <CustomTable>
                <Header>
                    <div>
                        <h2>Digital Contract</h2>
                        <SubText style={{ fontSize: '14px' }}>Streamline your workflow with electronic signatures</SubText>
                    </div>
                    <AddButton onClick={() => {
                        setModal(true)
                    }}>+ New Contract</AddButton>
                </Header>
                <CustomTabs
                    tabpanelWidth={'400px'}
                    boxStyle={{
                        p: 0, marginTop: '10px', marginBottom: '25px'
                    }}
                    tabsData={[
                        {
                            label: 'Contract template',
                            icon: <ContactEmergencyOutlined style={{ fontSize: '16px' }} />,
                            content: renderItem(digitalData, "job type")
                        },



                    ]}
                />
                {modal && <Modal
                    customStyle={{ maxWidth: "90%", padding: 10 }}
                    onClose={() => setModal(false)}

                >
                    <PdfTemplateEditor
                        custom={fields}
                        onClose={()=>{

                            setModal(false)
                            init()
                        }}
                    />


                </Modal>}
            </CustomTable>


        </Container>
    )
}

export default DigitalContract;

const Container = styled.div`
width:100%;
background:#fff;
margin-top:30px;

`;

const SubText = styled.p`
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  color: grey;
  font-weight: 400;
  text-align:start;

`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right:20px;
  margin-left:20px;

  h2{
    margin-top:0px;
    margin-bottom:0px;
    text-align:start;
  }
`;

const AddButton = styled.button`
  background-color: #0F52BA;
  color: white;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #08417c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`;

const ButtonText = styled.h4`
  text-align: start;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0F52BA;
  cursor: pointer;
`;

const CustomTable = styled(TableContainer)`
&::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}
border-color: red;

.MuiTableBody-root {
  
  font-family: Inter;
  font-size: 13px;
}

.MuiTableHead-root {
  font-weight: 700;
  font-family: Inter;
  font-size: 14px;
  color:${palette.black};
}

.MuiTableRow-root {
  height: 35px;
  
  font-family: Inter;
  font-size: 13px;
  &:hover {
    background: #f8f8f8;
  }
}

.MuiTableCell-root {
  border-color: #f3f3f3;
  border-right:1px solid #f3f3f3;
  height:10px;
  font-family: Inter;
  white-space: nowrap;
  word-break: break-word;
  border-collapse: separate;
  line-height: 16px;
  color:${palette.black};
  font-size: 13px;
  font-weight:450;
}
.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;

}


.purple {
  background: #974EDE;
}

.green {
  background: #32cd32;
}

.dimgreen {
  background: #008000;
}
`;

const CustomHead = styled(TableHead)`
position: sticky;
top: 0;
background-color: white;
z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
position: sticky;
background:#f9f9f9;
color:${palette.black};
z-index: 1;
min-width:30px;
max-width:30px;

}
`
const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledPlayer = styled.div`
display:flex;
height:60vh; 
align-items:center; 
justify-content:center; 
`

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;