import { Player } from "@lottiefiles/react-lottie-player";
import React, { createContext, useContext, useState } from "react";
import { palette } from "../../utils/Palette";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ visible: false, message: "", animation: null });

  const showToast = (message, animation = null) => {
    setToast({ visible: true, message, animation });
    setTimeout(() => setToast({ visible: false, message: "", animation: null }), 2500);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.visible && <Toast message={toast.message} animation={toast.animation} />}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

const Toast = ({ message, animation }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.toast}>
        {animation && (
          <Player
            autoplay
            src={animation}
            keepLastFrame
            style={{ height: "35px", width: "35px" }}
          />
        )}
        <h4 style={{ margin: "0px",marginLeft:'10px',fontWeight:500,color:'white' }}>{message}</h4>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 9999,
    width: "100%",
  },
  toast: {
    backgroundColor:'black',
    padding: "7px 25px",
    borderRadius: "4px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};

export default ToastProvider;
