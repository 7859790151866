import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CustomDropdown = ({onChange,select}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(select||"quantity_select");
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
  
    {
      title: "Service (Single-Select)",
      subtitle: "Only one option can be chosen.",
       id:'single_select'
    },
    {
      title: "Services (Multi-Select)",
      subtitle: "Select one or more applicable services. Multiple selections are allowed.",
       id:'multi_select'
    },
    {
      title: "Additional Questions (Sub-text)",
      subtitle: "Enter any extra questions or clarifications that will help you better understand and fulfill the service request.",
      id:'quantity_select'
    },
 
  ];

  const selectedOption = options?.find((option) => option?.id === selected);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
        {selectedOption?.title}
        {isOpen ? <FaChevronUp style={{fontSize:'12px'}} /> : <FaChevronDown style={{fontSize:'12px'}}/>}
      </DropdownHeader>

      {isOpen && (
        <DropdownList>
          {options.map((option) => (
            <DropdownItem
              key={option.title}
              className={classNames({ selected: selected === option.title })}
              onClick={() => {
                setSelected(option.id);
                onChange(option.id)
                setIsOpen(false);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
        
                <LabelContainer>
                  <Title>{option.title}</Title>
                  <Subtitle>{option.subtitle}</Subtitle>
                </LabelContainer>
              </div>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default CustomDropdown;


const DropdownContainer = styled.div`
  position: relative;
  width:43.3%;
`;

const DropdownHeader = styled.div`
  background: white;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width:100%;

`;

const DropdownList = styled.div`
  position: absolute;
  top: 45px;
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  overflow: hidden;
`;

const DropdownItem = styled.div`
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:hover {
    background: #f0f7ff;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align:start;
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: #666;
  margin-top:3px;
    text-align:start;
`;