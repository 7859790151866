import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: auto; 
  span{
      margin-top:5px;
      margin-bottom:5px;
  }
  
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom:10px;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TipButton = styled.button`
  flex-shrink: 0;
  border: 1px solid #c3c3c3;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: ${({ active }) => (active ? "#000" : "white")};
  color: ${({ active }) => (active ? "#fff" : "333")};
  cursor: pointer;
`;

const CustomInput = styled.input`
  width: 60px;
  padding: 8px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  text-align: center;
`;

const TipsSelector = ({ predefinedTips, handleChange,data }) => {
    console.log(predefinedTips,"cddkn")
  const [selectedTip, setSelectedTip] = useState(null);
  const [customTip, setCustomTip] = useState("");

  const handleTipClick = (tip) => {
    setSelectedTip(tip);
    setCustomTip(""); // Clear custom input if a predefined tip is selected
    handleChange(tip === "noTip" ? 0 : tip);
  };

  const handleCustomClick = () => {
    setSelectedTip("custom");
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomTip(value);
    handleChange(value ? parseFloat(value) : null);
  };

  return (
    <TipsContainer>
      <span>Add a tip ?</span>
      <ButtonContainer>
        {/* <TipButton
          active={selectedTip === "noTip"}
          onClick={() => handleTipClick("noTip")}
        >
          No Thanks
        </TipButton> */}
        {predefinedTips.map((tip) => (
          <TipButton
            key={tip}
            active={selectedTip === tip}
            onClick={() => handleTipClick(tip)}
          >
            {tip}%
          </TipButton>
        ))}
        <TipButton
          active={selectedTip === "custom"}
          onClick={handleCustomClick}
        >
          Custom
        </TipButton>
      </ButtonContainer>
      {selectedTip === "custom" && (
          <div style={{display:'flex',marginBottom:'10px',alignItems:'center'}}>
               <span style={{fontSize:'12px',marginRight:'8px'}}>Tip percentage</span>
        <CustomInput
          type="number"
          value={customTip}
          onChange={handleCustomInputChange}
          placeholder="%" // Placeholder text
        />
        </div>
      )}
    </TipsContainer>
  );
};

TipsSelector.propTypes = {
  predefinedTips: PropTypes.arrayOf(PropTypes.number),
  handleChange: PropTypes.func.isRequired,
};

TipsSelector.defaultProps = {
  predefinedTips: [10, 15, 20],
};

export default TipsSelector;
