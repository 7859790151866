import { combineReducers } from '@reduxjs/toolkit';
import authReducers from '../authReducers';
import ReducerContacts from '../Contacts/Actions/ReducerContacts';
import jobFormReducer from "../BookingForm/bookingFormReducer";

const combinedReducers = combineReducers({
  auth: authReducers,
  contacts: ReducerContacts,
  jobForm: jobFormReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    return combinedReducers(undefined, action); // Reset state
  }
  return combinedReducers(state, action);
};

export default rootReducer;
