import { Checkbox, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addnewLineItems, submitEditLineItem } from "../../../store/Actions/dashboard.action";

import { palette } from "../../../utils/Palette";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { FiTrash } from "react-icons/fi";

const AddTax = (props) => {
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const [lineItem, setLineItem] = useState({
        name: props?.selectedlist?.name || '',
        value: props?.selectedlist?.value || '',
        defaultTax: props?.selectedlist?.defaultTax || false,
        index: props?.selectedlist?.index || 0,
        'description': props?.selectedlist?.description || '',
        taxAgencyName:props?.selectedlist?.taxAgencyName || '',
    })
    const [isNew, setNew] = useState(false)
    const dispatch = useDispatch()
  


    return (
        <>
            <Flexbox >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'start', }}>{props?.selectedlist?._id ? "Edit tax" : " Add tax"}</h2>
                    <RippleButton style={{ marginRight: -15 }} onClick={async () => {
                        if (props?.selectedlist?._id) {
                            await props?.onEdit({ ...lineItem, "_id": props?.selectedlist?._id, "stripeTaxId": props?.selectedlist?.stripeTaxId })
                        }
                        else {
                            await props?.onSubmit(lineItem)
                        }
                    }}>Save</RippleButton>
                </div>

                <div style={{ marginBottom: '10px', width: '95%' }} >
                    <Label>Name</Label>
                    <Inputbox
                        type="text"
                        placeholder='Name'
                        style={{ width: '100%' }}
                        value={lineItem?.name}
                        onChange={(e) => setLineItem({ ...lineItem, name: e.target.value })}
                    />
                </div>
                <div style={{ marginBottom: '10px', width: '95%' }} >
                    <Label>Registration number</Label>
                    <Inputbox
                        type="text"
                        placeholder='Registration number'
                        style={{ width: '100%' }}
                        value={lineItem?.description}
                        onChange={(e) => setLineItem({ ...lineItem, 'description': e.target.value })}
                    />
                </div>
                <div style={{ width: '95%' }}>
                    <Label>Rate</Label>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                        <CustomUrl type='number' placeholder='Rate'
                            value={lineItem?.value}
                            onChange={(e) => setLineItem({ ...lineItem, value: e.target.value })}
                        />
                        <span style={{
                            position: 'absolute',
                            right: '10px',
                            fontSize: '14px',
                            color: '#333'
                        }}>
                            %
                        </span>
                    </div>
                </div>
                     {/* {isNew ? <div style={{ marginBottom: '10px', width: '97%' }} >
                    <Label>Tax agency</Label>
                    <div style={{display:'flex'}}>
                    <Inputbox
                        type="text"
                        placeholder='Tax agency'
                        style={{ width: '100%' }}
                        value={lineItem?.taxAgencyName}
                        onChange={(e) => setLineItem({ ...lineItem, taxAgencyName: e.target.value })}
                    />
                    <IconButton onClick={()=>{
                        setLineItem({ ...lineItem, taxAgencyName: "" })
                        setNew(false)}} style={{marginLeft:'5px'}}>
                    <FiTrash style={{fontSize:'18px'}}/>
                    </IconButton>
                    </div>
                </div> :
                    <div style={{ width: '97%' }}>
                        <Label>Tax agency</Label>
                        <Selectbox style={{ width: '100%', height: '40px', background: 'white', fontSize: 14, borderRadius: 8 }}
                            onChange={(e) => {
                                console.log(e.target)
                                if(e?.target?.value==="+ New tax agency"){
                                    setLineItem({ ...lineItem, taxAgencyName: "" })
                                    setNew(true)
                                }
                                else{
                                setLineItem({ ...lineItem, taxAgencyName: e.target?.value })
                                }
                            }}
                            renderValue={() => lineItem?.taxAgencyName ? lineItem?.taxAgencyName : "Select agency"}
                            defaultValue={"0"}

                        >
                            {["+ New tax agency",...props?.taxAgency]?.map((item, index) =>
                                <StyledMenuItem key={item} value={item}>
                                    {item}
                                </StyledMenuItem>
                            )}
                        </Selectbox>
                    </div>} */}
                {/* <div style={{ display: 'flex', marginTop: '10px', alignSelf: 'start' }}>
                    <Checkbox
                        style={{ width: '25px', marginRight: '10px' }}
                        checked={lineItem.defaultTax}
                        onChange={() => setLineItem((prev) => ({ ...prev, defaultTax: !prev.defaultTax }))}



                    />
                    <Label style={{ marginTop: '12px', marginBottom: 0 }}>Default</Label>
                </div> */}
            </Flexbox>
        </>
    )
}

export default AddTax;


const Flexbox = styled.div`
width:32wv;
margin-left:10px;
flex-direction:column;
align-items:start;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`

const Inputbox = styled.input`
height: 35px;
font-size: 14px;
padding-left:10px;
width:100%;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;

border-radius:4px;
align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-top:15px;
    margin-bottom: 8px;
    margin-left:5px;
    align-self: flex-start;
    font-family:Inter;

  
`

const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:4px;
height: 35px;
width:99.5%;

&:focus {
    outline: none;
    border: 1px solid #000; /* Remove border on focus */
  }
`
const InputText = styled.textarea`
  font-family: Inter;
  height: 40px;
  font-size: 14px;
  padding: 10px;
  word-wrap: break-word;
  width:100%;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical; 
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;

  
p{
    text-align:start;
    display:flex;
    justify-content:start;
    align-self:start;
    margin-top:0px;
    margin-bottom:5px;
}
  .input{
    padding: 10.5px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    
    width: 20%;
    align-items: center;
    
    color: #344054;
 
  
    &:focus {
      outline: none;
      border-color: #000;
      box-shadow: none;
    }
  }
`;

const Selectbox = styled(Select)`
width:300px;
border-radius:8px;
text-align:start;
display:flex;
border:1px solid #f3f3f3;
height:38px;
font-size:14px;

.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
align-self:start;
@media (min-width: 260px) and (max-width: 700px){
    zoom:0.6;
}
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
