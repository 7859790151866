import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Draggable from "react-draggable";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Resizable } from "re-resizable";
import styled from "styled-components";
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaBuilding, FaSignature, FaCalendar, FaTextWidth, FaFilePdf, FaCheckSquare } from "react-icons/fa";
import { FiTrash, FiUpload, FiX } from "react-icons/fi";
import { palette } from "../../utils/Palette";
import RippleButton from "../../ReuseableComponets/RippleButton";

import InputBox from "../../ReuseableComponets/InputBox";
import { UploadImage, UploadNotesImage } from "../../store/Actions/dashboard.action";
import { useDispatch } from "react-redux";
import { Cancel } from "@mui/icons-material";
import { createDigitalTemplate } from "../../store/Actions/SettingsAction";
import { CircularProgress } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfTemplateEditor = (props) => {
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [fields, setFields] = useState([]);
    const [isResizing, setIsResizing] = useState(false);
    const pdfContainerRef = useRef();
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const buttons = [
        { label: "Name", type: "firstname", assigned: 'customer', icon: <FaUser /> },
        { label: "Email", type: "email", assigned: 'customer', icon: <FaEnvelope /> },
        { label: "Phone", type: "phone", assigned: 'customer', icon: <FaPhone /> },
        { label: "Address", type: "address", assigned: 'customer', icon: <FaMapMarkerAlt /> },
        { label: "Business Name", type: "businessname", assigned: 'customer', icon: <FaBuilding /> },
        { label: "Checkbox", type: "checkbox", assigned: 'customer', isEditable: true, icon: <FaCheckSquare /> },
        { label: "Text", type: "text", assigned: 'customer', isEditable: true, icon: <FaTextWidth /> },
        { label: "Signature", type: "signHere", assigned: 'customer', icon: <FaSignature /> },
    ];
    const [draggingType, setDraggingType] = useState(null);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [selectedFieldId, setSelectedFieldId] = React.useState(null);
    const [value, setValue] = useState({
        "name": "",
        "description": "",
        "pdfFile": '',
    })


    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setLoader(true);
        console.log(file, "sckcdnjksncdj")
        const res = await dispatch(UploadNotesImage([file]));
        if (res?.status === true) {
            setValue({ ...value, pdfFile: res?.data[0], fileName: file?.name })
        }
        setLoader(false);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const addField = (type, label) => {
        if (pdfContainerRef.current) {
            const containerRect = pdfContainerRef.current.getBoundingClientRect();
            const x = containerRect.width / 2 - 50;
            const y = 50;
            setFields([
                ...fields,
                { id: Date.now(), type, label, x, y, width: 100, height: 38 },
            ]);
        }
    };

    console.log(pdfFile, fields, "csknsd")

    const saveTemplate = () => {
        const templateData = { fields };
        console.log("Saved Template JSON:", templateData);
        // Send to backend
    };

    const updateFieldPosition = (index, x, y) => {
        setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = { ...updatedFields[index], x, y };
            return updatedFields;
        });
    };

    const updateFieldSize = (index, width, height) => {
        setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = { ...updatedFields[index], width, height };
            return updatedFields;
        });
    };
    const deleteField = (id) => {
        setFields((prevFields) => prevFields.filter((field) => field.id !== id));
    };

    const handleFieldClick = (fieldId) => {
        setSelectedFieldId(fieldId);
    };

    const handleOutsideClick = (e) => {
        if (!e.target.closest(".resizable-field")) {
            setSelectedFieldId(null);
        }
    };
    React.useEffect(() => {
        const handleOutsideClick = (event) => {
            if (pdfContainerRef.current && pdfContainerRef.current.contains(event.target)) {
                setSelectedFieldId(null);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const onSubmitTemplate = async () => {
        await dispatch(createDigitalTemplate({
            "name": value?.name,
            "description": value?.description,
            "pdfLink": pdfFile,
            "fields": fields
        }))
        props?.onClose()
    }
    return (
        <Container>
            <HeaderContainer>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CloseButton onClick={() => props?.onClose()}>
                        <FiX />
                    </CloseButton>
                    <h4 >{"Digital Contract"}</h4>
                </div>
                <RippleButton onClick={() => {
                    if (!pdfFile) {
                        setPdfFile(value?.pdfFile)
                    }
                    else {
                        onSubmitTemplate()
                    }
                }}>{pdfFile ? "Save" : 'Continue'}</RippleButton>
            </HeaderContainer>

            <div style={{ display: 'flex' }}>
                {pdfFile && (
                    <div
                        style={{
                            width: "180px",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "15px",

                            overflowY: "auto",
                            height: "90vh",
                        }}
                    >

                        {/* {selectedFieldId ? <div style={{
                            width: "165px",
                            border: "1px solid #ddd",
                            padding: "5px",
                            fontSize: '13px',
                            textAlign: 'start'
                        }}>
                            <h5 style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: '14px'
                            }}>
                                {fields.find((field) => field.id === selectedFieldId)?.label}
                                <FiTrash
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteField(selectedFieldId);
                                    }}
                                />
                            </h5>
                            <div style={{ marginTop: '10px' }}>
                                <label style={{ fontWeight: 'bold' }}>Assigned To:</label>
                                <select
                                    style={{
                                        width: '100%',
                                        padding: '5px',
                                        marginTop: '5px',
                                        fontSize: '13px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => {

                                    }}
                                    value={selectedFieldId?.assigned}
                                >
                                    <option value="">Select</option>
                                    <option value="customer">Customer</option>
                                    <option value="sales_rep">Sales Rep</option>
                                </select>
                            </div>
                        </div>
                            :  */}
                        <Document
                            file={pdfFile}
                            onLoadSuccess={({ numPages }) => {
                                setNumPages(numPages);
                            }}
                            className="pdf-document"
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <div
                                    key={`thumbnail_${index + 1}`}
                                    style={{
                                        width: "165px",
                                        height: "auto",
                                        cursor: "pointer",
                                        border: selectedPage === index + 1 ? `2px solid ${palette.newBlue}` : "1px solid #ccc",
                                        background: "#f5f5f5",
                                        borderRadius: "5px",
                                        marginBottom: '10px',
                                        overflow: "hidden",

                                    }}
                                    onClick={() => {
                                        setSelectedPage(index + 1);
                                        const pageElement = document.querySelector(
                                            `.pdf-page:nth-child(${index + 1})`
                                        );
                                        if (pageElement) {
                                            pageElement.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }}
                                >
                                    <Page
                                        pageNumber={index + 1}
                                        width={150}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                </div>
                            ))}
                        </Document>
                    </div>
                )}


                <div style={{ overflow: 'auto', width: pdfFile ? "794px" : '500px', maxHeight: '91vh' }}>
                    <div
                        ref={pdfContainerRef}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                            e.preventDefault();
                            const containerRect = pdfContainerRef.current.getBoundingClientRect();
                            const scaleFactor = 794 / containerRect.width;
                            const data = JSON.parse(e.dataTransfer.getData("field"));
                            const x = (e.clientX - containerRect.left) * scaleFactor - 50;
                            const y = (e.clientY - containerRect.top) * scaleFactor - 10;
                            console.log(data)
                            setFields([
                                ...fields,
                                { id: Date.now(), type: data.type, label: data.label, x, y, width: 130, height: 38, pageNumber: selectedPage, isEditable: data.isEditable || false, isCustom: data?.isCustom || false },
                            ]);
                        }}
                        style={{
                            position: "relative",

                        }}

                    >
                        {pdfFile ?
                            (
                                <Document
                                    file={pdfFile}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="pdf-document"
                                >
                                    <div
                                        className="pdf-page"
                                        key={`page_${selectedPage + 1}`}
                                    >
                                        <Page
                                            key={`page_${selectedPage}`}
                                            pageNumber={selectedPage}
                                            width={794}
                                            renderTextLayer={false}
                                        />
                                    </div>
                                    {/* {Array.from(new Array(numPages), (el, index) => (
                                        <div
                                            className="pdf-page"
                                            key={`page_${index + 1}`}
                                        >
                                            <Page
                                                key={`page_${selectedPage}`}
                                                pageNumber={selectedPage}
                                              width={794}
                                                renderTextLayer={false}
                                            />
                                        </div>
                                    ))} */}
                                </Document>
                            ) :
                            <DragArea

                                htmlFor={value?.pdfFile ? "" : "fileInput"}
                            >
                                {value?.pdfFile ?
                                    <UploadIcon style={{ position: 'relative' }}>
                                        <Cancel onClick={() => setValue({ ...value, pdfFile: '' })} style={{ position: 'absolute', top: -5, right: -5, color: 'black' }} />
                                        <FaFilePdf style={{ fontSize: '80px' }} />
                                        <ExpendTitle style={{ fontSize: '16px' }}>{value?.fileName}</ExpendTitle>
                                    </UploadIcon>
                                    : <>
                                        <UploadIcon>
                                            {loader ? (
                                                <CircularProgress /> 
                                            ) : (
                                                <FiUpload style={{ fontSize: '60px' }} />
                                            )}
                                        </UploadIcon>

                                        <ExpendTitle>Click to upload Pdf file</ExpendTitle>
                                        <ExpendTitle>Max 20MB</ExpendTitle>
                                        <FileInput

                                            id="fileInput"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleFileUpload}
                                        />
                                    </>}
                            </DragArea>
                        }

                        {fields.map((field, index) => (
                            field?.pageNumber === selectedPage &&
                            <Draggable
                                key={field.id}
                                bounds="parent"
                                disabled={isResizing}
                                handle={`.drag-handle-${field.id}`}
                                position={{ x: (field.x), y: field.y }}
                                onStop={(e, data) =>
                                    updateFieldPosition(index, data.x, data.y)
                                }
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: field.width,
                                        height: field.height,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFieldClick(field);
                                    }}
                                >
                                    {selectedFieldId?.id === field?.id && <div style={{ position: 'absolute', top: '-40px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', right: 0 }}>
                                        <select
                                            style={{
                                                width: '100%',
                                                padding: '4px',
                                                marginTop: '5px',
                                                fontSize: '13px',
                                                border: '1px solid #ccc',
                                                background: '#f5f5f5',
                                                borderRadius: '5px'
                                            }}
                                            onChange={(e) => {

                                            }}
                                            disabled={selectedFieldId?.type === 'signature' ? false : true}
                                            value={selectedFieldId?.assigned}
                                        >
                                            <option disabled value="">Assigned to</option>
                                            <option value="customer">Customer</option>
                                            <option value="sales_rep">Sales Rep</option>
                                        </select>

                                        <FiTrash onClick={(e) => {
                                            e.stopPropagation();
                                            deleteField(selectedFieldId?.id);
                                        }} style={{ fontSize: '20px', color: 'red', marginLeft: '10px', cursor: 'pointer' }} />

                                    </div>}
                                    <Resizable
                                        size={{ width: field?.type === 'checkbox' ? '30px' : field.width, height: field?.type === 'checkbox' ? '20px' : field.height }}
                                        minWidth={30}
                                        minHeight={20}
                                        maxWidth={800}
                                        maxHeight={600}
                                        style={{
                                            border: "1px solid #000",
                                            background: "rgba(0,123,255,0.2)",
                                            zIndex: 1000,
                                            transition: "width 0.2s, height 0.2s, transform 0.2s",
                                        }}
                                        enable={{
                                            top: true,
                                            right: true,
                                            bottom: true,
                                            left: true,
                                            topRight: true,
                                            bottomRight: true,
                                            bottomLeft: true,
                                            topLeft: true,
                                        }}
                                        onResizeStart={() => setIsResizing(true)}
                                        onResizeStop={(e, direction, ref, delta) => {
                                            const newWidth = parseInt(ref.style.width, 10);
                                            const newHeight = parseInt(ref.style.height, 10);

                                            setFields((prevFields) => {
                                                const updatedFields = [...prevFields];
                                                const updatedField = { ...updatedFields[index] };


                                                if (direction.includes("left")) {
                                                    const widthDiff = newWidth - updatedField.width;
                                                    updatedField.x -= widthDiff;
                                                }
                                                if (direction.includes("top")) {
                                                    const heightDiff = newHeight - updatedField.height;
                                                    updatedField.y -= heightDiff;
                                                }


                                                updatedField.width = newWidth;
                                                updatedField.height = newHeight;

                                                updatedFields[index] = updatedField;
                                                return updatedFields;
                                            });

                                            setIsResizing(false);
                                        }}

                                        handleStyles={selectedFieldId?.id === field?.id && {
                                            topLeft: {
                                                width: "5px",
                                                height: "5px",
                                                border: '1px solid #000',
                                                background: "#fff",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                top: "-3px",
                                                left: "-3px",
                                                zIndex: 10,
                                                cursor: "nw-resize",
                                            },
                                            topRight: {
                                                width: "5px",
                                                height: "5px",
                                                border: '1px solid #000',
                                                background: "#fff",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                top: "-3px",
                                                right: "-3px",
                                                zIndex: 10,
                                                cursor: "ne-resize",
                                            },
                                            bottomLeft: {
                                                width: "5px",
                                                height: "5px",
                                                border: '1px solid #000',
                                                background: "#fff",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                bottom: "-3px",
                                                left: "-3px",
                                                zIndex: 10,
                                                cursor: "sw-resize",
                                            },
                                            bottomRight: {
                                                width: "5px",
                                                height: "5px",
                                                border: '1px solid #000',
                                                background: "#fff",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                bottom: "-3px",
                                                right: "-3px",
                                                zIndex: 10,
                                                cursor: "se-resize",
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            {field?.type === 'checkbox' ? <div
                                                className={`drag-handle-${field.id}`}
                                                style={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    left: "5px",
                                                    right: "5px",
                                                    bottom: "5px",
                                                    cursor: "move",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >



                                            </div> : <div
                                                className={`drag-handle-${field.id}`}
                                                style={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    left: "5px",
                                                    right: "5px",
                                                    bottom: "5px",
                                                    cursor: "move",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >

                                                <span style={{ fontSize: '14px' }}>{field.label}</span>

                                            </div>}
                                        </div>
                                    </Resizable>
                                </div>
                            </Draggable>
                        ))}
                    </div>
                </div>
                {pdfFile ? <ButtonGridContainer>
                    <h4>Contact</h4>
                    <ButtonGrid>
                        {buttons.map((field, index) => (
                            <StyledButton
                                key={index}
                                draggable
                                onDragStart={(e) => {
                                    e.dataTransfer.setData("field", JSON.stringify({ type: field.type, label: field.label, isEditable: field.isEditable }));
                                    setDraggingType(field.type);
                                }}
                                onDragEnd={() => setDraggingType(null)}
                                style={{
                                    borderLeft: field.type === draggingType ? "8px solid blue" : "1px solid #ccc",
                                    backgroundColor: field.type === draggingType ? "rgba(0,123,255,0.1)" : "#f5f5f5",
                                }}
                            >
                                <IconWrapper>{field.icon}</IconWrapper>
                                <span>{field.label}</span>
                            </StyledButton>
                        ))}
                    </ButtonGrid>
                    <h4 style={{ marginTop: '20px' }}>Custom field</h4>
                    <ButtonGrid>
                        {props?.custom?.map((field, index) => (
                            <StyledButton
                                key={index}
                                draggable
                                onDragStart={(e) => {
                                    e.dataTransfer.setData("field", JSON.stringify({ type: field.type, label: field.label, isEditable: field?.isEditable, isCustom: field?.isCustom }));
                                    setDraggingType(field.type);
                                }}
                                onDragEnd={() => setDraggingType(null)}
                                style={{
                                    borderLeft: field.type === draggingType ? "8px solid blue" : "1px solid #ccc",
                                    backgroundColor: field.type === draggingType ? "rgba(0,123,255,0.1)" : "#f5f5f5",
                                }}
                            >

                                <span>{field.label}</span>
                            </StyledButton>
                        ))}
                    </ButtonGrid>
                </ButtonGridContainer> : <div>
                    <InputBox
                        header={"Template name"}
                        placeholder={"Template name"}
                        style={{ width: '250px' }}
                        onChange={(val) => setValue({ ...value, name: val?.target?.value })}
                    />
                    <div>
                        <Label>Description</Label>
                        <InputText
                            placeholder="Template description"
                            style={{ width: '250px' }}
                            onChange={(val) => setValue({ ...value, description: val?.target?.value })}
                        />
                    </div>
                </div>}

            </div>
        </Container>
    );
};

export default PdfTemplateEditor;

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:0px 10px;
    overflow:hidden;

    .pdf-page {
        
        border: 1px solid #ddd;
        overflow: hidden;
        position: relative;
        page-break-after: always;
    }

    .pdf-page:last-child {
        page-break-after: auto;
    }
`;

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-left:15px;
    height: auto;
`;
const ButtonGridContainer = styled.div`
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start;
    flex-direction:column;
    flex-wrap: wrap;
    height: auto;
    max-height: 100%;
    overflow-y: auto;
   h4{
       margin-top:0px;
       margin-left:15px;
       margin-bottom:10px;
   }
`;
const StyledButton = styled.button`
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 7px;
    height: 45px;
    width:140px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    [draggable] {
        transition: all 0.2s ease;
    }
    [draggable]:active {
        cursor: grabbing;
    }
    .dragging {
        border: 2px dashed blue;
        background: rgba(0, 123, 255, 0.2);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
    &:hover {
        background-color: #e0e0e0;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #007bff;
`;

const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    color: rgba(32, 32, 32, 0.90);
    display: flex;
    margin-top:13px;
    margin-bottom: 8px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-break: break-word; 
    overflow-wrap: anywhere; 

`;

const DragArea = styled.label`
  border: 2px dashed #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
margin:20px;
  background-color: #fafbff;
 
  color: #666;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #f1f6ff;
    border-color: #7b7bf5;
  }

  &.drag-over {
    background-color: #e6f0ff;
    border-color: #6b6bf5;
  }
`;

const UploadIcon = styled.div`
  font-size: 60px;
  color: #a9a9f5;
  margin-bottom: 10px;
`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 100px;
height: 100px;

`
const ProfilePic = styled.img`
background:#fff;
color:white;
height:80px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
font-weight:bold;
`

const ExpendTitle = styled.h4`
 
  margin:0px;
  text-align:start;
`;

const FileInput = styled.input`
  display: none;
`;
const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width:100%;
margin-bottom:15px;
margin-top:5px;
h4{
    margin:0;
}
`;

const CloseButton = styled.button`
background: none;
border: none;
cursor: pointer;
font-size:18px;
margin-right:10px;
display:flex;
`;
const InputText = styled.textarea`
font-family:Inter;
width: 100%;
border-radius: 5px;
font-size: 16px;
height: 75px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 0.5px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;