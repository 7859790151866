import React, { useEffect } from "react";
import styled from "styled-components";
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { googleUrl } from "../../store/Actions/SettingsAction";
import { FaGoogle } from "react-icons/fa";
function Connections() {
  //const [businessDetails, setBusinessDetails] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [isconnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();
  const facebookLogin = () => {
    const loginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: "email,public_profile",
      auth_type: "rerequest",
    };
    window.FB.login(loginOptions)
      .then((response) => {
        let array = response.authResponse.grantedScopes.split(",");
        if (array.length === 3) {
        } else {
          alert(
            "You need to provide all the facebook permissions to use the app."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const init = async () => {
    const token = await localStorage.getItem("fbToken");
    const tokenVal = JSON.parse(token);
    if (tokenVal?.token) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  };

  useEffect(() => {
    init();

    // window.FB.init({

    //   appId: '232761532983156',
    //   xfbml: true,
    // version: 'v3.2'
    // });
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId:
            "943526723324-3o55fkb05jroufqdffsb7ur94nt11nvf.apps.googleusercontent.com",
          plugin_name: "chats",
          scope: "https://www.googleapis.com/auth/business.manage",
        })
        .then(() => {
          //checkConnectionStatus();
        });
    });
  }, []);
  const responseFacebook = async (response) => {
    console.log(response);
    const response1 = await fetch(
      `https://graph.facebook.com/v12.0/${response.id}/accounts?access_token=${response.accessToken}&api_version=v12.0&access_token_source=TEST_USER`
    );
    const json = await response1.json();
    console.log(json, "json");
    localStorage.setItem(
      "fbToken",
      JSON.stringify({
        id: json?.data[0]?.id,
        token: response?.accessToken,
      })
    );
    // const response2 = await fetch(`https://graph.facebook.com/v12.0/${json?.data[0]?.id}?access_token=${response.accessToken}&api_version=v12.0&access_token_source=TEST_USER`);
    // const json = await response1.json();
    // console.log(json,"json")
    // setJsonData(json)
    // fetch(`https://graph.facebook.com/v12.0/${response.id}/leadgen_forms?access_token=${json.access_token}`)
    //   .then(response => response.json())
    //   .then(leadsDat => {
    //     console.log(leadsDat)

    //   })
  };

  const checkConnectionStatus = () => {
    window.gapi.client
      .request({
        path: "https://mybusiness.googleapis.com/v4/accounts",
      })
      .then((response) => {
        const { result } = response;
        if (result && result.accounts && result.accounts.length > 0) {
          // setBusinessDetails(result.accounts[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const responseGoogle = (response) => {
    console.log(response, "responsee");
    const headers = new Headers({
      Authorization: `Bearer ${response.accessToken}`,
    });
    fetch(`https://mybusiness.googleapis.com/v4/accounts`, {
      headers,
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setIsConnected(true);
        if (data.accounts && data.accounts.length > 0) {
          //setBusinessDetails(data.accounts[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGoogleConnect = async () => {
    try {
      const res = await dispatch(googleUrl());
      const url = res?.data?.url;

      if (url) {
        // Open the URL in a centered popup
        const width = 600;
        const height = 700;
        const left = window.screenX + (window.innerWidth - width) / 2;
        const top = window.screenY + (window.innerHeight - height) / 2;

        const popup = window.open(
          url,
          "GoogleAuth",
          `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars=yes,status=1`
        );

        // Listen for messages from the popup
        const handleMessage = (event) => {
          if (event.data.type === "oauth-callback") {
            console.log("Authorization Code:", event.data.code);
            console.log("State:", event.data.state);
            setIsConnected(true);

            // Clean up listener and close popup
            window.removeEventListener("message", handleMessage);
            if (popup) popup.close();
          }
        };

        window.addEventListener("message", handleMessage);

        // Clean up if the popup is manually closed
        const timer = setInterval(() => {
          if (popup.closed) {
            clearInterval(timer);
            window.removeEventListener("message", handleMessage);
          }
        }, 500);
      }
    } catch (error) {
      console.error("Error connecting to Google:", error);
    }
  };

  return (
    <Main>
      <Header>Connections</Header>
      <Name>
      Manage and secure your account by connecting with trusted services or updating your login preferences
      </Name>
      <Container>
        <ConnectButton onClick={handleGoogleConnect}>
          <FaGoogle />
          Connect with Google
        </ConnectButton>

        <StatusText isConnected={isconnected}>
          <StatusIndicator isConnected={isconnected} />
          {isconnected ? "Connected" : "Not connected yet"}
        </StatusText>
      </Container>
    </Main>
  );
}
export default Connections;

const Main = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  padding-bottom: 70px;
  margin-bottom: 50px;
  max-width: 72%;
  padding-right: 30px;
  padding-left: 30px;
`;
const Head = styled.div`
  width: 100%;
  @media (min-width: 260px) and (max-width: 1311px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`;
const Segment = styled.div`
  margin: 50px;
  padding: 25px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  .fb {
    height: 35px;
    background: #4169e1;
    color: #fff;
    border: 1px solid #4169e1;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const Name = styled.p`
text-align:start;
font-size: 14px;
line-height: 21px;
color: rgb(45, 47, 49);
font-weight: 400;
margin-top:-15px;
margin-bottom:0px;
`

const Header = styled.p
    `
font-weight: 600;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-top:40px;
margin-right:10px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  width: 100%;
  margin: 25px 0px;
  background-color: #fff;
`;

const ConnectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #4285f4;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: none;

  &:hover {
    background-color: #357ae8;
  }

  &:active {
    background-color: #2c5dc4;
  }
`;

const StatusText = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => (props.isConnected ? "green" : "gray")};
`;

const StatusIndicator = styled.span`
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${(props) => (props.isConnected ? "green" : "red")};
  margin-right: 8px;
`;
