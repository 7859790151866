import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
const initialState = {
  loader: false,
  formLoader:false,
  buttonLoader: false,
  preview: false,
  hours: 4,
  minutes: 0,
  value: {
    title: "",
    description: "",
    autoBooking: false,
    image: {
      url: "",
      key: "",
      alt: "",
    },
    fields: [],
    workingHours: {
      duration: {
        hours: 4,
        minutes: 0,
      },
    },
    payment: false,
  },
  selectedDays: {
    monday: { enabled: true, start: "08:00am", end: "05:00pm" },
    tuesday: { enabled: false, start: "08:00am", end: "05:00pm" },
    wednesday: { enabled: false, start: "08:00am", end: "05:00pm" },
    thursday: { enabled: true, start: "08:00am", end: "05:00pm" },
    friday: { enabled: true, start: "08:00am", end: "05:00pm" },
    saturday: { enabled: false, start: "08:00am", end: "05:00pm" },
    sunday: { enabled: false, start: "08:00am", end: "05:00pm" },
  },
  error: "",
  uniqueId: "",
  allData: {},
};

export const createOnlineForm = createAsyncThunk(
  "jobForm/create",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.post(
        ServiceEnum.createOnlineJobForm,
        value
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const updateOnlineForm = createAsyncThunk(
  "jobForm/update",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.put(
        `${ServiceEnum.UpdateOnlineJobForm}?formId=${value?.id}`,
        value
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const getOnlineBookingForm = createAsyncThunk(
  "jobForm/get",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.get(ServiceEnum.getonlineJobForm);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getonlineFormData = createAsyncThunk(
  "jobForm/get",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.get(
        `${ServiceEnum.getonlineJobId}?uniqueId=${value}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const getonlineFormDataId = createAsyncThunk(
  "jobForm/getform",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.get(
        `${ServiceEnum.getonlineJobFormId}?formId=${value}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const deleteonlineFormDataId = createAsyncThunk(
  "jobForm/deleteform",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.delete(
        `${ServiceEnum.deleteOnlineForm}?formId=${value}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const submitonlineFormData = createAsyncThunk(
  "jobForm/submit",
  async (value, { getState, rejectWithValue }) => {
    try {
      const response = await NetworkOps.post(
        `${ServiceEnum.submitOnlineForm}?formId=${value?.id}`,
        value
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const jobFormSlice = createSlice({
  name: "jobForm",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
    updateValue: (state, action) => {
      state.value = action.payload;
    },
    updateSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    },
    toggleDay: (state, action) => {
      const day = action.payload;
      state.selectedDays[day].enabled = !state.selectedDays[day].enabled;
    },
    updateTime: (state, action) => {
      const { day, type, value } = action.payload;
      state.selectedDays[day][type] = value;
    },
    setHours: (state, action) => {
      state.hours = action.payload;
    },
    setMinutes: (state, action) => {
      state.minutes = action.payload;
    },
    resetJobForm: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOnlineForm.pending, (state) => {
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(createOnlineForm.fulfilled, (state, action) => {
        if (action.payload?.success === true) {
          state.loader = false;

          state.uniqueId = action.payload?.data?.uniqueId || "";
          state.buttonLoader = false;
        } else {
          state.error = action.payload?.error || "Something went wrong";
          state.buttonLoader = false;
        }
   
      })
      .addCase(createOnlineForm.rejected, (state, action) => {
        state.buttonLoader = false;
        state.error = action.payload?.error || "Something went wrong";
       
      })
      .addCase(updateOnlineForm.fulfilled, (state, action) => {
        if (action.payload?.success === true) {
          state.loader = false;

          state.uniqueId = action.payload?.data?.uniqueId || "";
          state.buttonLoader = false;
        } else {
          state.error = action.payload?.error || "Something went wrong";
          state.buttonLoader = false;
        }

      })
      .addCase(updateOnlineForm.rejected, (state, action) => {
        state.buttonLoader = false;
        state.error = action.payload?.error || "Something went wrong";
 
      })
      .addCase(getOnlineBookingForm.pending, (state, action) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(getOnlineBookingForm.fulfilled, (state, action) => {
        state.loader = false;
        state.buttonLoader = false;
        if (action.payload?.success === true) {
          state.loader = false;
          state.allData = action.payload?.data || {};
        } else {
          state.loader = false;
        }
      })
      .addCase(getonlineFormDataId.pending, (state, action) => {
        state.formLoader = true;
        state.error = null;
      })
      .addCase(getonlineFormDataId.fulfilled, (state, action) => {
        state.formLoader = false;
        state.buttonLoader = false;
        if (action.payload?.success === true) {
          state.loader = false;
          (state.value = {
            title: action?.payload?.data?.title || "",
            description: action?.payload?.data?.description || "",
            autoBooking: action?.payload?.data?.autoBooking || false,
            image: action?.payload?.data?.image || {
              url: "",
              key: "",
              alt: "",
            },
            fields: action?.payload?.data?.fields || [],
            workingHours: {
              duration: {
                hours: 4,
                minutes: 0,
              },
            },
            payment: action?.payload?.data?.settings?.payment?.required || false,
          }),
            (state.selectedDays =
              action?.payload?.data?.settings?.workingHours),
            (state.hours =
              action?.payload?.data?.settings?.workingHours?.duration?.hours),
            (state.minutes =
              action?.payload?.data?.settings?.workingHours?.duration?.minutes),
            (state.allData = action.payload?.data || {});
        } else {
          state.loader = false;
        }
        console.log("API Response:", action.payload?.data);
      });
  },
});

export const {
  setLoader,
  setPreview,
  updateValue,
  updateSelectedDays,
  toggleDay,
  updateTime,
  setHours,
  setMinutes,
  resetJobForm,
} = jobFormSlice.actions;

export default jobFormSlice.reducer;
