import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { deleteCrew, getListForm, } from "../../store/Actions/dashboard.action";
import trash from '../../assets/trash.png'
import moment from "moment";
import { Box, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import plus from '../../assets/add.png'
import cross from '../../assets/cross.png'
import { calendarTeamColor, formatMobileNumber } from "../../utils/Commonfunction";
import FormDetails from "../../Components/Dashboard/FormDetails";
import RightModal from "../../Components/RightSideModal";
import { pendingInvite } from "../../store/Actions/dashboard.action";
import AddTeamMember from "../../Components/Settings/AddTeamMember";
import Modal from "../../Components/Modal";
import { plusIcon } from "../../assets";
import Header from "../../Components/Header/MainHeader";
import { palette } from "../../utils/Palette";
import { getAllCrew } from "../../store/Actions/SettingsAction";
import { Person2 } from "@mui/icons-material";


const CrewMember = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [isModalopen, setModalOpen] = useState(false)
    const [formDetailModal, setFormDetailModal] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [formNumber, setFormNumber] = useState('')
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getAllCrew())
        if (res.status === true) {
            setData(res?.data)
        }
        setLoader(false)
    }

    useEffect(() => {
        init()

    }, [])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    return (
        <Body>
            <Header nonsearchable />
            <FlexboxContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginLeft: '20px' }}>
                    <h2 style={{ textAlign: 'start' }}>Crew members</h2>

                </div>
                {loader ? <div style={{ display: 'flex', width: '100%',marginTop:'10%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </div>

                    : <CustomTable>

                        <div style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <CustomHead>
                                    <TableRow >

                                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                            Name

                                        </TableCell>
                                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                            Roles
                                        </TableCell>
                                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                            Phone number
                                        </TableCell>
                                        <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                            Email

                                        </TableCell>





                                    </TableRow>
                                </CustomHead>
                                {data?.map((item,index) => (
                                    <TableBody
                                        onClick={() => {
                                            props?.setFormDetailModal(true)
                                            props?.setSelectedData(item)
                                        }}
                                        key={item._id} style={{ background: '#fff' }}>
                                        <TableRow>
                                            <TableCell size="small">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ProfileIcon style={{background:calendarTeamColor[index]?.light,color:calendarTeamColor[index]?.original}}>
                                                        {getName(`${item?.firstName} ${item?.lastName}`)}
                                                    </ProfileIcon>
                                                    <NameLabel>{item?.firstName} {item?.lastName}</NameLabel>
                                                </div>
                                            </TableCell>
                                            <TableCell size="small">
                                                {item?.roles?.[0]?.name}
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>

                                                    <TaskLabel>{formatMobileNumber(item?.number)}</TaskLabel>
                                                </TaskDetails>
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>

                                                    <TaskLabel>{item?.email}</TaskLabel>
                                                </TaskDetails>
                                            </TableCell>


                                            {/* <TableCell size="small">
                                            <TaskDetails>

                                                <TaskLabel>{moment(item?.createdAt)?.format('DD MMM hh:mm A')}</TaskLabel>
                                            </TaskDetails>
                                        </TableCell> */}
                                            {/* <TableCell size="small">
                                            <ViewButton
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                setFormNumber(item?.formNo)
                                                setFormModal(true)
                                            }}
                                            >Open form</ViewButton>
                                        </TableCell> */}

                                        </TableRow>
                                    </TableBody>
                                ))}
                              

                            </Table>
                            {data?.length > 0 ? null : (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        width="100%"
                                        height="60vh"
                                        sx={{ color: 'gray' }}
                                    >
                                       <Person2 sx={{ fontSize: '50px', marginBottom: 2 }} />
                                        <Typography variant="h5" align="center">
                                            No crew member
                                        </Typography>
                                       
                                    </Box>
                                )}
                           
                        </div>

                    </CustomTable>}


                {isModalopen && <RightModal
                    isOpen={isModalopen}
                    onClose={() => setModalOpen(false)} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Add team member</h2>
                        <CloseButton onClick={() => setModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>
                    </div>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <AddTeamMember
                            onCancel={() => {
                                init()
                                setModalOpen(false)
                            }}
                        />
                    </div>
                </RightModal>}
            </FlexboxContainer>
        </Body>
    )
}

export default CrewMember;
const ViewButton = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#f3f3f3;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`

const FlexboxContainer = styled.div`
width:100%;
    background: #fff;
    display: flex;

    flex-direction: column;
    overflow-y:scroll;
  
    
    align-items: flex-start;
`;

const Body = styled.div`
display:flex;
flex-direction:column;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const CustomTable = styled(TableContainer)`
&::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}
border-color: red;

.MuiTableBody-root {
  
  font-family: Inter;
  font-size: 13px;
}

.MuiTableHead-root {
  font-weight: 700;
  font-family: Inter;
  font-size: 14px;
  color:${palette.black};
}

.MuiTableRow-root {
  height: 35px;
  
  font-family: Inter;
  font-size: 13px;
  &:hover {
    background: #f8f8f8;
  }
}

.MuiTableCell-root {
  border-color: #f3f3f3;
  border-right:1px solid #f3f3f3;
  height:10px;
  font-family: Inter;
  white-space: nowrap;
  word-break: break-word;
  border-collapse: separate;
  line-height: 16px;
  color:${palette.black};
  font-size: 13px;
  font-weight:450;
}
.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;

}


.purple {
  background: #974EDE;
}

.green {
  background: #32cd32;
}

.dimgreen {
  background: #008000;
}
`;

const CustomHead = styled(TableHead)`
position: sticky;
top: 0;
background-color: white;
z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
position: sticky;
background:#f9f9f9;
color:${palette.black};
z-index: 1;
min-width:30px;
max-width:30px;

}
`


const ProfileIcon = styled.div`
    background: #2b373f;

    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 10px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: 600;
`;

const NameLabel = styled.label`
    font-weight: bold;
    color: #344054;

`;

const TaskLabel = styled.label`
    color: #344054;
    text-align:start;
    
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
`;

const DateLabel = styled.label`
    margin-top: 5px;
    margin-right: 35px;
    color: #344054;
`;

const ActionIcon = styled.img`
    width: 22px;
    cursor: pointer;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content:start;
    align-self:start;
    max-width:350px;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-wrap;
`;
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
background:#0F52BA;
color:#fff;
font-size:14px;


display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`

