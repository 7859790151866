import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
const ShareEmbedTabs = ({
 

  formNumber,
  handleCopyClick,
  copy,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const handleChangeIndex = (index) => {
      setValue(index);
  };

  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

  return (
    <Box sx={{ bgcolor: "background.paper", width: 500 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#0F52BA",
          },
        }}
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          sx={{ textTransform: "none" }}
          label="Share a link"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Add to website"
          {...a11yProps(1)}
        />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <InputBoxContainer>
            <div style={{ fontSize: "14px", marginBottom: "20px" }}>
              Copy and paste your scheduled link into a message
            </div>
            <Inputbox>
              {`https://app.getnotifi.com/form/${formNumber}`}
              <CopyButton onClick={() => handleCopyClick(formNumber)}>
                {copy}
              </CopyButton>
            </Inputbox>
          </InputBoxContainer>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <h4 style={{ marginTop: 0, textAlign: "start" }}>Embed code</h4>
          <InputBoxContainer>
            <div style={{ fontSize: "14px", marginBottom: "20px" }}>
              Place your code in your HTML where you want your Notifi widget to
              appear
            </div>
            <EmbeddedInput style={{ whiteSpace: "pre-wrap" }}>
              {`
<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
    <iframe src="https://app.getnotifi.com/onlineForm/${formNumber}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe>
</div>
                            `}
            </EmbeddedInput>
          </InputBoxContainer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CopyButton
              style={{ alignSelf: "flex-end", marginRight: -20 }}
              onClick={handleCopyClick}
            >
              Copy code
            </CopyButton>
          </div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" >
   <hello/>
    </div>
  );
};

export default ShareEmbedTabs;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  @media (min-width: 260px) and (max-width: 721px) {
    margin-left: 0px;
  }
`;
const Inputbox = styled.div`
  align-self: center;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #344054;
  background: #f3f3f3;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  padding-left: 10px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const EmbeddedInput = styled.div`
  width: 100%;

  border-radius: 10px;
  display: flex;
  justify-content: start;
  height: auto;
  display: flex;
  font-size: 12px;
  border-style: dotted;

  boder: 1px solid #4169e1;
  font-weight: 400;
  color: gray;
  background: #f3f3f3;
  padding: 20px;
`;
const CopyButton = styled.button`
  border: 1px solid #4169e1;
  background: #4169e1;
  color: #fff;
  width: 150px;
  height: 38px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
`;
