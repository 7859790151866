import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FixedSizeList as List } from "react-window";
import debounce from "lodash.debounce";
import { palette } from "../../utils/Palette";
import { formatMobileNumber } from "../../utils/Commonfunction";
import { Avatar } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";

const Taxsearch = ({ Taxs, onCreateNew, onclose, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [filteredTaxs, setFilteredTaxs] = useState([]);
    const wrapperRef = useRef(null);

    const handleSearch = useCallback(
        debounce((value) => {
            const term = value.toLowerCase();
            const results = Taxs.filter(
                (customer) =>
                    customer.name.toLowerCase().includes(term) ||
                    customer.number?.toLowerCase().includes(term) ||
                    customer.address?.toLowerCase().includes(term) ||
                    customer.businessName?.toLowerCase().includes(term)
            );
            setFilteredTaxs(results);
        }, 300),
        [Taxs]
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        handleSearch(value);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onclose()
            setIsOpen(false);

        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        setFilteredTaxs(Taxs);
    }, [Taxs]);
    const getName = (channel) => {
        if (!channel) {
            return;
        }
        const trimmedChannel = channel.trim();
        const val = trimmedChannel.split(' ');

        if (val.length === 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
        } else {
            return `${val[0]?.charAt(0).toUpperCase()}`;
        }
    };
    const onClickFile = (customer) => {
        console.log("Row clicked", customer);
        onclose();
        onSelect(customer);
    }

    const Row = useCallback(
        ({ index, style }) => {
            const customer = filteredTaxs[index];
            return (
                <div style={{ ...style, pointerEvents: 'auto' }} onClick={() => onClickFile(customer)}>
                    <RowContainer >
                        <RowHeader>
                            <RowName>{filteredTaxs[index].name}</RowName>
                            <RowDetails>{filteredTaxs[index]?.value}%</RowDetails>
                        </RowHeader>
                    </RowContainer>
                </div>
            );
        },
        [filteredTaxs, onclose, onSelect]
    );



    return (
        <MainContainer ref={wrapperRef}>
            <Segment>
                <Input
                    type="text"
                    placeholder="Tax name"
                    onFocus={() => setIsOpen(true)}
                    onChange={handleInputChange}
                    value={searchTerm}
                    autoFocus
                    aria-label="Search Taxs"
                />
                {(
                    <Dropdown

                    >
                        <ListContainer>
                            <AutoSizer style={{ height: Math.min(filteredTaxs.length * 50, 250) }}>
                                {({ height, width }) => (
                                    <List
                                        height={Math.min(filteredTaxs.length * 40, 200)}
                                        itemCount={filteredTaxs.length}
                                        itemSize={50}
                                        width={width}

                                    >
                                        {Row}
                                    </List>
                                )}
                            </AutoSizer>
                        </ListContainer>

                    </Dropdown>
                )}
            </Segment>
        </MainContainer>
    );
};


Taxsearch.propTypes = {
    Taxs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            number: PropTypes.string,
            address: PropTypes.string,
            businessName: PropTypes.string,
        })
    ).isRequired,
    onCreateNew: PropTypes.func.isRequired,
    onclose: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
};

export default Taxsearch;


const MainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius:8px;
  z-index:10000000000;
`;
const Segment = styled.div`
position: relative;
width: 100%;
max-width: 400px;
margin: 0 auto;

background: #fff;
border-radius: 8px;
z-index: 1000;
`
const Input = styled.input`
  width: 70%;
  height: 35px;
  font-size: 16px;
  background: #ffffff;
  color:${palette.charcoleBlack}; 
  border: 1px solid #ddd;
  padding-left: 10px;
  border-radius: 5px;
  outline: none;
  margin: 10px;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;

const Dropdown = styled.div`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  text-align:start;
gap:10px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const ListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const CreateNew = styled.div`
  padding: 10px;
  color: #0f52ba;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ccc;
  background: white;
  font-size:14px;
  border-radius:8px;

  &:hover {
    background-color: #f9f9f9;
  }

  span {
    margin-left: 8px;
  }
`;
const Value = styled.p`
color:${palette.black};
font-size: 13px;
text-align:start;
line-height:16px;
width:100%;
margin-top:0px;
margin-bottom:4px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

.nameButtton{
    font-weight:600;
    color:${palette.charcoleBlack}
}

`
const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
 
  border-top: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
  cursor: pointer;
  position:relative;
  width:100%;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding:10px;
 
`;

const RowName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align:start;
`;

const RowDetails = styled.span`
  font-size: 13px;
  color: #757575;
  text-align:start;
  
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.newBlue};
    font-size: 11px;
    width: 25px;
    height: 25px;
   border-radius:4px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;
