
import AccountSettings from '../../assets/icons/AccountSettings.png'
import security from '../../assets/icons/security.png'
import Taxes from '../../assets/icons/Taxes.png'
import privacy from '../../assets/icons/privacy.png'
import TermsandConditions from '../../assets/icons/TermsandConditions.png'
import logout from '../../assets/logout.png'
import { AutoMode, CheckCircleOutlineSharp, DashboardCustomizeOutlined, FileCopyOutlined, InsertDriveFileOutlined, LeaderboardOutlined, LinkOutlined, LoginOutlined, LogoutOutlined, LowPriorityOutlined, ManageAccountsOutlined, Money, PriceCheck, ReceiptLongOutlined, ReportSharp, ShieldOutlined, TagOutlined } from '@mui/icons-material'
import Payment from '@mui/icons-material/Payment'; 
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import { FiPhoneCall } from 'react-icons/fi'
import { FaRegMoneyBillAlt, FaSignature, FaUserLock } from 'react-icons/fa'

export const DataList=[
   {
    title:'Account Settings',
    icon:'',
       data:[

         {
            title:'Account details',
            screen:'account-details',
            icon:AccountSettings,
            'outlined': ManageAccountsOutlined,
    
         },
         {
            title:'Login and security',
            screen:'login-and-security',
            icon:security,
            'outlined': LoginOutlined,
         },
    
       {
          title:'Tax details',
          screen:'tax-details',
          icon:Taxes,
          'outlined': ReceiptLongOutlined,
         },
    
        
   
 
    
       ]
   },
   {
      title:'General',
      icon:'',
         data:[
  
            {
               title:'Service plans',
               screen:'service-plans',
               icon:Taxes,
               'outlined': PriceCheck,
              },
              {
               title:'Call flow',
               screen:'call-flow',
               icon:Taxes,
               'outlined': FiPhoneCall,
              },
              {
               title:'Payments',
               screen:'payments',
               icon:Money,
               'outlined': FaRegMoneyBillAlt,
              },
          
              {
               title:'Reminders',
               screen:'automations',
               icon:Money,
               'outlined': AutoMode,
              },
            //   {
            //    title:'Digital contract',
            //    screen:'digital-contract',
            //    icon:Money,
            //    'outlined': FaSignature,
            //   },
              {
               title:'Reports',
               screen:'reports',
               icon:Money,
               'outlined': ReportSharp,
              },
              {
               title:'Automation',
               screen:'permissions',
               icon:Money,
               'outlined': FaUserLock,
              },
            //   {
            //    title:'Connections',
            //    screen:'connections',
            //    icon:LinkOutlined,
            //    'outlined': LinkOutlined,
            //   },
     
     
   
      
         ]
     },
   {
      title:'Customizations',
      icon:'',
      data:[

         {
            title:'Custom fields',
            screen:'custom-fields',
            'outlined': DashboardCustomizeOutlined,
           },
           {
            title:'Custom tags',
            screen:'custom-tags',
            'outlined': TagOutlined,
           },
           {
            title:'Job type & priority',
            screen:'type-priority',
            'outlined': LowPriorityOutlined,
           },
           {
            title:'Custom terms',
            screen:'terms-and-conditions',
            icon:Taxes,
            'outlined': InsertDriveFileOutlined,
           },
           {
            title:'Custom lead status',
            screen:'custom-status',
            icon:Taxes,
            'outlined': LeaderboardOutlined,
           },
           {
            title:'Checklist',
            screen:'checklist',
            icon:Taxes,
            'outlined': CheckCircleOutlineSharp,
           },
        
      ]
   },
{
    title:'Help',
    icon:'',
    data:[

     {
        title:'Terms of service',
        screen:'',
        icon:privacy,
        'outlined': FileCopyOutlined,
     },
     {
        title:'Privacy policy',
        screen:'',
        icon:TermsandConditions,
        'outlined': ShieldOutlined,
     },
     {
        title:'Log out',
        screen:'',
        icon:logout,
        'outlined': LogoutOutlined,
     },
   
    ]
}

]
export const DataListFB=[
   {
    title:'Account Settings',
    icon:'',
       data:[

         {
        title:'Account details',
        screen:'Account-details',
        icon:AccountSettings,
        'outlined': ManageAccountsOutlined,

     },
     {
        title:'Login and security',
        screen:'login-and-security',
        icon:security,
        'outlined': LoginOutlined,
     },

   {
      title:'Tax details',
      screen:'tax-details',
      icon:Taxes,
      'outlined': ReceiptLongOutlined,
     },

     {
      title:'terms-and-conditions',
      screen:'TaxDetails',
      icon:Taxes,
      'outlined': InsertDriveFileOutlined,
     },
     {
      title:'Payments',
      screen:'payments',
      icon:Money,
      'outlined': FaRegMoneyBillAlt,
     },
     {
      title:'Reports',
      screen:'reports',
      icon:Money,
      'outlined': ReportSharp,
     },
   
 
    
       ]
   },
{
    title:'Help',
    icon:'',
    data:[

     {
        title:'Terms of service',
        screen:'',
        icon:privacy,
        'outlined': FileCopyOutlined,
     },
     {
        title:'Privacy policy',
        screen:'',
        icon:TermsandConditions,
        'outlined': ShieldOutlined,
     },
     {
        title:'Log out',
        screen:'',
        icon:logout,
        'outlined': LogoutOutlined,
        
     },
   
    ]
}

]

export const StripeDataList = [
   {
     title: 'Account Management',
     icon: ManageAccountsOutlined, 
   },
   {
     title: 'Payments',
     icon: Payment, 
   },
   {
     title: 'Payouts',
     icon: MonetizationOn, 
   },
   // {
   //   title: 'Documents',
   //   icon: SettingsOutlined,
   // }
 ];
 