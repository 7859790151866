import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function TopModal({ children, onClose, customStyle, overlayStyle }) {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay style={overlayStyle} onClick={handleOverlayClick}>
      <Modal
        style={customStyle}
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        {children}
      </Modal>
    </Overlay>
  );
}

const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  justify-content: center;
  align-items: flex-start;
`;

const Modal = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2), 0px 8px 40px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  border-radius: 12px;
  position: relative;
  flex-shrink: 0;
  overflow: auto;
  height: auto;
  max-height: 70vh;
  width: 40%;
  z-index: 150;
`;

export default TopModal;
