import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MoreHoriz } from '@mui/icons-material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,

        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {

        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: '13px',
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenu({ label, menuItems,buttonStyle,Icon,labelLeft, onClick,iconStyle }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="customized-menu-button"
                aria-controls={open ? 'customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={buttonStyle?buttonStyle:{
                    backgroundColor: 'transparent',
                    minWidth: 'auto',
                    padding: 0,
                    border: 'none',
                    position: 'absolute',
                    right: 5,
                    top: 8,
                    fontWeight:'500'
                    // '&:hover': {
                    //     backgroundColor: '#ddd',
                    //   }


                }}
            >
                  {labelLeft && <span>{labelLeft}</span>}
               {Icon?<Icon style={{ color: 'black', fontSize: '18px',...iconStyle }}/>: <MoreHoriz style={{ color: 'black', fontSize: '18px',...iconStyle }} />}
                {label && <span>{label}</span>}
            </Button>
            <StyledMenu
                id="customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'customized-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                {menuItems.map((item, index) => (
                    <div key={index}>


                        {item.divider ? <Divider /> :
                            <MenuItem
                                style={{ fontSize: '13px', fontWeight: '500' }}
                                onClick={() => { onClick(item?.label); handleClose(); }}
                                disableRipple
                                disabled={item.disabled}
                                sx={{ cursor: item.disabled ? 'no-drop' : 'pointer' }}
                            >
                                {item.icon}
                                {item.label}
                            </MenuItem>}
                    </div>
                ))}
            </StyledMenu>
        </div>
    );
}
