import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Warning } from '@mui/icons-material';

const ErrorWrapper = styled.div`
  border: 1px solid #f5c6cb;
  border-left:8px solid #721c24;
  background-color: #f8d7da;
  color: #721c24;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const IconWrapper = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #721c24;
  margin-right:20px;
`;

const ErrorContent = styled.div`
display:flex;
align-items:start;
flex-direction:column;
`;

const ErrorHeader = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  text-align:start;
`;

const ErrorSubheader = styled.div`
  list-style-type: disc;
  color: #721c24;
  text-align:start;
  
`;

const ErrorComponent = ({ header, subheader, className }) => {
  return (
    <ErrorWrapper className={className}>
      <IconWrapper> 
        <Warning/>
      </IconWrapper>
      <ErrorContent>
        <ErrorHeader>{header}</ErrorHeader>
        <ErrorSubheader>{subheader}</ErrorSubheader>
      </ErrorContent>
    </ErrorWrapper>
  );
};


ErrorComponent.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ErrorComponent.defaultProps = {
  className: '',
};

export default ErrorComponent;
