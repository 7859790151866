import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getCalendarDataV2 } from '../../../store/Actions/dashboard.action';
import { calculateTotalTaxAmount, totalprice } from '../../../utils/Commonfunction';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../../utils/Palette';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useSelector } from 'react-redux';


const AllCrew = (props) => {
    const [expanded, setExpanded] = useState(false);
    const detail = useSelector(state => state?.auth?.userDetails)
    const [jobs, setLeads] = useState(props?.data || []);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [currentDate, setCurrentDate] = useState(new Date());
    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    const itemsToShow = expanded ? jobs?.length : 4;
    const getStartAndEndOfWeek = (date) => {
        const startOfWeek = moment(date).startOf('week').subtract(1, 'days').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('week').add(1, 'days').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };

    const init = async (date) => {
        setLoading(true);
        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);
        const res1 = await dispatch(getCalendarDataV2(startOfWeek, endOfWeek))
        const data = [...res1?.data?.detailData]
        const filterData = data.filter(item => moment(item?.date).format('DD-MMM YYYY') === moment(date).format('DD-MMM YYYY'))
        setLeads(filterData)
        setLoading(false);
    }
    useEffect(() => {

    }, [])

    const getName = (channel) => {
        if (!channel) {
            return;
        }
        const trimmedChannel = channel.trim();
        const val = trimmedChannel.split(' ');

        if (val.length === 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
        } else {
            return `${val[0]?.charAt(0).toUpperCase()}`;
        }
    };

    const flattenItems = (items) => {
        return items.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const calculateTotalAmount = (items) => {
        const flattenedItems = flattenItems(items);
        return `${([...flattenedItems].reduce(function (accumulator, currentValue) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
        }, 0) + calculateTotalTaxAmount([...flattenedItems], 0))?.toFixed(2)}`
    };
    const changeDate = (direction) => {
        const newDate = new Date(currentDate);
        if (direction === "left") {
            newDate.setDate(currentDate.getDate() - 1);
        } else if (direction === "right") {
            newDate.setDate(currentDate.getDate() + 1);
        }

        setCurrentDate(newDate);
        init(newDate)
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '20px' }}>
                <h3 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'start', marginLeft: '5px' }}>Crew members</h3>
                {/* <div style={{display:'flex',alignItems:'center'}}>
      <LeadTitle style={{marginRight:'10px'}}>{moment(currentDate).format("DD MMM YYYY")}</LeadTitle>
      <StyledIconBox onClick={() => changeDate("left")}>
        <KeyboardArrowLeft/>
      </StyledIconBox>
      <StyledIconBox onClick={() => changeDate("right")}>
        <KeyboardArrowRight/>
      </StyledIconBox>
      </div> */}
            </div>
            {loading ? (
                <LoaderWrapper>
                    <CircularProgress />
                </LoaderWrapper>
            ) : jobs?.length === 0 ? (
                <Container style={{ display: 'flex', alignItems: 'center', height: '85%', justifyContent: 'center' }}>
                    <NoAppointments>
                        <EventBusyIcon style={{ fontSize: 50, color: palette.grey }} />
                        <NoAppointmentsText>No Crew</NoAppointmentsText>

                    </NoAppointments>
                </Container>
            ) : (
                <Container>
                    {
                        jobs?.map((job) => (
                            <LeadCard onClick={() => {
                                props?.onClick(job)
                            }} key={job._id}>

                                <LeadInfo>
                                    {job?.name  ? <LeadTitle>{`${job?.name || ''}`} </LeadTitle> : <LeadTitle style={{ color: palette.newBlue }}>{`Add contact`} </LeadTitle>}
                                    <LeadTime>{job?.address}</LeadTime>
                                    <LeadTime> {`${job?.email}`}</LeadTime>
                                </LeadInfo>
                                <div>

                                    <LeadTime style={{ width: 'auto', display: 'flex', alignItems: 'center', marginTop: '0px' }}>


                                        {job?.status?.value || job?.status}

                                    </LeadTime>


                                </div>

                            </LeadCard>
                        ))
                    }

                </Container>
            )
            }
        </>
    )
};

export default AllCrew;
const Container = styled.div`
  width: 100%;
  max-width: 98%;
  border-radius: 8px;
  margin-top:15px;
  margin-bottom:25px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc; 
  }

  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.6); 
    transition: color 0.3s;

    &:hover {
      color: black; 
    }
  }
`;

const LeadCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor:pointer;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StatusIndicator = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${(props) => props.color || '#ccc'};
  margin-right: 8px;
  border-radius: 2px;

  @media (max-width: 768px) {
    height: 10px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const LeadInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width:72%;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const LeadTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align:start;
 

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const LeadTime = styled.div`
  font-size: 13px;
  text-align:start;
  color: #666;
  margin-Top: 4px;
  white-space: nowrap; 
  overflow: hidden;  
  text-overflow: ellipsis;
  width:80%;
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 8px;
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.newBlue};
    font-size: 13px;
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;
const PriceTag = styled.div`
  font-size: 14px;
  font-weight:600;
  color: #666;
 
  text-align:end;
  margin-right:10px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 4px;
  }
`;

const ShowMore = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #0073e6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:50px;
  padding: 30px;
`;
const NoAppointments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  color: ${palette.grey};
`;

const NoAppointmentsText = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
  color: ${palette.grey};
`;