import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment-timezone'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { deleteAddEvent, deleteAddTask, DoneAddTask, fetchAllContacts, fetchTeamData, getCalendarDataV2, submitEditEvent, submitEditTask, updatejobData } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components'
import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react';
import { calendarTeamColor, colorArray, dayViewColor } from '../../../utils/Commonfunction';
import { Avatar, ClickAwayListener, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth, CancelOutlined, Event, HandymanOutlined, KeyboardArrowDown, KeyboardArrowUp, TaskAltOutlined } from '@mui/icons-material';
import TopModal from '../../TopModal';
import AddTask from './AddTask';
import TopBarLoader from '../../../ReuseableComponets/TopBarLoader';
import { teamReducer } from '../../../store/Reducers/JobReducer';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import EventDetails from './Component/EventCard';
import { useRef } from 'react';
import { useEffect } from 'react';
import { submitEditVisit } from '../../../store/Actions/JobAction';
import CalendarUnscheduledJob from './Component/CalendarUnscheduledJob';
import { FiCalendar, FiCheckCircle, FiRefreshCcw } from 'react-icons/fi';
import { filterOptions } from '../../../utils/CommonFile';
import { FaGoogle } from 'react-icons/fa';
const styles = {
    container: {
        marginTop: '48px',
        marginBottom: '10px',
        cursor: 'pointer',
        height: '100%',
        color: '#000',
        padding: '100px 10px',
        border: '1px solid #ddd',
        background: '#f3f3f3',
        paddingRight: '2px',

    },
    textWrapper: {
        transform: 'rotate(-90deg) translateX(50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '140px',
        whiteSpace: 'nowrap',
        transformOrigin: 'right center',
        color: 'rgba(32, 32, 32, 0.95)',
        marginLeft: '-128px',
        marginRight: '20px',
        fontWeight: '500',
        lineHeight: '1.5'
    },
};

function Bookings(props) {
    const modalRef = useRef(null);
    const [selectedEvent] = React.useState(null);
    const localizer = momentLocalizer(moment)
    const [isChangeLoader, setIsChangeLoader] = React.useState(false);
    const [event, setEvents] = React.useState([])
    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Week")
    const [boolValue, setBoolValue] = useState(false);
    const [value, setValue] = useState({})
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [type, setType] = useState('week')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDragSlot, setSelectedDragSlot] = useState(null);
    const [clickPosition, setClickPosition] = useState({ top: window.innerHeight / 2, left: window.innerWidth / 2 });
    const [state, jobDispatch] = React.useReducer(teamReducer, {
        selectedTeam: JSON.parse(localStorage.getItem('selectedTeam')) || [],
        isShowModal: false,
        selectedType: JSON.parse(localStorage.getItem('selectedType')) || [],
    });
    const [isContainerOpen, setIsContainerOpen] = useState(false);

    const [opentooltip, setOpenTooltip] = React.useState(false);
    const [openIndex, setOpenIndex] = React.useState('');
    const handleTooltipClose = () => {
        setOpenTooltip(false);
        setOpenIndex(null)
    };

    const handleTooltipOpen = (index) => {
        setOpenIndex(index)
        setOpenTooltip(true);

    };
    const toggleContainer = () => {
        setIsContainerOpen((prev) => !prev);
    };

    const handleCheckboxClick = (item, index) => {
        jobDispatch({ type: 'TOGGLE_TEAM_MEMBER', payload: { ...item, index: index } });
    };
    const handleTypeClick = (item, index) => {
        jobDispatch({ type: 'TOGGLE_TYPE', payload: { ...item, index: index } });
    };

    const handleClickEvent = (event) => {
        jobDispatch({ type: 'TOGGLE_SHOW_MODAL' });

    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            jobDispatch({ type: 'TOGGLE_SHOW_MODAL' });
        }
    };


    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        const handleClick = (event) => {

            setClickPosition((prevPosition) => ({
                top: event.clientY,
                left: event.clientX,
            }));
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);



    const handleSelectSlot = (slotInfo, event) => {
        if (opentooltip) {
            return handleTooltipClose()
        }
        const isAllDayEvent = slotInfo.slots.length === 1 && moment(slotInfo.start).isSame(slotInfo.end, 'day');

        if (isAllDayEvent) {
            setAnchorEl(clickPosition);
        } else if (slotInfo.bounds) {
            setAnchorEl(slotInfo.bounds);
        } else {

            setAnchorEl(clickPosition);
        }

        setSelectedSlot(slotInfo);

    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (action) => {
        if (action === 'Add Task') {
            setBoolValue({ ...boolValue, isTask: true, type: action })
        }

        else if (action === 'Create Job') {
            setValue({
                date: selectedSlot?.start?.toISOString(),
                endDate: selectedSlot?.end?.toISOString(),
                time: selectedSlot?.start?.toISOString(),
                endTime: selectedSlot?.end?.toISOString(),
                edit: true

            })
            setIsModal(true)

        }
        else {
            setBoolValue({ ...boolValue, isTask: true, type: action })
        }
        handleMenuClose();
    };

    const fetchTeam = async (val) => {
        setSelected(val)
        if (val?.email === 'AllData') {
            setEvents(AllData)

            return
        }
        setEvents([])
        const res = await dispatch(fetchTeamData({
            "emails": [val?.email]
        }))
        let arr = []
        res.data?.[0]?.bookings.map((item) => {
            arr.push({
                data: item,
                title: item?.name || item?.to,
                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                color: '#daf0ff',
                description: item?.customerAddress,
            })
        })
        setEvents(arr)
    }
    const getStartAndEndOfWeek = (date) => {
        const startOfWeek = moment(date).startOf('week').subtract(1, 'days').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('week').add(1, 'days').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };
    const getStartAndEndOfMonth = (date) => {
        const startOfWeek = moment(date).startOf('month').format('YYYY-MM-DD');
        const endOfWeek = moment(date).endOf('month').format('YYYY-MM-DD');
        return { startOfWeek, endOfWeek };
    };

    function findTeamColor(assignedTeam) {
        const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam')) || [];
        for (const selectedMember of assignedTeam) {
            const matchingMember = selectedTeam.find(assigned => assigned.email === selectedMember.email);
            if (matchingMember) {
                const { index } = matchingMember;


                return {
                    original: calendarTeamColor[index]?.original || '#ffffff',
                    light: calendarTeamColor[index]?.light || '#ffffff'
                };
            }
        }

        return null;
    }


    const init = async () => {
        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(new Date());
        setIsChangeLoader(true)
        const res1 = await dispatch(getCalendarDataV2(startOfWeek, endOfWeek))
        let arr = []
        let teamArr = []
        const dataarr = [...res1?.data?.detailData]


        dataarr?.map((item, index) => {
            const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');

            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');

            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');

            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');

            let startdat = new Date(
                moment(newDate, 'DD-MMM-YYYY').year(),
                moment(newDate, 'DD-MMM-YYYY').month(),
                moment(newDate, 'DD-MMM-YYYY').date(),
                moment(newTime, 'HH:mm').hours(),
                moment(newTime, 'HH:mm').minutes()
            );
            const teamMembers = item?.team || item?.worker || [];
            const isInSelectedTeam = state.selectedTeam?.length > 0
                ? teamMembers.some(teamMember =>
                    state.selectedTeam.some(selected => selected.email === teamMember.email)
                )
                : false;
            const isInSelectedType = state.selectedType?.length > 0
                ? state.selectedType.some(type => type.id === item?.type)
                : false;

            if (state.selectedTeam?.length > 0 || state.selectedType?.length > 0) {
                if (isInSelectedTeam || isInSelectedType) {
                    if (!isNaN(startdat)) {

                        if ((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && (JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0) {
                            if (isInSelectedTeam && isInSelectedType) {
                                arr.push({
                                    data: item,
                                    title: item?.name || item?.to || item?.title,
                                    start: startdat,
                                    end: item?.endDate && item?.endTime
                                        ? new Date(
                                            moment(newEndDate, 'DD-MMM-YYYY').year(),
                                            moment(newEndDate, 'DD-MMM-YYYY').month(),
                                            moment(newEndDate, 'DD-MMM-YYYY').date(),
                                            moment(newEndTime, 'HH:mm').hours(),
                                            moment(newEndTime, 'HH:mm').minutes()
                                        )
                                        : new Date(
                                            moment(newDate, 'DD-MMM-YYYY').year(),
                                            moment(newDate, 'DD-MMM-YYYY').month(),
                                            moment(newDate, 'DD-MMM-YYYY').date(),
                                            moment(newTime, 'HH:mm').hours() + 1,
                                            moment(newTime, 'HH:mm').minutes()
                                        ),
                                    color: '#daf0ff',
                                    description: item?.customerAddress || item?.task || item?.description ||item?.subject,
                                    status: item?.bookingStatus,
                                    type:item?.source==='google'?'google': item?.type,
                                    teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                    teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                    index: index
                                });
                            }
                        }
                        else if ((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && isInSelectedTeam) {

                            arr.push({
                                data: item,
                                title: item?.name || item?.to || item?.title,
                                start: startdat,
                                end: item?.endDate && item?.endTime
                                    ? new Date(
                                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                                        moment(newEndTime, 'HH:mm').hours(),
                                        moment(newEndTime, 'HH:mm').minutes()
                                    )
                                    : new Date(
                                        moment(newDate, 'DD-MMM-YYYY').year(),
                                        moment(newDate, 'DD-MMM-YYYY').month(),
                                        moment(newDate, 'DD-MMM-YYYY').date(),
                                        moment(newTime, 'HH:mm').hours() + 1,
                                        moment(newTime, 'HH:mm').minutes()
                                    ),
                                color: '#daf0ff',
                                description: item?.customerAddress || item?.task || item?.description||item?.subject,
                                status: item?.bookingStatus,
                                type:item?.source==='google'?'google': item?.type,
                                teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                index: index
                            });
                        }
                        else if ((JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0 && isInSelectedType) {
                            arr.push({
                                data: item,
                                title: item?.name || item?.to || item?.title,
                                start: startdat,
                                end: item?.endDate && item?.endTime
                                    ? new Date(
                                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                                        moment(newEndTime, 'HH:mm').hours(),
                                        moment(newEndTime, 'HH:mm').minutes()
                                    )
                                    : new Date(
                                        moment(newDate, 'DD-MMM-YYYY').year(),
                                        moment(newDate, 'DD-MMM-YYYY').month(),
                                        moment(newDate, 'DD-MMM-YYYY').date(),
                                        moment(newTime, 'HH:mm').hours() + 1,
                                        moment(newTime, 'HH:mm').minutes()
                                    ),
                                color: '#daf0ff',
                                description: item?.customerAddress || item?.task || item?.description||item?.subject,
                                status: item?.bookingStatus,
                                type:item?.source==='google'?'google': item?.type,
                                teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                index: index
                            });
                        }
                    }
                }

            }
            else {
                if (!isNaN(startdat)) {

                    arr.push({
                        data: item,
                        title: item?.name || item?.to || item?.title,
                        start: startdat,
                        end: item?.endDate && item?.endTime
                            ? new Date(
                                moment(newEndDate, 'DD-MMM-YYYY').year(),
                                moment(newEndDate, 'DD-MMM-YYYY').month(),
                                moment(newEndDate, 'DD-MMM-YYYY').date(),
                                moment(newEndTime, 'HH:mm').hours(),
                                moment(newEndTime, 'HH:mm').minutes()
                            )
                            : new Date(
                                moment(newDate, 'DD-MMM-YYYY').year(),
                                moment(newDate, 'DD-MMM-YYYY').month(),
                                moment(newDate, 'DD-MMM-YYYY').date(),
                                moment(newTime, 'HH:mm').hours() + 1, // Add 1 hour if no end time
                                moment(newTime, 'HH:mm').minutes()
                            ),
                        color: '#daf0ff',
                        description: item?.customerAddress || item?.task || item?.description||item?.subject,
                        status: item?.bookingStatus,
                        type:item?.source==='google'?'google': item?.type,
                        teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                        teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                        index: index
                    });
                }

            }


        })
        console.log(res1?.data?.team)
        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
        setIsChangeLoader(false)
        const res10 = await dispatch(fetchAllContacts())
    }
    React.useEffect(() => {
        const invoiceParam = searchParams.get('type');

        if (invoiceParam) {
            setType(invoiceParam)
            setFilter(invoiceParam?.charAt(0).toUpperCase() + invoiceParam?.slice(1))

        }

        init()
    }, [])
    const handleSelectEvent = (event) => {
        setValue({})
        handleTooltipOpen(event?.index)
    };

    const MyEvent = ({ event }) => {
        return (
            <TooltipStyled
                onClose={handleTooltipClose}

                open={(opentooltip && (event?.index) === openIndex)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                disableInteractive
                disablePortal
                placement={'right-start'}

                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '550px',

                            paddingBottom: (event?.data?.type == 'task' || event?.data?.type == 'event') ? "0px" : '40px',
                            width: '350px',

                            overflowY: 'scroll',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff',
                        },
                    },
                }}
                title={
                    opentooltip &&
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{
                            pointerEvents: 'auto',
                        }}
                    >
                        <EventDetails
                            event={event}
                            onClickDeleteTask={async (date, id, type) => {
                                const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(date);
                                if (opentooltip) {
                                    handleTooltipClose()
                                }
                                if (type === 'task') {

                                    await dispatch(deleteAddTask(id));
                                    initRender(startOfWeek, endOfWeek);
                                }
                                else {
                                    await dispatch(deleteAddEvent(id));
                                    initRender(startOfWeek, endOfWeek);
                                }
                            }}
                            onClickEdit={async (date, data, type) => {
                                if (opentooltip) {
                                    handleTooltipClose()
                                }
                                setBoolValue({ ...boolValue, isTask: true, type: type === 'task' ? 'Add Task' : 'Add Event' })
                                setSelectedSlot(data)
                            }}
                            onClickTask={async (date, id) => {
                                if (opentooltip) {
                                    handleTooltipClose()
                                }
                                try {
                                    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);
                                    await dispatch(DoneAddTask(id));
                                    initRender(startOfWeek, endOfWeek);
                                } catch (error) {
                                    console.error('Error in onClickTask:', error);
                                }
                            }}

                        />
                    </div>
                }
            >
                <EventDiv style={{ display: 'flex', marginTop: (moment(event?.start).format('DD MM') !== moment(event?.end).format('DD MM') || moment(event?.start).format('hh:mm A') === moment(event?.end).format('hh:mm A')) && '-5px', paddingBottom: moment(event?.start).format('DD MM') !== moment(event?.end).format('DD MM') && '5px' }} onClick={() => handleTooltipOpen(event?.index)} status={event?.status}>
                    {event?.status === 'Completed' ? (
                        <div>
                            <FiCheckCircle style={{ color: 'green', marginRight: '4px' }} />
                        </div>
                    ) : event?.type === 'multivist' ? (
                        <div>
                            <FiRefreshCcw style={{ color: 'black', marginRight: '4px' }} />
                        </div>
                    ) :event?.type==='google'?(<FaGoogle style={{ color: 'black', marginRight: '4px' }}/>): null}
                    <div style={{ width: '90%' }}>
                        {event?.type==='google'?"Google":event?.title}&nbsp;
                        <Description>{event.description}</Description>
                        {
                            <TimeRange>{`${moment(event?.start).format('hh:mm A')} - ${moment(event?.end).format('hh:mm A')}`}</TimeRange>}
                        <div style={{ display: 'flex' }}>
                            {
                                event?.data?.team?.map((item) =>
                                    <Avatar style={{ width: '20px', height: '20px', fontSize: '10px', marginRight: '5px', background: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.light, color: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.original }}>{getName(`${item?.firstName} ${item?.lastName}`)}</Avatar>
                                )
                            }
                            {
                                event?.data?.worker?.map((item) =>
                                    <Avatar style={{ width: '20px', height: '20px', fontSize: '10px', marginRight: '5px', background: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.light, color: calendarTeamColor[team.findIndex(member => member.firstName === item.firstName)]?.original }}>{getName(`${item?.firstName} ${item?.lastName}`)}</Avatar>
                                )
                            }
                        </div>
                    </div>
                </EventDiv>
            </TooltipStyled>
        );
    };



    const eventStyleGetter = (event) => {
        const style = {
            backgroundColor: "#fff",
            borderRadius: '5px',
            padding: '1px',
            opacity: 0.8,
            color: 'black',
            fontSize: '12px',
            marginLeft: '3px',
            opacity: 1.0,
            display: 'block',
            border: '1.5px solid #ddd',
            boxShadow: '0 0 10px rgba(255, 255, 255, 0.6)',
            borderLeft: selected?.index ? `8px solid ${colorArray[selected?.index]}` :event?.type==='google'?`8px solid #FED000`: event?.type === "task" ? `8px solid red` : event?.type === "event" ? `8px solid green` : event?.type === 'multivist' ? `8px solid #6C757D` : `8px solid ${palette.newBlue}`,


        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {


        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            setFilter('Week')
            setType(Views.WEEK)
            toolbar.onView('week');
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
            initRender(startOfWeek, endOfWeek);
        };

        const goToDay = () => {
            setFilter('Day')
            setType(Views.DAY)
            toolbar.onView('day');
        };
        const goToMonth = () => {
            setFilter('Month')
            setType(Views.MONTH)
            toolbar.onView('month');
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(toolbar.date);
            initRender(startOfWeek, endOfWeek);
        };

        return (
            <div onClick={() => {
                if (opentooltip) {
                    return handleTooltipClose()
                }
            }} style={{ display: 'flex', width: '100%', marginTop: '-3px', marginBottom: '-3px', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="rbc-toolbar">


                    <span style={{ display: 'flex', marginLeft: '20px' }} className="rbc-btn-group">
                        <StyledTextBox type="button" onClick={goToToday}>
                            Today
                        </StyledTextBox>
                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('PREV')}>
                            <ArrowBackIosNew style={{ fontSize: '20px' }} />
                        </button>

                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', borderRadius: '4px', justifyContent: 'center', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                            <ArrowForwardIos style={{ fontSize: '20px' }} />
                        </button>
                        <span style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '400' }} >
                            <FiCalendar style={{ width: '16px', height: '16px' }} />
                            <div style={{ marginLeft: '8px', fontWeight: '400' }}>{toolbar.label}</div>
                        </span>

                    </span>

                    <span className="rbc-toolbar-label"></span>

                </div>
                <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                    {(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center', }}>
                        {
                            (JSON.parse(localStorage.getItem('selectedTeam')) || [])?.slice(0, 4)?.map((item, index) =>
                                <Tooltip arrow title={`${item?.firstName} ${item?.lastName || ''}`}>
                                    <Avatar style={{ height: '28px', marginLeft: '4px', width: '28px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                        <Typography style={{ fontSize: '11px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                    </Avatar>
                                </Tooltip>

                            )
                        }
                        {(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length - 4 > 0 && (
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                                            border: '1px solid #ddd',
                                        }
                                    }
                                }}
                                arrow title={(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.map((item, index) =>
                                    <AssignedSection style={{ marginBottom: '5px' }}>
                                        <Avatar style={{ height: '25px', width: '25px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                            <Typography style={{ fontSize: '11px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                        </Avatar>
                                        <Typography style={{ fontSize: '13px', fontWeight: '500', color: '#000' }}>{item?.firstName} {item?.lastName || ''}</Typography>

                                    </AssignedSection>

                                )}>
                                <Avatar style={{ height: '28px', marginLeft: '4px', width: '28px', background: palette.charcolegrey }}>
                                    <Typography style={{ fontSize: '11px', fontWeight: '600' }}> +{(JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length - 4}</Typography>
                                </Avatar>
                            </Tooltip>
                        )}
                    </div>}
                    <div style={{ position: 'relative', flexWrap: 'wrap' }}>
                        <RippleButton
                            onClick={handleClickEvent}
                            style={{ marginRight: '20px', borderRadius: '8px', color: palette.newBlue, background: '#fff', border: '0px solid #dce8f5' }}
                        >
                            Filters {((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 || (JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0) && <Avatar style={{ width: '22px', height: '20px', borderRadius: '4px', fontSize: '11px', background: palette.newBlue }}>On</Avatar>}  <KeyboardArrowDown style={{ fontWeight: '600', marginRight: '-15px' }} />
                        </RippleButton>
                        {state?.isShowModal &&
                            <>

                                <StyledPopupContainer
                                    ref={modalRef}
                                >
                                    <FilterMenu>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                            <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Filters</h4>
                                            <ClearButton onClick={() => {
                                                jobDispatch({ type: 'CLEAR_FILTER' });
                                                localStorage.setItem('selectedTeam', JSON.stringify([]));
                                                localStorage.setItem('selectedType', JSON.stringify([]));
                                                const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
                                                initRender(startOfWeek, endOfWeek);

                                                handleClickEvent()
                                            }} >Clear Filter</ClearButton>
                                        </div>
                                        <div style={{ display: 'flex', gap: '20px' }}>

                                            <div style={{ maxHeight: '220px', overflowY: 'scroll', flex: 1 }}>
                                                <h5 style={{ margin: '0 0 10px', fontWeight: '500', textAlign: 'start' }}>Assigned to</h5>
                                                {team?.length > 0 ?
                                                    team?.map((item, index) =>

                                                        <div onClick={() => handleCheckboxClick(item, index)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <AssignedSection style={{ background: state.selectedTeam.some(member => member.email === item.email) && '#dce8f5' }}>
                                                                <Avatar style={{ height: '22px', width: '22px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                                                    <Typography style={{ fontSize: '10px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                                                </Avatar>
                                                                <Typography style={{ fontSize: '13px', color: state.selectedTeam.some(member => member.email === item.email) ? 'black' : 'grey' }}>{item?.firstName} {item?.lastName || ''}</Typography>

                                                            </AssignedSection>
                                                            {/* <Checkbox
                                                                checked={state.selectedTeam.some(member => member.email === item.email)}
                                                                onChange={() => handleCheckboxClick(item, index)}

                                                            /> */}
                                                        </div>
                                                    ) : <div>No crew member</div>
                                                }
                                            </div>
                                            <div style={{ flex: 1, maxHeight: '220px', overflowY: 'auto', padding: '0px 10px', borderLeft: '1px solid #ddd' }}>
                                                <h5 style={{ margin: '0 0 10px', fontWeight: '500', textAlign: 'start' }}>Type Filters</h5>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {filterOptions?.map((filterOption, idx) => (
                                                        <AssignedSection style={{ background: state.selectedType.some(member => member.name === filterOption.name) && '#dce8f5' }} onClick={() => handleTypeClick(filterOption, idx)} key={filterOption?.id} >
                                                            {/* <Checkbox
                                                                checked={state?.selectedOptions?.includes(filterOption)}
                                                                onChange={() => {}}
                                                            /> */}
                                                            {filterOption?.name === 'Jobs' ? <HandymanOutlined style={{ fontSize: '18px', color: palette.newBlue }} /> : filterOption?.name === 'Tasks' ? <TaskAltOutlined style={{ fontSize: '18px', color: 'red' }} /> : filterOption?.name === 'Events' ? <Event style={{ fontSize: '18px', color: 'green' }} /> : <FiRefreshCcw color='grey' />}
                                                            <Typography style={{ marginLeft: '8px', fontSize: '13px', color: state.selectedType.some(member => member.name === filterOption.name) ? 'black' : 'grey' }}>
                                                                {filterOption?.name}
                                                            </Typography>
                                                        </AssignedSection>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', marginBottom: '-10px' }}>
                                            <RippleButton style={{ marginRight: '15px', border: '1px solid #ddd', background: '#fff', color: '#000' }} onClick={handleClickEvent}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                localStorage.setItem('selectedTeam', JSON.stringify(state.selectedTeam));
                                                localStorage.setItem('selectedType', JSON.stringify(state.selectedType));
                                                const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(toolbar.date);
                                                initRender(startOfWeek, endOfWeek);
                                                handleClickEvent()
                                            }}>Apply</RippleButton>
                                        </div>
                                    </FilterMenu>
                                </StyledPopupContainer>

                            </>

                        }
                    </div>
                    <ToggleTabs
                        labels={['Week', 'Month', "Day", "Map"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                                navigate('/dashboard/timeline')
                            }
                            else {
                                setFilter('Map')
                                navigate('/dashboard/calander/map')

                            }
                        }}

                    />
                </div>

            </div>
        );
    };
    const dayHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');
        return (
            <div style={{ height: '35px', padding: '15px', paddingTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const dayHeaderContentMonth = ({ label, view }) => {
        return (
            <div style={{ height: '35px', padding: '15px', paddingTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '500' }}>{label}</div>

            </div>
        );
    };
    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');


        return (
            <div style={{ height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '600' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'grey', fontWeight: '600', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const MyTimeSlotWrapper = (props) => {
        const { children, value } = props;

        if (value.getMinutes() === 30) {
            return null;
        }

        return (
            <div style={{ fontSize: '12px', fontWeight: '400', marginTop: 5, borderTop: 'none', color: 'grey', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };

    const dayFormat = 'dddd, MMMM D, YYYY';
    const monthFormat = 'MMMM YYYY';


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {

                backgroundColor: isToday ? '#FAF9F7' : 'white'

            },
        };
    }
    const MyEventWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                background: 'none',
                borderRadius: 4,
            }}
        >
            {children}
        </div>
    );

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);

    const timeGutterWraper = ({ event }) => {
        return (
            <div style={{ height: 'auto', width: '125%', fontSize: '13px', marginTop: '30px', paddingTop: '7px', borderTop: '1px solid #eee', borderColor: '#eee', marginRight: '-30px', marginLeft: '-10px', color: 'grey', paddingLeft: '10px' }}>
                All day
            </div>
        )
    }
    const CustomMonthEvent = ({ event }) => {
        return (
            <TooltipStyled
                onClose={handleTooltipClose}

                open={(opentooltip && (event?.index) === openIndex)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                disableInteractive
                disablePortal
                placement={'right-start'}
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '350px',
                            paddingBottom: (event?.data?.type == 'task' || event?.data?.type == 'event') ? "0px" : '40px',
                            width: '350px',

                            overflowY: 'scroll',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff',
                        },
                    },
                }}
                title={
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{
                            pointerEvents: 'auto',
                        }}
                    >
                        <EventDetails
                            {...props}
                            event={event}
                            onClickDeleteTask={async (date, id, type) => {
                                const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(date);
                                if (type === 'task') {
                                    await dispatch(deleteAddTask(id));
                                    initRender(startOfWeek, endOfWeek);
                                }
                                else {
                                    await dispatch(deleteAddEvent(id));
                                    initRender(startOfWeek, endOfWeek);
                                }
                            }}
                            onClickEdit={async (date, data, type) => {
                                setBoolValue({ ...boolValue, isTask: true, type: type === 'task' ? 'Add Task' : 'Add Event' })
                                setSelectedSlot(data)
                            }}
                            onClickTask={async (date, id) => {
                                try {
                                    const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(date);
                                    await dispatch(DoneAddTask(id));
                                    initRender(startOfWeek, endOfWeek);
                                } catch (error) {
                                    console.error('Error in onClickTask:', error);
                                }
                            }}
                        />
                    </div>

                }
            >
                <div onClick={() => handleTooltipOpen(event?.index)} style={{ padding: '3px', display: 'flex', borderRadius: '4px', fontWeight: '500', fontSize: '13px', color: palette.newBlue }}>
                    {event?.status === 'Completed' ? (
                        <div>
                            <FiCheckCircle style={{ color: 'green', marginRight: '4px' }} />
                        </div>
                    ) : event?.type === 'multivist' ?event?.type === 'google' ?<FaGoogle style={{ color: 'black', marginRight: '4px' }} />: (
                        <div>
                            <FiRefreshCcw style={{ color: 'black', marginRight: '4px' }} />
                        </div>
                    ) : null}

                    {event?.title||event?.description}
                </div>
            </TooltipStyled>
        );
    };
    const renderSelectedTimeInfo = () => {
        if (!selectedSlot) return '';
        const start = moment(selectedSlot.start).format('MMM DD, hh:mm A');
        const end = moment(selectedSlot.end).format('MMM DD, hh:mm A');
        return `${start} - ${end}`;
    };



    const initRender = async (start, end) => {

        setIsChangeLoader(true)
        const res1 = await dispatch(getCalendarDataV2(start, end))
        let arr = []
        let teamArr = []
        const dataarr = [...res1?.data?.detailData]
        dataarr?.map((item, index) => {
            const newDate = item?.timezone
                ? moment.tz(item?.date, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.date).format('DD-MMM-YYYY');

            const newEndDate = item?.timezone
                ? moment.tz(item?.endDate, item?.timezone).format('DD-MMM-YYYY')
                : moment(item?.endDate).format('DD-MMM-YYYY');

            const newTime = item?.timezone
                ? moment.tz(item?.time, item?.timezone).format('HH:mm')
                : moment(item?.time).format('HH:mm');

            const newEndTime = item?.timezone
                ? moment.tz(item?.endTime, item?.timezone).format('HH:mm')
                : moment(item?.endTime).format('HH:mm');

            let startdat = new Date(
                moment(newDate, 'DD-MMM-YYYY').year(),
                moment(newDate, 'DD-MMM-YYYY').month(),
                moment(newDate, 'DD-MMM-YYYY').date(),
                moment(newTime, 'HH:mm').hours(),
                moment(newTime, 'HH:mm').minutes()
            );
            const teamMembers = item?.team || item?.worker || [];
            const isInSelectedTeam = (JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0
                ? teamMembers.some(teamMember =>
                    (JSON.parse(localStorage.getItem('selectedTeam')) || []).some(selected => selected.email === teamMember.email)
                )
                : false;
            const isInSelectedType = state.selectedType?.length > 0
                ? state.selectedType.some(type => type.id === item?.type)
                : false;

            if ((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 || (JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0) {
                if (isInSelectedTeam || isInSelectedType) {
                    if (!isNaN(startdat)) {
                        if ((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && (JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0) {
                            if (isInSelectedTeam && isInSelectedType) {
                                arr.push({
                                    data: item,
                                    title: item?.name || item?.to || item?.title,
                                    start: startdat,
                                    end: item?.endDate && item?.endTime
                                        ? new Date(
                                            moment(newEndDate, 'DD-MMM-YYYY').year(),
                                            moment(newEndDate, 'DD-MMM-YYYY').month(),
                                            moment(newEndDate, 'DD-MMM-YYYY').date(),
                                            moment(newEndTime, 'HH:mm').hours(),
                                            moment(newEndTime, 'HH:mm').minutes()
                                        )
                                        : new Date(
                                            moment(newDate, 'DD-MMM-YYYY').year(),
                                            moment(newDate, 'DD-MMM-YYYY').month(),
                                            moment(newDate, 'DD-MMM-YYYY').date(),
                                            moment(newTime, 'HH:mm').hours() + 1,
                                            moment(newTime, 'HH:mm').minutes()
                                        ),
                                    color: '#daf0ff',
                                    description: item?.customerAddress || item?.task || item?.description||item?.subject,
                                    status: item?.bookingStatus,
                                    type:item?.source==='google'?'google': item?.type,
                                    teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                    teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                    index: index
                                });
                            }
                        }
                        else if ((JSON.parse(localStorage.getItem('selectedTeam')) || [])?.length > 0 && isInSelectedTeam) {

                            arr.push({
                                data: item,
                                title: item?.name || item?.to || item?.title,
                                start: startdat,
                                end: item?.endDate && item?.endTime
                                    ? new Date(
                                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                                        moment(newEndTime, 'HH:mm').hours(),
                                        moment(newEndTime, 'HH:mm').minutes()
                                    )
                                    : new Date(
                                        moment(newDate, 'DD-MMM-YYYY').year(),
                                        moment(newDate, 'DD-MMM-YYYY').month(),
                                        moment(newDate, 'DD-MMM-YYYY').date(),
                                        moment(newTime, 'HH:mm').hours() + 1,
                                        moment(newTime, 'HH:mm').minutes()
                                    ),
                                color: '#daf0ff',
                                description: item?.customerAddress || item?.task || item?.description||item?.subject,
                                status: item?.bookingStatus,
                                type:item?.source==='google'?'google': item?.type,
                                teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                index: index
                            });
                        }
                        else if ((JSON.parse(localStorage.getItem('selectedType')) || [])?.length > 0 && isInSelectedType) {
                            arr.push({
                                data: item,
                                title: item?.name || item?.to || item?.title,
                                start: startdat,
                                end: item?.endDate && item?.endTime
                                    ? new Date(
                                        moment(newEndDate, 'DD-MMM-YYYY').year(),
                                        moment(newEndDate, 'DD-MMM-YYYY').month(),
                                        moment(newEndDate, 'DD-MMM-YYYY').date(),
                                        moment(newEndTime, 'HH:mm').hours(),
                                        moment(newEndTime, 'HH:mm').minutes()
                                    )
                                    : new Date(
                                        moment(newDate, 'DD-MMM-YYYY').year(),
                                        moment(newDate, 'DD-MMM-YYYY').month(),
                                        moment(newDate, 'DD-MMM-YYYY').date(),
                                        moment(newTime, 'HH:mm').hours() + 1,
                                        moment(newTime, 'HH:mm').minutes()
                                    ),
                                color: '#daf0ff',
                                description: item?.customerAddress || item?.task || item?.description||item?.subject,
                                status: item?.bookingStatus,
                                type:item?.source==='google'?'google': item?.type,
                                teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                                teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                                index: index
                            });
                        }
                    }
                }

            }
            else {

                if (!isNaN(startdat)) {

                    arr.push({
                        data: item,
                        title: item?.name || item?.to || item?.title,
                        start: startdat,
                        end: item?.endDate && item?.endTime
                            ? new Date(
                                moment(newEndDate, 'DD-MMM-YYYY').year(),
                                moment(newEndDate, 'DD-MMM-YYYY').month(),
                                moment(newEndDate, 'DD-MMM-YYYY').date(),
                                moment(newEndTime, 'HH:mm').hours(),
                                moment(newEndTime, 'HH:mm').minutes()
                            )
                            : new Date(
                                moment(newDate, 'DD-MMM-YYYY').year(),
                                moment(newDate, 'DD-MMM-YYYY').month(),
                                moment(newDate, 'DD-MMM-YYYY').date(),
                                moment(newTime, 'HH:mm').hours() + 1,
                                moment(newTime, 'HH:mm').minutes()
                            ),
                        color: '#daf0ff',
                        description: item?.customerAddress || item?.task || item?.description||item?.subject,
                        status: item?.bookingStatus,
                        type:item?.source==='google'?'google': item?.type,
                        teamColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.light || '',
                        teamBgColor: findTeamColor(item?.team ? item?.team : item?.worker || [], state.selectedTeam)?.original || '',
                        index: index
                    });
                }
            }
        })
        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index + 1 })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
        setIsChangeLoader(false)
    }


    const handleNavigate = (date, view) => {
        if (view === 'week') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(date);

            initRender(startOfWeek, endOfWeek);


        }
        else if (view === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(date);

            initRender(startOfWeek, endOfWeek);
        }

    };
    const editSchedule = async (start, end, id, isAllDay) => {
        setIsChangeLoader(true)
        let startTime = new Date(start);
        let endTime = new Date(end);
        if (isAllDay) {

            startTime.setHours(0, 0, 0, 0);
            endTime.setHours(0, 0, 0, 0);
        }


        const res = await dispatch(updatejobData({ "date": new Date(start), "time": startTime, "endDate": new Date(end), "endTime": endTime }, id))
        if (type === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(start);

            initRender(startOfWeek, endOfWeek);
        }
        else {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(start);

            initRender(startOfWeek, endOfWeek);
        }
        setBoolValue({ ...boolValue, onDrop: true })


    }
    const editMultiSchedule = async (start, end, id, isAllDay, event) => {
        setIsChangeLoader(true)

        let startTime = new Date(start);
        let endTime = new Date(end);
        if (isAllDay) {

            startTime.setHours(0, 0, 0, 0);
            endTime.setHours(0, 0, 0, 0);
        }

        await dispatch(submitEditVisit({ ...event, "date": new Date(start), "time": startTime, "endDate": new Date(end), "endTime": endTime }, id, event._id))
        if (type === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(start);

            initRender(startOfWeek, endOfWeek);
        }
        else {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(start);

            initRender(startOfWeek, endOfWeek);
        }


    }
    const editTaskSchedule = async (start, end, id, isAllDay, event) => {
        setIsChangeLoader(true)

        let startTime = new Date(start);
        let endTime = new Date(end);
        if (isAllDay) {

            startTime.setHours(0, 0, 0, 0);
            endTime.setHours(0, 0, 0, 0);
        }

        await dispatch(submitEditTask({ ...event, "taskDescription": event?.task, "date": new Date(start), "time": startTime, "endDate": new Date(end), "endTime": endTime }, event._id))
        if (type === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(start);

            initRender(startOfWeek, endOfWeek);
        }
        else {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(start);

            initRender(startOfWeek, endOfWeek);
        }


    }
    const editEventSchedule = async (start, end, id, isAllDay, event) => {
        setIsChangeLoader(true)
        let startTime = new Date(start);
        let endTime = new Date(end);
        if (isAllDay) {

            startTime.setHours(0, 0, 0, 0);
            endTime.setHours(0, 0, 0, 0);
        }

        await dispatch(submitEditEvent({ ...event, "taskDescription": event?.description, "date": new Date(start), "time": startTime, "endDate": new Date(end), "endTime": endTime }, event.eventId))
        if (type === 'month') {
            const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(start);

            initRender(startOfWeek, endOfWeek);
        }
        else {
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(start);

            initRender(startOfWeek, endOfWeek);
        }


    }
    const onEventDrop = (data) => {
        if (data?.event?.type === "multivist") {
            editMultiSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)

        }

        else if (data?.event?.type === "task") {
            editTaskSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)
        }
        else if (data?.event?.type === "event") {
            editEventSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)
        }
        else {
            editSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay)
        }
    };
    const onEventResize = (data) => {
        const { start, end, event } = data;
        setEvents((prevEvents) =>
            prevEvents.map((evt) =>
                evt?.start === data?.start
                    ? { ...evt, start, end }
                    : evt
            )
        );
        if (data?.event?.type === "multivist") {
            editMultiSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)

        }

        else if (data?.event?.type === "task") {
            editTaskSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)
        }
        else if (data?.event?.type === "event") {
            editEventSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay, data?.event?.data)
        }
        else {
            editSchedule(data?.start, data?.end, data?.event?.data?.referenceNo, data?.isAllDay)
        }
    };
    const onResizeStart = (event) => {
        event?.dataTransfer?.setData("text/plain", JSON?.stringify(event))
    };
    const externalEvents = [
        { id: 2, title: "External Event 1" },
        { id: 3, title: "External Event 2" },
    ];

    const handleDropFromOutside = ({ start, end, allDay, event }) => {
        const newStart = new Date(start);
        newStart.setMinutes(start.getMinutes() - 0);
        start = newStart;
        if (!allDay) {
            const newEnd = new Date(end);
            newEnd.setMinutes(newEnd.getMinutes() + 30);
            end = newEnd;
        }
        if (selectedDragSlot?.type === "multivist") {
            editMultiSchedule(start, end, selectedDragSlot.referenceNo, allDay, selectedDragSlot)

        }

        else if (selectedDragSlot?.type === "task") {
            editTaskSchedule(start, end, selectedDragSlot.referenceNo, allDay, selectedDragSlot)
        }
        else if (selectedDragSlot?.type === "event") {
            editEventSchedule(start, end, selectedDragSlot.referenceNo, allDay, selectedDragSlot)
        }
        else {
            editSchedule(start, end, selectedDragSlot.referenceNo, allDay)
        }

    };


    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Body >

                {
                    isChangeLoader && <TopBarLoader color="#FF5733" height="5px" speed={1} />
                }

                <div style={{ display: 'flex', width: '100%', marginLeft: '-1px', height: '98vh', paddingTop: '15px', }}>
                    <CalanderTime
                        localizer={localizer}
                        events={event}

                        startAccessor="start"
                        view={type}
                        onSelectEvent={handleSelectEvent}
                        onNavigate={handleNavigate}
                        selectable
                        tooltipAccessor={null}
                        eventPropGetter={eventStyleGetter}
                        dayLayoutAlgorithm={'no-overlap'}
                        onEventDrop={onEventDrop}
                        onEventResize={onEventResize}
                        onDropFromOutside={handleDropFromOutside}
                        dragFromOutsideItem={(event) => {

                            console.log(event, "dscd")
                            const now = new Date();
                            const start = new Date(now.getTime() - 90 * 60 * 1000);
                            const end = new Date(start.getTime() + 60 * 60 * 1000);
                            if (type !== 'month') {
                                return {
                                    title: 'New Event',
                                    start,
                                    end,
                                };
                            }
                        }}
                        draggableAccessor={() => true}
                        onDragStart={onResizeStart}
                       
                        resizable
                        endAccessor="end"
                        style={{ height: '100c', width: '100%' }}
                        step={15} // This controls the slot duration
                        timeslots={2}
                        min={new Date(1970, 1, 1, 6, 0)}
                        max={maxTime}
                        popup={true}
                        onSelectSlot={handleSelectSlot}
                        formats={{
                            dayFormat: dayFormat,
                            monthHeaderFormat: monthFormat,
                            timeGutterFormat: 'h:mm A',
                            eventTimeRangeFormat: () => {
                                return ""
                            }

                        }}
                        allDayMaxRows={5}

                        allDayAccessor={(event) => console.log(" ")}
                        dayPropGetter={dayPropStyles}


                        components={{
                            toolbar: MyToolbar,

                            day: {
                                header: dayssHeaderContent
                            },


                            week: {
                                header: dayHeaderContent,

                            },
                            month: {
                                event: CustomMonthEvent,
                                header: dayHeaderContentMonth,


                            },

                            timeSlotWrapper: MyTimeSlotWrapper,
                            timeGutterHeader: timeGutterWraper,



                            event: MyEvent,
                            eventContainerWrapper: MyEventWrapper,




                        }}
                    />


                    {!isContainerOpen ?
                        <div style={styles.container} onClick={toggleContainer}>
                            <div style={styles.textWrapper}>

                                Unscheduled Jobs
                                <KeyboardArrowUp />
                            </div>
                        </div>
                        :

                        <div
                            style={{
                                width: '290px',

                                height: "100%",
                                marginTop: '-15px',
                                backgroundColor: "#fff",
                                borderLeft: '1px solid #ddd',
                                transition: "right 0.3s ease",
                                zIndex: 1,
                                overflowY: "auto",
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', margin: "0px 8px", }}>
                                <CancelOutlined style={{ fontSize: '18px', cursor: 'pointer', color: 'grey' }} onClick={toggleContainer} />
                                <h5 style={{ textAlign: "center", fontWeight: '500', marginLeft: '8px', color: "#000" }}>
                                    Unscheduled jobs
                                </h5>
                            </div>
                            <CalendarUnscheduledJob
                                onDragStart={(event) => {
                                    setBoolValue({ ...boolValue, onDrop: false })
                                    setSelectedDragSlot(event)
                                }}
                                onDrop={boolValue?.onDrop}
                            />
                        </div>}

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={anchorEl ? { top: anchorEl.top, left: anchorEl.left } : undefined}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        sx={{
                            zIndex: 1300000,
                            padding: '8px',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',

                        }}
                    >
                        <MenuItem disabled>
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '500',
                                    color: 'text.primary',
                                }}
                            >
                                {renderSelectedTimeInfo()}
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleMenuClick('Add Task')}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                paddingY: '8px',
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            }}
                        >
                            <TaskAltOutlined sx={{ fontSize: '20px', color: 'red' }} />
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>
                                Create Task
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleMenuClick('Create Job')}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                paddingY: '8px',
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            }}
                        >
                            <HandymanOutlined sx={{ fontSize: '20px', color: 'primary.main' }} />
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>
                                Create Job
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleMenuClick('Request Event')}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                paddingY: '8px',
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            }}
                        >
                            <CalendarMonth sx={{ fontSize: '20px', color: 'success.main' }} />
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>
                                Create Event
                            </Typography>
                        </MenuItem>
                    </Menu>

                    {
                        ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                            <CreateBooking
                                value={value?.edit ? value : selectedEvent}
                                team={team}
                                refresh={() => init()}
                                serviceRequest={value?.edit ? false : true}
                                newService={true}
                                setCreateBookingModal={() => {
                                    if (type === 'month') {
                                  
                                        const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(new Date());
                                        initRender(startOfWeek, endOfWeek);
                                    }
                                    else {
                                        init()
                                    }
                                    setIsModal(false)
                                }}
                                onSubmitsw={(val, editValue) => {
                        
                                    // sendchat(val, editValue)
                                    if (type === 'month') {
                                   
                                        const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(new Date());
                                        initRender(startOfWeek, endOfWeek);
                                    }
                                    else {

                                        init()
                                    }
                                    setIsModal(false)



                                }
                                }

                            />
                        </FullScreenModal>
                    }

                    {
                        boolValue?.isTask && <TopModal
                            overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                            customStyle={{ width: '370px', maxHeight: '90%', background: '#fff' }}

                            onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                        >
                            <AddTask
                                selectedSlot={selectedSlot}
                                team={team}
                                header={boolValue?.type}
                                onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                                onSubmit={() => {

                                    if (type === 'month') {
                                        const { startOfWeek, endOfWeek } = getStartAndEndOfMonth(new Date());
                                        initRender(startOfWeek, endOfWeek);
                                    }
                                    else {
                                        init()
                                    }
                                    setBoolValue({ ...boolValue, isTask: false })
                                }}

                            />
                        </TopModal>
                    }
                </div>
            </Body>
        </ClickAwayListener>
    );
}
export default Bookings;
const Body = styled.div`

width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const Header = styled.h4`
  text-align: start;
  margin: 10px 20px;
  margin-bottom:-10px;
  font-size: 1.8rem;
  font-weight: bold;
  color: rgba(32, 32, 32, 0.95);

  letter-spacing: 1.5px;
`;



const CalanderTime = styled(withDragAndDrop(Calendar))`

.rbc-time-view .rbc-row{
 
    max-height: calc(100vh - 200px); 
    border-color:#eee;
}
.rbc-time-slot {
  
    border: 0px solid #eee; 
  }

  
  
.rbc-day-slot {
    position:none;
}
.rbc-timeslot-group {
    max-height:200px;
    border-color:#eee;
    
  }
  .rbc-timeslot-group:nth-child(odd) {
  border-bottom: 1px dotted #eee;
}
  .rbc-time-view .rbc-allday-cell{
    border-color:#eee;
    
  }
  .rbc-selected-cell {
    background-color: rgba(0, 123, 255, 0.4) !important; 
  }
  .rbc-month-view .rbc-date-cell {
    margin-top: 2px;
  }
  
  .rbc-month-view .rbc-button-link {
    background: #eee;
    padding: 3px;
    border-radius: 10px;
    color: rgba(32, 32, 32, 0.55);
    font-size: 12px;
  }
  .rbc-month-view .rbc-show-more {
    color: grey;
    border: none;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    cursor: pointer;
    background: #fff; 
    text-decoration: underline; 
  }

  .rbc-month-view .rbc-show-more:hover {
    background: #eee; 
    
   
  }
  
  .rbc-active-range {
    background-color: rgba(255, 123, 0, 0.4) !important; 
  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    height: auto !important;
    display:flex;
    border-color:#eee;
    align-items:center;

}

 
  .rbc-events-container{
     overflow: visible;
  }
  .rbc-month-row {
    min-height: 120px;
  }

  .rbc-addons-dnd-resize-handle {
    background-color: red;
    height: 8px;
    cursor: row-resize;
}

.rbc-calendar {
    background-color: #f3f3f3;
    border-color:#eee;
}
.rbc-time-header {
    border-color:#eee;
}
.rbc-day-bg {
    border-color:#eee;
}
.rbc-row-bg{
    border-color:#eee;
}
.rbc-column-bg{
    border-color:#eee;
}
.rbc-month-view{
    
    border-color:#eee;
}
.rbc-time-header.rbc-overflowing {
    border-color:#eee;
}
.rbc-time-header-content{
    border-color:#eee;
}

.rbc-time-view .rbc-time-header.rbc-overflowing {
    border-color: #eee;
}

.rbc-label.rbc-time-header-gutter{
    border-color:#eee; 
}

  
.rbc-header {
    text-align: center;
    vertical-align: middle;
    horizontal-align:middle;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    border-color:#eee;
}
  


`

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;



const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  
  padding-top: 8px;
  font-weight:500;
  color:${palette.newBlue};
  font-size: 13px;
  font-weight:500;
  padding-left:5px;
  overflow: hidden;
  width:100%;
  white-space: nowrap; 
  overflow: hidden;  
  text-overflow: ellipsis;

`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  color:${palette.grey};
  font-weight:400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 11px;
  line-height: 14px;
  font-weight:400;
  color:${palette.grey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const FilterMenu = styled.div`
min-width:470px;
padding: 20px 15px 25px 15px;
background:#fff;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);
border:1px solid #ddd;
`;



const ClearButton = styled.button`
  background-color: white;
  color: ${palette.newBlue};
  border: 1px solid ${palette.newBlue};
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AssignedSection = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px;
  gap: 10px;
  width:100%;
  border-radius:4px;
  padding:8px;
  cursor:pointer;
  &:hover {
    background-color: #f3f3f3; 
  }
`;
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 80%;
    margin: 0;
    padding: 0;
    display: flex;
    top:45px;
    margin-left:-119px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const StyledTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px; 
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight:500;
  margin-right:10px;
  font-size: 14px; 
  color: #0f52ba;
  background-color: transparent; 

  &:hover {
    background-color: #dce8f5; 
  }

  svg {
    font-size: 16px; 
    color: #0f52ba;
    transition: color 0.3s;

    &:hover {
      color: #083f7c; 
    }
  }
`;