import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { calculateTotalTaxAmount, formatMobileNumber, totalprice } from "../../utils/Commonfunction";
import { editQuote, sendQuote, fetchAllContacts, UploadNotesImage, getInvoiceQuote, getContactDetailV2, saveAdditionalAddress, UploadInvoiceImage } from "../../store/Actions/dashboard.action";
import PreviewInvoice from "../../Pages/PreviewInvoice";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { palette } from "../../utils/Palette";
import { Cancel, CloudUpload, DraftsOutlined, EmailOutlined, HandymanOutlined, KeyboardArrowDown, KeyboardArrowUp, PaidOutlined, PersonAddAlt1Outlined, PreviewOutlined, RequestQuoteOutlined, TextsmsOutlined, ViewArray, } from "@mui/icons-material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../Components/Header/MainHeader";
import DatePickerComponent from "../../ReuseableComponets/DatePickerComponent";
import CustomerSearch from "../../Components/CustomerSearch/CustomerSearch";
import LineItemTable from "./Component/LineItemTable";
import EditCustomerModal from "./Component/EditCustomer";
import TopModal from "../../Components/TopModal";
import { editEstimate, getfetchQuoteId, sendEstimate, sendNewInvoice } from "../../store/Actions/PaymentAction";
import EmailCompose from "./Component/EmailTemplate";
import FullScreenModal from "../../Components/FullScreenModal";
import { Player } from "@lottiefiles/react-lottie-player";
import CreateBooking from "../../Components/Dashboard/CreateBooking";
import ErrorComponent from "../../Components/ErrorComponent/ErrorCompoennt";
import doneAnimation from "../../assets/done.json";
import { useToast } from "../../Components/Toaster/ToasterContext";
import AnimatedModal from "../../Components/ModalComponent/AnimatedModal";
import TagSelector from "./Component/TagSelector";
import imageCompression from 'browser-image-compression';
const NewEstimate = (props) => {
    const [searchParams] = useSearchParams();
    const { showToast } = useToast();
    const contactId = searchParams.get("contactId");
    const newNumber = searchParams.get("number");
    const drafts = searchParams.get("draft");
    const mainRef = useRef(null);
    const addLineFieldRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams();
    const invoicenum = useSelector(state => state?.auth?.userData?.numberOfInvoice)
    const [modalOpen, setModalOpen] = useState(false)
    const userdata = useSelector(state => state?.auth?.userData)
    const detailContact = useSelector(state => state?.auth?.allContacts)
    const numberOfQuote = useSelector(state => state?.auth?.userData?.numberOfQuotes)
    const [selectedImage, setSelectedImage] = useState(props?.value?.attachment || []);
    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [boolValue, setBoolValue] = useState({
        newItem: false,
        IsEditId: drafts ? false : (id !== "new" && id) ? true : false
    })
    const [contactData, setContactData] = useState(detailContact)
    const [loader, setloader] = useState(false)
    const [selectedContact, setSelectedContact] = useState(props?.value?.tempUserDetails || [])
    const [editInvoiceData, setEditInvoiceData] = useState(props?.value?.edit)
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const openAssign = Boolean(anchorEl1);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const openMoreAction = Boolean(anchorEl2);
    const dispatch = useDispatch()
    const [discount, setDiscount] = useState({
        value: '',
        type: '$'
    })
    const [deposit, setDeposit] = useState({
        value: 0,
        type: '$'
    })
    const [value, setValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",
        "phoneNumber": newNumber || props?.value?.to,
        "date": props?.value?.date || userdata?.date || new Date(),
        'quoteNumber': props?.value?.numberOfQuote || numberOfQuote + 1 || '',
        'currency': userdata?.value?.currency || 'CAD',
        "notesQuote": props?.value?.notesQuote || userdata?.notesQuote || "",
        deposit: props?.value?.deposit || userdata?.deposit || 0,
        depositType: props?.value?.depositType || userdata?.depositType || "",
        discount: props?.value?.discount || userdata?.discount || "",
        discountType: props?.value?.discountType || userdata?.discountType || "",
        "validUntil": props?.value?.validUntil || userdata?.valid || new Date(new Date().setMonth(new Date().getMonth() + 1)),
        "serviceAddress": props?.value?.serviceAddress || "",

    })
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",
        "phoneNumber": props?.value?.customerPhoneNumber || props?.value?.number || props?.value?.to,


    })
    const [addOwnerData, setAddOwnerData] = useState(props?.value?.items || [])
    const location = useLocation();

    const [addMaterialData, setAddMaterialData] = useState([])




    const init = async () => {
        if (id !== "new" && id) {
            setloader(true)
            const res2 = await dispatch(getfetchQuoteId(id))
            console.log(res2?.data, "quote dataa")
            if (res2?.status === true) {
                setValue({
                    "name": res2?.data?.name || "",
                    "email": res2?.data?.email || "",
                    "address": res2?.data?.address || "",
                    "phoneNumber": res2?.data?.customerPhoneNumber || res2?.data?.number || props?.value?.to,
                    "date": res2?.data?.date || res2?.data?.date || new Date(),
                    'quoteNumber': res2?.data?.numberOfQuote || numberOfQuote + 1 || '',
                    'currency': res2?.data?.currency || 'CAD',
                    "notesQuote": res2?.data?.notesQuote || userdata?.notesQuote || "",
                    deposit: res2?.data?.deposit || userdata?.deposit || '',
                    depositType: res2?.data?.depositType || userdata?.depositType || "",
                    discount: res2?.data?.discount || userdata?.discount || "",
                    discountType: res2?.data?.discountType || userdata?.discountType || "",
                    "validUntil": res2?.data?.validUntil || userdata?.validUntil || new Date(),
                    "referenceNo": res2?.data?.referenceNo,
                    "status": res2?.data?.status,
                    "serviceAddress": res2?.data?.serviceAddress,

                })
                setDiscount({ value: res2?.data?.discount || "", type: res2?.data?.discountType || '$' })
                setDeposit({ value: res2?.data?.deposit || 0, type: res2?.data?.depositType || '$' })
                setAddOwnerData([...res2?.data?.items])
                setSelectedImage(res2?.data?.attachment)
            }
            const res4 = await dispatch(getContactDetailV2(res2?.data?.contactId))
            if (res4.status === true) {
                setSelectedContact({
                    ...res4?.data[0], additionalAddress: [...(res4?.data[0]?.address ? [{ address: res4?.data[0]?.address }] : []), ...res4?.data[0]?.additionalAddress], additionalContact: [{
                        "name": res4?.data[0]?.name,
                        "number": res4?.data[0]?.number,
                        "email": res4?.data[0]?.email,

                    }, ...res4?.data[0]?.additionalContact]
                })
            }

            setloader(false)
        }
        if (contactId) {
            setloader(true)
            const res4 = await dispatch(getContactDetailV2(contactId))
            setValue({
                ...value,
                "name": res4?.data[0]?.name || "",
                "email": res4?.data[0]?.email || "",
                "address": res4?.data[0]?.address || "",
                "businessName": res4?.data[0]?.businessName || '',
                "phoneNumber": res4?.data[0]?.number || '',
                "mainAddress": res4?.data[0]?.mainAddress || '',

            })
            setSelectedContact({
                ...res4?.data[0], additionalAddress: [...(res4?.data[0]?.address ? [{ address: res4?.data[0]?.address }] : []), ...res4?.data[0]?.additionalAddress], additionalContact: [{
                    "name": res4?.data[0]?.name,
                    "number": res4?.data[0]?.number,
                    "email": res4?.data[0]?.email,

                }, ...res4?.data[0]?.additionalContact]
            })
            setloader(false)
        }
        const res1 = await dispatch(fetchAllContacts())
        setContactData(res1?.data)
    }
    useEffect(() => {
        init()
    }, [])
    const handleClickAssign = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleCloseAssign = () => {
        setAnchorEl1(null);
    };
    const handleMoreAction = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleCloseMoreAction = () => {
        setAnchorEl2(null);
    };
    useEffect(() => {
        if (isFocused && addLineFieldRef.current && mainRef.current) {
            addLineFieldRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
        else {
            if (mainRef.current) {
                mainRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [isFocused]);


    const DepositValue = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (value?.depositType === '$') {
            discountedTotalValue = parseFloat(value?.deposit);
            discountPercentage = (value?.deposit / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                }
                return accumulator;

            }, 0)?.toFixed(2) - discountvalue()) * 100)
        }

        else if (value?.depositType === '%') {
            discountedTotalValue = (([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                }
                return accumulator;

            }, 0)?.toFixed(2) - discountvalue()) * (value?.deposit / 100));
            discountPercentage = parseFloat(value?.deposit);
        }

        return discountedTotalValue.toFixed(2);
    };

    const converToInvoiceDraft = async () => {
        const val = {
            ...value, "date": '', "time": '', 'invoiceNumber': invoicenum + 1 || "", "phone": value?.phoneNumber, "items": addOwnerData, "total": `${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;

            }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}`, "subtotal": `${[...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;

            }, 0)?.toFixed(2)}`, date: value?.date, "attachment": selectedImage, invoiceDraft: true
        }
        const res = await dispatch(sendNewInvoice(val, "phoneOnly"))
        if (res.status === true) {
            navigate(`/dashboard/invoices/${res?.data}?draft=true`)

        }


    }

    const onPress = async (draft, to, cc, bcc, subject, body) => {


        const val = {
            ...value, "date": '', "time": '', "email": to?.[0] ? to?.[0] : value?.email, "cc": cc?.length > 0 ? cc : [], "bcc": bcc?.length > 0 ? bcc : [], "emailBody": body, "emailsubject": subject, "phone": value?.phoneNumber, "items": addOwnerData, "total": `${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;

            }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}`, "subtotal": `${[...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;
            }, 0)?.toFixed(2)}`, date: value?.date, "attachment": selectedImage
        }
        if (draft?.draft) {
            if (id !== "new" && id) {
                const res1 = await dispatch(editQuote({ ...val, referenceNo: value?.referenceNo, quoteDraft: true }))
                if (res1.status === true) {
                    await dispatch(getInvoiceQuote())
                    navigate('/dashboard/quotes')
                }
            }
            else if (props?.booking) {
                const res = await dispatch(sendQuote({ ...val, bookingRef: value?.referenceNo, bookingDeposit: DepositValue(), bookingQuote: true, quoteDraft: true }))
                if (res.status === true) {
                    navigate('/dashboard/quotes')
                }
            }
            else {
                const res = await dispatch(sendQuote({ ...val, quoteDraft: true }))
                if (res.status === true) {
                    await dispatch(getInvoiceQuote())
                    navigate('/dashboard/quotes')
                }
            }
        }
        else {
            if (id !== "new" && id) {
                const res1 = await dispatch(editEstimate({ ...val, referenceNo: value?.referenceNo }, id, boolValue?.communicationOption || draft?.communicationOption))
                if (res1.status === true) {
                    showToast("Estimate Sent successfully!", doneAnimation);
                    // props?.onSubmitsw(`Please find the quote attached from ${userdata?.businessName} for $${([...addOwnerData].reduce(function (accumulator, currentValue) {
                    //     return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    // }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}. https://app.getnotifi.com/quote/${res1?.data?.referenceNo}`, val)
                    navigate(-1)
                }
                else {
                    setBoolValue({ ...boolValue, error: res1?.message })
                }
                setBoolValue({ ...boolValue, isSend: false })
            }
            else if (props?.booking) {
                const res = await dispatch(sendQuote({ ...val, bookingRef: props?.value?.referenceNo, bookingDeposit: DepositValue(), bookingQuote: true }))

                if (res.status === true) {
                    showToast("Estimate Sent successfully!", doneAnimation);
                    // props?.onSubmitsw(`Please find the quote attached from ${userdata?.businessName} for $${([...addOwnerData].reduce(function (accumulator, currentValue) {
                    //     return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    // }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}. https://app.getnotifi.com/quote/${res?.data?.referenceNo}`, val)
                    navigate(-1)
                }
                else {
                    setBoolValue({ ...boolValue, error: res?.message })
                }
            }
            else {
                const res = await dispatch(sendEstimate(val, boolValue?.communicationOption || draft?.communicationOption))
                if (res.status === true) {
                    showToast("Estimate Sent successfully!", doneAnimation);
                    setBoolValue({ ...boolValue, isSend: false })
                    navigate(-1)


                }
                else {
                    setBoolValue({ ...boolValue, isSend: false, error: res?.message })
                }
            }
        }

    }

    const onPressDraft = async () => {
        const val = {
            ...value, "date": '', "time": '', "phone": value?.phoneNumber, "items": addOwnerData, "total": `${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;

            }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}`, "subtotal": `${[...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                if (!currentValue?.optional) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }
                return accumulator;
            }, 0)?.toFixed(2)}`, date: value?.date, "attachment": selectedImage
        }
        if (id !== "new" && id) {
            const res1 = await dispatch(editEstimate({ ...val, referenceNo: value?.referenceNo, quoteDraft: true }, id, "emailAndPhone"))
            if (res1.status === true) {
                showToast("Estimate saved successfully!", doneAnimation);
                setBoolValue({ ...boolValue, isSend: false })
                navigate(-1)
            }
            else {
                setBoolValue({ ...boolValue, isSend: false, error: res1?.message })
            }
        }

        else {
            const res = await dispatch(sendEstimate({ ...val, quoteDraft: true }, "emailAndPhone"))
            if (res.status === true) {
                showToast("Estimate saved!", doneAnimation);
                setBoolValue({ ...boolValue, isSend: false })
                navigate(-1)

            }
            else {
                setBoolValue({ ...boolValue, isSend: false, error: res?.message })
            }
        }
    }
    const simulateProgress = (callback) => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setUploadProgress(progress);
            if (progress >= 90) {
                clearInterval(interval);
                callback();
            }
        }, 200);
    };
    const imageHandleChange = async (e) => {
        setBoolValue({ ...boolValue, errorPhoto: '' });
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 500,
            useWebWorker: true,
        };
        const maxFileSizeMB = 5;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        const FileList = Array.from(e.target.files);
        const validFiles = [];
        const invalidFiles = [];
        FileList.forEach((file) => {
            if (file.size > maxFileSizeBytes) {
                invalidFiles.push(file.name);
            } else {
                validFiles.push(file);
            }
        });
        if (invalidFiles.length > 0) {
            setBoolValue({
                ...boolValue,
                errorPhoto: `The following files exceed the size limit of ${maxFileSizeMB} MB and failed to upload: ${invalidFiles.join(', ')}`,
            });
        }
        if (validFiles.length === 0) {
            return;
        }

        const imageList = [];

        await Promise.all(
            validFiles.map(async (file) => {
                const compressedFile = await imageCompression(file, options);
                const fileURL = URL.createObjectURL(compressedFile);
                imageList.push(fileURL);
            })
        );
        simulateProgress(async () => {
            try {
                const res = await dispatch(UploadInvoiceImage(validFiles));
                if (res?.status === true) {
                    console.log(res, "Upload successful");
                    setSelectedImage([...selectedImage, ...res?.data]);
                    setUploadProgress(100);
                } else {
                    setBoolValue({ ...boolValue, errorPhoto: res?.message });
                    setUploadProgress(0);
                }
            } catch (error) {
                console.error("Upload failed:", error);
            } finally {
                setTimeout(() => setUploadProgress(0), 500);
            }
        });
    };
    const handleDragEnter = (e) => {


        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(true);
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setBoolValue({ ...boolValue, errorPhoto: '' });

        if (boolValue?.IsEditId) {
            setBoolValue({ ...boolValue, IsEditId: false });
        }

        setIsDragOver(false);
        const fileList = e.dataTransfer.files;
        if (!fileList.length) return;

        const maxFileSizeMB = 5;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        const filesArray = Array.from(fileList);

        const validFiles = [];
        const invalidFiles = [];
        filesArray.forEach((file) => {
            if (file.size > maxFileSizeBytes) {
                invalidFiles.push(file.name);
            } else {
                validFiles.push(file);
            }
        });
        if (invalidFiles.length > 0) {
            setBoolValue({
                ...boolValue,
                errorPhoto: `The following files exceed the size limit of ${maxFileSizeMB} MB and failed to upload: ${invalidFiles.join(', ')}`,
            });
        }

        if (validFiles.length === 0) {
            return;
        }

        const imageList = validFiles.map((file) => URL.createObjectURL(file));

        simulateProgress(async () => {
            try {
                const res = await dispatch(UploadInvoiceImage(validFiles));
                if (res?.status === true) {
                    console.log(res, "Upload successful");
                    setSelectedImage([...selectedImage, ...res?.data]);
                    setUploadProgress(100);
                } else {
                    setBoolValue({ ...boolValue, errorPhoto: res?.message });
                    setUploadProgress(0);
                }
            } catch (error) {
                console.error("Upload failed:", error);
            } finally {
                setTimeout(() => setUploadProgress(0), 500);
            }
        });
    };

    const converTimeAndEndTime = () => {
        const startTime = new Date();
        startTime.setHours(9);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        const endTime = new Date();
        endTime.setHours(17);
        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        return { startTime, endTime }
    }

    const discountvalue = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (value?.discount) {
            if (value?.discountType === '$') {
                discountedTotalValue = parseFloat(value?.discount);
                discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2)) * 100)
            }

            else if (value?.discountType === '%') {
                discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2) * (value?.discount / 100));
                discountPercentage = parseFloat(value?.discount);
            }
        }
        return discountedTotalValue.toFixed(2);
    };



    const flattenItems = (items) => {
        return items.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const CircularProgress = ({ progress }) => (
        <CircularProgressContainer>
            <SVG width="90" height="90" viewBox="0 0 36 36">
                <BackgroundCircle className="background-circle" />
                <ProgressCircle className="progress-circle" progress={progress} />
            </SVG>
            <PercentageText className="percentage-text">{`${progress}%`}</PercentageText>
        </CircularProgressContainer>
    );



    return (
        <>

            <Body>
                <Header
                    nonsearchable
                />

                {loader ? <div style={{ display: 'flex', height: 'calc(100% - 40px)', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../assets/newloader1.json')}
                    className="player"
                    style={{ width: '200px' }}
                    loop
                    autoplay
                />
                </div> : <Container>

                    <EstimateHeader>
                        <h1 style={{ display: 'flex' }}>{(id !== "new" && id) ? `Estimate #${value?.quoteNumber}` : "New Estimate"}
                            {(id !== "new" && id) && <QuoteStatusContainer status={value?.status}>
                                <span>●</span>
                                {value?.status === 'Waiting for confirmation' ? 'Waiting' : value?.status}
                            </QuoteStatusContainer>}
                        </h1>
                        <div className="button-group">
                            {(id === "new" && id) && <button className="button preview" onClick={() => {
                                if (boolValue?.isEdit) {
                                    setBoolValue({ ...boolValue, isEdit: boolValue?.isEdit ? false : true })
                                }
                                else {
                                    onPressDraft()
                                }
                            }}>{boolValue?.isEdit ? "Edit" : "Save"}</button>}

                            {<button className="button save" onClick={(e) => {

                                if (boolValue?.IsEditId) {

                                    setBoolValue({ ...boolValue, IsEditId: false, isEdit: false })
                                }

                                else {
                                    handleClickAssign(e)
                                }
                            }}>{(boolValue?.IsEditId) ? "Edit" : "Send & Continue"}</button>}
                            <Menu
                                anchorEl={anchorEl1}
                                id="account-menu"
                                open={openAssign}
                                onClose={handleCloseAssign}
                                onClick={handleCloseAssign}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',


                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.16))',
                                        mt: 6,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,

                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            left: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            >
                                <div style={{
                                    maxHeight: '300px',
                                    width: '180px',
                                    overflowY: 'scroll',
                                }}>
                                    <MenuItem
                                        style={{ fontSize: '15px', fontWeight: '500' }}
                                        onClick={() => {

                                            if (!value?.phoneNumber || !value?.email) {
                                                return setBoolValue({ ...boolValue, error: 'Customer: Add a customer to this estimate.' })
                                            }
                                            setBoolValue({ ...boolValue, isSend: true, error: false, communicationOption: 'emailAndPhone' })


                                        }}
                                    >
                                        <RequestQuoteOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Send estimate</MenuItem>
                                    <MenuItem
                                        style={{ fontSize: '15px', fontWeight: '500' }}
                                        onClick={() => {

                                            if (!value?.phoneNumber) {
                                                return setBoolValue({ ...boolValue, error: 'Customer: Add a customer to this estimate.' })
                                            }
                                            setBoolValue({ ...boolValue, error: false, communicationOption: 'phoneOnly' })
                                            onPress({ draft: false, communicationOption: 'phoneOnly' })



                                        }}
                                    >
                                        <TextsmsOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Send as text</MenuItem>
                                    <MenuItem
                                        style={{ fontSize: '15px', fontWeight: '500' }}
                                        onClick={() => {
                                            if (!value?.email) {
                                                return setBoolValue({ ...boolValue, error: 'Customer: Please provide a valid email address for this estimate.' })
                                            }
                                            setBoolValue({ ...boolValue, isSend: true, error: false, communicationOption: 'emailOnly' })


                                        }}
                                    >
                                        <EmailOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Send as email</MenuItem>

                                    {(id === "new" && id) && <MenuItem
                                        style={{ fontSize: '15px', fontWeight: '500' }}
                                        onClick={() => {

                                            setBoolValue({ ...boolValue, isEdit: boolValue?.isEdit ? false : true })


                                        }}
                                    >
                                        <ViewArray style={{ fontSize: '18px', marginRight: '10px' }} /> Preview estimate</MenuItem>}
                                </div>




                            </Menu>
                            {(id !== "new" && id) && <button className="button preview" onClick={handleMoreAction}>More Action  <KeyboardArrowDown style={{ marginLeft: '10px', marginRight: '-5px' }} /> </button>}
                            <Menu
                                anchorEl={anchorEl2}
                                id="account-menu"
                                open={openMoreAction}
                                onClose={handleCloseMoreAction}
                                onClick={handleCloseMoreAction}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',


                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.16))',
                                        mt: 6,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,

                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            left: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            >
                                <div style={{
                                    maxHeight: '300px',
                                    width: '180px',
                                    overflowY: 'scroll',
                                }}>
                                    <MenuItem
                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                        onClick={() => onPressDraft()}
                                    >
                                        <RequestQuoteOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Save estimate</MenuItem>
                                    <MenuItem
                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                        onClick={() => setBoolValue({ ...boolValue, isEdit: boolValue?.isEdit ? false : true })}
                                    >
                                        <PreviewOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> {boolValue?.isEdit ? "Edit estimate" : "Preview estimate"}</MenuItem>
                                    <MenuItem
                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                        onClick={() => converToInvoiceDraft()}
                                    >
                                        <PaidOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Convert to invoice</MenuItem>
                                    <MenuItem
                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                        onClick={() => setModalOpen(true)}
                                    >
                                        <HandymanOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> Convert to job</MenuItem>

                                </div>




                            </Menu>
                        </div>
                    </EstimateHeader>
                    {boolValue?.error && <div style={{ paddingBottom: '20px' }}>
                        <ErrorComponent
                            header="There was an issue with your estimate. Please try again."
                            subheader={boolValue?.error}
                        />
                    </div>}

                    {!boolValue?.isEdit ? <>
                        <Section style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                            <ExpendContainer isExpanded={boolValue?.isExpanded} aria-expanded={boolValue?.isExpanded}>
                                <ExpendHeader onClick={() => setBoolValue({ ...boolValue, isExpanded: boolValue?.isExpanded ? false : true })}>
                                    <ExpendTitle style={{ marginTop: '0px', display: 'flex' }}>Business Details
                                    </ExpendTitle>
                                    <div style={{ display: 'flex' }}>
                                        <IconButton>
                                            {boolValue?.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </ExpendHeader>
                                {boolValue?.isExpanded && <FlexSection style={{ border: '0px solid #fff', padding: '20px 20px', alignItems: 'center' }}  >
                                    <CustomerContainer style={{ border: '0px solid #fff' }} >
                                        <SelectedCustomer>{userdata?.businessName}</SelectedCustomer>
                                        {userdata?.address && <Value>{userdata?.address}</Value>}
                                        {userdata?.email && <Value>{userdata?.email}</Value>}
                                        <Value>{formatMobileNumber(userdata?.phoneNumber)}</Value>
                                    </CustomerContainer>

                                    <ProfilePic src={userdata?.logoImage} />
                                </FlexSection>}
                            </ExpendContainer>
                        </Section>
                        <Section>
                            <FlexSection>
                                {(value?.phoneNumber || value?.email) ? <CustomerContainer style={{ border: '0px solid #fff' }} >
                                    <Title>Bill to</Title>
                                    <SelectedCustomer>{value?.name}</SelectedCustomer>
                                    {value?.address && <Value>{value?.address}</Value>}
                                    {value?.email && <Value>{value?.email}</Value>}
                                    <Value>{formatMobileNumber(value?.phoneNumber)}</Value>
                                    <div style={{ marginTop: '20px' }}>
                                        {!boolValue?.IsEditId && <Link onClick={() => setBoolValue({ ...boolValue, isEditContact: true })}>Edit details</Link>}
                                        {(id === "new" && id) && <Link onClick={() => {
                                            setValue({
                                                ...value,
                                                name: '',
                                                address: '',
                                                email: '',
                                                businessName: '',
                                                phoneNumber: '',
                                            })
                                            setSelectedContact({})
                                            setBoolValue({ ...boolValue, isContact: true })
                                        }}>
                                            Switch to different customer
                                        </Link>}

                                    </div>
                                </CustomerContainer> : <CustomerCard style={{ border: boolValue?.isContact && '0px solid #fff' }} onClick={() => !boolValue?.isContact && setBoolValue({ ...boolValue, isContact: true })}>
                                    {boolValue?.isContact ?
                                        <CustomerSearch
                                            customers={contactData}
                                            onclose={() => setBoolValue({ ...boolValue, isContact: false })}
                                            onSelect={(newValue) => {
                                                setBoolValue({ ...boolValue, isContact: false, error: false })
                                                setSelectedContact({
                                                    ...newValue, additionalAddress: [...(newValue?.address ? [{ address: newValue.address }] : []), ...newValue?.additionalAddress], additionalContact: [{
                                                        "name": newValue?.name,
                                                        "number": newValue?.number,
                                                        "email": newValue?.email,

                                                    }, ...newValue?.additionalContact]
                                                })
                                                setValue({
                                                    ...value,
                                                    name: newValue?.name,
                                                    address: newValue?.address,
                                                    email: newValue?.email,
                                                    businessName: newValue?.businessName,
                                                    phoneNumber: newValue?.number,
                                                    contactId: newValue?._id,
                                                })
                                                setEditValue({
                                                    name: newValue?.name,
                                                    address: newValue?.address,
                                                    email: newValue?.email,
                                                    businessName: newValue?.businessName,
                                                    phoneNumber: newValue?.number
                                                })

                                            }}
                                        /> : <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <PersonAddAlt1Outlined style={{ marginRight: '10px' }} />
                                            <p style={{ margin: 0 }}>Add customer</p>

                                        </div>}
                                </CustomerCard>}
                                <Details>
                                    <div className="detail">
                                        <label>Estimate number</label>
                                        <div className="date-picker">
                                            <Inputbox
                                                type="text"
                                                value={value?.quoteNumber}
                                                placeholder='Estimate number'

                                                style={{ width: '185px', paddingLeft: '10px' }}
                                                readOnly={boolValue?.IsEditId ? true : false}

                                                onChange={(e) => {
                                                    setValue({
                                                        ...value, quoteNumber: e.target.value
                                                    })
                                                }}
                                            // onBlur={() => onClick("email")}
                                            />
                                        </div>
                                    </div>

                                    <div className="detail">
                                        <label>Date</label>

                                        <DatePickerComponent

                                            selectedDate={value?.date}
                                            onDateChange={(text) => {
                                                boolValue?.IsEditId && setBoolValue({ ...boolValue, IsEditId: false })
                                                setValue({
                                                    ...value, date: text
                                                })
                                            }}
                                            customStyle={{ width: '200px', height: '40px' }}
                                            disabled={boolValue?.IsEditId ? true : false}
                                        />

                                    </div>
                                    <div className="detail">
                                        <label>Valid until</label>
                                        <div>
                                            <DatePickerComponent

                                                selectedDate={value?.validUntil}
                                                onDateChange={(text) => {
                                                    boolValue?.IsEditId && setBoolValue({ ...boolValue, IsEditId: false })

                                                    setValue({
                                                        ...value, validUntil: text
                                                    })
                                                }}
                                                customStyle={{ width: '200px', height: '40px' }}
                                                disabled={boolValue?.IsEditId ? true : false}

                                            />

                                        </div>
                                    </div>
                                    {/* <div className="detail">
                                        <label>Tags</label>
                                        <div >
                                            <AnimatedModal
                                                buttonStyles={{
                                                    customStyles: { width: '200px', textAlign: 'start' }
                                                }
                                                }
                                                buttonContent={<Link style={{ margin: 0, textAlign: 'end' }}>
                                                    + Add tag
                                                </Link>}
                                                modalContent={() => {
                                                    return (
                                                        <div style={{ width: '100%' }}>
                                                            <TagSelector />
                                                        </div>
                                                    )
                                                }}

                                            />

                                        </div>
                                    </div> */}

                                </Details>
                            </FlexSection>
                            <InputText
                                placeholder="Type your message"
                                type="text"
                                value={value?.notesQuote}
                                readOnly={!editInvoiceData ? false : true}
                                onChange={(e) => {
                                    setValue({ ...value, 'notesQuote': e.target.value })
                                }}
                                readOnly={boolValue?.IsEditId ? true : false}

                            />
                            <Divider />
                            <LineItemTable
                                isEditid={boolValue?.IsEditId}
                                items={addOwnerData}
                                discount={value?.discount ? { type: value?.discountType || '$', value: value?.discount } : discount}
                                deposit={value?.deposit ? { type: value?.depositType || '$', value: value?.deposit } : deposit}
                                setownerData={(data) => {

                                    setAddOwnerData(data)
                                }}
                                setDiscount={(val) => {
                                    boolValue?.IsEditId && setBoolValue({ ...boolValue, IsEditId: false })

                                    setValue({ ...value, discount: val?.discount, discountType: val?.discountType })
                                }}
                                setDeposit={(val) => {
                                    boolValue?.IsEditId && setBoolValue({ ...boolValue, IsEditId: false })

                                    setValue({ ...value, deposit: val?.deposit, depositType: val?.depositType })
                                }}
                                onFocus={() => boolValue?.IsEditId && setBoolValue({ ...boolValue, IsEditId: false })}
                            />
                        </Section>
                        <Section style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                            <ExpendContainer isExpanded={boolValue?.isExpanded} aria-expanded={boolValue?.isExpanded}>
                                <ExpendHeader onClick={() => setBoolValue({ ...boolValue, isExpanded: boolValue?.isExpanded ? false : true })}>
                                    <ExpendTitle style={{ marginTop: '0px', display: 'flex' }}>Terms & Conditions
                                    </ExpendTitle>
                                    <div style={{ display: 'flex' }}>
                                        <IconButton>
                                            {boolValue?.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </ExpendHeader>
                                {boolValue?.isExpanded && <BottomMessageText>{userdata?.termCondition?.[0]?.quotes}</BottomMessageText>}
                            </ExpendContainer>
                        </Section>
                        <Section style={{ paddingTop: '5px', paddingBottom: '15px', marginBottom: '100px' }}>
                            <ExpendHeader onClick={() => setBoolValue({ ...boolValue, isExpanded: boolValue?.isExpanded ? false : true })}>
                                <ExpendTitle style={{ marginTop: '0px', display: 'flex' }}>Attachments
                                </ExpendTitle>
                            </ExpendHeader>
                            {!boolValue?.IsEditId && <DragArea
                                className={isDragOver ? "drag-over" : ""}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                htmlFor="fileInput"
                            >
                                {uploadProgress > 0 ?
                                    <CircularProgress progress={uploadProgress} /> : <>
                                        <UploadIcon>
                                            <CloudUpload style={{ fontSize: '60px' }} />
                                        </UploadIcon>

                                        <ExpendTitle>Drag files here or click to upload</ExpendTitle>
                                        <ExpendTitle>Max 20MB</ExpendTitle>
                                        <FileInput

                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            multiple

                                            onChange={imageHandleChange}
                                        />
                                    </>}
                            </DragArea>}
                            {boolValue?.errorPhoto && <Label style={{ color: 'red', marginBottom: '10px' }}>{boolValue?.errorPhoto}</Label>}
                            <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '10px' }}>
                                {selectedImage?.map((item) =>
                                    <div style={{ flexWrap: 'wrap', position: 'relative', padding: '0px 15px', overflow: 'hidden' }}>
                                        <PhotosStyle src={item} />
                                        <div
                                            onClick={() => {
                                                const arr = selectedImage?.filter(obj => obj !== item)
                                                setSelectedImage(arr)
                                            }}
                                            style={{ position: 'absolute', display: 'flex', top: 0, right: 15, background: 'white', borderRadius: 10 }}>
                                            {!boolValue?.IsEditId && <Cancel style={{ fontSize: '22px' }} />}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Section>
                    </> :
                        <div
                            style={{

                                width: '210mm',

                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                background: 'white',
                                boxShadow: "0px 4px 16px rgba(30, 30, 30, 0.08)",

                                boxSizing: 'border-box',

                            }}
                        >
                            <PreviewInvoice
                                type={"Estimate"}
                                userDetails={{ ...value, attachment: selectedImage }}
                                bundleData={addOwnerData?.filter((item) => item?.type === 'Bundle')}
                                quoteData={addOwnerData?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')}
                                materialData={addOwnerData?.filter((item) => item?.type === 'Material')}
                            />
                        </div>
                    }

                </Container>}

            </Body>
            {boolValue?.isEditContact && <TopModal overlayStyle={{ alignItems: "center", paddingTop: '0px' }} onClose={() => setBoolValue({ ...boolValue, isEditContact: false })} customStyle={{ width: '600px', padding: '0px', height: '89%', maxHeight: '80%' }}>

                <EditCustomerModal
                    address={selectedContact?.additionalAddress}
                    subContact={selectedContact?.additionalContact}
                    value={value}
                    onSave={(val) => {
                        setValue({ ...value, ...val, serviceAddress: val?.serviceAddress })
                        setBoolValue({ ...boolValue, isEditContact: false, error: false })

                    }}
                    newAddress={async (val) => {
                        await dispatch(saveAdditionalAddress({ ...val, "contactId": selectedContact?._id }))
                        const res4 = await dispatch(getContactDetailV2(selectedContact?._id))
                        if (res4.status === true) {
                            setSelectedContact({
                                ...res4?.data[0], additionalAddress: [...(res4?.data[0]?.address ? [{ address: res4?.data[0]?.address }] : []), ...res4?.data[0]?.additionalAddress], additionalContact: [{
                                    "name": res4?.data[0]?.name,
                                    "number": res4?.data[0]?.number,
                                    "email": res4?.data[0]?.email,

                                }, ...res4?.data[0]?.additionalContact]
                            })

                        }
                    }}
                    onCancel={() => setBoolValue({ ...boolValue, isEditContact: false, error: false })}
                />

            </TopModal>}
            {boolValue?.isSend && <TopModal onClose={() => setBoolValue({ ...boolValue, isSend: false })} overlayStyle={{ alignItems: "center", paddingTop: '0px' }} customStyle={{ width: '75%', padding: '0px', maxHeight: '80%' }}>
                <div style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
                    <div style={{ width: '525px' }}>
                        <EmailCompose
                            type={'Estimate'}
                            onCancel={() => setBoolValue({ ...boolValue, isSend: false })}
                            email={value?.email}
                            subjectval={`${userdata?.businessName} - New Estimate ${value?.quoteNumber}`}
                            onSubmit={async (val) => {
                                const { to, cc, bcc, subject, body } = val;
                                await onPress({ draft: false }, to, cc, bcc, subject, body)

                            }}
                        />
                    </div>
                    <div
                        style={{

                            width: '210mm',
                            zoom: 0.82,
                            display: 'flex',
                            justifyContent: 'center',
                            background: 'white',
                            boxShadow: "0px 4px 16px rgba(30, 30, 30, 0.05)",

                            boxSizing: 'border-box',

                        }}
                    >
                        <PreviewInvoice
                            type={"Estimate"}
                            userDetails={{ ...value, attachment: selectedImage }}
                            bundleData={addOwnerData?.filter((item) => item?.type === 'Bundle')}
                            quoteData={addOwnerData?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')}
                            materialData={addOwnerData?.filter((item) => item?.type === 'Material')}
                        />
                    </div>
                </div>
            </TopModal>}
            {modalOpen && (
                <FullScreenModal onClose={() => {

                    setModalOpen(false)


                }}>

                    <CreateBooking
                        {...props}
                        value={{
                            "endDate": new Date(), "time": converTimeAndEndTime()?.startTime, "endTime": converTimeAndEndTime()?.endTime, "attachment": selectedImage, ...editValue, "phone": value?.phoneNumber, "items": addOwnerData, "total": `${[...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                            }, 0)?.toFixed(2) - discountvalue()}`, "subtotal": `${[...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }, 0)?.toFixed(2)}`, quoteNo: value?.referenceNo, bookingDeposit: value?.deposit, bookingDepositType: value?.depositType, ...value, "phone": value?.phoneNumber, "to": value?.phoneNumber, "address": value?.serviceAddress || value?.address, "date": new Date(),
                        }}
                        serviceRequest={false}
                        newService={true}
                        additionalAddress={selectedContact?.additionalAddress}
                        setCreateBookingModal={(val) => {
                            setModalOpen(false)
                            if (val?.number) {
                                navigate(`/dashboard/servicerequests/${val?.number}`)
                            }

                        }}
                        team={userdata?.crewMembers}
                        onSubmitsw={async (val, editValue) => {
                            navigate(`/dashboard/servicerequests/${editValue?.number}`)
                            setModalOpen(false)

                        }
                        }
                    />


                </FullScreenModal>
            )}

        </>
    );
};

export default NewEstimate;
const Container = styled.div`
  max-width: 1030px;
  min-width:900px;
  margin: 0 auto;
  padding: 20px;
display:flex;
align-self:center;

flex-grow: 1;
flex-direction:column;
padding-bottom:80px;
height:100vh;



`;

const EstimateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .button-group {
    display: flex;
    gap: 10px;

    .button {
      padding: 8px 18px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display:flex;
      justify-content:center;
      align-items:center;
      font-size: 14px;
    }

    .preview {
      background-color: white;
      border: 1px solid #0f52ba;
      color: #0f52ba;
    }

    .save {
      background-color: #0f52ba;
      color: white;
    }
  }
`;

const Section = styled.div`
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 10px;

  gap: 20px;
  margin-bottom: 20px;
  box-shadow:0px 4px 16px rgba(30, 30, 30, 0.07);
  border: 1px solid #f3f3f3;
`;
const FlexSection = styled.div`
  display: flex;
  gap: 20px;
  justify-content:space-between;
  border-bottom:1px solid #ddd;
  padding: 0px 20px;
`;
const Inputbox = styled.input`
height: 35px;
font-size: 13px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
&:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  &[readonly] {
    border: 1px solid grey;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`

const CustomerCard = styled.div`
position:relative;
  flex: 1;
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #0f52ba;
  cursor: pointer;

  height:160px;
`;

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 70px; 
  font-size: 13px;
  padding: 10px;
  margin-bottom:20px;
  line-height: 16px;
  word-wrap: break-word;
  width: 100%;
  margin-top: 10px;
  display: block;
  border-radius: 4px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 0px solid #fff;
  resize: vertical;
  box-sizing: border-box;
  outline: none;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  
  

  @media (min-width: 260px) and (max-width: 721px) {
    width: 96.6%;
  }
`;
const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;
const Details = styled.div`
  flex: 2;

  .detail {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 25px;

    label {
      font-weight: bold;
      font-size: 14px;
      margin-right:25px;
    }

    input {
      padding-left: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    
    }

    .date-picker {
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        width: auto;
      }
    }
  }
`;


const Body = styled.div`

width:calc(100% - 240px);
height:100vh;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
overflow-x:scroll;

`
const CustomerContainer = styled.div`
 
  
`;

const Title = styled.div`
  font-size: 14px;
  color: gray;
  margin-bottom: 8px;
  text-align:start;
`;
const ExpendTitle = styled.h4`
 
  margin:0px;
  text-align:start;
`;

const SelectedCustomer = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align:start;
`;

const Link = styled.div`
  color: #0f52ba;
  font-size: 14px;
  cursor: pointer;
  margin: 6px 0;
  text-align:start;
  font-weight:500;

  &:hover {
    text-decoration: underline;
  }
`;
const Value = styled.p`
color:${palette.black};
font-size: 13px;
text-align:start;
line-height:16px;
width:100%;
margin-top:0px;
margin-bottom:4px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

.nameButtton{
    font-weight:600;
    color:${palette.charcoleBlack}
}

`
const ExpendContainer = styled.div`
  background-color: #ffffff;
  color: #333333;
  border-radius: 8px;
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  ${({ isExpanded }) =>
        isExpanded
            ? `
    max-height: auto; 
   
  `
            : `
   
   
  `}
  
`;

const ExpendHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding:0px 15px;
  padding-top:10px;
`;

const BottomMessageText = styled.p`
    font-size: 14px;
    color: ${palette.black};
    line-height:1.2;
    white-space: pre-line;
    margin-bottom:10px;
    font-weight:500;
    text-align:start;
    padding:0px 15px;
    margin-top:10px;

`;
const DragArea = styled.label`
  border: 2px dashed #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
margin:20px;
  background-color: #fafbff;
 
  color: #666;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #f1f6ff;
    border-color: #7b7bf5;
  }

  &.drag-over {
    background-color: #e6f0ff;
    border-color: #6b6bf5;
  }
`;

const UploadIcon = styled.div`
  font-size: 60px;
  color: #a9a9f5;
  margin-bottom: 10px;
`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 100px;
height: 100px;

`
const ProfilePic = styled.img`
background:#fff;
color:white;
height:80px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
font-weight:bold;
`

const QuoteStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  height:20px;
  padding:0px 10px;
  font-size:13px;
  font-weight:400;
  border-radius: 12px;
  margin-top: 5px;
  background: ${(props) => {
        switch (props.status) {
            case 'Waiting for confirmation':
                return '#ffe4c2';
            case 'Draft':
                return '#e0e4ea';
            case 'Rejected':
                return '#ffd4df';
            default:
                return '#d4f0d4';
        }
    }};
  color: ${(props) => {
        switch (props.status) {
            case 'Waiting for confirmation':
                return '#fa811f';
            case 'Draft':
                return '#586171';
            case 'Rejected':
                return '#c0113d';
            default:
                return '#29740f';
        }
    }};
    span{
        margin-right:5px;
    }
`;
const StyledTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px; 
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  font-size: 12px; 
  color: #0f52ba;
  background-color: transparent; 

  &:hover {
    text-decoration:underline;
  }

  svg {
    font-size: 16px; 
    color: #0f52ba;
    transition: color 0.3s;

    &:hover {
      color: #083f7c;
    }
  }
`;

const CircularProgressContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px auto;
`;

const SVG = styled.svg`
  transform: rotate(-90deg);
  overflow: visible;
`;

const BackgroundCircle = styled.circle`
  cx: 18;
  cy: 18;
  r: 15.915;
  fill: none;
  stroke: #f3f3f3;
  stroke-width: 3;
`;

const ProgressCircle = styled.circle`
  cx: 18;
  cy: 18;
  r: 15.915;
  fill: none;
  stroke: ${palette.newBlue};
  stroke-width: 3;
  stroke-dasharray: ${({ progress }) => `${progress}, 100`};
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
`;

const PercentageText = styled.div`
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: ${palette.newBlue};
`;
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`

