import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";


const AnimatedModal = ({
  buttonContent = "Open Modal",
  modalContent,
  buttonStyles = {},
  modalStyles = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef(null);

  const openModal = (e) => {
    e.stopPropagation()
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonPosition({ x: rect.left, y: rect.top });
    }
    setIsOpen(true);
  };

  const closeModal = (e) => {
    e.stopPropagation()
    setIsOpen(false);
  };

  return (
    <div
    
    >
      <Button
        ref={buttonRef}
        onClick={openModal}
        background={buttonStyles.background}
        color={buttonStyles.color}
        fontSize={buttonStyles.fontSize}
        customStyles={buttonStyles.customStyles}
      >
        {buttonContent}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <ModalOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <ModalContent
              initial={{
                x: buttonPosition.x - window.innerWidth / 2,
                y: buttonPosition.y - window.innerHeight / 2,
                scale: 0,
              }}
              animate={{ x: 0, y: 0, scale: 1 }}
              exit={{
                x: buttonPosition.x - window.innerWidth / 2,
                y: buttonPosition.y - window.innerHeight / 2,
                scale: 0,
              }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
              width={modalStyles.width}
              background={modalStyles.background}
              borderRadius={modalStyles.borderRadius}
              customStyles={modalStyles.customStyles}
              onClick={(e) => e.stopPropagation()}
            >
              {modalContent(closeModal)}
            </ModalContent>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedModal;

const Button = styled.div`
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  background: ${(props) => props.background || "#fff"};
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "13px"};
  ${(props) => props.customStyles || ""}
`;

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1800;
`;

const ModalContent = styled(motion.div)`
  width: ${(props) => props.width || "auto"};
  background: ${(props) => props.background || "white"};
  border-radius: ${(props) => props.borderRadius || "10px"};
  height:auto;
  max-height:70vh;
  max-width:74%;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  ${(props) => props.customStyles || ""}
`;
