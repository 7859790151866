import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classNames from "classnames";
import { palette } from "../../../utils/Palette";
import { CancelOutlined } from "@mui/icons-material";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { FiX } from "react-icons/fi";

const ComposeContainer = styled.div`
  width: auto;
 position:relative;
  padding: 20px;
  border: 1px solid #e0e0e0;
  height:100%;
  display:flex;
  flex-direction:column;
 
  background-color: #ffffff;
`;


const Section = styled.div`
  display: flex;
  align-items: stretch; 
  margin-bottom: 20px;
  align-content:center;
`;

const Label = styled.label`
  background-color: #f2f2f2;
  color: #333;
  font-weight: 500;
  padding: 10px;
  border-radius: 4px 0 0 4px;

  text-align: right;
  font-size: 12px;
`;

const EmailInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  min-height: 35px;
  padding-left:10px;
  transition: border-color 0.3s;

  &.focus {
    border-color: ${palette.charcoleBlack};
   
  }
`;

const EmailChip = styled.div`
  display: flex;
  align-items: center;
  background-color: #e3f2fd;
  border-radius: 16px;
  padding: 0px 8px;
  margin:4px 0px;
  font-size: 13px;
  color: #1565c0;
  font-weight: 400;

  span {
    margin-left: 8px;
    font-weight: 500;
    color: #d32f2f;
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 100px;
  border-radius: 0 4px 4px 0;

  &::placeholder {
    color: #aaa;
  }
`;

const SubjectLabel = styled.label`
font-size: 12px;
font-weight: 500;
margin-bottom: 5px;
align-text:start;
display:flex;
margin-left:3px;
align-self: flex-start;
font-family:Inter;
text-align:flex-start;


  
`
const Input = styled.input`
height: 35px;
font-size: 13px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
width:97%;
&:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`;

const TextArea = styled.textarea`
font-family: 'Inter', sans-serif; 
min-height: 129px; 
height: 39px; 
font-size: 13px;
padding: 10px;
line-height: 16px;
word-wrap: break-word;
width: 100%;
display: block;
border-radius: 4px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;


resize: vertical;
box-sizing: border-box;
margin-top:0px;
margin-right:0px;
outline: none;
&:focus {
  outline: 1.5px solid black;
  border: 0.5px solid ${palette.charcoleBlack};

}
::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}

`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background-color: #0f52ba;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #084295;
  }

`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position:fixed;
  bottom:10%;
right:12.5%;
  background-color:#fff;
  width: 75%;
  display: flex;
align-self:center;
  height: auto;
padding-bottom:20px;
align-items: center;
border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
  z-index: 1000; 
 border-top:1px solid #ddd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
`;
const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;

  display:flex;
  align-items:center;
  gap:10px;
  text-align:start;
`;
const Link = styled.div`
  color: grey;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  padding: 12px 20px;
  margin-right:10px;
  border: none;
  border-radius: 8px;
  display:flex;
  align-items:center;
  text-align:start;
  font-weight:500;

  &:hover {
    text-decoration: underline;
    color: black;
  }
`;

const EmailCompose = ({ onSubmit,email,subjectval,onCancel,type}) => {
    console.log(email,"ddd")
    const [to, setTo] = useState(email ? [email] : []);
    const [cc, setCc] = useState([]);
    const [bcc, setBcc] = useState([]);
    const [subject, setSubject] = useState(subjectval||"");
    const [body, setBody] = useState("");
    const [value, setvaue] = useState({
        to: '',
        bcc: '',
        cc: ''
    });
    const [currentInput, setCurrentInput] = useState("");
    const [focusedField, setFocusedField] = useState("");

    const handleAddEmail = (list, setList, type) => {
        if (type === 'cc') {
            if (value?.cc.trim() && validateEmail(value?.cc)) {
                setList([...list, value?.cc.trim()]);
                setvaue({ ...value, cc: "" })
            }
        }
        else if (type === 'bcc') {
            if (value?.bcc?.trim() && validateEmail(value?.bcc)) {
                setList([...list, value?.bcc?.trim()]);
                setvaue({ ...value, bcc: "" })
            }
        }
        else {
            if (currentInput.trim() && validateEmail(currentInput)) {
                setList([...list, currentInput.trim()]);
                setCurrentInput("");
            }
        }
    };

    const handleKeyDown = (e, list, setList, type) => {
        if (e.key === "Enter" || e.key === "Tab" || e.key === ",") {
            e.preventDefault();
            handleAddEmail(list, setList, type);
        }
    };

    const handleRemoveEmail = (index, list, setList) => {
        const updatedList = [...list];
        updatedList.splice(index, 1);
        setList(updatedList);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async() => {
        if (onSubmit) {
           await onSubmit({ to, cc, bcc, subject, body });
        }
    };


    return (
        <ComposeContainer>
            <Header><FiX onClick={()=>onCancel()}/>{`Email ${type}`}</Header>
            <Section>
                <Label>To</Label>
                <EmailInputWrapper className={classNames({ focus: focusedField === "to" })}>
                    {to.length === 1 && (
                        <EmailChip>
                            {to[0]}
                            <span onClick={() => handleRemoveEmail(0, to, setTo)}>×</span>
                        </EmailChip>
                    )}
                    {to.length === 0 && (
                        <EmailInput
                            type="text"
                            placeholder="Add recipient"
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, to, setTo)}
                            onBlur={() => {
                                handleAddEmail(to, setTo);
                                setFocusedField("");
                            }}
                            onFocus={() => setFocusedField("to")}
                        />
                    )}
                </EmailInputWrapper>
            </Section>
            <Section>
                <Label>Cc</Label>
                <EmailInputWrapper className={classNames({ focus: focusedField === "cc" })}>
                    {cc.map((email, index) => (
                        <EmailChip key={index}>
                            {email}
                            <span onClick={() => handleRemoveEmail(index, cc, setCc)}>×</span>
                        </EmailChip>
                    ))}
                    <EmailInput
                        type="text"
                        placeholder="Add Cc"
                        value={value?.cc}
                        onChange={(e) => setvaue({ ...value, cc: e.target.value })}
                        onKeyDown={(e) => handleKeyDown(e, cc, setCc, "cc")}
                        onBlur={() => {
                            handleAddEmail(cc, setCc, "cc");
                            setFocusedField("");
                        }}
                        onFocus={() => setFocusedField("cc")}
                    />
                </EmailInputWrapper>
            </Section>
            <Section>
                <Label>Bcc</Label>
                <EmailInputWrapper className={classNames({ focus: focusedField === "bcc" })}>
                    {bcc.map((email, index) => (
                        <EmailChip key={index}>
                            {email}
                            <span onClick={() => handleRemoveEmail(index, bcc, setBcc)}>×</span>
                        </EmailChip>
                    ))}
                    <EmailInput
                        type="text"
                        placeholder="Add Bcc"
                        value={value?.bcc}
                        onChange={(e) => setvaue({ ...value, bcc: e.target.value })}
                        onKeyDown={(e) => handleKeyDown(e, bcc, setBcc, "bcc")}
                        onBlur={() => {
                            handleAddEmail(bcc, setBcc, "bcc");
                            setFocusedField("");
                        }}
                        onFocus={() => setFocusedField("bcc")}
                    />
                </EmailInputWrapper>
            </Section>

            {/* Subject Section */}
            <Section style={{ flexDirection: 'column' }}>
                <SubjectLabel>Subject</SubjectLabel>
                <Input
                    type="text"
                    placeholder="Enter subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
            </Section>

            {/* <Section>

                <TextArea
                    placeholder="Write your mail"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                />
            </Section> */}

            <Actions>
                    <Link onClick={onCancel}>Cancel</Link>
                    <RippleButton style={{marginRight:'30px',marginTop:'20px'}} onClick={handleSubmit}>Send</RippleButton>
                </Actions>
           
        </ComposeContainer>
    );
};

EmailCompose.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default EmailCompose;
