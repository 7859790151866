import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { FiFilter, FiSend } from 'react-icons/fi';
import { palette } from '../../../utils/Palette';
import TopModal from '../../TopModal';
import { Box } from '@mui/material';
import RippleButton from '../../../ReuseableComponets/RippleButton';


const FollowUpCard = ({ initialDays, onsubmit, type, message, handleDelete, onClickMessageChange }) => {
  const [isEditingDays, setIsEditingDays] = useState(false);
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [value, setVaule] = useState({
    "name": "Reminder for Invoice",
    "subject": message?.subject || '',
    "body": message?.body || '',
    "triggerDays": message?.triggerDays || initialDays,
    "category": type,
    "type": ["email", "text"],
    "isEnabled": message?.isEnabled || false,
    triggerTime: message?.triggerTime || '09:00'
  })

  const handleDayChange = (e) => {
    setVaule({ ...value, triggerDays: e.target.value });
  };

  const formatTo24Hour = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  };
  const handleTimeChange = (e) => {
    const inputValue = e.target.value;

    setVaule({ ...value, triggerTime: formatTo24Hour(inputValue) });
  };
  const formatTime = (value) => {
    const [hours, minutes] = value.split(":").map(Number);

    if (hours !== undefined && minutes !== undefined) {
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = String(minutes).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    return value;
  };

  const onPress = async () => {

    await onsubmit({ triggerDays: value?.triggerDays,triggerTime:value?.triggerTime, category: value?.category==='job'?'booking':value?.category, isEnabled: value?.isEnabled, _id: message?._id })
  }

  return (
    <Container>
      <ContentWrapper>
        <Header>
          {type==='job'?`Follow-up with clients ${value?.triggerDays} days before their upcoming jobs are scheduled`: `Follow-up with clients ${value?.triggerDays} days after ${type === 'invoice' ? 'invoice' : 'estimate'
            } are sent`}
          <StatusButton isActive={value?.isEnabled} onClick={() => value?.isEnabled && setDeleteModalOpen(true)}>
            <StatusDot isActive={value?.isEnabled} />
            {value?.isEnabled ? 'Active' : 'Inactive'}
          </StatusButton>
        </Header>

        <Section label={"When this happens"}>
          <Icon>
            <CalendarMonthOutlined />
          </Icon>
          <TextContent>

            {isEditingTime ? (
              <Input
                type='time'
                value={value?.triggerTime}
                autoFocus
                style={{ maxWidth: '75px', fontFamily: 'Inter',marginRight:'5px' }}
                onChange={handleTimeChange}
                onBlur={() => setIsEditingTime(false)}
              />
            ) : (
              <span style={{marginRight:'5px'}} onClick={() => setIsEditingTime(true)}>{formatTime(value?.triggerTime)?.toLowerCase()}</span>
            )}
            At  <span> {isEditingDays ? (
              <Input
                type="number"
                value={value?.triggerDays}
                autoFocus
                onChange={handleDayChange}
                onBlur={() => setIsEditingDays(false)}
                style={{ maxWidth: '70px' }}
              />
            ) : (
              <span onClick={() => setIsEditingDays(true)}>{value?.triggerDays} day(s)</span>
            )}</span> {type==='job'?'before their upcoming jobs are scheduled': `after ${type === 'invoice' ? "invoice" : "estimate"} are sent`}
          </TextContent>
        </Section>
        <LineWrapper>
          <Line />
        </LineWrapper>
        <Section label={"And only if"}>
          <Icon>
            <FiFilter />
          </Icon>
          <TextContent>
            Status is awaiting response
          </TextContent>
        </Section>
        <LineWrapper>
          <Line />
        </LineWrapper>
        <Section label={"Then do this"}>
          <Icon>
            <FiSend />
          </Icon>
          <TextContent>
            Notify the client by text or email with <span onClick={onClickMessageChange}>this message</span>
          </TextContent>
        </Section>
      </ContentWrapper>

      <SaveButton onClick={() => onPress()}>Save</SaveButton>

      {deleteModalOpen && (
        <TopModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          overlayStyle={{ alignItems: 'center', paddingTop: '0px' }}
          customStyle={{ width: '80%', padding: 0 }}
        >
          <Box p={3}>
            <Title style={{ textAlign: 'start' }}>
              Are you sure you want to disable the follow up?
            </Title>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <RippleButton  style={{ backgroundColor: '#fff', borderColor: '#ddd', color: '#000', marginRight: '20px' }} onClick={() => setDeleteModalOpen(false)}>
                Cancel
              </RippleButton>
              <RippleButton onClick={() => handleDelete({ triggerDays: value?.triggerDays, category: value?.category==='job'?'booking':value?.category, isEnabled: value?.isEnabled, _id: message?._id })} style={{ backgroundColor: 'red', borderColor: 'red' }}>
                Disable
              </RippleButton>
            </Box>
          </Box>
        </TopModal>
      )}


    </Container>
  );
};

FollowUpCard.propTypes = {
  initialDays: PropTypes.number.isRequired,
  initialTime: PropTypes.string.isRequired,
};

FollowUpCard.defaultProps = {
  initialDays: 3,
  initialTime: '1:30 pm',
};

export default FollowUpCard;

const Container = styled.div`
border: 1px solid #ddd;
border-radius: 8px;
padding: 20px;
background-color: #fff;
height: 95%;
display: flex;
flex-direction: column;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: start;;
  text-align:start;
    margin-bottom:60px;
  border-radius: 8px;
`;

const StatusButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ isActive }) => (isActive ? '#e0f7e9' : '#fdecea')};
  color: ${({ isActive }) => (isActive ? '#2e7d32' : '#c62828')};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#c8e6c9' : '#f8d7da')};
  }
`;

const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#2e7d32' : '#c62828')};
  margin-right: 5px;
`;

const Section = styled.div`
  position: relative;
  display: flex;
  align-items: center;
 
  border: 1px solid #ddd;
  padding: 10px;

  &::before {
    content: "${(props) => props.label || ""}";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff; 
    padding: 0 5px;
    font-size: 14px; 
    color: #666;
  }
`;
const LineWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
width: 1px;
height: 45px;
background-color: #ddd;
position: relative;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const TextContent = styled.div`
  flex: 1;
  font-size:14px;
  color: #4a5568;
  text-align:start;
  line-height: 1.5;

  & > span {
    color: ${palette.newBlue};
    font-weight: bold;
    cursor: pointer;
  }
`;

const SaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${palette.newBlue};
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
margin-bottom:20px;

  &:hover {
    background-color: #2f855a;
  }
`;

const Input = styled.input`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.9rem;
  width: 80%;
  margin-left: 5px;



`;
const Title = styled.h4`
  margin: 0;
  font-size: 14px;
`;