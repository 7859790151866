import React, { useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { saveTaxes } from "../../store/Actions/dashboard.action";
import { palette } from "../../utils/Palette";
import { ReceiptLongOutlined } from "@mui/icons-material";
import RightModal from "../RightSideModal";
import AddTax from "./component/AddTax";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Box, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { getAllagencies } from "../../store/Actions/SettingsAction";
function TaxDetails(props) {
    const userdata = useSelector(state => state?.auth?.userData)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectList, setSelectedList] = useState(false)
    const [taxAgencies,setTaxAgencies]=useState([])
    const [addOwnerData, setAddOwnerData] = useState([{
        name: '',
        value: '',
        defaultTax: false,
        index: 0,
        taxAgencyName: ''
    }])


    const init = async () => {
        let res1 = await dispatch(getAllagencies())
        console.log(res1?.data,"cdskcdn")
        setTaxAgencies(res1?.data || [])
    }

    useEffect(() => {
        init()
        console.log(userdata?.tax,"scdkkjjnkjnkjknjkn")
        setAddOwnerData([...userdata?.tax || ''])
    }, [userdata?.tax])

    const deleteHandler = async (index) => {
        setLoader(true)
        const data = [...addOwnerData]
        data.splice(index, 1)

        const res = await dispatch(saveTaxes({
            "tax": [...data]
        }))
        setAddOwnerData(data)
        setLoader(false)
    }

    const onSubmit = async (value, edit) => {
        setLoader(true)
        const data = [...addOwnerData]

        if (edit) {
            data[value?.index] = value;
        } else {
            data.push(value);
        }
        if (data.length > 0) {

            if (data.length === 1) {

                data[0]['defaultTax'] = true;
            }
        }

        const res = await dispatch(saveTaxes({
            "tax": [...data],
            "newAddedTax": { 
                "name": value?.name, 
                "value":  value?.value, 
                "taxAgencyName":  value?.taxAgencyName, 
            },
        }))

        setLoader(false)

    }
    return (
        <>
            <Container>


                <CustomTable>
                    <Header>
                        <h2>Tax details</h2>
                        <AddButton onClick={() => {
                            setSelectedList({})
                            setModalOpen(true)
                        }}>+ New Tax</AddButton>
                    </Header>

                    {loader ? <div style={{ display: 'flex', height: '70%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>

                        : <Table aria-label="simple table">
                            <CustomHead>
                                <TableRow>

                                    <TableCell className="sticky" size="small" style={{ cursor: 'pointer', width: '100px', }}>
                                        #</TableCell>

                                    <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                        Name
                                    </TableCell>

                                    <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Rate</TableCell>
                                    <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Registration number</TableCell>
                                    <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Actions</TableCell>
                                </TableRow>
                            </CustomHead>
                            {addOwnerData?.map((item, index) => <>
                                <TableBody  >
                                    <TableRow >
                                        <TableCell style={{ fontWeight: '500' }} className="sticky" size='small'>{index + 1}</TableCell>
                                        <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  >
                                            {item?.name}
                                        </TableCell>
                                        <TableCell size='small' style={{ fontWeight: '500' }}  >
                                            {item?.value}

                                        </TableCell>
                                        <TableCell size='small' style={{ fontWeight: '500' }}  >
                                            {item?.description}

                                        </TableCell>
                                        <TableCell size='small' style={{ maxWidth: '200px' }} >
                                            <ActionIcons>
                                                <FiEdit onClick={async () => {
                                                    setSelectedList({ ...item, index: index })
                                                    setModalOpen(true)
                                                }} />
                                                <FiTrash2 onClick={async () => {
                                                    deleteHandler(index)
                                                }} />

                                            </ActionIcons >
                                        </TableCell>

                                    </TableRow>

                                </TableBody>

                            </>)}

                        </Table>}
                    {addOwnerData?.length > 0 ? null : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            height="60vh"
                            sx={{ color: 'gray' }}
                        >
                            <ReceiptLongOutlined sx={{ fontSize: '50px', marginBottom: 2 }} />
                            <Typography variant="h5" align="center">
                                No Tax details available
                            </Typography>
                            <Typography variant="body2" align="center" color="textSecondary">
                                You can add new tax to manage your data more effectively.
                            </Typography>
                        </Box>
                    )}

                </CustomTable>
                {modalOpen && (
                    <RightModal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}>
                        <AddTax
                            selectedlist={selectList}
                            onSubmit={(value) => {
                                onSubmit(value, false)
                                setModalOpen(false)
                            }}
                            onEdit={(value) => {
                                onSubmit(value, true)
                                setModalOpen(false)
                            }}
                            taxAgency={taxAgencies||[]}

                        />
                    </RightModal>
                )}


            </Container>
        </>
    )
}

export default TaxDetails;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right:20px;
  margin-left:20px;

  h2{
    margin-top:0px;
    margin-bottom:0px;
  }
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;



const AddButton = styled.button`
  background-color: #0F52BA;
  color: white;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #08417c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`;
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }


  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:30px;
  max-width:30px;

}
`;

const Container = styled.div`

width:100%;
background:#fff;
padding-top:30px;

display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`;
