import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, Radio, Tooltip } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { palette } from '../../../../utils/Palette';
import { FiPlus } from 'react-icons/fi';
import Autocomplete from 'react-google-autocomplete';

const Container = styled.div`
    padding: 20px 10px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.h4`
    margin: 0;
    font-size: 1.25rem;
    color: #333;
`;

const CloseIcon = styled(Cancel)`
    cursor: pointer;
    color: #888;
    font-size: 1.2rem;
`;

const SearchInput = styled.input`
    width: 95%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    background: #f3f3f3;
    margin-bottom: 20px;

    &:focus {
        outline: 1.5px solid black;
        border: 0.5px solid ${palette.charcoleBlack};
      
      }
      ::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: gray;
      }
`;

const AddressList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const AddressItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 8px;
    cursor: pointer;
`;

const AddressDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const AddressTitle = styled.h5`
    margin: 0;
    font-size: 14px;
    color: #333;
    text-align: start;
    font-weight: 500;
`;

const AddressText = styled.p`
    margin: 0;
    font-size: 13px;
    color: #666;
    text-align: start;
    margin-top: 5px;
`;

const AddressSelector = ({
    selectedContact,
    onClick,
    searchPlaceholder = "Search",
    onClose,
    value,
    nonHeader,
    onPlaceSelectedAddress
}) => {
    const [search, setSearch] = useState("");

    const searchFilterFunction = (value) => {
        setSearch(value);
    };
    const [isAddress, setIsAddress] = useState(false)

    const filteredAddresses = selectedContact?.filter((item) =>
        item?.title?.toLowerCase().includes(search.toLowerCase()) ||
        item?.address?.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Container>
            {!nonHeader && <Header>
                <Title>Select Address</Title>
                <CloseIcon onClick={onClose} />
            </Header>}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchInput
                    placeholder={searchPlaceholder}
                    value={search}
                    onChange={(e) => searchFilterFunction(e.target.value)}
                />
                <Tooltip title="Add address" arrow placement='top'>
                    <IconButton onClick={() => setIsAddress(true)} style={{ marginTop: '-20px', marginLeft: '10px' }}>
                        <FiPlus />
                    </IconButton>
                </Tooltip>
            </div>
            {isAddress ? <Place
                apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                types={['address']}
                options={{
                    types: ["address"],
                }}

                style={{
                    width: '95%',
                    borderRadius: '5px',
                    fontSize: '14px',
                    height: '25px',
                    fontWeight: '400',
                    fontFamily: 'Inter, sans-serif',
                    color: '#344054',
                    alignSelf: 'start',
                    margin: '10px 0px',
                    background: '#ffffff',
                    border: '0.5px solid #d0d5dd',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    padding: '10px 14px',
                }}
                placeholder='Search address'
                onPlaceSelected={(details) => {
                    setIsAddress(false)
                    const street = `${details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('street_number')
                    )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('route'),
                    )?.short_name || ''}`;

                    const zipCode = details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('postal_code'),
                    )?.short_name;
                    const region = details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('administrative_area_level_1'),
                    )?.long_name;
                    const city = details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('locality'),
                    )?.long_name;
                    const country = details?.address_components.find((addressComponent) =>
                        addressComponent.types.includes('country'),
                    )?.long_name;
                    let address = { postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address, title: details?.formatted_address, }           
                    onPlaceSelectedAddress({...address, address: address?.address, title: address?.address,})
                    setIsAddress(false)
                }}

            /> : <AddressList>
                {filteredAddresses?.map((item, index) => (
                    <AddressItem key={index} onClick={() => onClick(item)}>
                        <AddressDetails>
                            <Radio
                                checked={
                                    item?.address === value?.address &&
                                    item?.title === value?.title
                                }
                                style={{ marginRight: "10px", color: "#0F52BA" }}
                            />
                            <div>
                                <AddressTitle>
                                    {item?.title || `Address ${index + 1}`}
                                </AddressTitle>
                                <AddressText>{item?.address}</AddressText>
                            </div>
                        </AddressDetails>
                    </AddressItem>
                ))}
            </AddressList>}
        </Container>
    );
};

AddressSelector.propTypes = {
    selectedContact: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    value: PropTypes.shape({
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
    }).isRequired,
    nonHeader: PropTypes.bool.isRequired,
};

export default AddressSelector;

const Place = styled(Autocomplete)`
height:30px;
width:296px;
border-radius:4px;
border:1px solid lightgray;
font-weight:400;
padding-left:12px;
@media (min-width: 260px) and (max-width: 721px){
    width:85%;
    }
&::placeholder {
  font-size:14px;
  font-weight:400;
}
&:focus {
outline:none;
border:1px solid lightgray;
}
`
