import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ToggleTabs from "../../../ReuseableComponets/ToggleTab";
import InputBox from "../../../ReuseableComponets/InputBox";
import { ArrowDropDown } from "@mui/icons-material";
import { palette } from "../../../utils/Palette";
import Autocomplete from 'react-google-autocomplete';
import AddressSearch from "../../../Components/CustomerSearch/AddressSearch";
import { Radio } from '@mui/material'
import { formatMobileNumber } from "../../../utils/Commonfunction";
import AddressSelector from "../../../Components/Dashboard/Bookings/Component/AddressSelectorComponent";
const ModalContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width:100%;
  display:flex;
  width:100%;
  height:100%;
  
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align:start;
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background: ${({ active }) => (active ? "#e6f0ff" : "transparent")};
  border: none;
  color: ${({ active }) => (active ? "#0F52BA" : "#555")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  padding: 10px 20px;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #f0f0f0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #333;
  font-size: 13px;
  text-align:start;
  margin-left:3px;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: #0F52BA;
    box-shadow: 0px 0px 5px rgba(15, 82, 186, 0.3);
  }
`;

const Actions = styled.div`
position: fixed;
bottom: 10%;

width: 525px;
display: flex;
align-self:center;
height: auto;
justify-content: flex-end;
border-radius:8px;
align-items: center;
  padding:25px;


  background:white;
`;

const Button = styled.button`
  background-color: ${({ variant }) =>
        variant === "primary" ? "#0F52BA" : "transparent"};
  color: ${({ variant }) => (variant === "primary" ? "#fff" : "#0F52BA")};
  border: ${({ variant }) =>
        variant === "primary" ? "none" : "1px solid #0F52BA"};
  padding: 9px 25px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    opacity: 0.9;
  }
`;
const EditCustomerModal = ({ customerData, value, address, subContact, onSave, onCancel, newAddress }) => {
    const [activeTab, setActiveTab] = React.useState("Contact");
    const [isAddress, setIsAddress] = React.useState(false);
    const [isserviceAddress, setIsserviceAddress] = React.useState(false);
    const [isNew, setNew] = React.useState(false);
    const [addressData, setAddressData] = useState(address || [])
    const [values, setValue] = useState({
        "name": value?.name || "",
        "email": value?.email || "",
        "address": value?.address || "",
        "phoneNumber": value?.phoneNumber,
        "serviceAddress": value?.serviceAddress || value?.address || "",
        title: value?.title || ''

    })
    return (
        <ModalContainer>
            <div style={{ padding: "20px",width:'100%' }}>
                <Header>Edit Customer</Header>
                <ToggleTabs
                    labels={['Contact', "Sub contact", "Service Address"]}
                    selected={activeTab}
                    onToggle={(val) => setActiveTab(val)}

                />
                {activeTab == 'Contact' ? <Form>
                    <InputBox value={values?.name} header={"Customer name"} placeholder={"Customer name"} style={{ width: '95%' }} onChange={(e) => setValue({ ...values, name: e.target.value })} />

                    <InputBox value={values?.email} header={"Email"} placeholder={"Email"} style={{ width: '95%' }} headerStyle={{ marginTop: '0px' }} onChange={(e) => setValue({ ...values, email: e.target.value })} />
                    <Header style={{ fontSize: '16px', marginBottom: '0px' }}>Billing address</Header>
                    <StyledSelect
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            !isAddress && setIsAddress(true);
                        }}
                    >
                        {values?.address || "Choose different billing address"}
                        <ArrowDropDown />
                        {isAddress &&
                            <AddressSearch
                                customers={addressData}
                                
                                onSelect={(item) => {
                                    setValue({ ...values, address: item?.address, title: item?.title })
                                    setIsAddress(false)
                                }}
                                onclose={() => setIsAddress(false)} />}
                    </StyledSelect>
                    <Label htmlFor={'Address'}>Address</Label>


                    <Place
                        apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                        types={['address']}
                        options={{
                            types: ["address"],
                        }}
                        style={{
                            width: '95%',
                            borderRadius: '5px',
                            fontSize: '14px',
                            height: '20px',
                            fontWeight: '400',
                            fontFamily: 'Inter, sans-serif',
                            color: '#344054',
                            background: '#ffffff',
                            border: '0.5px solid #d0d5dd',
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            padding: '10px 14px',
                        }}
                        placeholder='Address'

                        onChange={(e) => {
                            console.log(e.target.value, "eee")
                            // setValue({ ...values, address: e.target.value })
                        }}
                        onPlaceSelected={(details) => {
                            // const street = `${details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('street_number')
                            // )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('route'),
                            // )?.short_name || ''}`;

                            // const zipCode = details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('postal_code'),
                            // )?.short_name;
                            // const region = details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('administrative_area_level_1'),
                            // )?.long_name;
                            // const city = details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('locality'),
                            // )?.long_name;
                            // const country = details?.address_components.find((addressComponent) =>
                            //     addressComponent.types.includes('country'),
                            // )?.long_name;
                            // setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country })
                            // console.log(details, zipCode, city, street, "details")
                            setValue({ ...values, address: details?.formatted_address })
                            // setValues({ ...values, address: details?.formatted_address })
                        }}
                        // defaultValue={address?.street}
                        // value={address?.street}
                        defaultValue={values?.address}
                    />
                </Form> : activeTab === 'Sub contact' ? <StyledForm>
                    <Header style={{ fontSize: '14px', marginBottom: '10px' }}>Select an Alternate Contact for the Quote</Header>
                    <div>
                        {subContact?.map((item, index) => (
                            <StyledContactItem
                                key={index}
                                // onClick={() => onContactClick(item)}
                                onClick={() => {
                                    var cleaned2 = ("" + item?.number).replace(/\D/g, "");
                                    setValue({ ...values, name: item?.name, email: item?.email, phoneNumber: cleaned2?.length > 10 ? `+${cleaned2}` : `+1${cleaned2}` })
                                }}
                            >
                                <Radio style={{ marginRight: "10px", color: "#0F52BA" }} checked={("" + item?.number).replace(/\D/g, "") === ("" + values?.phoneNumber).replace(/\D/g, "") ? true : false} />
                                <StyledContactDetails>
                                    <ContactName>{item?.name}</ContactName>
                                    <ContactNumber>{formatMobileNumber(item?.number)}</ContactNumber>
                                </StyledContactDetails>
                            </StyledContactItem>
                        ))}
                    </div>
                </StyledForm> :
                    <StyledForm style={{marginBottom:'80px'}}>
                          <Label style={{ marginTop: '30px',marginBottom:'10px' }} htmlFor={'Address'}>
                                Service Address
                            </Label>

                    {!isNew&&    
                    
                    <StyledSelect
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                !isserviceAddress && setIsserviceAddress(true);
                            }}
                        >
                            {values?.serviceAddress||values?.address  || "Choose service address"}
                            <ArrowDropDown />
                            {isserviceAddress && (
                                <AddressSearch
                                    customers={addressData}
                                    onSelect={(val) => {
                                        setValue({ ...values,serviceAddress: val?.address, title:val?.title})
                                        setNew(false)
                                    }}
                                    isNew={true}
                                    onCreateNew={() => {
                                        setNew(true)
                                        setIsserviceAddress(false)
                                    }}
                                    onclose={() => setIsserviceAddress(false)}
                                />
                            )}
                        </StyledSelect>}

                        <div style={{ marginBottom: '70px', }}>
                          
                            {!isNew ? null : (
                                <Place
                                    apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                    types={['address']}
                                    options={{
                                        types: ['address'],
                                    }}
                                    style={{
                                        width: '95%',
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        height: '20px',
                                        fontWeight: '400',
                                        fontFamily: 'Inter, sans-serif',
                                        marginTop: '10px',
                                        color: '#344054',
                                        background: '#ffffff',
                                        border: '0.5px solid #d0d5dd',
                                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                        padding: '10px 14px',
                                    }}
                                    placeholder='Address'
                                   autoFocus
                                    onPlaceSelected={(details) => {
                                        setNew(false)
                                        const formattedAddress = details?.formatted_address || '';
                                        const street = `${details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('street_number')
                                        )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('route'),
                                        )?.short_name || ''}`;

                                        const zipCode = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('postal_code'),
                                        )?.short_name;
                                        const region = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('administrative_area_level_1'),
                                        )?.long_name;
                                        const city = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('locality'),
                                        )?.long_name;
                                        const country = details?.address_components.find((addressComponent) =>
                                            addressComponent.types.includes('country'),
                                        )?.long_name;
                                        setAddressData([...addressData, { address: formattedAddress, title: `Address ${addressData?.length + 1}` }])
                                        setValue({ ...values, serviceAddress: formattedAddress, title: `Address ${addressData?.length + 1}` })
                                        newAddress({ postalCode: zipCode, city: city, state: region, street: street, country: country,address: formattedAddress, title: `Address ${addressData?.length + 1}` })
                                       
                                    }}

                                />
                            )}
                        </div>
                    </StyledForm>

                    // <StyledForm>
                    //     <StyledSelect
                    //         onClick={(e) => {
                    //             e.preventDefault();
                    //             e.stopPropagation();
                    //             !isAddress && setIsAddress(true);
                    //         }}
                    //     >
                    //         {values?.address || "Choose service address"}
                    //         <ArrowDropDown />
                    //         {isAddress &&
                    //             <AddressSearch
                    //                 customers={address}
                    //                 onSelect={(val) => setValue({ ...values, ...val })}
                    //                 onCreateNew={()=>setnew(true)}
                    //                 onclose={() => setIsAddress(false)} />}
                    //     </StyledSelect>

                    //     <AddressSelector
                    //         selectedContact={addressData}
                    //         nonHeader
                    //         value={{
                    //             title: values?.title,
                    //             address: values?.serviceAddress,
                    //         }}
                    //         onClick={(item) => {
                    //             setValue({ ...values, serviceAddress: item?.address, title: item?.title })

                    //         }}
                    //         onPlaceSelectedAddress={(item) => {
                    //             setAddressData([...addressData, { address: item?.address, title: `Address ${addressData?.length + 1}` }])
                    //             setValue({ ...values, serviceAddress: item?.address, title: `Address ${addressData?.length + 1}` })
                    //             newAddress({ ...item, title: `Address ${addressData?.length + 1}` })
                    //         }}

                    //     />
                    //     <div>
                    //         <Label style={{ marginTop: '30px' }} htmlFor={'Address'}>Address</Label>


                    //         <Place
                    //             apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                    //             types={['address']}
                    //             options={{
                    //                 types: ["address"],
                    //             }}
                    //             style={{
                    //                 width: '95%',
                    //                 borderRadius: '5px',
                    //                 fontSize: '14px',
                    //                 height: '20px',
                    //                 fontWeight: '400',
                    //                 fontFamily: 'Inter, sans-serif',
                    //                 color: '#344054',
                    //                 background: '#ffffff',
                    //                 border: '0.5px solid #d0d5dd',
                    //                 boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    //                 padding: '10px 14px',
                    //             }}
                    //             placeholder='Address'

                    //             onChange={(e) => {
                    //                 console.log(e.target.value, "eee")
                    //                 setValue({ ...values, address: e.target.value })
                    //             }}
                    //             onPlaceSelected={(details) => {
                    //                 // const street = `${details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('street_number')
                    //                 // )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('route'),
                    //                 // )?.short_name || ''}`;

                    //                 // const zipCode = details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('postal_code'),
                    //                 // )?.short_name;
                    //                 // const region = details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('administrative_area_level_1'),
                    //                 // )?.long_name;
                    //                 // const city = details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('locality'),
                    //                 // )?.long_name;
                    //                 // const country = details?.address_components.find((addressComponent) =>
                    //                 //     addressComponent.types.includes('country'),
                    //                 // )?.long_name;
                    //                 // setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country })
                    //                 // console.log(details, zipCode, city, street, "details")
                    //                 setValue({ ...values, address: details?.formatted_address })
                    //                 // setValues({ ...values, address: details?.formatted_address })
                    //             }}
                    //             // defaultValue={address?.street}
                    //             // value={address?.street}
                    //             value={values?.address}
                    //         />

                    //     </div>
                    // </StyledForm>


                }
                <Actions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button variant="primary" onClick={() => onSave(values)}>
                        Save
                    </Button>
                </Actions>
            </div>
        </ModalContainer>
    );
};

// PropTypes
EditCustomerModal.propTypes = {
    customerData: PropTypes.shape({
        customer: PropTypes.string.isRequired,
        email: PropTypes.string,
        phone: PropTypes.string,
        contactFirstName: PropTypes.string,
        contactLastName: PropTypes.string,
    }),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

// Default Props
EditCustomerModal.defaultProps = {
    customerData: {
        customer: "",
        email: "",
        phone: "",
        contactFirstName: "",
        contactLastName: "",
    },
};

export default EditCustomerModal;

const StyledSelect = styled.button`
  width: 100%;
 height:40px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  appearance: none;
  cursor: pointer;
  padding-left:10px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;
const Place = styled(Autocomplete)`
width:50%;
display:flex;
margin-right:0px;
margin-left:0px;
border-radius:5px;
font-size: 16px;
height:44px;
border-radius:4px;
border:1px solid  ${palette.charcoleBlack};
font-weight:400;
padding-left:12px;
margin-bottom:70px;

&::placeholder {
  font-size:14px;
  font-weight:300;
}
&:focus {
    
    outline: 1.5px solid ${palette.charcoleBlack};
  
  }
`
const StyledForm = styled.form`
  margin: 15px 0;
`;

const StyledContactItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
 
  padding: 7px 0;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledContactDetails = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const ContactName = styled.h5`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;

const ContactNumber = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: grey;
  margin-top:3px;
`;