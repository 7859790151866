import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaCalendarAlt, FaPaperPlane } from "react-icons/fa";
import RightModal from "../../RightSideModal";
import FollowUpCard from "../component/AddReminders";
import DynamicTemplate from "../component/AddReminderMessage";
import Modal from "../../Modal";
import { FiInfo, FiSend } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  createFollowUps,
  getAllAtomations,
  updateFollwup,
} from "../../../store/Actions/SettingsAction";
import { Box, Tooltip, Typography } from "@mui/material";
import CustomTabs from "../../../ReuseableComponets/CustomTab";
import {
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  HandymanOutlined,
  PaidOutlined,
  RequestQuoteOutlined,
} from "@mui/icons-material";
import { palette } from "../../../utils/Palette";

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding-top: 30px;

  display: flex;
  @media (min-width: 260px) and (max-width: 1300px) {
    width: 100%;
    flex-shrink: 0;
    overflow: auto;
  }
`;

const Header = styled.h2`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  max-width: 380px;
  height: 100%;
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ebedf0;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 500;
`;

const SubHeader = styled.div`
  color: #555;
  font-size: 14px;
  padding: 10px 15px;
  text-align: left;
`;

const Card = styled.div`
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  position: relative; /* Enable positioning for the Dot */
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
`;

const CardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #333;
  width: 90%;
  text-align: start;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? "green" : "red")};
  margin-right: 8px;
`;
const CreateNew = styled.div`
  padding: 10px;
  color: #0f52ba;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #eee;
  background: white;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 500;
  background: #dce8f5;

  &:hover {
    background-color: #dce8f5;
  }

  span {
    margin-left: 8px;
  }
`;

const AutomationsPage = () => {
  const [isMessage, setIsMessage] = useState(false);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [columns, setColumns] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setVaule] = useState({
    name: "Reminder for Invoice",
    subject: "Upcoming Invoice Due",
    body: `We just wanted to send a quick note to see if you had a chance to look at the quote we sent recently? We're happy to answer any questions you might have.
We're excited to get to work, so if you'd like to proceed let us know and we can get started!
`,
    textBody: `Hi {{FIRST_NAME}}, we’re following up on the quote we sent. Let us know if you have any questions!`,
    type: ["email", "text"],
    isEnabled: true,
  });

  const dispatch = useDispatch();
  const init = async () => {
    setLoader(true);
    const res = await dispatch(getAllAtomations());
    const invoice = res?.data?.filter((item) => item?.category == "invoice");
    const quote = res?.data?.filter((item) => item?.category == "quote");
    const booking = res?.data?.filter((item) => item?.category == "booking");
    setInvoice(invoice);
    setJobs(booking);
    setColumns(quote);
    setLoader(false);
  };
  useEffect(() => {
    init();
  }, []);

  const onPress = async (val) => {
    if (val?._id) {
      const res = await dispatch(
        updateFollwup(
          {
            ...value,
            category: val?.category,
            triggerTime: val?.triggerTime,
            triggerDays: val?.triggerDays,
            isEnabled: true,
          },
          val?._id
        )
      );
      setIsFollowUp(false);
    } else {
      const res = await dispatch(
        createFollowUps({
          ...value,
          category: val?.category,
          triggerTime: val?.triggerTime,
          triggerDays: val?.triggerDays,
          isEnabled: true,
        })
      );
      setIsFollowUp(false);
    }
    init();
  };

  const disableTemplate = async (val) => {
    const res = await dispatch(
      updateFollwup(
        {
          ...value,
          category: val?.category,
          triggerDays: val?.triggerDays,
          isEnabled: false,
        },
        val?._id
      )
    );
    setIsFollowUp(false);
    init();
  };
  const renderItem = (fields, type) => {
    return (
      <>
        <Header>
          <ButtonGroup>
            <AddButton
              onClick={() => {
                if (type === "invoice") {
                  setIsFollowUp(true);
                  setVaule({
                    name: "Reminder for Invoice",
                    subject: "Upcoming Invoice Due",
                    body: `We just wanted to send a quick note to see if you had a chance to look at the invoice we sent recently? We're happy to answer any questions you might have.
We're excited to get to work, so if you'd like to proceed let us know and we can get started!
`,
                    textBody: `Hi {{FIRST_NAME}}, we’re following up on the invoice we sent. Let us know if you have any questions!`,
                    type: ["email", "text"],
                    isEnabled: false,
                    category: "invoice",
                  });
                } else if (type === "job") {
                  setIsFollowUp(true);
                  setVaule({
                    name: "{{COMPANY_NAME}} - Upcoming Job Reminder",
                    subject: "Reminder: Upcoming Job Scheduled",
                    body: `We hope this message finds you well! This is a friendly reminder that your upcoming job is scheduled for {{JOB_DATE}} at {{JOB_TIME}}.`,
                    textBody: `Hi {{FIRST_NAME}}, this is a reminder about your scheduled job on {{JOB_DATE}} at {{JOB_TIME}}. If you have questions or need to reschedule, contact us at {{PHONE_NUMBER}}.`,
                    type: ["email", "text"],
                    isEnabled: false,
                    category: "job",
                  });
                } else {
                  setIsFollowUp(true);
                  setVaule({
                    name: "Reminder for estimate",
                    subject: "Upcoming Estimate",
                    body: `We just wanted to send a quick note to see if you had a chance to look at the quote we sent recently? We're happy to answer any questions you might have.
We're excited to get to work, so if you'd like to proceed let us know and we can get started!
`,
                    textBody: `Hi {{FIRST_NAME}}, we’re following up on the quote we sent. Let us know if you have any questions!`,
                    type: ["email", "text"],
                    isEnabled: false,
                    category: "quote",
                  });
                }
              }}
            >
              <ButtonText> + New {type} follow-up</ButtonText>
            </AddButton>
          </ButtonGroup>
        </Header>
        {loader ? (
          <StyledPlayer>
            {" "}
            <Player
              src={require("../../../assets/loaders.json")}
              className="player"
              style={{ width: "200px", height: "200px" }}
              loop
              autoplay
            />
          </StyledPlayer>
        ) : (
          <Table
            style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
            sx={{ width: "100%" }}
            aria-label="simple table"
          >
            <CustomHead>
              <TableRow>
                <TableCell
                  size="small"
                  style={{ cursor: "pointer", width: "100px" }}
                >
                  #
                </TableCell>
                <TableCell
                  style={{
                    position: "relative",
                    color: palette.black,
                    fontWeight: "700",
                  }}
                  size="small"
                >
                  Follow up
                </TableCell>

                <TableCell
                  style={{
                    width: "100px",
                    position: "relative",
                    color: palette.black,
                    fontWeight: "700",
                  }}
                  size="small"
                ></TableCell>
              </TableRow>
            </CustomHead>

            {fields?.length > 0 &&
              fields?.map((field, index) => (
                <TableBody
                  onClick={() => {
                    if (type === "estimate") {
                      setVaule({
                        name: "Reminder for estimate",
                        subject: field?.subject || "",
                        body: field?.body || "",
                        triggerDays: field?.triggerDays,
                        triggerTime: field?.triggerTime,
                        category: "quote",
                        type: field?.type,
                        textBody: field?.textBody,
                        isEnabled: field?.isEnabled,
                        _id: field?._id,
                      });
                      setIsFollowUp(true);
                    } else if (type === "job") {
                      setVaule({
                        name: "Upcoming Job Reminder",
                        subject: field?.subject || "",
                        body: field?.body || "",
                        triggerDays: field?.triggerDays,
                        triggerTime: field?.triggerTime,
                        category: "job",
                        type: field?.type,
                        textBody: field?.textBody,
                        isEnabled: field?.isEnabled,
                        _id: field?._id,
                      });
                      setIsFollowUp(true);
                    } else {
                      setVaule({
                        name: "Reminder for Invoice",
                        subject: field?.subject || "",
                        body: field?.body || "",
                        triggerDays: field?.triggerDays,
                        category: "invoice",
                        type: field?.type,
                        textBody: field?.textBody,
                        isEnabled: field?.isEnabled,
                        triggerTime: field?.triggerTime,
                        _id: field?._id,
                      });
                      setIsFollowUp(true);
                    }
                  }}
                  key={index}
                  style={{ background: "#fff", cursor: "pointer" }}
                >
                  <TableRow>
                    <TableCell style={{ fontWeight: "500" }} size="small">
                      {index + 1}
                    </TableCell>

                    <TableCell
                      size="small"
                      style={{ fontWeight: "500", color: palette.newBlue }}
                    >
                      {" "}
                      <div style={{ display: "flex" }}>
                        {" "}
                        <FiSend
                          style={{ color: "black", marginRight: "8px" }}
                        />
                        {type === "job"
                          ? `Follow-up with clients ${field?.triggerDays} days before their upcoming jobs are scheduled`
                          : `Follow-up with clients ${field?.triggerDays} days after estimate are sent`}
                      </div>
                    </TableCell>

                    <TableCell size="small">
                      <Tooltip
                        arrow
                        placement="top"
                        title={field.isEnabled ? "Active" : "Inactive"}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Dot isActive={field.isEnabled} />
                        {field.isEnabled ? "Active" : "Inactive"}
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        )}
        {fields?.length > 0 ? null : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="60vh"
            sx={{ color: "gray" }}
          >
            {type == "invoice" ? (
              <PaidOutlined sx={{ fontSize: "50px", marginBottom: 2 }} />
            ) :type == "job" ? <HandymanOutlined
            sx={{ fontSize: "50px", marginBottom: 2 }}
          />: (
              <RequestQuoteOutlined
                sx={{ fontSize: "50px", marginBottom: 2 }}
              />
            )}
            <Typography variant="h5" align="center">
              No {type} available
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              You can add new {type} to manage your data more effectively.
            </Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Container>
        <CustomTable>
          <h2
            style={{
              textAlign: "start",
              marginLeft: "15px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            Reminders
          </h2>

          <SubHeader>
            Configure the triggers and conditions, and we will complete routine
            tasks and perform actions automatically.
          </SubHeader>

          <CustomTabs
            tabpanelWidth={"400px"}
            boxStyle={{
              p: 0,
              marginTop: "10px",
              marginBottom: "25px",
            }}
            tabsData={[
              {
                label: "Estimates",
                icon: <RequestQuoteOutlined style={{ fontSize: "16px" }} />,
                content: renderItem(columns, "estimate"),
              },

              {
                label: "Invoices",
                icon: <PaidOutlined style={{ fontSize: "16px" }} />,
                content: renderItem(invoice, "invoice"),
              },
              {
                label: "Jobs",
                icon: <HandymanOutlined style={{ fontSize: "16px" }} />,
                content: renderItem(jobs, "job"),
              },
            ]}
          />
        </CustomTable>
        {/* <div style={{ display: 'flex', gap: 25, marginTop: '20px' }}>


                    <Column >
                        <ColumnHeader>
                            <span>{"Estimate"}</span>
                            <span>{columns?.length}</span>
                        </ColumnHeader>
                        <SubHeader>{"Manage your Estimates efficiently and stay organized."}</SubHeader>
                        {columns?.map((card) => (
                            <Card onClick={() => {
                                setVaule({
                                    "name": "Reminder for Invoice",
                                    "subject": card?.subject || '',
                                    "body": card?.body || '',
                                    "triggerDays": card?.triggerDays,
                                    "category": "quote",
                                    "type": card?.type,
                                    "isEnabled": card?.isEnabled,
                                    _id: card?._id,
                                })
                                setIsFollowUp(true)
                            }} key={card.id}>
                                <Tooltip arrow placement="top" title={card.isEnabled ? 'Active' : 'Inactive'}>
                                    <Dot isActive={card.isEnabled} />
                                </Tooltip>
                                <Icon>
                                    <FiSend />
                                </Icon>
                                <CardTitle>{`Follow-up with clients ${card?.triggerDays} days after estimate are sent`}</CardTitle>
                            </Card>
                        ))}
                        {
                            columns?.length < 1 && <Card>
                                <Icon>
                                    <FiInfo />
                                </Icon>
                                <CardTitle>{`No follow-up for estimates`}</CardTitle>
                            </Card>
                        }
                        <CreateNew onClick={() => {
                            setIsFollowUp(true)
                            setVaule({
                                "name": "Reminder for Invoice",
                                "subject": "Upcoming Estimate",
                                "body": `Hi {{FIRST_NAME}},
                            
We just wanted to send a quick note to see if you had a chance to look at the quote we sent recently? We're happy to answer any questions you might have.
We're excited to get to work, so if you'd like to proceed let us know and we can get started!
                                
Thanks,
                                    
{{COMPANY_NAME}}
{{PHONE_NUMBER}}`,
                                "textBody": `Hi {{FIRST_NAME}}, we’re following up on the quote we sent. Let us know if you have any questions!`,
                                "type": ["email", "text"],
                                "isEnabled": false,
                                "category": "quote"

                            })
                        }} aria-label="Create New Customer">
                            <span style={{ marginRight: '5px' }}>+ </span> Create a new Estimate
                        </CreateNew>
                    </Column>


                    <Column>
                        <ColumnHeader>
                            <span>{"Invoices"}</span>
                            <span>{invoice?.length}</span>
                        </ColumnHeader>
                        <SubHeader>{"Keep track of your invoices and follow-ups."}</SubHeader>
                        {invoice?.map((card) => (
                            <Card onClick={() => {
                                setVaule({
                                    "name": "Reminder for Invoice",
                                    "subject": card?.subject || '',
                                    "body": card?.body || '',
                                    "triggerDays": card?.triggerDays,
                                    "category": "invoice",
                                    "type": card?.type,
                                    "isEnabled": card?.isEnabled,
                                    _id: card?._id,
                                })
                                setIsFollowUp(true)
                            }} key={card.id}>
                                <Tooltip arrow placement="top" title={card.isEnabled ? 'Active' : 'Inactive'}>
                                    <Dot isActive={card.isEnabled} />
                                </Tooltip>
                                <Icon>
                                    <FiSend />
                                </Icon>
                                <CardTitle>{`Follow-up with clients ${card?.triggerDays} days after invoice are sent`}</CardTitle>
                            </Card>
                        ))}
                        {
                            invoice?.length < 1 && <Card>
                                <Icon>
                                    <FiInfo />
                                </Icon>
                                <CardTitle>{`No follow-up for invoices`}</CardTitle>
                            </Card>
                        }
                        <CreateNew
                            onClick={() => {
                                setIsFollowUp(true)
                                setVaule({
                                    "name": "Reminder for Invoice",
                                    "subject": "Upcoming Invoice Due",
                                    "body": `Hi {{FIRST_NAME}},
                            
We just wanted to send a quick note to see if you had a chance to look at the quote we sent recently? We're happy to answer any questions you might have.
We're excited to get to work, so if you'd like to proceed let us know and we can get started!
                                
Thanks,
                                    
{{COMPANY_NAME}}
{{PHONE_NUMBER}}`,
                                    "textBody": `Hi {{FIRST_NAME}}, we’re following up on the quote we sent. Let us know if you have any questions!`,
                                    "type": ["email", "text"],
                                    "isEnabled": false,
                                    "category": "invoice"

                                })
                            }}
                            aria-label="Create New Customer">
                            <span style={{ marginRight: '5px' }}>+ </span> Create a new invoice
                        </CreateNew>
                    </Column>

                </div> */}
        {isFollowUp && (
          <RightModal
            isOpen={isFollowUp}
            customStyle={{ padding: 0 }}
            onClose={() => setIsFollowUp(false)}
          >
            <FollowUpCard
              onClickMessageChange={() => setIsMessage(true)}
              message={value}
              type={value?.category}
              onsubmit={async (val) => {
                await onPress(val);
              }}
              handleDelete={async (val) => {
                await disableTemplate(val);
              }}
            />
          </RightModal>
        )}
        {isMessage && (
          <Modal
            customStyle={{ width: "65%", maxWidth: "950px", padding: 0 }}
            onClose={() => setIsMessage(false)}
          >
            <DynamicTemplate
              messages={value}
              setMessages={(val) => {
                setVaule({
                  ...value,
                  body: val?.body,
                  subject: val?.subject,
                  textBody: val?.textBody,
                  type: val?.type,
                });
                setIsMessage(false);
              }}
              onCancel={() => setIsMessage(false)}
              type={value?.category}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default AutomationsPage;
const AddButton = styled.button`
  border: 1px solid #0f52ba;
  margin-top: 5px;

  justify-content: center;
  display: flex;
  padding: 7px 12px;
  border-radius: 20px;
  cursor: pointer;
  background: #fff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: rgba(15, 82, 186, 0.1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
`;
const ButtonText = styled.h4`
  text-align: start;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0f52ba;
  cursor: pointer;
`;

const StyledPlayer = styled.div`
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;

  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 30px;
    max-width: 30px;
  }
`;
