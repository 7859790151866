import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { calculateTotalTaxAmount, putCommaInvalue, } from "../../../utils/Commonfunction";
import { Checkbox, Divider, IconButton, Snackbar, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { palette } from "../../../utils/Palette";
import { AddCircleOutlined, ArrowDropDown, Cancel, DragIndicatorOutlined, LoginOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FiEdit, FiInfo, FiTrash2 } from "react-icons/fi";
import TaxSearch from "../../../Components/CustomerSearch/TaxSearch";
import InputPicker from "../../../ReuseableComponets/InputPicker";
import LineItemSearch from "../../../Components/CustomerSearch/LineItemSearch";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import TopModal from "../../../Components/TopModal";
import IOSSwitchComponent from "../../../ReuseableComponets/IOSSwitch";
import EditBundle from "../../../Components/Dashboard/Payments/EditBundle";

const LineItemTable = (props) => {
  const { type } = props;
  const mainRef = useRef(null);
  const addLineFieldRef = useRef(null);
  const navigate = useNavigate()
  const userdata = useSelector(state => state?.auth?.userData)
  const lineItem = useSelector(state => state?.auth?.userData?.lineItem)
  const bundleItem = useSelector(state => state?.auth?.userData?.bundleData)
  const detailContact = useSelector(state => state?.auth?.allContacts)
  const tax = useSelector(state => state?.auth?.userData?.tax)
  const numberOfQuote = useSelector(state => state?.auth?.userData?.numberOfQuotes)
  const [selectedImage, setSelectedImage] = useState(props?.value?.attachment || []);
  const [isFocused, setIsFocused] = useState(false)
  const [boolValue, setBoolValue] = useState({
    newItem: false
  })
  const [editInvoiceData, setEditInvoiceData] = useState(props?.value?.edit)
  const [modaBundleOpen, setmodaBundleOpen] = useState(false)
  const dispatch = useDispatch()
  const [discount, setDiscount] = useState(props?.discount || {
    value: '',
    type: '$'
  })
  const [deposit, setDeposit] = useState(props?.deposit || {
    value: '',
    type: '$'
  })
  console.log(props?.deposit)
  const [value, setValue] = useState({
    "name": props?.value?.name || "",
    "email": props?.value?.email || "",
    "address": props?.value?.address || "",
    "phoneNumber": props?.value?.customerPhoneNumber || props?.value?.number || props?.value?.to,
    "date": props?.value?.date || userdata?.date || new Date(),
    'quoteNumber': props?.value?.numberOfQuote || numberOfQuote + 1 || '',
    'currency': userdata?.value?.currency || 'CAD',
    "notesQuote": props?.value?.notesQuote || userdata?.notesQuote || "",
    deposit: type === 'Invoice' ? 0 : props?.deposit?.value || userdata?.deposit || 0,
    depositType: props?.deposit?.type || userdata?.depositType || "",
    discount: props?.discount?.value || userdata?.discount || 0,
    discountType: props?.discount?.type || userdata?.discountType || "",
    acceptPaymentOnline: props?.acceptPaymentOnline || false
  })
  const [editValue, setEditValue] = useState({
    "name": props?.value?.name || "",
    "email": props?.value?.email || "",
    "address": props?.value?.address || "",
    "phoneNumber": props?.value?.customerPhoneNumber || props?.value?.number || props?.value?.to,


  })


  const [addMaterialData, setAddMaterialData] = useState([])
  const [open1, setopen1] = useState(false)
  const [addOwnerData, setAddOwnerData] = useState(props?.items || [])
  const [invoicedata, setInvoiceData] = useState({
    "name": "",
    "price": "0",
    "description": "",
    "quantity": "1",
    "tax": "0",
    "taxNumber": '',
    "taxName": "no Tax",
    "optional": false
  })


  const [focusedRowIndex, setFocusedRowIndex] = useState(null);

  const handleInputFocus = (index) => {


    setFocusedRowIndex(index)
  };
  const handleInputBlur = () => setFocusedRowIndex(null);
  useEffect(() => {
    if (isFocused && addLineFieldRef.current && mainRef.current) {
      addLineFieldRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
    else {
      if (mainRef.current) {
        mainRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [isFocused]);

  const taxprices = (price, quantity) => {
    const cleanText = price?.toString()?.replace(/,/g, '');
    const taxes = parseFloat(price ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

    return taxes;
  }
  const DepositValue = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (value?.depositType === '$') {
      discountedTotalValue = parseFloat(value?.deposit);
      discountPercentage = (value?.deposit / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }, 0)?.toFixed(2) - discountvalue()) * 100)
    }

    else if (value?.depositType === '%') {
      discountedTotalValue = (([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }, 0)?.toFixed(2) - discountvalue()) * (value?.deposit / 100));
      discountPercentage = parseFloat(value?.deposit);
    }

    return discountedTotalValue.toFixed(2);
  };




  function validateInput(event) {
    if (event.key === 'e') {
      event.preventDefault();
    }
  }
  function validateInput1(event) {
    if (event.key === '-' || event.key === 'e' || event.key === '.') {
      event.preventDefault();
    }
  }
  function calculateMarkupPercentage(costPrice, unitPrice) {

    const markupPercentage = ((unitPrice - costPrice) / costPrice) * 100;
    return parseFloat(markupPercentage)?.toFixed(2);
  }
  const applyDiscount = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (value?.discountType === '$') {
      discountedTotalValue = parseFloat(value?.discount);
      discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }
      return accumulator;
       
      }, 0)?.toFixed(2)) * 100)
    }
    else if (value?.discountType === '%') {
      discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }
      return accumulator;
      }, 0)?.toFixed(2) * (value?.discount / 100));
      discountPercentage = parseFloat(value?.discount);
    }

    return `${discountedTotalValue.toFixed(2)}`;
  };
  const applyDiscountPercentage = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (value?.discountType === '$') {
      discountedTotalValue = parseFloat(value?.discount);
      discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }
      return accumulator;
       
      }, 0)?.toFixed(2)) * 100)
    }
    else if (value?.discountType === '%') {
      discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }
      return accumulator;
      }, 0)?.toFixed(2) * (value?.discount / 100));
      discountPercentage = parseFloat(value?.discount);
    }

    return `(${discountPercentage?.toFixed(2)} %)`;
  };
  const discountvalue = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (value?.discount) {
      if (value?.discountType === '$') {
        discountedTotalValue = parseFloat(value?.discount);
        discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
          if (!currentValue?.optional) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
        }
        return accumulator;
        }, 0)?.toFixed(2)) * 100)
      }

      else if (value?.discountType === '%') {
        discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
          if (!currentValue?.optional) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
        }
        return accumulator;
        }, 0)?.toFixed(2) * (value?.discount / 100));
        discountPercentage = parseFloat(value?.discount);
      }
    }
    return discountedTotalValue.toFixed(2);
  };
  const applyQuoteDeposit = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (value?.depositType === '$') {
      discountedTotalValue = parseFloat(value?.deposit);
      discountPercentage = (value?.deposit / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }
      return accumulator;
       
      }, 0)?.toFixed(2) - discountvalue()) * 100)
    }

    else if (value?.depositType === '%') {
      discountedTotalValue = (([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }
      return accumulator;
      }, 0)?.toFixed(2) - discountvalue()) * (value?.deposit / 100));
      discountPercentage = parseFloat(value?.deposit);
    }

    return `${discountedTotalValue.toFixed(2)} (${discountPercentage?.toFixed(2)} %)`;
  };
  // const applyDeposit = () => {
  //   var discountedTotalValue = 0
  //   let discountPercentage = 0;
  //   if (value?.depositType === '$') {
  //     discountedTotalValue = parseFloat(value?.deposit);
  //     discountPercentage = (value?.deposit / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
  //       return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
  //     }, 0)?.toFixed(2) - discountvalue()) * 100)
  //   }

  //   else if (value?.depositType === '%') {
  //     discountedTotalValue = (([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
  //       return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
  //     }, 0)?.toFixed(2) - discountvalue()) * (value?.deposit / 100));
  //     discountPercentage = parseFloat(value?.deposit);
  //   }

  //   return `${discountedTotalValue.toFixed(2)} (${discountPercentage?.toFixed(2)} %)`;
  // };
  // const DepositValue = () => {
  //     var discountedTotalValue = 0
  //     let discountPercentage = 0;
  //     if (value?.depositType === '$') {
  //         discountedTotalValue = parseFloat(value?.deposit);
  //         discountPercentage = (value?.deposit / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
  //             return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
  //         }, 0)?.toFixed(2) - discountvalue()) * 100)
  //     }

  //     else if (value?.depositType === '%') {
  //         discountedTotalValue = (([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
  //             return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
  //         }, 0)?.toFixed(2) - discountvalue()) * (value?.deposit / 100));
  //         discountPercentage = parseFloat(value?.deposit);
  //     }

  //     return discountedTotalValue.toFixed(2);
  // };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const draggedItem = addOwnerData[result.source.index];
    const remainingItems = [...addOwnerData];
    remainingItems.splice(result.source.index, 1);
    remainingItems.splice(result.destination.index, 0, draggedItem);

    setAddOwnerData(remainingItems);

    if (editInvoiceData) {
      setEditInvoiceData(false)
    }
  };
  const handleDragStart = () => {
    const focusedElement = document.activeElement;
    if (focusedElement && focusedElement.tagName === "INPUT") {
      focusedElement.blur();
    }
  };
  const calc = () => {
    const totalTax = {};
    const totalPrices = {};
    const discountAmount = parseFloat(discountvalue()) || 0;
    const items = flattenItems([...addOwnerData]);

    const totalOriginalPrice = items.reduce((acc, item) => {
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity);
      if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
        return acc;
      }
      return acc + (price * quantity);
    }, 0);
    items.forEach(item => {
      const taxName = item.taxName;
      const taxAmount = parseFloat(item.tax);
      const taxNumber = item?.taxNumber || '';
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity);

      if (item?.optional ||isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
        return;
      }

      const totalPrice = price * quantity;
      const weightage = totalPrice / totalOriginalPrice;
      const discountForItem = discountAmount * weightage;
      const discountedPrice = totalPrice - discountForItem;

      if (!isNaN(taxAmount) && taxAmount > 0) {
        const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2));

        if (totalTax.hasOwnProperty(taxName)) {
          totalTax[taxName].taxAmount += taxValue;
          totalTax[taxName].taxPercentage = taxAmount;
          totalTax[taxName].taxNumber = taxNumber;
          totalPrices[taxName] += discountedPrice;
        } else {
          totalTax[taxName] = {
            taxAmount: taxValue,
            taxName,
            taxPercentage: taxAmount,
            taxNumber
          };
          totalPrices[taxName] = discountedPrice;
        }
      }
    });

    const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
      taxName,
      taxAmount: parseFloat(taxAmount.toFixed(2)),
      taxNumber,
      taxPercentage,
      totalPrice: parseFloat(totalPrices[taxName].toFixed(2))
    }));

    return totalTaxArray
  };

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setopen1(false)}
      >
        <Cancel fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const flattenItems = (items) => {
    return items.reduce((acc, item) => {
      if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
        acc.push(...item.lineItems);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const calculateTotalAmount = (items) => {
    const flattenedItems = flattenItems(items);
    return `${([...flattenedItems].reduce(function (accumulator, currentValue) {
      if (!currentValue?.optional) {
        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
    }
    return accumulator;
    }, 0) - discountvalue() + calculateTotalTaxAmount([...flattenedItems], discountvalue()))?.toFixed(2)}`
  };


  const totalprice = (price, quantity, tax) => {

    const cleanText = price?.toString()?.replace(/,/g, '');
    const taxes = ((parseFloat(price ? price : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)
    const fromatedvalue = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) + taxes;


    return fromatedvalue
  }
  const handleInputChange = (index, field, value) => {
    const updatedLineItems = [...addOwnerData];
    updatedLineItems[index][field] = value;
    if (field === "price") {
      const unitPrice = updatedLineItems[index].unitPrice || 1;
      const markupPercentage = ((value - unitPrice) / unitPrice) * 100;
      updatedLineItems[index].markup = parseFloat(markupPercentage).toFixed(2);
    }
    else if (field === "unitPrice") {

      const markupPercentage = ((updatedLineItems[index].price - value) / value) * 100;
      updatedLineItems[index].markup = parseFloat(markupPercentage).toFixed(2);
    }

    setAddOwnerData(updatedLineItems);
    props?.setownerData(updatedLineItems)
  };

  const handleRemoveItem = (index) => {
    const updatedLineItems = addOwnerData.filter((_, idx) => idx !== index);
    setAddOwnerData(updatedLineItems);
    props?.setownerData(updatedLineItems)
  };
  const tooltipContent = (
    <div style={{ minWidth: '150px' }}>
      {calc()?.map((item, index) => (
        <div key={index} style={{ margin: '10px 0px', display: 'flex', justifyContent: 'space-between' }}>
          {item.taxName}
          <div> ${putCommaInvalue(parseFloat(item?.taxAmount)?.toFixed(2))}</div>
        </div>
      ))}
    </div>
  );
  const applyDeposit = () => {
    var discountedTotalValue = 0
    let discountPercentage = 0;
    if (deposit?.type === '$') {
      discountedTotalValue = parseFloat(deposit?.value);
      discountPercentage = (deposit?.value / ([...addOwnerData]?.reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }
      return accumulator;
       
      }, 0)?.toFixed(2) - discountvalue()) * 100)
    }

    else if (deposit?.type === '%') {
      discountedTotalValue = ((([...addOwnerData]).reduce(function (accumulator, currentValue) {
        if (!currentValue?.optional) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
      }
      return accumulator;
      }, 0)?.toFixed(2) - discountvalue()) * (deposit?.value / 100));
      discountPercentage = parseFloat(deposit?.value);
    }

    return discountedTotalValue.toFixed(2);
  };



  return (
    <>
      <div >
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId="selectedItems">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}

              >
                <Table   >
                  <thead>
                    <tr>
                      <th ></th>
                      <th style={{ maxWidth: '100px' }}>Items</th>
                      <th></th>
                      <th style={{ maxWidth: '100px' }}>Quantity</th>
                      <th >Price ($)</th>

                      <th style={{ maxWidth: '100px', textAlign: 'end' }}>Amount</th>
                      <th style={{ width: '20px', }}></th>
                    </tr>
                  </thead>


                  {addOwnerData?.map((item, index) =>
                    <Draggable key={item.name} draggableId={item.name} index={index}>
                      {(provided, snapshot) => (
                        <>
                          <tbody
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              top: 'auto',
                              left: 'auto',
                              userSelect: 'none',
                              marginBottom: '20px',
                              height: "120px",
                              background: snapshot.isDragging ? '#f3f3f3' : 'white',
                              width: '100%',
                              ...provided.draggableProps.style,
                            }}>


                            {item?.type === 'Bundle' ? <>

                              <tr

                                style={{
                                  backgroundColor: focusedRowIndex === index ? "#f1f6ff" : "transparent",
                                  borderBottom: "0px solid #fff",
                                  width: '100%'
                                }}>
                                <td style={{ cursor: "pointer" }}><div style={{ width: '1px' }}> <DragIndicatorOutlined style={{ fontSize: '18px', }} /></div></td>
                                <td style={{ maxWidth: '120px' }}> <Inputbox
                                  type="text"
                                  placeholder='item name'
                                  onFocus={() => handleInputFocus(index)}
                                  onBlur={handleInputBlur}
                                  readOnly={props?.isEditid ? true : false}
                                  value={item.name}
                                  autoFocus={props?.isEditid ? false : index === addOwnerData?.length - 1 && true}
                                  onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                  style={{ width: '96%', paddingLeft: '10px' }}

                                /></td>
                                <td colSpan={'3'}> <InputText
                                  type="text"
                                  placeholder='Description'
                                  value={item.description}
                                  onChange={(e) => handleInputChange(index, "description", e.target.value)}
                                  style={{ paddingLeft: '10px', width: '100%' }}
                                  readOnly={props?.isEditid ? true : false}
                                  onFocus={() => handleInputFocus(index)}
                                  onBlur={handleInputBlur}
                                /></td>

                                <td style={{ maxWidth: '100px', textAlign: 'end' }}>${putCommaInvalue(calculateTotalAmount([...item?.lineItems]))}</td>
                                <td onClick={() => handleRemoveItem(index)} style={{ cursor: "pointer" }}><TrashIcon /></td>
                              </tr>
                              <tr style={{
                                backgroundColor: focusedRowIndex === index ? "#f1f6ff" : "transparent",
                              }}>

                                <td colSpan={"2"}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <label style={{ marginRight: '20px' }}>Type</label> <InputPicker
                                      value={item?.type}
                                      style={{ width: '100%', height: '40px', marginLeft: '0px', backgroundColor: '#fff' }}
                                      readOnly={props?.isEditid ? true : false}
                                      label='Type'
                                      onChange={(val) => handleInputChange(index, "type", val)}
                                      onFocus={() => handleInputFocus(index)}
                                      onBlur={handleInputBlur}
                                    />
                                  </div>
                                </td>
                                <td colSpan="3"  >
                                  <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <label style={{ marginRight: '20px', width: '150px' }}>Category Visibility</label>
                                    <SelectDrpoDown id="category-visibility" value={item?.categoryVisiblity}
                                      readOnly={props?.isEditid ? true : false}
                                      disabled={props?.isEditid ? true : false}
                                      onChange={(event) => {
                                        handleInputChange(index, "categoryVisiblity", event.target.value)


                                      }}>
                                      <Option value="Items and item prices are visible to your customer">
                                        Items and item prices are visible to your customer
                                      </Option>
                                      <Option value="Items are NOT visible to your customer">
                                        Items are NOT visible to your customer
                                      </Option>
                                      <Option value="Items are visible, but item prices are NOT visible to your customer">
                                        Items are visible, but item prices are NOT visible to your customer
                                      </Option>
                                    </SelectDrpoDown>

                                  </div>
                                </td>

                                <td colSpan="2" >
                                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    {!props?.isEditid && <ActionIcons>
                                      <Tooltip title={
                                        <div style={{ minWidth: '150px' }}>
                                          {item?.lineItems?.map((itemvals, index) => (
                                            <div key={index} style={{ margin: '10px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                              {itemvals?.name}
                                              <div> ${putCommaInvalue(parseFloat(totalprice(itemvals?.price || '1', itemvals?.quantity || '1', itemvals?.tax))?.toFixed(2))}</div>
                                            </div>
                                          ))}
                                        </div>
                                      } placement="top" arrow >
                                        <VisibilityOutlined />
                                      </Tooltip>
                                      <FiEdit onClick={() => {
                                        setInvoiceData({ ...item, index: index })
                                        setmodaBundleOpen(true)
                                      }} style={{ marginRight: '10px', marginTop: '1.5px' }} />
                                    </ActionIcons>}
                                    <Tooltip title='Add-on (optional)' placement="top" arrow >
                                      <div>
                                        <IOSSwitchComponent
                                          checked={item.optional}
                                          onChange={(e) => handleInputChange(index, "optional", e.target.checked)}


                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </td>

                              </tr>


                            </>
                              :

                              <>
                                <tr

                                  style={{
                                    backgroundColor: focusedRowIndex === index ? "#f1f6ff" : "transparent",
                                    borderBottom: "0px solid #fff",
                                  }}>
                                  <td style={{ cursor: "pointer" }}><div style={{ width: '1px' }}> <DragIndicatorOutlined style={{ fontSize: '18px', }} /></div></td>
                                  <td style={{ maxWidth: '120px' }}> <Inputbox
                                    type="text"
                                    placeholder='item name'
                                    onFocus={() => handleInputFocus(index)}
                                    onBlur={handleInputBlur}
                                    value={item.name}
                                    readOnly={props?.isEditid ? true : false}
                                    autoFocus={props?.isEditid ? false : index === addOwnerData?.length - 1 && true}
                                    onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                    style={{ width: '96%', paddingLeft: '10px' }}



                                  /></td>
                                  <td> <InputText
                                    type="text"
                                    placeholder='Description'
                                    value={item.description}
                                    onChange={(e) => handleInputChange(index, "description", e.target.value)}
                                    style={{ paddingLeft: '10px' }}
                                    readOnly={props?.isEditid ? true : false}
                                    onFocus={() => handleInputFocus(index)}
                                    onBlur={handleInputBlur}

                                  /></td>
                                  <td style={{ maxWidth: '50px' }}> <Inputbox
                                    type='number'
                                    onKeyDown={validateInput1}
                                    value={item.quantity}
                                    onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                    style={{ width: '91%', paddingLeft: '10px' }}
                                    onFocus={() => handleInputFocus(index)}
                                    readOnly={props?.isEditid ? true : false}
                                    onBlur={handleInputBlur}
                                  /></td>
                                  <td style={{ maxWidth: '50px' }}> <Inputbox
                                    type='number' placeholder='Price' min='0'
                                    onKeyDown={validateInput}
                                    value={item.price}
                                    onChange={(e) => handleInputChange(index, "price", e.target.value)}
                                    style={{ width: '91%', paddingLeft: '10px' }}
                                    onFocus={() => handleInputFocus(index)}
                                    readOnly={props?.isEditid ? true : false}
                                    onBlur={handleInputBlur}
                                  /></td>
                                  <td style={{ maxWidth: '100px', textAlign: 'end' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</td>
                                  <td style={{ cursor: "pointer" }}>{!props?.isEditid && <TrashIcon onClick={() => handleRemoveItem(index)} />}</td>
                                </tr>
                                <tr style={{
                                  backgroundColor: focusedRowIndex === index ? "#f1f6ff" : "transparent",
                                }}>
                                  <td colSpan={'2'} style={{ maxWidth: '120px' }}><div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '10px' }}>Cost ($)</label>
                                    <div style={{ position: 'relative', width: '65%' }}>
                                      <Inputbox
                                        type="text"
                                        placeholder='Cost'
                                        value={item.unitPrice}
                                        onChange={(e) => handleInputChange(index, "unitPrice", e.target.value)}
                                        style={{ width: '94%' }}
                                        onFocus={() => handleInputFocus(index)}
                                        readOnly={props?.isEditid ? true : false}
                                        onBlur={handleInputBlur}

                                      />
                                    </div>
                                  </div>
                                  </td>
                                  <td colSpan="2">
                                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '20px' }}>Markup</label> <div style={{ position: 'relative', width: '100%' }}><Inputbox
                                      type="text"
                                      placeholder='Markup'
                                      value={item.markup}
                                      type='number'
                                      onKeyDown={validateInput1}
                                      onChange={(e) => handleInputChange(index, "markup", e.target.value)}
                                      onFocus={() => handleInputFocus(index)}
                                      onBlur={handleInputBlur}
                                      readOnly={props?.isEditid ? true : false}
                                      style={{ width: '96%', paddingLeft: '10px' }}

                                    />
                                      <PercentageSymbol>%</PercentageSymbol>
                                    </div>
                                      <label style={{ marginRight: '20px', marginLeft: '20px' }}>Type</label>
                                      <SelectDrpoDown
                                        id="category-visibility"
                                        value={item?.type}
                                        style={{ width: '100%', height: '40px', marginLeft: '0px' }}
                                        onChange={(event) => {
                                          handleInputChange(index, "type", event.target.value)


                                        }}
                                        onFocus={() => handleInputFocus(index)}
                                        readOnly={props?.isEditid ? true : false}
                                        disabled={props?.isEditid ? true : false}
                                        onBlur={handleInputBlur}
                                      >
                                        <Option disabled>Select</Option>
                                        <Option value="Service">
                                          Service
                                        </Option>
                                        <Option value="Material">
                                          Material
                                        </Option>
                                      </SelectDrpoDown>
                                    </div>
                                  </td>
                                  <td style={{ maxWidth: '150px' }} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                      <label style={{ marginRight: '20px', }}>Tax</label>

                                      <StyledSelect disabled={props?.isEditid ? true : false} onClick={() => !boolValue?.istax && setBoolValue({ ...boolValue, istax: true, index: index })}>
                                        {item?.tax ? `${item?.tax} %` : "Tax"}
                                        <ArrowDropDown />
                                        {(boolValue?.istax && index === boolValue?.index) && <TaxSearch

                                          Taxs={[{ name: 'No tax', value: '0' }, ...tax]}
                                          onSelect={(val) => {
                                            handleInputChange(index, "tax", val?.value)
                                            handleInputChange(index, "taxName", val?.name)
                                            handleInputChange(index, "taxNumber", val?.description)


                                          }}
                                          onclose={() => setBoolValue({ ...boolValue, istax: false })} />}
                                      </StyledSelect>
                                    </div>
                                  </td>
                                  {/* <td style={{ maxWidth: '100px', textAlign: 'end' }}>${putCommaInvalue(parseFloat(taxprice(item?.price, item?.quantity, item?.tax))?.toFixed(2))}</td> */}
                                  <td colSpan="2" >
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                      <label style={{ marginRight: '20px', marginTop: '3px' }}>Add-on</label>
                                      <Tooltip title='Add-on (optional)' placement="top" arrow >
                                        <div>
                                          <IOSSwitchComponent
                                            checked={item.optional}
                                            onChange={(e) => handleInputChange(index, "optional", e.target.checked)}
                                            disabled={props?.isEditid ? true : false}
                                          />
                                        </div>

                                        {/* <Checkbox
                    style={{ width: '20px', height: '42px' }}
                    checked={item.optional}
                    onChange={(e) => handleInputChange(index, "optional", e.target.checked)}

                  /> */}
                                      </Tooltip>
                                    </div>
                                  </td>

                                </tr>
                              </>}
                            {provided.placeholder}
                          </tbody>

                        </>
                      )}
                    </Draggable>





                  )}



                </Table>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!props?.isEditid && <ButtonContainer>
          <StyledButton
            onClick={() => {
              setBoolValue({ ...boolValue, isLineItem: true });

            }}
          >
            <AddCircleOutlined style={{ paddingBottom: '0px' }} /> <span>Add item</span>
          </StyledButton>

          <StyledButton
            marginLeft="20px"
            onClick={() => {
              setBoolValue({ ...boolValue, isbundleItem: true });
            }}
            style={{ color: '#000' }}
          >
            <LoginOutlined /> <span>Insert bundle</span>
          </StyledButton>

          {boolValue?.isLineItem && (
            <LineItemSearch
              customers={lineItem}
              onSelect={(newValue) => {
                const taxItem = tax.find(t => t.name === newValue.taxName);
                setAddOwnerData([...addOwnerData, { ...newValue, taxNumber: taxItem?.description, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) }])
                props?.setownerData([...addOwnerData, { ...newValue, taxNumber: taxItem?.description, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) }])
              }}
              onCreateNew={() => {
                setAddOwnerData([...addOwnerData, {
                  "name": "",
                  "price": "0",
                  "description": "",
                  "quantity": "1",
                  "tax": "0",
                  "taxNumber": '',
                  "taxName": "no Tax",
                  "optional": false
                }])
                setBoolValue({ ...boolValue, isLineItem: false })
              }}

              onclose={() => setBoolValue({ ...boolValue, isLineItem: false })}
            />
          )}
          {boolValue?.isbundleItem && (
            <LineItemSearch
              customers={bundleItem}
              onSelect={(newValue) => {
                setAddOwnerData([...addOwnerData, { ...newValue, lineItems: newValue?.lineItems, categoryVisiblity: 'Items and item prices are visible to your customer' }])
                props?.setownerData([...addOwnerData, { ...newValue, lineItems: newValue?.lineItems, categoryVisiblity: 'Items and item prices are visible to your customer' }])
              }}
              nonNewCreate={true}

              onclose={() => setBoolValue({ ...boolValue, isbundleItem: false })}
            />
          )}
        </ButtonContainer>}
        <Divider />
        {(userdata?.stripeAccountId && type === 'Invoice') && <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '20px', alignSelf: 'start', zIndex: 1 }}>
          <Checkbox
            style={{ width: '25px', marginRight: '10px', height: '25px', zIndex: '1px' }}
            checked={value.acceptPaymentOnline}
            onChange={() => {
              if (value.acceptPaymentOnline) {
                setValue({ ...value, acceptPaymentOnline: false })
                props?.setAllowPaymet(false)
              }
              else {
                setValue({ ...value, acceptPaymentOnline: true })
                props?.setAllowPaymet(true)

              }
            }
            }
          // key={index}
          // value={item?.email}
          // checked={day.includes(item?.email)}
          // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

          />
          <Label style={{ marginTop: '4px', fontSize: '14px', marginBottom: 0 }}>Allow online payment</Label>
        </div>}
        <SummaryWrapper>
          <SummaryRow>
            <SummaryLabel>Subtotal</SummaryLabel>
            <SummaryValue>{`$${putCommaInvalue([...addOwnerData].reduce(function (accumulator, currentValue) {
                     if (!currentValue?.optional) {
                      return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                  }
                  return accumulator;
            }, 0)?.toFixed(2))}`}</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>Discount {value?.discount?applyDiscountPercentage():''}</SummaryLabel>
            {props?.isEditid ? <SummaryValue>${applyDiscount()}</SummaryValue> : !value?.discount ?
              <SummaryLink onClick={() => setBoolValue({ ...boolValue, discount: true })}>{'Add Discount'}</SummaryLink>
              :
              <div style={{ display: 'flex' }}>
                <SummaryValue>${applyDiscount()}</SummaryValue>
                <TrashIcon style={{ marginLeft: '10px', fontSize: '16px' }} onClick={() => {
                  setValue({ ...value, discount: '', discountType: '$' })
                  setDiscount({ value: 0, type: '$' })
                  props?.setDiscount({ discount: 0, discountType: '$' })
                }} />
              </div>
            }
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>Tax</SummaryLabel>
            <Tooltip arrow placement="top" title={tooltipContent}>
              <div style={{ display: 'flex' }}>
                <SummaryValue>{`$${putCommaInvalue(calculateTotalTaxAmount(flattenItems([...addOwnerData]), discountvalue())?.toFixed(2))}`}</SummaryValue>
                <FiInfo style={{ marginLeft: '10px' }} />
              </div>
            </Tooltip>
          </SummaryRow>
          <SummaryTotal>
            <SummaryLabel>Total</SummaryLabel>
            <SummaryValue>{`$${putCommaInvalue(calculateTotalAmount(addOwnerData))}`}</SummaryValue>
          </SummaryTotal>
          {(props?.tips !== 'No Thanks' && props?.tips !== 0&&props?.tips) && <SummaryRow>
            <SummaryLabel>Tip</SummaryLabel>
            <SummaryValue>{`$${putCommaInvalue((calculateTotalAmount(addOwnerData) * ((props?.tips === 'No Thanks' ? 0 : props?.tips || 0) / 100)))}`}</SummaryValue>
          </SummaryRow>}
          <SummaryRow>
            <SummaryLabel>Required deposit</SummaryLabel>
            {
              type === 'Invoice' ? value?.deposit ? <div style={{ display: 'flex' }}>
                <SummaryValue>${applyDeposit()}</SummaryValue>
                <TrashIcon style={{ marginLeft: '10px', fontSize: '16px' }} onClick={() => {
                  setValue({ ...value, deposit: '', discountType: '$' })
                  props?.setDeposit({ discount: 0, discountType: '$' })
                }} />
              </div> :props?.isEditid? <SummaryValue>{`$${isNaN(applyDeposit())?'0.00':applyDeposit()}`}</SummaryValue>:
                <SummaryApplyLink
                  readOnly={props?.isEditid ? true : false}
                  disabled={props?.isEditid ? true : false}
                  value={value?.deposit ? JSON.stringify({
                    deposit: value?.deposit,
                    depositType: value?.depositType

                  }) :'Apply deposit'}
                  id="category-visibility"
                  onChange={(event) => {
                    const selectedValue = JSON.parse(event.target.value);
                    setValue({ ...value, deposit: selectedValue?.deposit, depositType: selectedValue?.depositType })
                    props?.setDeposit({ deposit: selectedValue?.deposit, depositType: selectedValue?.depositType })
                  }}>
                  <Option value="Apply deposit" disabled >
                    Apply deposit
                  </Option>
                  {deposit?.value && parseFloat(deposit?.value) > 0 && <Option value={JSON.stringify({ deposit: deposit?.value, depositType: deposit?.type })}>
                    ${applyDeposit()}
                  </Option>}

                </SummaryApplyLink> :
                props?.isEditid ? <SummaryValue>${applyDeposit()}</SummaryValue> : !value?.deposit ?
                  <SummaryLink onClick={() => {

                    if (userdata?.stripeAccountId !== '') {
                      setBoolValue({ ...boolValue, deposit: true })
                    }
                    else {
                      setopen1(true)
                    }
                  }}>{"Add Deposit"}</SummaryLink>
                  :
                  <div style={{ display: 'flex' }}>
                    <SummaryValue>${applyQuoteDeposit()}</SummaryValue>
                    <TrashIcon style={{ marginLeft: '10px', fontSize: '16px' }} onClick={() => {
                      setValue({ ...value, deposit: '', discountType: '$' })
                      setDeposit({ value: 0, type: '$' })
                      props?.setDeposit({ discount: 0, discountType: '$' })
                    }} />
                  </div>
            }

          </SummaryRow>
        </SummaryWrapper>
        {boolValue?.discount && <TopModal
          onClose={() => setBoolValue({ ...boolValue, discount: false })}
          overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
          customStyle={{ width: '400px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>
              {"Add Discount"}
            </h4>
            <Cancel onClick={() => {
              setBoolValue({ ...boolValue, discount: false })
            }} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex' }}>

            <ToggleButtonGroup
              value={discount?.type}
              exclusive
              onChange={(event, newAlignment) => {
                setDiscount({ ...discount, type: newAlignment });
              }}
              style={{ height: '34px', marginRight: '20px' }}
              aria-label="text alignment"
            >
              <ToggleButton value="$" aria-label="left aligned">
                $
              </ToggleButton>
              <ToggleButton value="%" aria-label="right aligned">
                %
              </ToggleButton>
            </ToggleButtonGroup>
            <Inputbox value={discount?.value} onChange={(e) => setDiscount({ ...discount, value: e.target.value })} style={{ width: '70%' }} placeholder="Discount" />

          </div>
          {discount?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
          }, 0)) < discount?.value && <Label style={{ textAlign: 'start', color: 'red', marginTop: '5px' }}>{"Discount is more than the sub total"}</Label>}
          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end', marginBottom: '20px' }}>
            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
              setBoolValue({ ...boolValue, discount: false })
            }}>Cancel</RippleButton>
            <RippleButton onClick={async () => {
              if (discount?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
              }, 0)) < discount?.value) {

              }
              else {
                setValue({ ...value, discount: discount?.value, discountType: discount?.type })
                props?.setDiscount({ discount: discount?.value, discountType: discount?.type })
                setBoolValue({ ...boolValue, discount: false })
              }
            }}>Save</RippleButton>
          </div>

        </TopModal>}
        {boolValue?.deposit && <TopModal
          onClose={() => setBoolValue({ ...boolValue, deposit: false })}
          overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
          customStyle={{ width: '400px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>
              {"Add deposit"}
            </h4>
            <Cancel onClick={() => {
              setBoolValue({ ...boolValue, deposit: false })
            }} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex' }}>

            <ToggleButtonGroup
              value={deposit?.type}
              exclusive
              onChange={(event, newAlignment) => {
                setDeposit({ ...deposit, type: newAlignment });
              }}
              style={{ height: '34px', marginRight: '20px' }}
              aria-label="text alignment"
            >
              <ToggleButton value="$" aria-label="left aligned">
                $
              </ToggleButton>
              <ToggleButton value="%" aria-label="right aligned">
                %
              </ToggleButton>
            </ToggleButtonGroup>
            <Inputbox value={deposit?.value} onChange={(e) => setDeposit({ ...deposit, value: e.target.value })} style={{ width: '70%' }} placeholder="Deposit" />

          </div>
          {deposit?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
          }, 0)) < deposit?.value && <Label style={{ textAlign: 'start', color: 'red', marginTop: '5px' }}>{"Deposit is more than the total"}</Label>}
          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end', marginBottom: '20px' }}>
            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
              setBoolValue({ ...boolValue, deposit: false })
            }}>Cancel</RippleButton>
            <RippleButton onClick={async () => {
              if (deposit?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
              }, 0)) < deposit?.value) {

              }
              else {
                setValue({ ...value, deposit: deposit?.value, depositType: deposit?.type })
                props?.setDeposit({ deposit: deposit?.value, depositType: deposit?.type })
                setBoolValue({ ...boolValue, deposit: false })
              }
            }}>Save</RippleButton>
          </div>

        </TopModal>}
        {modaBundleOpen && (
          <TopModal
            isOpen={modaBundleOpen}
            customStyle={{
              width: '47%', maxHeight: '98%', height: '100%', flexWrap: 'wrap', position: 'relative', overflow: 'hidden', paddingLeft: '0px',
              paddingRight: '0px',
            }}
            overlayStyle={{ paddingTop: '0px', alignItems: 'center' }}
            onClose={() => {
              setBoolValue({ ...boolValue, newItem: false, newBundle: false })
              setmodaBundleOpen(false)
            }}>
            <EditBundle
              {...props}
              value={{ ...invoicedata }}
              lineItem={lineItem}
              setModalOpenInvoice={() => {
                setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                setmodaBundleOpen(false)

              }}
              setValue={(val) => {
                setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                const updatedData = [...addOwnerData];
                updatedData[invoicedata?.index] = val;
                setAddOwnerData(updatedData);
                setInvoiceData({
                  "name": "",
                  "price": "",
                  "description": "",
                  "quantity": "1",
                  "tax": "0",
                  "taxName": "no Tax",
                  "taxNumber": ''
                })
                setmodaBundleOpen(false)


              }
              }
            />
          </TopModal>
        )}

      </div>
      <Snackbar

        open={open1}
        onClose={() => setopen1(false)}
        autoHideDuration={3000}
        message="Connect your bank account"
        style={{ zIndex: 100000000000 }}

        action={action}

        key={"top" + "right"}
      />
    </>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 4px;
  margin-top:20px;
 
  thead{
    background-color: #f1f1f1;
  }
  th,
  td {
    padding: 14px;
    text-align: left;
    border: 0px solid #fff;
  
    font-size:14px;
    label{
      font-size:13px;
    }
  }
  tr{
    border-bottom:1px solid #ddd;
  }
  th {
   
    font-weight: bold;
  }

  .add-item {
    color: #0f52ba;
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
  }
`;
const Inputbox = styled.input`
height: 35px;
font-size: 13px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
&:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const StyledSelect = styled.button`
  width: 100%;
 height:40px;
  font-size: 14px;
  
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  color: ${(props) => (props.disabled ? '#000' : '#000')};
  appearance: none;
  cursor: pointer;
  padding-left:10px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 12px 0;
  margin: 0 20px;
  justify-content: flex-start;
 
  position: relative;
`;

const StyledButton = styled.div`
  color:${palette.newBlue};
  display: flex;
  align-items:center;
  font-size:14px;
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  cursor: pointer;

 

  svg {
    margin-right: 10px;
    vertical-align: middle;
 
  }
  span {
    display: inline-block;
    vertical-align: middle; 
  }
  &:hover {
    opacity: 0.8;
  }
`;
const SummaryWrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  color: #333;
  align-self: flex-end; 
  margin-left: auto; 
  padding:15px 20px;
  padding-bottom:0px;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;

const SummaryLabel = styled.span`
  font-size: 14px;
`;

const SummaryValue = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const SummaryLink = styled.span`
  font-size: 14px;
  color: ${palette.newBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SummaryApplyLink = styled.select`
  font-size: 14px;
  color: ${palette.newBlue};
  cursor: pointer;
  border:none;
  appearance: none;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;
const SummaryTotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  padding-bottom:10px;
`;
const TrashIcon = styled(FiTrash2)`
  color: #0F52BA;
  font-size: 20px; 
  cursor: pointer;

  &:hover {
    color: red;
  }
`;
const Label = styled.label`
font-size: 13px;
font-weight: 500;
margin-bottom: 5px;
align-text:start;
display:flex;
margin-top:5px;
margin-left:3px;
align-self: flex-start;
font-family:Inter;
text-align:flex-start;


  
`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 39px; 
  height: 39px; 
  font-size: 13px;
  padding: 10px;
  line-height: 16px;
  word-wrap: break-word;
  width: 101%;
  display: block;
  border-radius: 4px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  

  resize: vertical;
  box-sizing: border-box;
  margin-top:0px;
  margin-right:0px;
  outline: none;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  
`;
const PercentageSymbol = styled.span`
  position: absolute;
  right:10px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 13px;
  color: grey;
  pointer-events: none; 
`;
const SelectDrpoDown = styled.select`
 height:40px;
  font-size: 13px;
  padding-left:10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  background: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  width:100%;
  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;


export default LineItemTable;
