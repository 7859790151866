import { EmailOutlined, MessageOutlined } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import CustomTabs from "../../../ReuseableComponets/CustomTab";
import { palette } from "../../../utils/Palette";
import { Menu, MenuItem, Typography, ListSubheader } from "@mui/material";
import { useSelector } from "react-redux";
import { formatMobileNumber } from "../../../utils/Commonfunction";
import { FiX } from "react-icons/fi";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import EmailTemplate from "./EmailPreview";





const DynamicTemplate = ({ messages, setMessages, onCancel, type }) => {
    console.log(messages,type)
    const [activeTab, setActiveTab] = useState(0);
    const userData = useSelector(state => state?.auth?.userData)
    const [message, setMessage] = useState(messages?.body || '');
    const [textMessage, setTextMessage] = useState(messages?.textBody || `We’re following up on the quote we sent. Let us know if you have any questions!`);
    const [subject, SetSubject] = useState(messages?.subject || "");
    const [anchorEl, setAnchorEl] = useState(null);
    const textareaRef = useRef(null);
    const textSubjectRef = useRef(null);
    const [lastFocusedField, setLastFocusedField] = useState(null);
    const [choice, setChoice] = useState(messages?.type|| ["email", "text"]);

    const variables = [
        {
            category: "General",
            items: [
                { key: "CURRENT_DATE", label: "Current Date", sample: "2024-12-26" },
            ],
        },
        {
            category: "Client",
            items: [
                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "NAME", label: "Name", sample: "John Doe" },
                { key: "FIRST_NAME", label: "First Name", sample: "Robert" },
                { key: "LAST_NAME", label: "Last Name", sample: "Prasher" },
            ],
        },
        {
            category: "Your Branding",
            items: [
                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "CONTACT_EMAIL", label: "Contact Email", sample: userData?.email },
                { key: "PHONE_NUMBER", label: "Phone Number", sample: formatMobileNumber(`+${localStorage.getItem('number')}`) },
            ],
        },
        {
            category: "Quote",
            items: [
                { key: "DEPOSIT_AMOUNT", label: "Deposit Amount", sample: "$500" },
                { key: "JOB_TITLE", label: "Job Title", sample: "Project Manager" },
                { key: "DISCOUNT_AMOUNT", label: "Discount Amount", sample: "$50" },
                { key: "ADDRESS", label: "Address", sample: "123 Main St, City, Country" },
                { key: "QUOTE_NUMBER", label: "Quote Number", sample: "#12345" },
                { key: "SENT_DATE", label: "Quote Sent Date", sample: "2024-12-20" },
                { key: "TOTAL", label: "Quote Total", sample: "$2,000" },

            ],
        },
    ];
    const variablesJob = [
        {
            category: "General",
            items: [
                { key: "CURRENT_DATE", label: "Current Date", sample: "2024-12-26" },
            ],
        },
        {
            category: "Client",
            items: [
                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "NAME", label: "Name", sample: "John Doe" },
                { key: "FIRST_NAME", label: "First Name", sample: "Robert" },
                { key: "LAST_NAME", label: "Last Name", sample: "Prasher" },
            ],
        },
        {
            category: "Your Branding",
            items: [
                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "CONTACT_EMAIL", label: "Contact Email", sample: userData?.email },
                { key: "PHONE_NUMBER", label: "Phone Number", sample: formatMobileNumber(`+${localStorage.getItem('number')}`) },
            ],
        },
        {
            category: "Job",
            items: [
                { key: "JOB_NUMBER", label: "Job Number", sample: "#56789" },
                { key: "JOB_DATE", label: "Job Date", sample: "2024-12-25" },
                { key: "JOB_TIME", label: "Job Time", sample: "10:00 AM" },
                { key: "JOB_NAME", label: "Job Name", sample: "Construction Project" },
                { key: "JOB_ADDRESS", label: "Job Address", sample: "456 Elm St, City, Country" },
            ],
        }
    ];
    const invoiceVariables = [
        {
            category: "General",
            items: [
                { key: "CURRENT_DATE", label: "Current Date", sample: "2024-12-26" },
            ],
        },
        {
            category: "Client",
            items: [

                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "NAME", label: "Name", sample: "John Doe" },
                { key: "FIRST_NAME", label: "First Name", sample: "Robert" },
                { key: "LAST_NAME", label: "Last Name", sample: "Prasher" },

            ],
        },
        {
            category: "Your Branding",
            items: [
                { key: "COMPANY_NAME", label: "Company Name", sample: userData?.businessName },
                { key: "CONTACT_EMAIL", label: "Contact Email", sample: userData?.email },
                { key: "PHONE_NUMBER", label: "Phone Number", sample: formatMobileNumber(`+${localStorage.getItem('number')}`) },
            ],
        },
        {
            category: "Invoice",
            items: [
                { key: "INVOICE_NUMBER", label: "Invoice Number", sample: "#INV-12345" },
                { key: "ISSUE_DATE", label: "Invoice Issue Date", sample: "2024-12-20" },
                { key: "DUE_DATE", label: "Invoice Due Date", sample: "2025-01-20" },
                { key: "TOTAL_AMOUNT", label: "Total Amount", sample: "$2,500" },
                { key: "DISCOUNT_AMOUNT", label: "Discount Amount", sample: "$50" },
                { key: "TAX_AMOUNT", label: "Tax Amount", sample: "$250" },

            ],
        },
    ];

    const handleInsertVariable = (item) => {
        const variableSyntax = `{{${item}}}`;
        const textarea = textareaRef.current;
        const textSubject = textSubjectRef.current;

        if (lastFocusedField === "message") {
            const cursorPosition = textarea.selectionStart;
            const beforeCursor = message.slice(0, cursorPosition);
            const afterCursor = message.slice(cursorPosition);

            const updatedMessage = `${beforeCursor}${variableSyntax}${afterCursor}`;
            setMessage(updatedMessage);

            setTimeout(() => {
                textarea.setSelectionRange(
                    cursorPosition + variableSyntax.length,
                    cursorPosition + variableSyntax.length
                );
                textarea.focus();
            }, 0);
        } else if (lastFocusedField === "subject") {
            const cursorPosition = textSubject.selectionStart;
            const beforeCursor = subject.slice(0, cursorPosition);
            const afterCursor = subject.slice(cursorPosition);

            const updatedSubject = `${beforeCursor}${variableSyntax}${afterCursor}`;
            SetSubject(updatedSubject);

            setTimeout(() => {
                textSubject.setSelectionRange(
                    cursorPosition + variableSyntax.length,
                    cursorPosition + variableSyntax.length
                );
                textSubject.focus();
            }, 0);
        }
        else if (lastFocusedField === "textMessage") {
            const cursorPosition = textarea.selectionStart;
            const beforeCursor = textMessage.slice(0, cursorPosition);
            const afterCursor = textMessage.slice(cursorPosition);
            setTextMessage(`${beforeCursor}${variableSyntax}${afterCursor}`);
            textarea.setSelectionRange(cursorPosition + variableSyntax.length, cursorPosition + variableSyntax.length);
            textarea.focus();
        }
    };

    const replaceVariablesWithSamples = (message) => {
        let updatedMessage = message;

        (type === 'invoice' ? invoiceVariables :type==='job'?variablesJob: variables).forEach((group) => {
            group.items.forEach((item) => {
                const variableSyntax = `{{${item.key}}}`;
                if (updatedMessage.includes(variableSyntax)) {
                    updatedMessage = updatedMessage.replace(
                        new RegExp(variableSyntax, "g"),
                        item.sample
                    );
                }
            });
        });

        return updatedMessage;
    };
    return (
        <Container style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <Header>{type==='job'?`Follow-up for their upcoming jobs are scheduled`:`Follow-up for ${type==='invoice'?'invoice':'estimate'}`} <FiX onClick={() => onCancel()} style={{ marginRight: '10px', fontSize: '20px' }} /></Header>
            <SubHeader>
                Notifications are sent the same way the client's last {type} was sent,
                either by text or email.
            </SubHeader>
            <SelectDrpoDown
                id="category-visibility"
                value={JSON.stringify(choice)}
                style={{ width: '22%', height: '40px', marginLeft: '0px' }}
                onChange={(e) => setChoice(JSON.parse(e.target.value))} 
            >
                <Option disabled>Select</Option>
                <Option value={JSON.stringify(["email", "text"])}>
                    Email and text message
                </Option>
                <Option value={JSON.stringify(["text"])}>
                    Text message
                </Option>
                <Option value={JSON.stringify(["email"])}>
                    Email
                </Option>
            </SelectDrpoDown>
            <div style={{ display: 'flex',    flex: 1,
            overflowY: 'auto',
            marginBottom: '10px', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ width: '48%', marginRight: '40px' }}>
                    <CustomTabs
                        tabpanelWidth={'80%'}
                        activeValue={(val) => setActiveTab(val)}
                        boxStyle={{
                            p: 0, marginTop: '10px', marginBottom: '25px'
                        }}
                        tabsData={[
                            {
                                label: 'Email',
                                icon: <EmailOutlined style={{ fontSize: '16px' }} />,
                                content: <>
                                    <FieldContainer>
                                        <Label>Subject</Label>
                                        <InputBox
                                            ref={textSubjectRef}
                                            placeholder="Subject"
                                            value={subject}
                                            onChange={(e) => SetSubject(e.target.value)}
                                            onFocus={() => setLastFocusedField("subject")}
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <Label>Message</Label>
                                        <Input
                                            ref={textareaRef}
                                            value={message}
                                            onFocus={() => setLastFocusedField("message")}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Type your message here..."
                                        />
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <InsertButton color={'red'} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ marginTop: "10px", border: 'none' }}>
                                                    Reset
                                                </InsertButton>
                                                <InsertButton color={palette.newBlue} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ marginTop: "10px" }}>
                                                    Insert variable
                                                </InsertButton>
                                            </div>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}

                                                onClick={(event) => setAnchorEl(null)}
                                               
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        borderRadius: '4px',

                                                        minWidth: 180,

                                                        boxShadow:
                                                            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                                        '& .MuiMenu-list': {

                                                        },

                                                    },
                                                }}
                                                style={{ maxHeight: 350 }}
                                            >
                                                {(type === 'invoice' ? invoiceVariables :type==='job'?variablesJob: variables)?.map((group) => (
                                                    <React.Fragment key={group.category}>
                                                        <ListSubheader style={{ fontWeight: "500", fontSize: "14px", color: 'grey' }}>
                                                            {group.category}
                                                        </ListSubheader>
                                                        {group?.items?.map((item) => (
                                                            <MenuItem
                                                                key={item.key}
                                                                onClick={() => handleInsertVariable(item?.key)}
                                                                style={{ display: "flex", justifyContent: "space-between" }}
                                                            >
                                                                <span style={{ fontSize: '14px', fontWeight: '500' }}>{item.label}</span>

                                                            </MenuItem>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </Menu>
                                        </>

                                    </FieldContainer>
                                </>
                            },

                            {
                                label: 'Text message',
                                icon: <MessageOutlined style={{ fontSize: '16px' }} />,
                                content: <>
                                    <FieldContainer>
                                        <Label>Message</Label>
                                        <Input
                                            ref={textareaRef}
                                            value={textMessage}
                                            onChange={(e) => setTextMessage(e.target.value)}
                                            onFocus={() => setLastFocusedField("textMessage")}
                                            placeholder="Type your text message here..."
                                        />
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <InsertButton color={'red'} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ marginTop: "10px", border: 'none' }}>
                                                    Reset
                                                </InsertButton>
                                                <InsertButton color={palette.newBlue} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ marginTop: "10px" }}>
                                                    Insert variable
                                                </InsertButton>
                                            </div>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}

                                                onClick={(event) => setAnchorEl(null)}
                                              
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        borderRadius: '4px',

                                                        minWidth: 180,

                                                        boxShadow:
                                                            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                                        '& .MuiMenu-list': {

                                                        },

                                                    },
                                                }}
                                                style={{ maxHeight: 350 }}
                                            >
                                                {(type === 'invoice' ? invoiceVariables :type==='job'?variablesJob: variables)?.map((group) => (
                                                    <React.Fragment key={group.category}>
                                                        <ListSubheader style={{ fontWeight: "500", fontSize: "14px", color: 'grey' }}>
                                                            {group.category}
                                                        </ListSubheader>
                                                        {group?.items?.map((item) => (
                                                            <MenuItem
                                                                key={item.key}
                                                                onClick={() => handleInsertVariable(item?.key)}
                                                                style={{ display: "flex", justifyContent: "space-between" }}
                                                            >
                                                                <span style={{ fontSize: '14px', fontWeight: '500' }}>{item.label}</span>

                                                            </MenuItem>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </Menu>
                                        </>
                                    </FieldContainer>
                                </>
                            }

                        ]}
                    />

                </div>
                <div style={{ display: 'flex', width: '48%' }}>
                    <FieldContainer>
                        <Label style={{ marginTop: '15px' }}>Preview</Label>
                        {activeTab === 0 ? <EmailTemplate body={message} type={type}/> :

                            <InText>
                                <InChat>
                                    <Messagetext>{replaceVariablesWithSamples(`${textMessage} ${type==='job'?'':`\n\nTo review and approve click here https://app.getnotifi.com/${type}/zq37d9t8g5l`}`)}</Messagetext>

                                </InChat>
                                <div
                                    style={{
                                        position: "absolute",
                                        backgroundColor: '#F3F3F3',
                                        //backgroundColor:"red",
                                        width: 20,
                                        height: 15,
                                        bottom: -2,
                                        borderBottomRightRadius: 20,
                                        left: -10
                                    }}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#ffffff",
                                        //backgroundColor:"green",
                                        width: 20,
                                        height: 35,
                                        bottom: -6,
                                        borderBottomRightRadius: 18,
                                        left: -20

                                    }}
                                />

                            </InText>
                        }
                    </FieldContainer>
                </div>
            </div>
            <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', justifyContent: 'flex-end', }}>
                <RippleButton onClick={() => onCancel()} style={{ background: '#fff', color: '#000', border: 'none', marginRight: '15px' }} >Cancel</RippleButton>
                <RippleButton onClick={() => setMessages({ body: message, subject: subject, textBody: textMessage,type:choice })} style={{ marginRight: -15 }} >{"Save"}</RippleButton>
            </div>
        </Container>
    );
};

export default DynamicTemplate;


const Container = styled.div`

  margin: 20px auto;
  border-radius: 8px;
  padding:5px 20px;
  background: #fff;
`;

const Header = styled.h2`
  font-size: 18px;
  text-align:start;
  color: #333;
  margin-bottom: 10px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top:0px;
`;

const SubHeader = styled.p`
  font-size: 14px;
  color: #555;
  text-align:start;
  margin-bottom: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px 15px;
  font-size: 14px;
  color: ${(props) => (props.active ? "#0f52ba" : "#555")};
  border: none;
  background: ${(props) => (props.active ? "#fff" : "#f9f9f9")};
  border-bottom: ${(props) => (props.active ? "2px solid #0f52ba" : "none")};
  cursor: pointer;

  &:hover {
    color: #0f52ba;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
  max-width:auto;

  min-width:100%;
`;

const Label = styled.label`
font-size: 13px;
font-weight: 500;
margin-bottom: 5px;
align-text:start;
display:flex;
margin-top:5px;
margin-left:3px;
align-self: flex-start;
font-family:Inter;
text-align:flex-start;
`;

const Input = styled.textarea`
font-family: 'Inter', sans-serif; 
min-height: 39px; 
height: 140px; 
font-size: 13px;
padding: 10px;
line-height: 16px;
word-wrap: break-word;
width: 99%;
display: block;
border-radius: 4px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
margin-left:3px;


resize: vertical;
box-sizing: border-box;
margin-top:0px;
margin-right:0px;
outline: none;
&:focus {
  outline: 1.5px solid black;
  border: 0.5px solid ${palette.charcoleBlack};

}
::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}
`;
const InputBox = styled.input`
height: 35px;
font-size: 13px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
width:96%;
&:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`;

const PreviewBox = styled.div`
background:#F3F3F3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top:20px;
  padding: 15px;
  color: #333;
  font-family: "Inter", sans-serif;
  text-align:start;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;

  
`;

const Dropdown = styled.select`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
`;
const InsertButton = styled.button`
  display: flex;
  justify-content: center;
  margin: 3px;
  align-items: center;
  padding: 7px 15px;
  border-radius: 4px;

  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  font-size: 14px;
  color: ${(props) => props?.color};
  font-weight: 500;
  background-color: transparent;
  border: 1px solid ${(props) => props?.color};

  svg {
    font-size: 16px;
    color: ${(props) => props?.color};
    transition: color 0.3s ease;
  }

  &:hover {
    
    color: ${(props) => props?.color};
    svg {
      color: ${(props) => props?.color}; 
    }
  }
`;
const InText = styled.div`
display:flex;
justify-content:flex-start;
margin-bottom:15px;
margin-left:5px;
position:relative;
margin-top:20px;
.pink{
    background:pink;
    }
    .orange{
    background: #ff9900;
    }
    .violet{
    background:#974EDE;
    }
    .tooltip {
      visibility: hidden;
      width: auto;
      color: rgba(32, 32, 32, 0.6);
      text-align: center;
      display:flex;
      align-items:center;
      border-radius: 5px;
      padding: 5px;
      font-size:10px;
      margin-left: 10px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  
    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
`
const InChat = styled.div`
background:#F3F3F3;
border-radius:10px 10px 10px 10px;
padding:12px;
padding-top:0px;
padding-bottom:3px;
text-align:start;
max-width:100%;
word-break: break-word; 
overflow-wrap: anywhere; 
display: inline-block;
align-items:center;
margin-left:0px;
.intime{
color:rgb(70, 110, 255);
font-size:12px;
padding:0px;
margin-top:-8px;
}
`
const Messagetext = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;
`

const SelectDrpoDown = styled.select`
 height:40px;
  font-size: 13px;
  padding-left:10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  background: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  width:100%;
  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;
  display:flex;
  margin-bottom:15px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
