import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { getListForm } from "../../../store/Actions/dashboard.action";
import moment from "moment";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { formatMobileNumber } from "../../../utils/Commonfunction";
import { palette } from "../../../utils/Palette";
import { FiEdit, FiEdit2 } from "react-icons/fi";
import { FaInvision } from "react-icons/fa";
import { VisibilityOutlined } from "@mui/icons-material";
import { MdOutlineVisibility } from "react-icons/md";

const FormDataList = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const init = async () => {
    setLoader(true);
    const res = await dispatch(getListForm());
    setData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const getName = (channel) => {
    if (!channel) {
      return;
    }
    const val = channel?.split(" ");

    if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]
        ?.charAt(0)
        .toUpperCase()}`;
    } else {
      return `${val[0]?.charAt(0).toUpperCase()}`;
    }
  };
  return (
    <FlexboxContainer>
      {loader ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <Player
            src={require("../../../assets/loaders.json")}
            className="player"
            style={{ width: "200px", height: "200px" }}
            loop
            autoplay
          />
        </div>
      ) : (
        <CustomTable>
          <div
            style={{
              overflowY: "auto",
              height: "99vh",
              width: "100%",
              paddingBottom: "100px",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <CustomHead>
                <TableRow>
                  <TableCell size="small">
                    <div>Customer name</div>
                  </TableCell>
                  <TableCell className={"customcell"} size="small">
                    Phone number
                  </TableCell>
                  <TableCell
                    style={{
                      color: palette.black,
                      position: "relative",
                      fontWeight: "700",
                      maxWidth: "180px",
                    }}
                    size="small"
                  >
                    Customer address
                  </TableCell>
                  <TableCell className={"customcell"} size="small">
                    Form name
                  </TableCell>
                  <TableCell className={"customcell"} size="small">
                    Submitted at
                  </TableCell>
                  <TableCell size="small"></TableCell>
                </TableRow>
              </CustomHead>
              {data?.map((item) => (
                <TableBody
                  onClick={() => {
                    props?.setFormDetailModal(true);
                    props?.setSelectedData(item);
                  }}
                  key={item._id}
                  style={{ background: "#fff", cursor: "pointer" }}
                >
                  <TableRow>
                    <TableCell size="small">
                      {item?.customerDetails?.name}
                    </TableCell>
                    <TableCell size="small" style={{ width: "180px" }}>
                      {formatMobileNumber(item?.customerDetails?.to)}
                    </TableCell>
                    <TableCell
                      size="small"
                      style={{ maxWidth: "180px", textWrap: "wrap" }}
                    >
                      {item?.customerDetails?.address}
                    </TableCell>
                    <TableCell size="small">
                      {item?.header || "New request"}
                    </TableCell>
                    <TableCell size="small">
                      {moment(item?.createdAt)?.format("DD MMM hh:mm A")}
                    </TableCell>
                    <TableCell size="small">
                      <ActionIcons>
                        <FiEdit onClick={async () => {props?.openModal(item)}} />
                      </ActionIcons>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
            {data?.length > 0 ? null : (
              <h3
                style={{
                  color: "gray",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30vh",
                  marginBottom: "30vh",
                }}
              >
                No forms
              </h3>
            )}
          </div>
        </CustomTable>
      )}
    </FlexboxContainer>
  );
};

export default FormDataList;

const FlexboxContainer = styled.div`
  min-width: 84vw;
  background: #fff;
  display: flex;
  margin-left: -25px;
  margin-right: 30px;
  flex-direction: column;

  align-items: flex-start;
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left: 1px solid #f3f3f3;
    font-weight: 700;
    color: ${palette.newBlue};
    z-index: 1;
    min-width: 150px;
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background: #f8f8f8;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;
  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 150px;
  }
  .customcell {
    min-width: 150px;
    color: ${palette.black};
    font-weight: 700;
  }
`;
const ActionIcons = styled.div`
  display: flex;
  gap: 20px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
