
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from '@mui/material';
import styled from '@emotion/styled';


// Create Checkbox component
const Checkbox = ({ checked, onChange,customStyle, label,...props }) => {
    return (
        <CheckboxContainer style={customStyle}>
            <FormControlLabel
                control={
                    <MuiCheckbox
                   
                        checked={checked}
                        onChange={onChange}
                       
                        {...props}
                    />
                }
                label={<Typography style={{ fontSize: '14px',textAlign:'start' }}>{label}</Typography>}
                style={{ marginTop: '10px', }}
            />
        </CheckboxContainer>
    );
};

// Define PropTypes
Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default Checkbox;


// Define styled components
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left:10px;
  font-size:12px;
  align-self: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
