import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from '../utils/Palette';

const InputBox = ({ type, placeholder, value, header, onChange, headerStyle, error, ...props }) => {
    return (
        <div style={{ width: '100%' }}>
            {header && <Label style={headerStyle}>{header}</Label>}
            <StyledInputBox
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                error={error}
                {...props}
            />
            {error && <ErrorText>{error}</ErrorText>}
        </div>
    );
};

InputBox.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    header: PropTypes.string,
    headerStyle: PropTypes.object,
    error: PropTypes.string
};

InputBox.defaultProps = {
    type: 'text',
    placeholder: '',
    header: ''
};

export default InputBox;

const StyledInputBox = styled.input`
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  height: 20px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  color: #344054;
  background: #ffffff;
  border: ${({ error }) => (error ? '1.5px solid red' : '0.5px solid #d0d5dd')};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out;

  &:focus {
    outline: 1.5px solid ${({ error }) => (error ? 'red' : 'black')};
    border:  ${({ error }) => (error ? 'red' : palette.charcoleBlack)};
    background: ${({ error }) => (error ? '#ffe6e6' : '#ffffff')};
  }

  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;


const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    margin-left: 4px;
    color: ${palette.charcoleBlack};
    display: flex;
    margin-bottom: 8px;
    margin-top: 20px;
    align-self: flex-start;
    font-family: Inter;
`;

const ErrorText = styled.span`
    color: red;
    font-size: 12px;
    margin-top: 4px;
    display: block;
    text-align:start;
`;
